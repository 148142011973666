import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useMemo, useRef, useState } from "react";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import PopupModal from "./PopupModal";
import SyncOrgDataModal from "./SyncOrgDataModal";
import LoaderGif from "../../images/365 loading.gif";

const Step3 = (props) => {
    const [helpPopup, setHelpPopup] = useState(true);
    const [appContext] = useContext(AppContext);
    const [itemTypeValue, setItemType] = useState("");
    const [isRefreshed, setIsRefreshed] = useState(false);
    const [isSyncRefreshed, setIsSyncRefreshed] = useState(false);
    const [gridApi, setGridApi] = useState([]);
    const [syncPopup, setSyncPopup] = useState(true);

    //Popup form
    const [infoPopup, setInfoPopup] = useState(true);

    const validate = () => {
        props.nextStep();

    };

    function closeModal(params) {
        setHelpPopup(params);
    }

    //Ag Grid
    const rowHeight = 25;
    const headerHeight = 30;
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const gridRef = useRef();

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true
    }), []);

    const columnDefs = [
        { field: 'displayName', filter: true, headerName: 'Display Name', width: 220, },
        { field: 'name', filter: true, headerName: 'Name', width: 170, },
        { field: 'itemType', filter: true, headerName: 'Type', width: 160, },
        {
            field: 'mailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Mailbox" class='mail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;
                });
                return input;
            }
        },
        {
            field: 'archiveMailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Archive Mailbox" class='archivemail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;
                });
                return input;
            }
        },
        {
            field: 'onedrive',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Onedrive" class='onedrive-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'site',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Sharepoint" class='sharepoint-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'groupMailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Group Mailbox" class='groupmail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'groupSite',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Group Sharepoint" class='groupsite-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'teams',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Teams" class='teams-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        }
    ];

    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }

    //Pop Modal
    const handleClose = (params) => {
        setIsRefreshed(false);
        setInfoPopup(params);
    };

    const openPopup = (event) => {
        setIsRefreshed(true);
        const name = event.target.name;
        setItemType(name);

        return setInfoPopup(false);
    }

    //Sync Popup

    const handleSyncModalClose = (params) => {
        setIsSyncRefreshed(false);
        setSyncPopup(params);
    };

    const handleReSync = (event) => {
        setIsSyncRefreshed(true);
        setSyncPopup(false);
    }


    return (<div id="step2">
        <div>
            <div id="UpdatePanel1">
                <div className="card">
                    <h4 className="card-header ">
                        <span id="TitleLabel" className="SectionTitle">Exclude Items&nbsp;</span>
                        <a id="Job_btnHelp" style={{ float: 'right' }} onClick={() => {
                            let url = "https://cloudcover365.virtualdcs.co.uk/helpme/Step3-ExcludingItems.html";
                            window.open(url, '_blank');
                        }} ><i className="fad fa-question"></i></a>
                    </h4>
                    <div className="card-body ">
                        <table cellSpacing="0" cellPadding="0" id="Wizard1" style={{ height: "100%", width: "100%", borderCollapse: "collapse" }} >
                            <tbody>
                                <tr style={{ height: "100%" }} >
                                    <td>
                                        <div>
                                            <div id="Wizard1_SelectedPanel" style={{ borderStyle: "None", height: "210px", width: "100%" }} >
                                                <div>
                                                    <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 210, borderCollapse: "collapse" }} >
                                                        {/*<input type="search" placeholder="search..." onChange={onFilterTextChange} className="form-control form-control-sm" />*/}
                                                        {
                                                            <AgGridReact style={{ width: "80%" }}
                                                                ref={gridRef}
                                                                getRowStyle={getRowStyle}
                                                                onGridReady={onGridReady}
                                                                defaultColDef={defaultColDef}
                                                                rowSelection="single"
                                                                enableCellTextSelection="true"
                                                                rowData={appContext.excludedObjects}
                                                                columnDefs={columnDefs}
                                                                overlayLoadingTemplate={
                                                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                                                                overlayNoRowsTemplate={
                                                                    '<span className="ag-overlay-loading-center">No records to show</span>'
                                                                }
                                                                rowHeight={rowHeight}
                                                            >
                                                            </AgGridReact>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <table cellSpacing="5" cellPadding="5">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <button name="user" value="Exclude User(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" > Exclude User(s) </button>
                                                            </td>
                                                            <td>
                                                                <button name="group" value="Exclude Group(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" >Exclude Group(s)</button>
                                                            </td>
                                                            <td>
                                                                <button name="site" value="Exclude Site(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" > Exclude Site(s) </button>
                                                            </td>
                                                            <td>
                                                                <button name="team" value="Exclude Team(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" >Exclude Team(s)</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <PopupModal displayModal={!infoPopup} closeModal={handleClose} itemType={itemTypeValue} popupType="ExcludeObjects" refresh={isRefreshed} />
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer p-0">
                        <table cellSpacing="5" cellPadding="5" align="right">
                            <tbody>
                                <tr>
                                    <td align="left">
                                        <button onClick={handleReSync} title="Synchronisation of Microsoft organization objects with the organization cache database" className="btn btn-primary btn-sm">Re-Sync</button>
                                    </td>
                                    <td align="right">
                                        <ActionButtons {...props} nextStep={validate} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <SyncOrgDataModal displayModal={!syncPopup} closeModal={handleSyncModalClose} refresh={isSyncRefreshed} />
        {/*<HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step3-ExcludingItems.html" />*/}
    </div >
    );
}
export default Step3;
