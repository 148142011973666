import React, { useState, useEffect, useContext } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import "../../CSS/general.css";
import Step1 from "../AdditionLineControl/Step1";
import Step2 from "../AdditionLineControl/Step2";
import Step3 from "../AdditionLineControl/Step3";
import Step4 from "../AdditionLineControl/Step4";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import { useHistory } from 'react-router-dom';

const AdditionLineControlModal = props => {
    const [stepWizrd, setStepWizard] = useState(null);
    const [additionLines, setAdditionLines] = useState(null);
    const [uniqueAdditionLines, setUniqueAdditionLines] = useState([]);
    const [companies, setCompanies] = useState(null);
    const [companyContacts, setCompanyContacts] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [helpPopup, setHelpPopup] = useState(true);
    const { setAuthContext } = useContext(AuthenticationContext);
    const [agreements, setAgreements] = useState(null);
    const [typesIds, setTypesIds] = useState(null);
    const assignStepWizard = (instance) => {

        setStepWizard(instance);
    };
    const history = useHistory();
    function closeHelpModal(params) {
        setHelpPopup(params);
    }

    const handleComplete = () => {
        props.closeModal(true);
    };

    const closeJobControlPopup = () => {
        stepWizrd.goToStep(1);
        stepWizrd.isActive = false;

        props.closeModal(true);
    }

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"

    };

    const closePopupModal = () => {

        closeJobControlPopup();
        props.closeModal(true);
    }

    useEffect(() => {

        getAddditionLine();

       
    }, [])
    

    const updateAggreements = (uniqueLines) => {

        setUniqueAdditionLines(uniqueLines);
    };

    
   async function getAddditionLine()
    {
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
       await Axios.get(API_ROUTE + Routes.GET_CONNECTWISE_TYPEIDS, config)
           .then((response) => {

               if (response && response.data) {
                   let responsedata = response.data;

                   setTypesIds(responsedata);

               }

           })
           .catch((reason) => {


           });

        await Axios.get(API_ROUTE + Routes.GET_ADDITION_LINES, config)
            .then((response) => {
              
                if (response && response.data) {
                    let responsedata = response.data;

                    setAdditionLines(responsedata);

                }

            })
            .catch((reason) => {


            });

       await Axios.get(API_ROUTE + Routes.GET_CONNECTWISE_COMPANIES, config)
           .then((response) => {
       
               if (response && response.data) {
                   let responsedata = response.data;
                   setCompanies(responsedata);

               }

           })
           .catch((reason) => {


           });

       await Axios.get(API_ROUTE + Routes.GET_CONNECTWISE_AGREEMENTS, config)
           .then((response) => {

               if (response && response.data) {
                   let responsedata = response.data;

                   setAgreements(responsedata);

               }

           })
           .catch((reason) => {
               if (reason?.response?.status === 403) {
                   history.push('/Unauthorized'); // Redirect on 401
               }

           });


    }

    return (
        <div >
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
               
            >
                <div style={{ width: "99%", display: 'flex', justifyContent: 'center' }} >
                    <div className="modal-content" style={{ width: "99%" }} >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <span className="SectionTitle">ConnectWise-Maintenance</span>
                               
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closePopupModal}></button>
                        </div>
                        <div className="modal-body">

                            <div>
                                <div className="horizontal-stepper">
                                    <div className={`h-step ${activeStep === 0 ? " active" : (activeStep > 0 ? " completed" : "")}`}>
                                        <div className="circle">
                                            <span>1</span>
                                        </div>
                                        <div className="content">Company Check</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 1 ? " active" : (activeStep > 1 ? " completed" : "")}`}>
                                        <div className="circle"><span>2</span></div>
                                        <div className="content">Check Contacts</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 2 ? " active" : (activeStep > 2 ? " completed" : "")}`}>
                                        <div className="circle"><span>3</span></div>
                                        <div className="content">Created Agreements</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 3 ? " active" : (activeStep > 3 ? " completed" : "")}`}>
                                        <div className="circle"><span>4</span></div>
                                        <div className="content">Update Additions</div>
                                    </div>
                                </div>
                            </div>
                                <StepWizard isLazyMount instance={assignStepWizard} currentStep={activeStep} onStepChange={handleStepChange}>

                                <Step1 closePopupModal={closePopupModal} companies={companies} agreements={agreements} />
                                <Step2 closePopupModal={closePopupModal} updateAggreements={updateAggreements} typesIds={typesIds} />
                                <Step3 closePopupModal={closePopupModal} uniqueAdditionLines={uniqueAdditionLines}  />
                                <Step4 closePopupModal={closePopupModal}  />

                                </StepWizard>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
            <HelpModal displayModal={!helpPopup} closeModal={closeHelpModal} about="JobManagement.html" />

        </div>
    );
};

export default AdditionLineControlModal;
