
import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import Axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from "reactstrap/lib/FormGroup";
import Form from "reactstrap/lib/Form";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import moment from "moment";
import { NavBar } from "../NavBar";
import { id } from "date-fns/locale";
import { isThursday } from "date-fns";
import { AgGridReact } from 'ag-grid-react';
import { Spinner, Button } from "reactstrap";
import DateTimePicker from 'react-datetime-picker';
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif";

function AdminLogs(props) {
    const { setAuthContext } = useContext(AuthenticationContext);
    const authContext = useContext(AuthenticationContext);
    const [logs, setLogs] = useState();
    const [orgId, setOrgId] = useState("");
    const [source, setSource] = useState("");
    const [type, setType] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [gridApi, setGridApi] = useState(null);
    const [orgSelectItems, setOrgSelectItems] = useState([]);
    const [typeSelectItems, setTypeSelectItems] = useState([]);
    const [sourceSelectItems, setSourceSelectItems] = useState([]);
    const [logSlide, setLogSlide] = useState(true);

    const onGridReady = (params) => {
        setGridApi(params.api);
        getSelectItems();
        getLogs();        
    }
    const toggleSlide = () => {
        setLogSlide(prevLogSlide => !prevLogSlide);
    };

    const dateFormatter = (params) => {
        return moment(params.value).format("DD-MM-YYYY HH:mm:ss").toString();
    };
    const secFormatter = (params) => {
        const value = params.value;
        
        const match = value.match(/(\d+\.\d{1,3})/);

        if (match) {
            return (match[1]);
        } else {
            return '';
        }
        return '';
    };
    
    useEffect(() => {
        getLogs();
    }, [logSlide]);
    function onFromDatetimeChange(params) {
        setStartDate(params);
        setInitialLoad(false);
    }
    function onToDatetimeChange(params) {
        setEndDate(params);
        setInitialLoad(false);
    }
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    async function getSelectItems() {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        Axios.get(API_ROUTE + Routes.GET_SELECT_ITEMS, config)
            .then((response) => {
                let selectItems = response.data;
                var noOption = (
                    <option key="none" value={""}>
                        ---
                    </option>
                );
                let orgSelectItems = [];
                orgSelectItems.push(noOption);
                if (selectItems.orgSelect) {
                    if (selectItems.orgSelect.length > 0) {
                        for (var i = 0; i < selectItems.orgSelect.length; i++) {
                            const orgSelectItem = selectItems.orgSelect[i];
                            var selectItem = (
                                <option key={i} value={orgSelectItem.id}>
                                    {orgSelectItem.name}
                                </option>
                            );
                            orgSelectItems.push(selectItem);
                        }
                    }
                }
                let sourceSelectItems = [];
                sourceSelectItems.push(noOption);
                if (selectItems.sourceSelect) {
                    if (selectItems.sourceSelect.length > 0) {
                        for (var i = 0; i < selectItems.sourceSelect.length; i++) {
                            const sourceSelectItem = selectItems.sourceSelect[i];
                            var selectItem = (
                                <option key={i} value={sourceSelectItem}>
                                    {sourceSelectItem}
                                </option>
                            );
                            sourceSelectItems.push(selectItem);
                        }
                    }
                }
                let typeSelectItems = [];
                typeSelectItems.push(noOption);
                if (selectItems.typeSelect) {
                    if (selectItems.typeSelect.length > 0) {
                        for (var i = 0; i < selectItems.typeSelect.length; i++) {
                            const typeSelectItem = selectItems.typeSelect[i];
                            var selectItem = (
                                <option key={i} value={typeSelectItem}>
                                    {typeSelectItem}
                                </option>
                            );
                            typeSelectItems.push(selectItem);
                        }
                    }
                }
                setOrgSelectItems(orgSelectItems);
                setTypeSelectItems(typeSelectItems);
                setSourceSelectItems(sourceSelectItems);
            })
            .catch((reason) => {
            });
    }
    async function reset() {
        setOrgId("");
        setSource("");
        setType("");
        setInitialLoad(true);
        setStartDate(new Date());
        setEndDate(new Date());
        getSelectItems();
        getLogs();
    }
    async function getLogs() {
        if (gridApi)
            gridApi.showLoadingOverlay();
        var tokenString = await setAuthContext("", new Date());
        {
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            let data = {
                OrgId: orgId,
                StartDate: startDate,
                EndDate: endDate,
                Source: source,
                Type: type,
                isInitial: initialLoad,
                Is365Logs: logSlide,
            };
            await Axios.post(API_ROUTE + Routes.GET_ADMIN_LOGS, data, config)
                .then((response) => {

                    let logs = response.data;
                    setLogs(logs);
                    if (gridApi) {
                        gridApi.hideOverlay();
                        gridApi.sizeColumnsToFit();
                    }

                })
                .catch((reason) => {
                    if (gridApi) {
                        gridApi.hideOverlay();
                        gridApi.sizeColumnsToFit();
                    }

                });
        }
    }

    function onFilterTextChange(params) {
        gridApi.setQuickFilter(params.target.value);
        gridApi.sizeColumnsToFit();
    }

    function onBtnExport(params) {
        gridApi.exportDataAsCsv();
        gridApi.sizeColumnsToFit();

    }

    function handleOrgChange(event) {
        setInitialLoad(false);
        setOrgId(event.target.value);
        gridApi.sizeColumnsToFit();
    }
    function handleSourceChange(event) {
        setInitialLoad(false);
        setSource(event.target.value);
        gridApi.sizeColumnsToFit();
    }
    function handleTypeChange(event) {
        setInitialLoad(false);
        setType(event.target.value);
        gridApi.sizeColumnsToFit();
    }

    return (

        <div>
            <NavBar page="eventlogs" authContext={authContext} />
            <div className="row mb-1 event_log_selection">
                <div className="" style={{ display: 'contents' }}>
                    <Form>
                        <FormGroup className="ms-4 me-4">
                            <Label className="mb-2">From</Label>
                            <div>
                                <DateTimePicker
                                    onChange={onFromDatetimeChange}
                                    value={startDate}
                                    format={"dd-MM-yyyy HH:mm:ss"}
                                    disableClock={true}
                                />
                            </div>

                        </FormGroup>
                    </Form>
                </div>
                <div className="" style={{ display: 'contents'  }}>
                    <Form>
                        <FormGroup className="me-3">
                            <Label className="mb-2">To</Label>
                            <div>
                                <DateTimePicker
                                    onChange={onToDatetimeChange}
                                    value={endDate}
                                    format={"dd-MM-yyyy HH:mm:ss"}
                                    disableClock={true}
                                />
                            </div>
                        </FormGroup>
                    </Form>
                </div>
                <div className="col">
                    <Form>
                        <FormGroup>
                            <Label className="mb-2">Org</Label>
                            <Input
                                type="select"
                                name="orgSelect"
                                id="orgSelect"
                                className="form-select"
                                onChange={handleOrgChange}
                            //disabled={this.state.gettingLogs}
                            //value={this.state.orgId}
                            >
                                {orgSelectItems}
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className="col">
                    <Form>
                        <FormGroup>
                            <Label className="mb-2">Source</Label>
                            <Input
                                type="select"
                                name="sourceSelect"
                                id="sourceSelect"
                                className="form-select"
                                onChange={handleSourceChange}
                            //disabled={this.state.gettingLogs}
                            //value={this.state.source}
                            >
                                {sourceSelectItems}
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className="col">
                    <Form>
                        <FormGroup>
                            <Label className="mb-2">Type</Label>
                            <Input width="10"
                                type="select"
                                name="typeSelect"
                                id="typeSelect"
                                className="form-select"
                                onChange={handleTypeChange}
                            //disabled={this.state.gettingLogs}
                            //value={this.state.type}
                            >
                                {typeSelectItems}
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className="d-flex" style={{ width: '20rem' }}>
                    <Form className="me-1">
                        <FormGroup>
                            <div
                                className="spacer"
                                style={{ minHeight: "32px" }}
                            ></div>
                            <Button variant="primary" className="btn-primary"
                                style={{ borderColor: '#5191ce' }}
                                onClick={getLogs}
                            //disabled={this.state.gettingLogs}
                            >
                                Get Logs
                            </Button>
                        </FormGroup>
                    </Form>
                    <Form>
                        <FormGroup>
                            <div
                                className="spacer"
                                style={{ minHeight: "32px" }}
                            ></div>
                            <Button className="btn-primary"
                                style={{ borderColor: '#5191ce' }}
                                onClick={reset}
                            //disabled={this.state.gettingLogs}
                            >
                                Reset
                            </Button>
                            
                        </FormGroup>
                       
                    </Form>
                    <Form className="me-1">
                        <FormGroup>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>

                                <div
                                    onClick={toggleSlide}
                                    style={{
                                        width: '115px',
                                        height: '27px',
                                        borderRadius: '30px',
                                        backgroundColor: "white",
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        transition: 'background-color 0.3s ease',
                                        padding: '0 4px', // Adds space for the sliding knob
                                        border: '1px solid #29232338'
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: logSlide ? '3px' : '46%', // Moves the knob between states
                                            width: logSlide ?'50px' :'60px', // Adjusts width to maintain the sliding text appearance
                                            height: '23px',
                                            color: '#fff',
                                            fontSize: '10px',
                                            fontWeight: 'bold',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            lineHeight: 1,
                                            padding: '3px 0',
                                            backgroundColor:'#5191ce', 
                                            borderRadius: '15px', // Makes the sliding knob rounded
                                            transition: '0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15)',
                                        }}
                                    >
                                        <span
                                            style={{
                                                transition: 'left 0.3s ease',
                                                position: 'absolute',
                                                left: logSlide ? '' : '-35px', // Adjust this value as needed to position the "365" text
                                                color: logSlide ? '#fff' : '#000',
                                                fontWeight: '400'
                                            }}
                                        >
                                            365
                                        </span>
                                        <span
                                            style={{
                                                transition: 'left 0.3s ease',
                                                position: 'absolute',
                                                left: logSlide ? '53px' : '', // Adjust this value as needed to position the "Guardian" text
                                                color: logSlide ? '#000' : '#fff',
                                                fontWeight:'400'
                                            }}
                                        >
                                            Guardian
                                        </span>
                                    </div>
                                </div>

                            </div>


                            <Button
                                className="btn-primary custom-margin"
                                onClick={(e) => {
                                    if (gridApi) {
                                        gridApi.setFilterModel(null);
                                    }
                                }}
                                variant="primary"
                                style={{ borderColor: '#5191ce' }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="white" stroke="white" viewBox="0 0 32 32" width="20" style={{ cursor: 'pointer' }}>
                                    <path strokeWidth=".5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>
                                    <path strokeWidth=".5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>
                                </svg>
                            </Button>

                            <a id="ContentPlaceHolder1_btnDownload" style={{ color: "#007bff", cursor: 'pointer', padding: '5px 5px' }} className="me-2" >
                                <Button
                                    className="btn-primary"
                                    style={{ borderColor: '#5191ce' }}
                                    onClick={(e) => {
                                        if (gridApi) {
                                            gridApi.exportDataAsCsv();
                                        }
                                    }}
                                    variant="primary"
                                >
                                    <i className="far fa-download fa-lg" style={{ color: 'white', fill: 'transparent', fontSize: '16px' }}></i>
                                </Button>
                            </a>
                        </FormGroup>
                    </Form>
                </div>
            </div>
            
            <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: 700, borderCollapse: "collapse" }} >

                <AgGridReact style={{ width: "100%" }}
                    rowData={logs}
                    onGridReady={onGridReady}
                    pagination={true}
                    //getRowStyle={getRowStyle}

                    overlayLoadingTemplate={
                        `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:70px;"></div>`
                    }
                    overlayNoRowsTemplate={
                        //`<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:70px;" aria-label="loading"></div>`
                        `No records to show`
                    }
                    suppressScrollOnNewData={true}
                    onFirstDataRendered={onFirstDataRendered}
                    enableCellTextSelection="true"
                    ensureDomOrder="true"
                    rowBuffer={500}

                    columnDefs={[

                        {
                            field: 'eventId', headerName: 'Id', maxWidth: "100", minWidth: "75",  sortable: true, filter: true, resizable: true
                        }, {
                            field: 'eventTime', headerName: 'Time', valueFormatter: dateFormatter, maxWidth: "200", sortable: true, filter: true,
                            comparator: function (valueA, valueB) {
                                // Handle null values
                                if (valueA == null && valueB == null) {
                                    return 0;
                                }
                                if (valueA == null) {
                                    return -1;
                                }
                                if (valueB == null) {
                                    return 1;
                                }

                                // Handle empty values
                                if (valueA.trim() === '' && valueB.trim() === '') {
                                    return 0;
                                }
                                if (valueA.trim() === '') {
                                    return -1;
                                }
                                if (valueB.trim() === '') {
                                    return 1;
                                }

                                // Compare non-null and non-empty values
                                const normalizedA = valueA.toLowerCase();
                                const normalizedB = valueB.toLowerCase();

                                if (normalizedA < normalizedB) {
                                    return -1;
                                }
                                if (normalizedA > normalizedB) {
                                    return 1;
                                }
                                return 0;
                            },
                            getQuickFilterText: params => {
                                return dateFormatter(params);
                            }
                        },
                        {
                    field: 'eventSource', headerName: 'Source', maxWidth: "130", resizable: true, sortable: true, filter: true
                        }, {
                    field: 'eventType', headerName: 'Type', maxWidth: "110", resizable: true, sortable: true, filter: true
                        }, {
                    field: 'eventDetail', headerName: 'Description', minWidth: "450", wrapText: true, autoHeight: true, resizable: true, maxWidth: "900", sortable: true, filter: true
                        }, {
                    field: 'orgID', headerName: 'Organisation', maxWidth: "250", resizable: true, sortable: true, filter: true
                        },
                        {
                    field: 'eventUser', headerName: 'User', maxWidth: "250", resizable: true, sortable: true, filter: true
                        },
                        {
                            field: 'duration', headerName: 'Time(sec)', valueFormatter: secFormatter, maxWidth: "250", resizable: true
                        }
                    ]}
                >
                </AgGridReact>



            </div>

        </div>
    );
}
export default AdminLogs;