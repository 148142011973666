import React, { useState, useContext, useEffect } from "react";
import "../../CSS/general.css";
import Axios from "axios";
import ActionButtons from "./ActionButton";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import RepoModalPopup from "../../components/MainComponents/RepoModalPopup";
import { AppContext } from "../MainComponents/AppContext";
import AlertModal from "../MainComponents/AlertMessagePopup";
import { loginAuthProvider } from "../../LoginAuthProvider";
//import Spinner from "reactstrap/lib/Spinner";
import LoaderGif from "../../images/365 loading.gif";
import AuthenticationContext from "../../Store/Authentication-Context";

const Step4 = (props) => {
    const { setAuthContext, setRepoData, checkDataForId, RepoData } = useContext(AuthenticationContext);
    const [error, setError] = useState("");
    const [repoError, setRepoError] = useState("");
    const [helpPopup, setHelpPopup] = useState(true);
    const [appContext] = useContext(AppContext);
    const [repos, setRepos] = useState([]);
    const [mailboxEnabled, setmailboxEnabled] = useState(false);
    const [repositoryEnabled, setRepositoryEnabled] = useState(false);
    const [onedriveEnabled, setOnedriveEnabled] = useState(false);
    const [archiveMailboxEnabled, setArchiveMailboxEnabled] = useState(false);
    const [siteEnabled, setSitesEnabled] = useState(false);
    const [teamsEnabled, setTeamsEnabled] = useState(false);
    const [jobName, setjobName] = useState("");
    const [periodicallyDisabled, setPeriodicallyDisabled] = useState(true);
    const [jobDailyHours, setJobDailyHours] = useState("Anytime");
    const [jobDailyType, setJobDailyType] = useState("");
    const [jobPeriodically, setJobPeriodically] = useState("");
    const [defaultRepo, setDefaultRepo] = useState("");
    const [mailRepo, setMailRepo] = useState("");
    const [archiveMailRepo, setArchiveMailRepo] = useState("");
    const [onedriveRepo, setOnedriveRepo] = useState("");
    const [siteRepo, setSiteRepo] = useState("");
    const [teamsRepo, setTeamsRepo] = useState("");
    const [runNow, setRunNow] = useState(false);
    const [enableCreateRepos, setEnableCreateRepos] = useState(false);
    const [immutable, setJobImmutable] = useState(false);
    const [enableImmutability, setEnableImmutability] = useState(false);
    const [feature, setFeature] = useState([]);
    const [showMailWarning, setShowMailWarning] = useState(false);
    const [showArchiveWarning, setShowArchiveWarning] = useState(false);
    const [showOnedriveWarning, setShowOnedriveWarning] = useState(false);
    const [showSiteWarning, setShowSiteWarning] = useState(false);
    const [showTeamsWarning, setShowTeamsWarning] = useState(false);
    const [callingEvent, setCallingEvent] = useState("");
    const [enableCreateRepository, setEnableCreateRepository] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [priceModel, setPriceModel] = useState({});
    const [popUpRepo, setPopUpRepo] = useState([]);
    const [backupArray, setBackupArray] = useState([]);
    const [inProgress, setInProgress] = useState(false);
    const _mailbox = appContext.isEditJob ? "" : "-Mail";
    const _archiveMailbox = appContext.isEditJob ? "" : "-ArchiveMailbox";
    const _onedrive = appContext.isEditJob ? "" : "-Onedrive";
    const _sites = appContext.isEditJob ? "" : "-Sharepoint";
    const _teams = appContext.isEditJob ? "" : "-Teams";
    const [finishDisabled, setFinishDisabled] = useState(true);

    let counter = 0;

    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
    let repoId = "";
    let payloads = [];

    const validate = () => {
        if (appContext.partialOrg.length > 0) {
            payloads = [];

            if (appContext.isEditJob) {
                let proxyId = "";
                let editPayload = "";
                if (appContext.job.backupType === "EntireOrganization") {
                    editPayload = {
                        name: appContext.job.name + _mailbox,
                        description: appContext.job.description,
                        isEnabled: true,
                        runNow: runNow,
                        backupType: appContext.job.backupType,
                        excludedItems: [],
                        schedulePolicy: scheduledPolicy()
                    }
                }
                else {
                    editPayload = {
                        name: appContext.job.name + _mailbox,
                        description: appContext.job.description,
                        isEnabled: true,
                        runNow: runNow,
                        backupType: appContext.job.backupType,
                        selectedItems: [{
                            type: "PartialOrganization",
                            mailbox: appContext.partialOrg.find((item) => item === "mailbox") ? true : false,
                            oneDrive: appContext.partialOrg.find((item) => item === "oneDrive") ? true : false,
                            archiveMailbox: appContext.partialOrg.find((item) => item === "archiveMailbox") ? true : false,
                            sites: appContext.partialOrg.find((item) => item === "sites") ? true : false,
                            teams: appContext.partialOrg.find((item) => item === "teams") ? true : false,
                            teamsChats: appContext.partialOrg.find((item) => item === "teamsChat") ? true : false
                        }],
                        excludedItems: [],
                        schedulePolicy: scheduledPolicy()
                    }
                }
                repoId = getDefaultRepo();
                if (repoId === undefined)
                    return;
                editPayload.repositoryId = repoId;
                proxyId = getProxyId(repoId);
                if (proxyId !== "")
                    editPayload.proxyId = proxyId;
                for (var j = 0; j < appContext.excludedObjects.length > 0; j++) {
                    var Item = getSelectedObjects(appContext.excludedObjects[j]);
                    editPayload.excludedItems.push(Item);
                }
                payloads.push(editPayload);
            }
            else {
                for (var i = 0; i < appContext.partialOrg.length; i++) {
                    let proxyId = "";
                    switch (appContext.partialOrg[i]) {
                        case "mailbox":
                            proxyId = "";
                            let payloadMailbox = {
                                name: appContext.job.name + _mailbox,
                                description: appContext.job.description,
                                isEnabled: true,
                                runNow: runNow,
                                backupType: appContext.job.backupType,
                                selectedItems: [{
                                    type: "PartialOrganization",
                                    mailbox: false,
                                    oneDrive: false,
                                    archiveMailbox: false,
                                    sites: false,
                                    teams: false,
                                    teamsChats: false
                                }],
                                excludedItems: [],
                                schedulePolicy: scheduledPolicy()
                            }
                            payloadMailbox.selectedItems[0].mailbox = true;
                            repoId = getMailBoxRepo();
                            if (repoId === undefined)
                                return;
                            payloadMailbox.repositoryId = repoId;
                            proxyId = getProxyId(repoId);
                            if (proxyId !== "")
                                payloadMailbox.proxyId = proxyId;
                            for (var j = 0; j < appContext.excludedObjects.length > 0; j++) {

                                var Item = getSelectedObjects(appContext.excludedObjects[j]);
                                payloadMailbox.excludedItems.push(Item);
                            }
                            payloads.push(payloadMailbox);
                            break;
                        case "oneDrive":
                            proxyId = "";
                            let payloadOnedrive = {
                                name: appContext.job.name + _onedrive,
                                description: appContext.job.description,
                                isEnabled: true,
                                runNow: runNow,
                                backupType: appContext.job.backupType,
                                selectedItems: [{
                                    type: "PartialOrganization",
                                    mailbox: false,
                                    oneDrive: false,
                                    archiveMailbox: false,
                                    sites: false,
                                    teams: false,
                                    teamsChats: false
                                }],
                                excludedItems: [],
                                schedulePolicy: scheduledPolicy()
                            }

                            payloadOnedrive.selectedItems[0].oneDrive = true;
                            repoId = getOnedriveRepo();
                            if (repoId === undefined)
                                return;
                            payloadOnedrive.repositoryId = repoId;
                            proxyId = getProxyId(repoId);
                            if (proxyId !== "")
                                payloadOnedrive.proxyId = proxyId;
                            for (var k = 0; k < appContext.excludedObjects.length > 0; k++) {
                                var Item = getSelectedObjects(appContext.excludedObjects[k]);
                                payloadOnedrive.excludedItems.push(Item);
                            }
                            payloads.push(payloadOnedrive);
                            break;
                        case "archiveMailbox":
                            proxyId = "";
                            let payloadArchiveMailbox = {
                                name: appContext.job.name + _archiveMailbox,
                                description: appContext.job.description,
                                isEnabled: true,
                                runNow: runNow,
                                backupType: appContext.job.backupType,
                                selectedItems: [{
                                    type: "PartialOrganization",
                                    mailbox: false,
                                    oneDrive: false,
                                    archiveMailbox: false,
                                    sites: false,
                                    teams: false,
                                    teamsChats: false
                                }],
                                excludedItems: [],
                                schedulePolicy: scheduledPolicy()
                            }
                            payloadArchiveMailbox.selectedItems[0].archiveMailbox = true;
                            repoId = getArchiveMailboxRepo();
                            if (repoId === undefined)
                                return;
                            payloadArchiveMailbox.repositoryId = repoId;
                            proxyId = getProxyId(repoId);
                            if (proxyId !== "")
                                payloadArchiveMailbox.proxyId = proxyId;
                            for (var l = 0; l < appContext.excludedObjects.length > 0; l++) {
                                var Item = getSelectedObjects(appContext.excludedObjects[l]);
                                payloadArchiveMailbox.excludedItems.push(Item);
                            }
                            payloads.push(payloadArchiveMailbox);
                            break;
                        case "sites":
                            proxyId = "";
                            let payloadSites = {
                                name: appContext.job.name + _sites,
                                description: appContext.job.description,
                                isEnabled: true,
                                runNow: runNow,
                                backupType: appContext.job.backupType,
                                selectedItems: [{
                                    type: "PartialOrganization",
                                    mailbox: false,
                                    oneDrive: false,
                                    archiveMailbox: false,
                                    sites: false,
                                    teams: false,
                                    teamsChats: false
                                }],
                                excludedItems: [],
                                schedulePolicy: scheduledPolicy()
                            }
                            payloadSites.selectedItems[0].sites = true;
                            repoId = getSitesRepo();
                            if (repoId === undefined)
                                return;
                            payloadSites.repositoryId = repoId;
                            proxyId = getProxyId(repoId);
                            if (proxyId !== "")
                                payloadSites.proxyId = proxyId;
                            for (var m = 0; m < appContext.excludedObjects.length > 0; m++) {
                                var Item = getSelectedObjects(appContext.excludedObjects[m]);
                                payloadSites.excludedItems.push(Item);
                            }
                            payloads.push(payloadSites);
                            break;
                        case "teams":
                            proxyId = "";
                            let payloadTeams = {
                                name: appContext.job.name + _teams,
                                description: appContext.job.description,
                                isEnabled: true,
                                runNow: runNow,
                                backupType: appContext.job.backupType,
                                selectedItems: [{
                                    type: "PartialOrganization",
                                    mailbox: false,
                                    oneDrive: false,
                                    archiveMailbox: false,
                                    sites: false,
                                    teams: false,
                                    teamsChats: false
                                }],
                                excludedItems: [],
                                schedulePolicy: scheduledPolicy()
                            }
                            payloadTeams.selectedItems[0].teams = true;
                            repoId = getTeamsRepo();
                            if (repoId === undefined)
                                return;
                            payloadTeams.repositoryId = repoId;
                            proxyId = getProxyId(repoId);
                            if (proxyId !== "")
                                payloadTeams.proxyId = proxyId;
                            for (var n = 0; n < appContext.excludedObjects.length > 0; n++) {
                                var Item = getSelectedObjects(appContext.excludedObjects[n]);
                                payloadTeams.excludedItems.push(Item);
                            }
                            if (appContext.partialOrg.find((item) => item === "teamsChat"))
                                payloadTeams.selectedItems[0].teamsChats = true;

                            payloads.push(payloadTeams);
                            break;
                        default:
                            break;
                    }
                }
            }
            var wait = createBackupJobs();
        }
        else if (appContext.selectedObjects.length > 0) {

            createSelectedItemJob();
        }
    };

    async function createSelectedItemJob() {
        setInProgress(true);
        let payload = {
            name: appContext.job.name,
            description: appContext.job.description,
            isEnabled: true,
            runNow: runNow,
            backupType: appContext.job.backupType,
            selectedItems: [],
            excludedItems: [],
        };

        payload.schedulePolicy = scheduledPolicy();
        repoId = getDefaultRepo();
        if (repoId === undefined)
            return;
        payload.repositoryId = repoId;
        let proxyId = getProxyId(repoId);
        if (proxyId !== "")
            payload.proxyId = proxyId;
        for (var i = 0; i < appContext.selectedObjects.length > 0; i++) {
            var Item = getSelectedObjects(appContext.selectedObjects[i]);
            payload.selectedItems.push(Item);
        }

        for (var i = 0; i < appContext.excludedObjects.length > 0; i++) {
            var Item = getSelectedObjects(appContext.excludedObjects[i]);
            payload.excludedItems.push(Item);
        }
        var wait = await createJob(payload);
        setInProgress(false);
        if (wait && typeof wait !== "undefined") {
            setAlertMessage({
                header: "Info",
                message: wait.toLowerCase().includes("target repositories for a backup job and its copy job must have the same retention type") ? "Copy Job cannot be created for Itemlevel backedup Jobs" : wait,
            });
            setAlertPop(false);
        }
        else {
            props.closePopupModal();
        }
    }

    function closeModal(params) {
        setHelpPopup(params);
    }

    const scheduledPolicy = () => {
        let policy = {
            backupWindowEnabled: false,
            scheduleEnabled: true,
            dailyTime: getDailyTime(),
            type: periodicallyDisabled ? "Daily" : "Periodically",
            dailyType: jobDailyType,
            retryEnabled: true,
            retryNumber: 3,
            retryWaitInterval: 10
        };

        if (!periodicallyDisabled)
            policy.periodicallyEvery = jobPeriodically;

        return policy;
    }

    function getSelectedObjects(object) {
        switch (object.itemType) {
            case "user":
                var user = {
                    type: "User",
                    user: {
                        id: object.id,
                        displayName: object.displayName,
                        name: object.name,
                        type: object.type,
                        locationType: object.locationType
                    },
                    mailbox: object.mailbox,
                    archiveMailbox: object.archiveMailbox,
                    oneDrive: object.onedrive,
                    personalSite: object.site
                }
                return user;
            case "group":
                var group = {
                    type: "Group",
                    group: {
                        id: object.id,
                        displayName: object.displayName,
                        name: object.name === "" || object.name === undefined ? object.displayName : object.name,
                        type: object.type,
                        locationType: object.locationType
                    },
                    members: object.mailbox || object.archiveMailbox || object.onedrive || object.site,
                    memberMailbox: object.mailbox,
                    memberArchiveMailbox: object.archiveMailbox,
                    memberOnedrive: object.onedrive,
                    memberSite: object.site,
                    mailbox: object.groupMailbox,
                    groupSite: object.groupSite,
                    site: object.groupSite
                }
                return group;
            case "site":
                var site = {
                    type: "Site",
                    site: {
                        id: object.id,
                        url: object.url,
                        name: object.name,
                        isCloud: object.isCloud,
                        isPersonal: object.isPersonal,
                        title: object.title
                    }
                }
                return site;
            case "team":
                var teams = {
                    type: "Team",
                    team: {
                        id: object.id,
                        url: object.url,
                        displayName: object.displayName,
                        description: object.description,
                        mail: object.mail
                    },
                    teamsChats: object.teamsChat
                }
                return teams;
        }
    }

    function getDefaultRepo() {
        if (defaultRepo)
            return defaultRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    function getMailBoxRepo() {
        if (appContext.isEditJob) {
            return getDefaultRepo();
        } else if (mailRepo)
            return mailRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    function getOnedriveRepo() {
        if (appContext.isEditJob) {
            return getDefaultRepo();
        } else if (onedriveRepo)
            return onedriveRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    function getArchiveMailboxRepo() {
        if (appContext.isEditJob) {
            return getDefaultRepo();
        } else if (archiveMailRepo)
            return archiveMailRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    function getSitesRepo() {
        if (appContext.isEditJob)
            return getDefaultRepo();
        else if (siteRepo)
            return siteRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    function getTeamsRepo() {
        if (appContext.isEditJob)
            return getDefaultRepo();
        else if (teamsRepo)
            return teamsRepo;
        else {
            setError("Please select Repository from the list below");
            setInProgress(false);
        }
    }

    const onChangevalue = (event) => {
        const targetValue = event.target.value;
        if (targetValue && targetValue === "Daily")
            setPeriodicallyDisabled(true);
        else
            setPeriodicallyDisabled(false);
    };

    function setJobRepositories() {
        setjobName(appContext.job.name);
        if (appContext.isEditJob) {
            setRepositoryEnabled(true);
            return;
        }
        setmailboxEnabled(appContext.partialOrg.find((item) => item === "mailbox"));
        setOnedriveEnabled(appContext.partialOrg.find((item) => item === "oneDrive"));
        setArchiveMailboxEnabled(appContext.partialOrg.find((item) => item === "archiveMailbox"));
        setSitesEnabled(appContext.partialOrg.find((item) => item === "sites"));
        setTeamsEnabled(appContext.partialOrg.find((item) => item === "teams"));
        setRepositoryEnabled(appContext.selectedObjects.length > 0);
        checkAllRepo();
    }

    function RandomTime(startHour, endHour) {
        var hour = Math.floor(Math.random() * (endHour - startHour + 1) + startHour);
        var min = Math.floor(Math.random() * 59);
        var sec = Math.floor(Math.random() * 59);
        return hour + ":" + min + ":" + sec;
    }

    const jobdailyHourChanged = (event) => {
        setJobDailyHours(event.target.value);
    }

    function getDailyTime() {
        switch (jobDailyHours) {
            case "Anytime":
                return RandomTime(0, 23);
            case "Morning":
                return RandomTime(0, 11);
            case "Afternoon":
                return RandomTime(12, 17);
            case "Evening":
                return RandomTime(18, 23);
            default:
                var dailyTime = jobDailyHours + ":" + Math.floor(Math.random() * 59) + ":" + Math.floor(Math.random() * 59);
                return dailyTime;
        }
    }

    const jobDailyTypeChanged = (event) => {
        setJobDailyType(event.target.value);
    }

    const jobPeriodicallyChanged = (event) => {
        setJobPeriodically(event.target.value);
    }

    function checkAllRepo() {
        let isAnyRepoSelected = finishDisabled;

        const flag =
            (mailboxEnabled ? (mailRepo !== "" && mailRepo !== "null") : true) &&
            (archiveMailboxEnabled ? (archiveMailRepo !== "" && archiveMailRepo !== "null") : true) &&
            (onedriveEnabled ? (onedriveRepo !== "" && onedriveRepo !== "null") : true) &&
            (siteEnabled ? (siteRepo !== "" && siteRepo !== "null") : true) &&
            (teamsEnabled ? (teamsRepo !== "" && teamsRepo !== "null") : true);
        setFinishDisabled(!flag);
    }

    const onMailRepoChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        setError("");
        
        if (targetValue != null && targetValue != "null") {
            setMailRepo(targetValue);
            const selectedMailRepo = appContext.repositories.Repositories.find(obj => {
                return obj.id === targetValue;
            });

            if (selectedMailRepo.description !== "MAIL") {
                setShowMailWarning(true);
            }
            else {
                setShowMailWarning(false);
            }
        }
        checkAllRepo();

    }

    const onRepoNameChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        setError("");
        
        if (targetValue !== null && targetValue != "null") {
            setDefaultRepo(targetValue);
        }
        else
            setDefaultRepo("");
        checkAllRepo();
    }

    const onArchiveMailRepoChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        setError("");
        
        if (targetValue != null && targetValue != "null") {
            setArchiveMailRepo(targetValue);
            const selectedMailRepo = appContext.repositories.Repositories.find(obj => {
                return obj.id === targetValue;
            });

            if (selectedMailRepo.description !== "MAIL") {
                setShowArchiveWarning(true);
            }
            else {
                setShowArchiveWarning(false);
            }
        }
        checkAllRepo();
    }

    const onOnedriveRepoChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        setError("");
        
        if (targetValue != null && targetValue != "null") {
            setOnedriveRepo(targetValue);
            const selectedMailRepo = appContext.repositories.Repositories.find(obj => {
                return obj.id === targetValue;
            });

            if (selectedMailRepo.description !== "ONEDRIVE") {
                setShowOnedriveWarning(true);
            }
            else {
                setShowOnedriveWarning(false);
            }
        }
        checkAllRepo();
    }

    const onSiteRepoChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        setError("");
        
        if (targetValue != null && targetValue != "null") {
            setSiteRepo(targetValue);
            const selectedMailRepo = appContext.repositories.Repositories.find(obj => {
                return obj.id === targetValue;
            });

            if (selectedMailRepo.description !== "SHAREPOINT") {
                setShowSiteWarning(true);
            }
            else {
                setShowSiteWarning(false);
            }
        }
        checkAllRepo();
    }

    const onTeamsRepoChanged = (event) => {
        setRepoError("");
        var targetValue = event.target.value;
        
        setError("");
        if (targetValue != null && targetValue != "null") {
            setTeamsRepo(targetValue);
            const selectedMailRepo = appContext.repositories.Repositories.find(obj => {
                return obj.id === targetValue;
            });

            if (selectedMailRepo.description !== "SHAREPOINT") {
                setShowTeamsWarning(true);
            }
            else {
                setShowTeamsWarning(false);
            }
        }
        checkAllRepo();
    }

    async function createBackupJobs() {
        setInProgress(true);
        var responseArr = [];
        for (var i = 0; i < payloads.length; i++) {
            var response = await createJob(payloads[i]);
            if (response && typeof response !== 'undefined')
                responseArr.push(payloads[i].name + ": " + response);
        }
        setInProgress(false);
        if (responseArr.length > 0) {
            setAlertMessage({
                header: "Info",
                message: responseArr,
            });
            setAlertPop(false);
        }
        else {
            props.closePopupModal();
        }
    }

    async function createJob(payloadItem) {

        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        let payload = {
            postString: JSON.stringify(payloadItem),
            method: appContext.isEditJob ? "EDIT" : "POST",
            url: appContext.isEditJob ? "/Jobs/" + appContext.job.id : "/Organizations/" + appContext.orgId + "/Jobs",
            orgId: appContext.orgId,
            immutable: appContext.isEditJob ? immutable : false,
        };
        const data = await Axios.post(API_ROUTE + Routes.SET_JOB, payload, config)
            .then((response) => {
                props.jobRefreshed();
            })
            .catch((reason) => {
                return reason.response.data.message
            });
        return data;
    }
    async function getPriceModel() {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let ModelUserName = "NA";
        if (sessionStorage.UserName) {
            ModelUserName = sessionStorage.UserName;
        }
        let priceingModalData = await Axios.get(API_ROUTE + Routes.PRICING_MODEL_ID + "/" + appContext.orgId + "/" + ModelUserName, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                return selectItems;

            })
            .catch((reason) => {
                console.log("catch Repo ; " + reason);
            });
        if (priceingModalData) {
            setPriceModel(priceingModalData)
        }
    }
    async function CreateRepos() {
        setCallingEvent("Create Repositories");
        setRepoError("");
        setPopUpRepo({
            id: "",
            name: "",
            retentionType: "SnapshotBased",
            retentionPolicy: "1 Year",
            retentionCount: 0,
            encryption: true,
            baseUrl: Urls.SERVER_PATH,
        });
        await getPriceModel()
        setShowModal(true);
    }

    async function CreateRepository() {
        setCallingEvent("Create");
        setRepoError("");
        setPopUpRepo({
            id: "",
            name: "",
            retentionType: "SnapshotBased",
            retentionPolicy: "1 Year",
            retentionCount: 0,
            encryption: true,
            baseUrl: Urls.SERVER_PATH,
        });
        await getPriceModel()
        setShowModal(true);
    }

    const popupSwap = (show, reload = false) => {
        ////await getPriceModel()
        setShowModal(show);
    }

    const clearData = () => { }

    const cancel = () => {
        setShowModal(false);
    }

    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }

    function setCorrespondingRepos() {
        setEnableCreateRepository(false);
        if (appContext.partialOrg.length > 0) {
            setEnableCreateRepos(false);
            setBackupArray([]);
            let repoArray = [];
            let mail = appContext.repositories.Repositories.map(function (x) {
                return x.name.toLowerCase().includes("mail") && !x.name.toLowerCase().includes("-copy");
            });
            if (mail.find((y) => y === true))
                setMailRepo(appContext.repositories.Repositories[mail.findIndex((y) => y === true)].id);
            else
                repoArray.push("Mail backup Repository") ;

            let archive = appContext.repositories.Repositories.map(function (x) {
                return x.name.toLowerCase().includes("archive") && !x.name.toLowerCase().includes("-copy");
            });
            if (archive.find((y) => y === true))
                setArchiveMailRepo(appContext.repositories.Repositories[archive.findIndex((y) => y === true)].id);
            else
                repoArray.push("Archive backup Repository");

            let team = appContext.repositories.Repositories.map(function (x) {
                return x.name.toLowerCase().includes("team") && !x.name.toLowerCase().includes("-copy");
            });
            if (team.find((y) => y === true))
                setTeamsRepo(appContext.repositories.Repositories[team.findIndex((y) => y === true)].id);
            else
                repoArray.push("Teams backup Repository");

            let sharepoint = appContext.repositories.Repositories.map(function (x) {
                return x.name.toLowerCase().includes("sharepoint") && !x.name.toLowerCase().includes("-copy");
            });
            if (sharepoint.find((y) => y === true))
                setSiteRepo(appContext.repositories.Repositories[sharepoint.findIndex((y) => y === true)].id);
            else
                repoArray.push("Sharepoint backup Repository");

            let onedrive = appContext.repositories.Repositories.map(function (x) {
                return x.name.toLowerCase().includes("onedrive") && !x.name.toLowerCase().includes("-copy");
            });
            if (onedrive.find((y) => y === true))
                setOnedriveRepo(appContext.repositories.Repositories[onedrive.findIndex((y) => y === true)].id);
            else
                repoArray.push("Onedrive backup Repository");
            setBackupArray(repoArray);

            if (repoArray.length > 0 && !appContext.isEditJob) {
                setRepoError("Mandatory repositories are not created please use the create repositories option to create all required repositories");
                setEnableCreateRepos(true);
            }
        } else if (appContext.repositories.Repositories.length === 0) {
            setEnableCreateRepository(true);
        }
    }

    const repoRefresh = () => {
        var wait = getRepositories();
        props.callBackReloadRepository();
    }

    async function getRepositories() {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        //var isRepoAvailable = await checkDataForId(appContext.orgId);
        //let repoData = {}
        //if (isRepoAvailable) {
        //    setRepositories( RepoData[appContext.orgId]);
        //}
        //else {
         let repoData = await Axios.get(API_ROUTE + Routes.GET_REPO + "?orgId=" + appContext.orgId + "&orgName=" + appContext.orgName + "&baseUrl=" + Urls.SERVER_PATH, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                appContext.repositories = selectItems;
                setRepoData({ id: appContext.orgId, data: selectItems });
                setRepositories(appContext.repositories.Repositories);
                return selectItems;

            })
            .catch((reason) => {
            });
        //}
    }

    function setRepositories() {
        let searchTypeOptions = [];
        if (appContext.repositories.Repositories) {
            if (appContext.repositories.Repositories.length > 0) {
                for (var i = 0; i < appContext.repositories.Repositories.length; i++) {
                    const repo = appContext.repositories.Repositories[i];
                    if (!repo.name.toLowerCase().includes("-copy")) {
                        var repoType = "";
                        if (repo.description !== "" && repo.description !== undefined) {
                            if (repo.description === "SHAREPOINT")
                                repoType = "(OTHERS)"
                            else
                                repoType = "(" + repo.description + ")"
                        }
                        var repoOption = (
                            <option key={i} value={repo.id}>
                                {repo.name + repoType}
                            </option>
                        );
                        searchTypeOptions.push(repoOption);
                    }
                }
                setRepos(searchTypeOptions);
            }
            setCorrespondingRepos();
        }
    }

    function getProxyId(params) {
        var index = appContext.repositories.Repositories.findIndex((x) => x.id === params);
        if (appContext.repositories.Repositories.find((x) => x.id === params))
            return appContext.repositories.Repositories[index].proxyId;
        else
            return "";
    }
    async function getOrgDetails() {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        const data = await Axios.get(API_ROUTE + Routes.GET_PRODUCT_FEATURE + "/" + appContext.orgId + "/OLOCKIMMUT", config)
            .then((response) => {
                let result = JSON.parse(response.data);
                setFeature(result)
                return result;
            })
            .catch((reason) => {
                setFeature([]);
                return reason;
            });

        return data;
    }

    useEffect(() => {
        setRepositories();
    }, [appContext.repositories.Repositories]);

    useEffect(() => {
        setJobRepositories();
    }, [appContext.partialOrg.length]);
    
    useEffect(() => {
        setJobRepositories();
    }, [appContext.selectedObjects.length]);

    useEffect(() => {
        setDefaultRepo(appContext.job.repositoryId);
        if (appContext.job.schedulePolicy?.dailyType)
            setJobDailyType(appContext.job.schedulePolicy?.dailyType);
        else
            setJobDailyType("Everyday");

        if (appContext.job.schedulePolicy?.dailyTime)
            setJobDailyHours(appContext.job.schedulePolicy?.dailyTime.substring(0, 2));

    }, [appContext.job.repositoryId]);

    useEffect(() => {
        if (appContext.job.schedulePolicy?.type === "Periodically") {
            setPeriodicallyDisabled(false);
            setJobPeriodically(appContext.job.schedulePolicy?.periodicallyEvery)
        }
        else {
            setPeriodicallyDisabled(true);
        }
    }, [appContext.job.schedulePolicy?.type]);

    useEffect(() => {
        getOrgDetails();
    }, []);

    useEffect(() => {
        checkAllRepo();
    }, [
        mailRepo,
        archiveMailRepo,
        onedriveRepo,
        siteRepo,
        teamsRepo,
        finishDisabled
    ]);

    return (

        <div id="step4">
            <div id="UpdatePanel1">
                <div className="card">
                    <h4 className="card-header ">
                        <span id="TitleLabel" className="SectionTitle">Schedule & Confirm&nbsp;</span>
                        <a id="Job_btnHelp" style={{ float: 'right' }} onClick={() => {
                            let url = "https://cloudcover365.virtualdcs.co.uk/helpme/Step4-Scheduling.html";
                            window.open(url, '_blank');
                        }} ><i className="fad fa-question"></i></a>
                    </h4>
                    <div className="card-body" style={{ borderStyle: "None", maxHeight: "390px", minHeight:'345px', width: "100%", overflowY: "scroll" }}>
                        {repoError && <div style={{ textAlign: 'center' }}><span id="ValidatorMailBoxRepo" style={{ color: "red", position: 'relative', top: '-11px' }} >{repoError}</span></div>}
                        
                        <div className="row">
                            <div className="col-6 ps-3">
                                <div className="card" style={{ height:'100%' }}>
                                    <div className="card-body">
                                        <span id="Wizard1_LabelRuntheJob" className="wizardtext">Run the job</span>
                                        <span name="GroupName" >
                                            <div style={{ display: 'flex', alignItems: 'center' }} className="mt-3">
                                                <input type="radio" name="jobRun" onChange={onChangevalue} value="Daily" checked={periodicallyDisabled} />&nbsp;Daily:&nbsp;
                                                <select name="jobdailyHourName" id="jobdailyHourId" className="form-select ms-2 me-2" style={{ fontSize: '14px' }} value={jobDailyHours !== "" ? jobDailyHours : setJobDailyHours("Anytime")} onChange={jobdailyHourChanged} disabled={!periodicallyDisabled}>
                                                    <option value="Anytime">Anytime</option>
                                                    <option value="Morning">Morning  (00:00 - 11:59)</option>
                                                    <option value="Afternoon">Afternoon (12:00 - 18:00)</option>
                                                    <option value="Evening">Evening (18:01 - 23.59)</option>
                                                    <option value="00">00:00:01 - 01:00:00</option>
                                                    <option value="01">01:00:01 - 02:00:00</option>
                                                    <option value="02">02:00:01 - 03:00:00</option>
                                                    <option value="03">03:00:01 - 04:00:00</option>
                                                    <option value="04">04:00:01 - 05:00:00</option>
                                                    <option value="05">05:00:01 - 06:00:00</option>
                                                    <option value="06">06:00:01 - 07:00:00</option>
                                                    <option value="07">07:00:01 - 08:00:00</option>
                                                    <option value="08">08:00:01 - 09:00:00</option>
                                                    <option value="09">09:00:01 - 10:00:00</option>
                                                    <option value="10">10:00:01 - 11:00:00</option>
                                                    <option value="11">11:00:01 - 12:00:00</option>
                                                    <option value="12">12:00:01 - 13:00:00</option>
                                                    <option value="13">13:00:01 - 14:00:00</option>
                                                    <option value="14">14:00:01 - 15:00:00</option>
                                                    <option value="15">15:00:01 - 16:00:00</option>
                                                    <option value="16">16:00:01 - 17:00:00</option>
                                                    <option value="17">17:00:01 - 18:00:00</option>
                                                    <option value="18">18:00:01 - 19:00:00</option>
                                                    <option value="19">19:00:01 - 20:00:00</option>
                                                    <option value="20">20:00:01 - 21:00:00</option>
                                                    <option value="21">21:00:01 - 22:00:00</option>
                                                    <option value="22">22:00:01 - 23:00:00</option>
                                                    <option value="23">23:00:01 - 00:00:00</option>
                                                </select>
                                                <select name="jobDailyType" id="jobDailyTypeId" className="form-select me-2" style={{ fontSize: '14px' }} value={jobDailyType !== "" ? jobDailyType : setJobDailyType("Everyday")} onChange={jobDailyTypeChanged} disabled={!periodicallyDisabled}>
                                                    <option value="Everyday">Everyday</option>
                                                    <option value="Workdays">Workdays</option>
                                                    <option value="Weekends">Weekends</option>
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                    <option value="Sunday">Sunday</option>
                                                </select> (GMT)
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }} className="me-3 mt-4">
                                                <input type="radio" name="jobRun" onChange={onChangevalue} value="Periodically" checked={!periodicallyDisabled} />&nbsp;Periodically:&nbsp;
                                                <select name="Wizard1$jobperiodicallyevery" className="form-select ms-2" style={{ fontSize: '14px', width: '32%' }} id="periodically" value={jobPeriodically != "" ? jobPeriodically : setJobPeriodically("Hours8")} onChange={jobPeriodicallyChanged} disabled={periodicallyDisabled}>
                                                    <option defaultValue value="Hours8">3 times a day</option>
                                                    <option value="Hours4">6 times a day</option>
                                                    <option value="Hours2">12 times a day</option>
                                                </select>
                                            </div>
                                        </span>
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input id="Wizard1_jobisrun" type="checkbox" className="" onClick={(e) => { setRunNow(e.target.checked); }} name="Wizard1$jobisrun" />&nbsp;
                                            <span id="Wizard1_lblRunFin" className="wizardtext">
                                                Run initial backup when I click finish.
                                            </span>
                                        </div>
                                        <div className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input id="Wizard1_jobisimmutable" type="checkbox" checked={props.copyJobState || immutable} onClick={(e) => { setJobImmutable(e.target.checked); }} name="Wizard1$jobisimmutable" disabled={(feature.length === 0 || feature?.featureValue === "" || props.copyJobState) || !appContext.isEditJob} />&nbsp;
                                            <span id="Wizard1_lblRunImmutable" className="wizardtext">
                                                Enable backup copy with Object Lock immutability
                                            </span>
                                        </div>

                                        <div className="mt-3" >
                                            {(feature.length === 0 || feature?.featureValue === "") ? <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: 'xx-small' }} >&nbsp;&nbsp;* Backup copy jobs copy data to a second location, where they will be held immutable.
                                            </div> :
                                                <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: 'xx-small' }} >&nbsp;&nbsp;* Backup copy jobs copy data to a second location, where they will be held immutable  for {feature.featureValue} days.
                                                </div>
                                            }
                                            {appContext.isEditJob ? <></> : <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: 'xx-small' }} >&nbsp;&nbsp;* This option can be enabled by returning to edit once you have created your primary backup job.<br></br>
                                            </div>
                                            }
                                            {(feature.length === 0 || feature?.featureValue === "") &&
                                                <p style={{ color: "red", fontFamily: "'Poppins', sans-serif", fontSize: 'xx-small' }}>&nbsp;&nbsp;* This feature is not included in your package.<br></br></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card" style={{ height:'100%' }}>
                                    <div className="card-body">
                                        <span className="wizardtext">Select a repository to define your retention period.</span>
                                        <span id="ValidatorMailBoxRepo" style={{ color: "red" }} >{error}</span>
                                        <table cellSpacing="5" cellPadding="5" style={{ width:'100%' }}>
                                            <tbody>
                                                <tr>
                                                    <th> Job</th>
                                                    <th> Repository</th>
                                                </tr>
                                                {
                                                    repositoryEnabled &&
                                                    <tr>
                                                        <td> <label> {jobName}</label></td>
                                                        <td>
                                                            <select className="form-select" value={defaultRepo != "" ? defaultRepo : "null"} onChange={onRepoNameChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    mailboxEnabled &&
                                                    <tr>
                                                        <td><label> {jobName}{_mailbox}</label></td>
                                                        <td>
                                                            <select className="form-select" value={mailRepo !== "" ? mailRepo : "null"} onChange={onMailRepoChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                        {showMailWarning &&
                                                            <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Mailbox Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>
                                                        }
                                                    </tr>
                                                }
                                                {
                                                    archiveMailboxEnabled &&
                                                    <tr>
                                                        <td><label> {jobName}{_archiveMailbox}</label></td>
                                                        <td>
                                                            <select className="form-select" value={archiveMailRepo !== "" ? archiveMailRepo : "null"} onChange={onArchiveMailRepoChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                        {showArchiveWarning &&
                                                            <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Archive Mailbox Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>
                                                        }
                                                    </tr>
                                                }
                                                {
                                                    onedriveEnabled &&
                                                    <tr>
                                                        <td><label> {jobName}{_onedrive}</label></td>
                                                        <td>
                                                            <select className="form-select" value={onedriveRepo !== "" ? onedriveRepo : "null"} onChange={onOnedriveRepoChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                        {showOnedriveWarning &&
                                                            <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Onedrive Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>
                                                        }
                                                    </tr>
                                                }{
                                                    siteEnabled &&
                                                    <tr>
                                                        <td><label> {jobName}{_sites}</label></td>
                                                        <td>
                                                            <select className="form-select" value={siteRepo != "" ? siteRepo : "null"} onChange={onSiteRepoChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                        {showSiteWarning &&
                                                            <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Sharepoint Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>
                                                        }
                                                    </tr>
                                                }{
                                                    teamsEnabled &&
                                                    <tr>
                                                        <td><label> {jobName}{_teams}</label></td>
                                                        <td>
                                                            <select className="form-select" value={teamsRepo != "" ? teamsRepo : "null"} onChange={onTeamsRepoChanged}>
                                                                <option value={"null"}>Select Repository</option>
                                                                {repos}
                                                            </select>
                                                        </td>
                                                        {showTeamsWarning &&
                                                            <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Teams Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>
                                                        }
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<table cellSpacing="0" cellPadding="0" id="Wizard1" style={{ height: "100%", width: "100%", borderCollapse: "collapse" }} >*/}
                        {/*    <tbody>*/}
                        {/*        <tr style={{ height: "100%", verticalAlign: 'top' }} >*/}
                        {/*            <td style={{ width: "60%" }}>*/}
                        {/*                <div>*/}
                        {/*                    <span id="Wizard1_LabelRuntheJob" className="wizardtext">Run the job</span>*/}
                        {/*                    <span name="GroupName" >*/}
                        {/*                        <div style={{ display: 'flex', alignItems: 'center' }} className="me-3"> */}
                        {/*                            <input type="radio" name="jobRun" onChange={onChangevalue} value="Daily" checked={periodicallyDisabled} />&nbsp;Daily:&nbsp;*/}
                        {/*                            <select name="jobdailyHourName" id="jobdailyHourId" className="form-select ms-2 me-2" style={{ fontSize:'14px' }} value={jobDailyHours !== "" ? jobDailyHours : setJobDailyHours("Anytime")} onChange={jobdailyHourChanged} disabled={!periodicallyDisabled}>*/}
                        {/*                                <option value="Anytime">Anytime</option>*/}
                        {/*                                <option value="Morning">Morning  (00:00 - 11:59)</option>*/}
                        {/*                                <option value="Afternoon">Afternoon (12:00 - 18:00)</option>*/}
                        {/*                                <option value="Evening">Evening (18:01 - 23.59)</option>*/}
                        {/*                                <option value="00">00:00:01 - 01:00:00</option>*/}
                        {/*                                <option value="01">01:00:01 - 02:00:00</option>*/}
                        {/*                                <option value="02">02:00:01 - 03:00:00</option>*/}
                        {/*                                <option value="03">03:00:01 - 04:00:00</option>*/}
                        {/*                                <option value="04">04:00:01 - 05:00:00</option>*/}
                        {/*                                <option value="05">05:00:01 - 06:00:00</option>*/}
                        {/*                                <option value="06">06:00:01 - 07:00:00</option>*/}
                        {/*                                <option value="07">07:00:01 - 08:00:00</option>*/}
                        {/*                                <option value="08">08:00:01 - 09:00:00</option>*/}
                        {/*                                <option value="09">09:00:01 - 10:00:00</option>*/}
                        {/*                                <option value="10">10:00:01 - 11:00:00</option>*/}
                        {/*                                <option value="11">11:00:01 - 12:00:00</option>*/}
                        {/*                                <option value="12">12:00:01 - 13:00:00</option>*/}
                        {/*                                <option value="13">13:00:01 - 14:00:00</option>*/}
                        {/*                                <option value="14">14:00:01 - 15:00:00</option>*/}
                        {/*                                <option value="15">15:00:01 - 16:00:00</option>*/}
                        {/*                                <option value="16">16:00:01 - 17:00:00</option>*/}
                        {/*                                <option value="17">17:00:01 - 18:00:00</option>*/}
                        {/*                                <option value="18">18:00:01 - 19:00:00</option>*/}
                        {/*                                <option value="19">19:00:01 - 20:00:00</option>*/}
                        {/*                                <option value="20">20:00:01 - 21:00:00</option>*/}
                        {/*                                <option value="21">21:00:01 - 22:00:00</option>*/}
                        {/*                                <option value="22">22:00:01 - 23:00:00</option>*/}
                        {/*                                <option value="23">23:00:01 - 00:00:00</option>*/}
                        {/*                            </select>*/}
                        {/*                            <select name="jobDailyType" id="jobDailyTypeId" className="form-select me-2" style={{ fontSize: '14px' }} value={jobDailyType !== "" ? jobDailyType : setJobDailyType("Everyday")} onChange={jobDailyTypeChanged} disabled={!periodicallyDisabled}>*/}
                        {/*                                <option value="Everyday">Everyday</option>*/}
                        {/*                                <option value="Workdays">Workdays</option>*/}
                        {/*                                <option value="Weekends">Weekends</option>*/}
                        {/*                                <option value="Monday">Monday</option>*/}
                        {/*                                <option value="Tuesday">Tuesday</option>*/}
                        {/*                                <option value="Wednesday">Wednesday</option>*/}
                        {/*                                <option value="Thursday">Thursday</option>*/}
                        {/*                                <option value="Friday">Friday</option>*/}
                        {/*                                <option value="Saturday">Saturday</option>*/}
                        {/*                                <option value="Sunday">Sunday</option>*/}
                        {/*                            </select> (GMT)*/}
                        {/*                        </div>*/}
                        {/*                        <div style={{ display: 'flex', alignItems: 'center' }} className="me-3 mt-2">*/}
                        {/*                            <input type="radio" name="jobRun" onChange={onChangevalue} value="Periodically" checked={!periodicallyDisabled} />&nbsp;Periodically:&nbsp;*/}
                        {/*                            <select name="Wizard1$jobperiodicallyevery" className="form-select ms-2" style={{ fontSize: '14px', width:'23%' }} id="periodically" value={jobPeriodically != "" ? jobPeriodically : setJobPeriodically("Hours8")} onChange={jobPeriodicallyChanged} disabled={periodicallyDisabled}>*/}
                        {/*                                <option defaultValue value="Hours8">3 times a day</option>*/}
                        {/*                                <option value="Hours4">6 times a day</option>*/}
                        {/*                                <option value="Hours2">12 times a day</option>*/}
                        {/*                            </select>*/}
                        {/*                        </div>*/}
                        {/*                    </span>*/}
                        {/*                    <input id="Wizard1_jobisrun" type="checkbox" className="mt-4" onClick={(e) => { setRunNow(e.target.checked); }} name="Wizard1$jobisrun" />&nbsp;*/}
                        {/*                    <span id="Wizard1_lblRunFin" className="wizardtext">*/}
                        {/*                        Run initial backup when I click finish.*/}
                        {/*                    </span>*/}

                        {/*                    <div >*/}
                        {/*                        <input id="Wizard1_jobisimmutable" type="checkbox" onClick={(e) => { setJobImmutable(e.target.checked); }} name="Wizard1$jobisimmutable" disabled={(feature.length === 0 || feature?.featureValue === "") || !appContext.isEditJob} />&nbsp;*/}
                        {/*                        <span id="Wizard1_lblRunImmutable" className="wizardtext mb-1">*/}
                        {/*                            Enable backup copy with Object Lock immutability*/}
                        {/*                        </span>*/}
                        {/*                        <br />*/}

                        {/*                        {(feature.length === 0 || feature?.featureValue === "") ? <div style={{  fontFamily: "'Poppins', sans-serif", fontSize:'11.5px' }} >&nbsp;&nbsp;* Backup copy jobs copy data to a second location, where they will be held immutable.*/}
                        {/*                        </div> :*/}
                        {/*                            <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: '11.5px' }} >&nbsp;&nbsp;* Backup copy jobs copy data to a second location, where they will be held immutable  for {feature.featureValue} days.*/}
                        {/*                            </div>*/}
                        {/*                        }*/}
                        {/*                        {appContext.isEditJob ? <></> : <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: '11.5px' }} >&nbsp;&nbsp;* This option can be enabled by returning to edit once you have created your primary backup job.<br></br>*/}
                        {/*                        </div>*/}
                        {/*                        }*/}
                        {/*                        {(feature.length === 0 || feature?.featureValue === "") &&*/}
                        {/*                            <p style={{ color: "red", fontFamily: "'Poppins', sans-serif", fontSize: '11.5px' }}>&nbsp;&nbsp;* This feature is not included in your package.<br></br></p>*/}
                        {/*                        }*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </td>*/}
                        {/*            <td>*/}
                        {/*                <div>*/}
                        {/*                    <span className="wizardtext">Select a repository to define your retention period.</span>*/}
                        {/*                    <span id="ValidatorMailBoxRepo" style={{ color: "red" }} >{error}</span>*/}
                        {/*                    <table cellSpacing="5" cellPadding="5">*/}
                        {/*                        <tbody>*/}
                        {/*                            <tr>*/}
                        {/*                                <th> Job</th>*/}
                        {/*                                <th> Repository</th>*/}
                        {/*                            </tr>*/}
                        {/*                            {*/}
                        {/*                                repositoryEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td> <label> {jobName}</label></td>*/}
                        {/*                                        <td>*/}
                        {/*                                            <select className="form-select" value={defaultRepo != "" ? defaultRepo : "null"} onChange={onRepoNameChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                </tr>*/}
                        {/*                            }*/}
                        {/*                            {*/}
                        {/*                                mailboxEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td><label> {jobName}{_mailbox}</label></td>*/}
                        {/*                                    <td>*/}
                        {/*                                            <select className="form-select" value={mailRepo !== "" ? mailRepo : "null"} onChange={onMailRepoChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                    {showMailWarning &&*/}
                        {/*                                        <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Mailbox Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>*/}
                        {/*                                    }*/}
                        {/*                                </tr>*/}
                        {/*                            }*/}
                        {/*                            {*/}
                        {/*                                archiveMailboxEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td><label> {jobName}{_archiveMailbox}</label></td>*/}
                        {/*                                    <td>*/}
                        {/*                                            <select className="form-select" value={archiveMailRepo !== "" ? archiveMailRepo : "null"} onChange={onArchiveMailRepoChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                    {showArchiveWarning &&*/}
                        {/*                                        <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Archive Mailbox Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>*/}
                        {/*                                    }*/}
                        {/*                                </tr>*/}
                        {/*                            }*/}
                        {/*                            {*/}
                        {/*                                onedriveEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td><label> {jobName}{_onedrive}</label></td>*/}
                        {/*                                    <td>*/}
                        {/*                                            <select className="form-select" value={onedriveRepo !== "" ? onedriveRepo : "null"} onChange={onOnedriveRepoChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                    {showOnedriveWarning &&*/}
                        {/*                                        <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Onedrive Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>*/}
                        {/*                                    }*/}
                        {/*                                </tr>*/}
                        {/*                            }{*/}
                        {/*                                siteEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td><label> {jobName}{_sites}</label></td>*/}
                        {/*                                    <td>*/}
                        {/*                                        <select className="form-select" value={siteRepo != "" ? siteRepo : "null"} onChange={onSiteRepoChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                    {showSiteWarning &&*/}
                        {/*                                        <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Sharepoint Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>*/}
                        {/*                                    }*/}
                        {/*                                </tr>*/}
                        {/*                            }{*/}
                        {/*                                teamsEnabled &&*/}
                        {/*                                <tr>*/}
                        {/*                                    <td><label> {jobName}{_teams}</label></td>*/}
                        {/*                                    <td>*/}
                        {/*                                            <select className="form-select" value={teamsRepo != "" ? teamsRepo : "null"} onChange={onTeamsRepoChanged}>*/}
                        {/*                                            <option value={"null"}>Select Repository</option>*/}
                        {/*                                            {repos}*/}
                        {/*                                        </select>*/}
                        {/*                                    </td>*/}
                        {/*                                    {showTeamsWarning &&*/}
                        {/*                                        <td><i class="fa fa-exclamation-triangle" style={{ color: "#ff9100" }} title="Teams Repository type is different and there might be a delay in the job backup due to server configurations"></i></td>*/}
                        {/*                                    }*/}
                        {/*                                </tr>*/}
                        {/*                            }*/}
                        {/*                        </tbody>*/}
                        {/*                    </table>*/}
                        {/*                </div>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                    </div>
                    <div className="card-footer p-0">
                        {
                            inProgress &&
                            <div style={{ position: 'absolute', height: '100%', width: '100%', background: 'white',top:'0px', opacity: '0.8' }}>
                                <div className="justify-content-center align-items-center d-flex h-100" >
                                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                                </div>
                            </div>
                        }
                        {
                            !inProgress &&
                            <div>
                                <table cellSpacing="5" cellPadding="5" align="right">
                                    <tbody>
                                        <tr>
                                            <td >
                                                    <ActionButtons {...props} isDisabled={finishDisabled} lastStep={validate} enableCreateRepos={enableCreateRepos} enableCreateRepository={enableCreateRepository} CreateRepos={CreateRepos} CreateRepository={CreateRepository} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                        
                    
                </div>
                <input type="hidden" name="btnhelp" id="btnhelp" />
            </div>
            {/*<HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step4-Scheduling.html" />*/}
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
            <RepoModalPopup show={showModal} popupSwap={popupSwap} callingEvent={callingEvent} repository={popUpRepo} encryptionEnabled={appContext.repositories.EncryptionEnabled} baseUrl={Urls.SERVER_PATH} clearData={clearData} cancel={cancel} orgId={appContext.orgId} orgName={appContext.tenantId} alertPop={closeAlertModal} repoArray={backupArray} callbackRepoRefresh={repoRefresh} repoModalClass={'create-repo-job-modal'} retention={(Object.keys(priceModel).length !== 0 && priceModel.features.length !== 0) ? priceModel.features.find(feature =>  feature.featureType === "RETENTION").featureValue : 1} immutability={(Object.keys(priceModel).length !== 0 && priceModel.features.length !== 0) ? priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").featureValue : "30"} />
        </div>
    );
}
export default Step4;