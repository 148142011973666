import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Brush, } from 'recharts';
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import AuthenticationContext from "../../Store/Authentication-Context";
import DatePicker from 'react-datepicker';
import { NavBar } from "../NavBar";
//import Spinner from 'react-bootstrap/Spinner';
import LoaderGif from "../../images/365 loading.gif";


const pad = (num) => num < 10 ? '0' + num : num;
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        value={value}
        onClick={onClick}
        readOnly
        ref={ref}
        className="form-control"
    />
));
function getDayOfWeek(dateString) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const now = new Date();

    return days[date.getDay()];
}

const format12Hour = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = pad(minutes);
    return `${pad(hours)}:${strMinutes} ${ampm}`;
};

const CustomTick = (props) => {
    const { x, y, payload } = props;
    const { value, index } = payload;
    const date = new Date(value);
    const now = new Date();
    let formattedDate = "Date";
    try {
        formattedDate = String(date.getDate());
    } catch (error) {
        console.log(error);
    }

    const isToday = (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    );

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {formattedDate}
            </text>
            <text x={1} y={15} dy={20} textAnchor="middle" fill="#999" style={{ fontSize: '0.8em' }}>
                {isToday ? 'Now' : getDayOfWeek(value).substring(0, 3)}
            </text>
        </g>
    );
};


const CustomTick2 = (props) => {
    const { x, y, payload } = props;
    const { value, index } = payload;
    const date = new Date(value);
    let formattedTime = "";
    try {
        formattedTime = format12Hour(date);
    } catch (error) {
        console.log(error);
    };

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                <tspan x="0" dy="1.2em" style={{ fontSize: '0.8em' }} >{formattedTime.substring(0, 5)}</tspan>
                <tspan x="0" dy="1.2em" style={{ fontSize: '0.6em' }}>{formattedTime.substring(5)}</tspan>
            </text>
        </g>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    const date = new Date(label);
    let formattedTime = "";
    try {
        formattedTime = format12Hour(date);
    } catch (error) {
        console.log(error);
    }
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                <div>
                    {formattedTime}
                </div>
                <div>
                    {`${String(pad(date.getDate()))}`}/
                    {`${String(pad(date.getMonth() + 1))}`}/
                    {`${String(pad(date.getFullYear()))}`}
                </div>
                {payload.map((entry, index) => (
                    <div key={`item-${index}`} style={{ color: entry.color }}>
                        {entry.name}: {entry.value}
                    </div>
                ))}
            </div>
        );
    }
    return null;
};


class WeeklyChart extends React.Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = {
            week_day: true,
            selectedDateTime: new Date(),
            myArray: [],
            data1: [],
            data2: [],
            cardDate: { running: 0, failed: 0, stopped: 0, longRunning: 0, longSeeding: 0, label: 'Live' },
            isDataReady:false
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }
    handleBarClick = async (data) => {
        this.setState({ isDataReady: false });
        const pad = (number) => (number < 10 ? '0' : '') + number;
        const { createdDate } = data;
        this.setState({
            cardDate: {
                running: data.Running, failed: data.Failed, stopped: data.Stopped, longRunning: data.longRunning, longSeeding: data.longSeeding,
                label: String(pad(new Date(createdDate).getDate())) + '/' +
                    String(pad(new Date(createdDate).getMonth() + 1)) + '/' +
                    String(pad(new Date(createdDate).getFullYear())) + '  ' +
                    String(pad(new Date(createdDate).getHours())) + ':' +
                    String(pad(new Date(createdDate).getMinutes())) 
            }
        });
        const chartDateTime = new Date(createdDate);
        let dateTime = '';
        const now = new Date();
        if (
            chartDateTime.getFullYear() !== now.getFullYear() ||
            chartDateTime.getMonth() !== now.getMonth() ||
            chartDateTime.getDate() !== now.getDate() ||
            chartDateTime.getHours() !== now.getHours() ||
            chartDateTime.getMinutes() !== now.getMinutes()
        ) {
            dateTime = '/' + chartDateTime.getFullYear() + '-' +
                pad(chartDateTime.getMonth() + 1) + '-' +
                pad(chartDateTime.getDate()) + 'T' +
                pad(chartDateTime.getHours()) + ':' +
                pad(chartDateTime.getMinutes()) + ':' +
                pad(chartDateTime.getSeconds()) + 'Z';
        }

        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        this.setState({ week_day: false });
        try {
            const dailyResponse = await Axios.get(API_ROUTE + Routes.GET_365_DAY_JOB_MANAGEMENT + dateTime, config);
            if (dailyResponse && dailyResponse.data) {
                this.setState(prevState => ({
                    myArray: [...prevState.myArray, ...dailyResponse.data]
                }));

                const updatedData1 = dailyResponse.data.map(({ running, stopped, failed, ...rest }) => ({
                    Running: running,
                    Stopped: stopped,
                    Failed: failed,
                    ...rest
                }));

                this.setState({ data1: updatedData1 });
                this.setState({ isDataReady: true });
            }
        } catch (error) {
            console.error("Error fetching daily data:", error);
        }
    };
    handleChartClick = async () => {


    };

    handleDateChange = (date) => {
        this.setState({ selectedDateTime: date });
    };

    fetchData = async () => {
        
        this.setState({ isDataReady: false });
        const { selectedDateTime } = this.state;
        let dateTime = '';
        const now = new Date();
        if (
            selectedDateTime.getFullYear() !== now.getFullYear() ||
            selectedDateTime.getMonth() !== now.getMonth() ||
            selectedDateTime.getDate() !== now.getDate() ||
            selectedDateTime.getHours() !== now.getHours() ||
            selectedDateTime.getMinutes() !== now.getMinutes()
        ) {
            dateTime = '/' + selectedDateTime.getFullYear() + '-' +
                pad(selectedDateTime.getMonth() + 1) + '-' +
                pad(selectedDateTime.getDate()) + 'T' +
                pad(selectedDateTime.getHours()) + ':' +
                pad(selectedDateTime.getMinutes()) + ':' +
                pad(selectedDateTime.getSeconds()) + 'Z';
        }
        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        this.setState({ week_day: true });
        try {
            const weeklyResponse = await Axios.get(API_ROUTE + Routes.GET_365_WEEK_JOB_MANAGEMENT + dateTime, config);
            if (weeklyResponse && weeklyResponse.data) {
                this.setState(prevState => ({
                    myArray: [...prevState.myArray, ...weeklyResponse.data]
                }));
                const updatedData1 = weeklyResponse.data.map(({ running, stopped, failed, ...rest }) => ({
                    Running: running,
                    Stopped: stopped,
                    Failed: failed,
                    ...rest
                }));
                this.setState({ data1: updatedData1 });
            }
            const liveResponse = await Axios.get(API_ROUTE + Routes.GET_365_JOB_LIVE_STATUS, config);
            if (liveResponse && liveResponse.data) {
                this.setState({ cardDate: { running: liveResponse.data.running, failed: liveResponse.data.failed, stopped: liveResponse.data.stopped, longRunning: liveResponse.data.longRunning, longSeeding: liveResponse.data.longSeeding, label: 'Live' } });
                this.setState(prevState => ({
                    myArray: [...prevState.myArray, liveResponse.data]
                }));
                this.setState({ data2: liveResponse.data });
            }
            this.setState({ isDataReady: true });

        } catch (error) {
            this.setState({ isDataReady: true });
            console.error('Error fetching job management data:', error);

        }
    };

    render() {
        const { selectedDateTime } = this.state;
        const { week_day } = this.state.week_day;
        const visibi = week_day ? 'hidden' : 'visible';
        this.state.data1.forEach(obj => {
            if (obj['createdDate']) {
                const createdDate = new Date(obj['createdDate']);
                const today = new Date();
                if (obj['createdDate'] === "0001-01-01T00:00:00") {
                    obj['createDay'] = 'Now';
                } else {
                    obj['createDay'] = getDayOfWeek(obj['createdDate']);
                }
            }
        });

        return (
            <>< NavBar page="jobmanagement" authContext={this.context} />
                <div style={{ maxHeight: '95vh', overflowX: 'auto' }}>
                        
                        <div className="ms-2 SectionTitle" style={{
                            fontSize: '2vw',
                            fontWeight: '500'
                        }} >
                            Job Monitoring
                        </div>
                        {this.state.cardDate &&
                            <h3 className="mb-3 SectionTitle" style={{ textAlign: "center", fontSize: '30px' }}>{this.state.cardDate.label}</h3>}
                        <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                            {Object.entries(this.state.cardDate).map(([key, value]) => (
                                key !== 'label' &&
                                <div key={key} style={{ textAlign: 'center', textTransform: 'capitalize' }} >
                                    <div style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '10px',
                                        boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: key === 'running' ? '#92D050' :
                                            key === 'stopped' ? '#ED7F33' :
                                                key === 'failed' ? '#FF0000' :
                                                    '#9DC3E6',
                                        width: 140,
                                        height: 75,
                                        color: 'white',
                                        fontSize: '20px',
                                        fontWeight: '1000px'
                                    }}>
                                        {value}
                                    </div>
                                    {key}
                                </div>
                            ))}
                        </div>
                    <div className=" col-10 d-flex justify-content-center" id="datePickerRestore" style={{ marginLeft: '150px', padding: "15px 0 15px 0" }}>
                        <DatePicker
                            selected={selectedDateTime}
                            onChange={this.handleDateChange}
                            showTimeSelect
                            timeFormat="h:mm aa"
                            maxDate={new Date()}
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            dropdownMode="select"
                            className="form-control"
                            customInput={<CustomInput />}
                        />
                        <button
                            onClick={this.fetchData}
                            className="btn btn-primary ms-2"
                        >
                            Show
                        </button>
                    </div>
                    {!this.state.week_day && <button  
                        onClick={this.fetchData}
                        style={{
                            backgroundColor: '#D3D3D3',
                            color: 'white',
                            border: 'none',
                            textAlign: 'center',
                            borderRadius: '20px',
                            padding: '2px 20px',
                            cursor: 'pointer',
                            outline: 'none',
                            marginLeft: '450px',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            letterSpacing: '-0.5px',
                            alignContent: 'center'
                        }}
                    >
                        {'<'}
                    </button>}
                    {this.state.isDataReady ? <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ textAlign: 'center', alignItems: 'center' }}>
                            <div style={{ backgroundColor: 'white', padding: '20px' }}>
                                <ResponsiveContainer
                                    width={1200}
                                    height={500}>
                                    <BarChart
                                        data={this.state.data1}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        onClick={this.handleChartClick}
                                    >
                                        <CartesianGrid strokeDasharray="3 0" vertical={false} />
                                        <XAxis
                                            dataKey="createdDate"
                                            interval={0}
                                            tick={this.state.week_day ? < CustomTick /> : < CustomTick2 />}
                                        />
                                        <YAxis tickCount={6} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend y={40} wrapperStyle={{ lineHeight: '40px' }} />
                                        <ReferenceLine y={0} stroke="#000" />
                                        {!this.state.week_day && <Brush dataKey="name" height={13} y={450} stroke="#2671bfcc" />}
                                        <Bar dataKey="Failed" fill="red" barSize={30} onClick={(e) => this.handleBarClick(e.payload)} />
                                        <Bar dataKey="Running" fill="#92D050" barSize={30} onClick={(e) => this.handleBarClick(e.payload)} />
                                        <Bar dataKey="Stopped" fill="#ed7f33" barSize={30} onClick={(e) => this.handleBarClick(e.payload)} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div> : <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                        <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                    </div>}
                    
                </div>
            </>
        );
    }
}

export default WeeklyChart;



