import React, { Component } from "react";
import { loginAuthProvider } from "../LoginAuthProvider";
import { API_ROUTE, Routes } from "../Helpers/Constants";
import { Table, Spinner, Button } from "reactstrap";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from "reactstrap/lib/FormGroup";
import Form from "reactstrap/lib/Form";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import moment from "moment";
import { NavBar } from "./NavBar";
import { id } from "date-fns/locale";
import { isThursday } from "date-fns";
import AuthenticationContext from "../Store/Authentication-Context";

export class LogViewer extends Component {
  static displayName = LogViewer.name;
    static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      orgId: "",
      source: "",
      type: "",
      skip: 0,
      admin: true,
      checkedForAdmin: false,
      logs: [],
      error: false,
      errorMessage: "",
      initialGet: false,
      gettingLogs: false,
      orgSelectItems: [],
      typeSelectItems: [],
      sourceSelectItems: [],
    };
    this.getLogs = this.getLogs.bind(this);
    this.getIsAdmin = this.getIsAdmin.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getSelectItems = this.getSelectItems.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.resetLogs = this.resetLogs.bind(this);
  }

  componentDidMount() {
    this.getIsAdmin();
  }

  async getIsAdmin(event) {
    if (event) {
      event.preventDefault();
    }
      const { setAuthContext } = this.context;

      var tokenString = await setAuthContext(new Date());
    this.setState(
      {
        gettingLogs: true,
      },
      () => {
        const config = {
          headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
          },
        };
        Axios.get(API_ROUTE + Routes.GET_IS_DCS_ADMIN, config)
          .then((response) => {
            this.setState(
              {
                admin: true,
                checkedForAdmin: true,
              },
              () => {
                this.getSelectItems();
              }
            );
          })
          .catch((reason) => {
            this.setState({
              admin: false,
              checkedForAdmin: true,
            });
          });
      }
    );
  }

  async getLogs(event, direction) {
    if (event) {
      event.preventDefault();
    }
      const { setAuthContext } = this.context;

      var tokenString = await setAuthContext(new Date());
    let skip = 0;
    if (direction) {
      if (direction == "-") {
        skip = this.state.skip - 50;
        if (skip < 0) {
          skip = 0;
        }
      } else if (direction == "+") {
        skip = this.state.skip + 50;
        if (this.state.logs.length < 50) {
          skip = this.state.skip;
        }
      }
    }
    this.setState(
      {
        gettingLogs: true,
        skip: skip,
      },
      () => {
        const config = {
          headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
          },
        };
        let data = {
          OrgId: this.state.orgId,
          Date: this.state.date,
          Skip: this.state.skip,
          Source: this.state.source,
          Type: this.state.type,
          };          
        Axios.post(API_ROUTE + Routes.GET_ADMIN_LOGS, data, config)
          .then((response) => {
            let logs = response.data;
            this.setState({
              logs: logs,
              initialGet: true,
              gettingLogs: false,
            });
          })
          .catch((reason) => {
            this.setState({
              error: true,
              errorMessage: reason.response.data,
              gettingLogs: false,
            });
          });
      }
    );
  }

  async getSelectItems(event) {
    if (event) {
      event.preventDefault();
    }
      const { setAuthContext } = this.context;

      var tokenString = await setAuthContext(new Date());
    const config = {
      headers: {
            Authorisation: `Bearer ` + tokenString,
            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
      },
    };
    Axios.get(API_ROUTE + Routes.GET_SELECT_ITEMS, config)
      .then((response) => {
        let selectItems = response.data;
        let orgSelectItems = selectItems.orgSelect;
        let typeSelectItems = selectItems.typeSelect;
        let sourceSelectItems = selectItems.sourceSelect;
        this.setState(
          {
            orgSelectItems: orgSelectItems,
            typeSelectItems: typeSelectItems,
            sourceSelectItems: sourceSelectItems,
          },
          () => {
            this.getLogs();
          }
        );
      })
      .catch((reason) => {
        this.setState({
          error: true,
          errorMessage: reason.response.data,
        });
      });
  }

  handleDateChange(event) {
      let date = moment(event.target.value).format("YYYY-MM-DDTHH:mm:ss.sssZ");      
    this.setState(
      {
        date: date
      }
    );
  }

  handleOrgChange(event) {
    let orgId = event.target.value;
    this.setState(
      {
        orgId: orgId,
        skip: 0,
      },
      () => {
        this.getLogs();
      }
    );
  }

  handleSourceChange(event) {
    let source = event.target.value;
    this.setState(
      {
        source: source,
        skip: 0,
      },
      () => {
        this.getLogs();
      }
    );
  }

  handleTypeChange(event) {
    let type = event.target.value;
    this.setState(
      {
        type: type,
        skip: 0,
      },
      () => {
        this.getLogs();
      }
    );
  }

  resetLogs(event){
    event.preventDefault();
    this.setState({
      date: new Date(),
      orgId: "",
      source: "",
      type: "",
      skip: 0,
      logs: []
    },()=>{
      this.getLogs();
    })
  }

  render() {
    if (!this.state.checkedForAdmin) {
      return <p>Loading.</p>;
    }
    if (this.state.checkedForAdmin && !this.state.admin) {
      return <p>Error.</p>;
    }
    let logRows = [];
    for (var i = 0; i < this.state.logs.length; i++) {
      const log = this.state.logs[i];
      let eventTime = moment(log.eventTime)
        .format("DD/MM/YYYY HH:mm:SS")
        .toString();
      let logRow = (
        <tr key={i}>
          <td>{log.eventId}</td>
          <td>{eventTime}</td>
          <td>{log.eventSource}</td>
          <td>{log.eventType}</td>
          <td
            style={{
              maxWidth: "400px",
              maxHeight: "400px",
              overflowY: "scroll",
              overflowX: "auto",
            }}
          >
            {log.eventDetail}
          </td>
          <td>{log.orgID}</td>
          <td>{log.eventUser}</td>
        </tr>
      );
      logRows.push(logRow);
    }
    let loadingSpinner = null;
    let logTable = null;
    if (this.state.gettingLogs) {
      loadingSpinner = <Spinner color="primary" />;
      logTable = <div>{loadingSpinner}</div>;
    } else {
      logTable = (
        <Table>
          <thead>
            <tr>
              <th>Event ID</th>
              <th>Event Time</th>
              <th>Event Source</th>
              <th>Event Type</th>
              <th>Detail</th>
              <th>Organisation</th>
              <th>Event User</th>
            </tr>
          </thead>
          <tbody>
            {logRows}
            {loadingSpinner}
          </tbody>
        </Table>
      );
    }

    var noOption = (
      <option key="none" value={""}>
        ---
      </option>
    );
    let orgSelectItems = [];
    orgSelectItems.push(noOption);
    if (this.state.orgSelectItems) {
      if (this.state.orgSelectItems.length > 0) {
        for (var i = 0; i < this.state.orgSelectItems.length; i++) {
          const orgSelectItem = this.state.orgSelectItems[i];
          var selectItem = (
            <option key={i} value={orgSelectItem.id}>
              {orgSelectItem.name}
            </option>
          );
          orgSelectItems.push(selectItem);
        }
      }
    }
    let sourceSelectItems = [];
    sourceSelectItems.push(noOption);
    if (this.state.sourceSelectItems) {
      if (this.state.sourceSelectItems.length > 0) {
        for (var i = 0; i < this.state.sourceSelectItems.length; i++) {
          const sourceSelectItem = this.state.sourceSelectItems[i];
          var selectItem = (
            <option key={i} value={sourceSelectItem}>
              {sourceSelectItem}
            </option>
          );
          sourceSelectItems.push(selectItem);
        }
      }
    }
    let typeSelectItems = [];
    typeSelectItems.push(noOption);
    if (this.state.typeSelectItems) {
      if (this.state.typeSelectItems.length > 0) {
        for (var i = 0; i < this.state.typeSelectItems.length; i++) {
          const typeSelectItem = this.state.typeSelectItems[i];
          var selectItem = (
            <option key={i} value={typeSelectItem}>
              {typeSelectItem}
            </option>
          );
          typeSelectItems.push(selectItem);
        }
      }
    }
    let minusButton = null;
    let plusButton = null;
    if (this.state.logs && this.state.logs.length > 0) {
      let minusDisabled = false;
      let plusDisabled = false;
      if (this.state.logs.length < 50 || this.state.gettingLogs) {
        plusDisabled = true;
      }
      if (this.state.skip == 0 || this.state.gettingLogs) {
        minusDisabled = true;
      }
      minusButton = (
        <Button disabled={minusDisabled} onClick={(e) => this.getLogs(e, "-")}>
          -
        </Button>
      );
      plusButton = (
        <Button disabled={plusDisabled} onClick={(e) => this.getLogs(e, "+")}>
          +
        </Button>
      );
    }
    return (
      <div className="m-1">
        <NavBar page="eventlogs" />
        <div className="row">
          <div className="col">
            <Form>
              <FormGroup>
                <Label>Date</Label>
                <Input
                  type="date"
                  name="dateSelect"
                  id="dateSelect"
                  value={moment(this.state.date).format("YYYY-MM-DD").toString()}
                  onChange={this.handleDateChange}/>
                {/*<div className="row">
                  <DatePicker
                    disabled={this.state.gettingLogs}
                    selected={this.state.date}
                    onChange={(date) => this.handleDateChange(date)}
                    dateFormat="dd/MM/yyyy"
                    className="pb-1 pt-1 datepicker"
                  />
                </div>*/}
              </FormGroup>
            </Form>
          </div>
          <div className="col">
            <Form>
              <FormGroup>
                <Label>Org</Label>
                <Input
                  type="select"
                  name="orgSelect"
                  id="orgSelect"
                  onChange={this.handleOrgChange}
                  disabled={this.state.gettingLogs}
                  value={this.state.orgId}
                >
                  {orgSelectItems}
                </Input>
              </FormGroup>
            </Form>
          </div>
          <div className="col">
            <Form>
              <FormGroup>
                <Label>Source</Label>
                <Input
                  type="select"
                  name="sourceSelect"
                  id="sourceSelect"
                  onChange={this.handleSourceChange}
                  disabled={this.state.gettingLogs}
                  value={this.state.source}
                >
                  {sourceSelectItems}
                </Input>
              </FormGroup>
            </Form>
          </div>
          <div className="col">
            <Form>
              <FormGroup>
                <Label>Type</Label>
                <Input
                  type="select"
                  name="typeSelect"
                  id="typeSelect"
                  onChange={this.handleTypeChange}
                  disabled={this.state.gettingLogs}
                  value={this.state.type}
                >
                  {typeSelectItems}
                </Input>
              </FormGroup>
            </Form>
          </div>
          <div className="col-1">
            <Form>
              <FormGroup>
                <div
                  className="spacer"
                  style={{ marginBottom: "8px", minHeight: "24px" }}
                ></div>
                <Button
                  onClick={this.getLogs}
                  disabled={this.state.gettingLogs}
                >
                  Get Logs
                </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="col">
            <Form>
              <FormGroup>
                <div
                  className="spacer"
                  style={{ marginBottom: "8px", minHeight: "24px" }}
                ></div>
                <Button
                  onClick={this.resetLogs}
                  disabled={this.state.gettingLogs}
                >
                  Reset
                </Button>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col">{minusButton}</div>
          <div className="col"></div>
          <div className="col">{plusButton}</div>
          <div className="col"></div>
        </div>
        <div className="row">{logTable}</div>
      </div>
    );
  }
}
