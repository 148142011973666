import React, { useState, useEffect, useRef, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import { AgGridReact } from 'ag-grid-react';
import { Container } from 'react-bootstrap';
import { NavBar } from "../NavBar";
import LoaderGif from "../../images/365 loading.gif";
import { Modal } from "reactstrap";
import Exchanges from "../../images/exchange.png";
import Onedrives from "../../images/onedrive.png";
import Sharepoints from "../../images/sharepoint.png";
import Teams from "../../images/teams-icon.png";
import LicenseIcon from "../../images/licenseicon.png";
import { AppContext } from "../MainComponents/AppContext";

const TenantSummary = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("orgId")
    const orgName = urlParams.get("orgName");
    const { setAuthContext } = useContext(AuthenticationContext);
    const authContext = useContext(AuthenticationContext);
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState();
    const [licenseInfo, setLicenseInfo] = useState([]);
    const [columnDefs, setColumnDefs] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [metrics, setMetrics] = useState({});
    const [header, setHeaderName] = useState();
    const [appContext] = useContext(AppContext);
    async function getTenantSummary() {
        var token = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + token,
            },
        };
        
        Axios.get(API_ROUTE + Routes.GET_TENANT_SUMMARY + "/" + orgId, config)
            .then((response) => {
                if (response.data) {
                    let responseData = response.data;
                    const calculateMetrics = (key) => {
                        const users = responseData[key];
                        const totalCount = users.length;
                        const totalUsage = users.reduce((acc, user) => acc + user.usageSizeBytes, 0);
                        const licenceCount = users.licenseCount;
                        const icon = key === "top10MailUsers" ? Exchanges :
                                     key === "top10SharePointUsers" ? Sharepoints :
                                     key === "top10OneDrivesUsers" ? Onedrives :
                                    key === "top10TeamsUsers" ? Teams : LicenseIcon;
                        const totalUsers = key === "top10MailUsers"
                            ? "Total Mail Users"
                            : key === "top10SharePointUsers"
                                ? "Total SharePoint Sites"
                                : key === "top10OneDrivesUsers"
                                    ? "Total OneDrive Sites"
                                    : key === "top10TeamsUsers"
                                        ? "Total Teams Users"
                                        : "Total Users";

                        const usersData = (key === "assignedLicense"? users : users.slice(0,10)).map((user, index) => ({
                            id: index + 1,
                            name: user.displayName,
                            userid: user.userId,
                            spName: user.sharepointName,
                            spWebUrl: user.spWebUrl,
                            value: key === "assignedLicense" ? user.licenseCount : (user.usageSizeBytes < 1024
                                ? user.usageSizeBytes + " KB"
                                : (user.usageSizeBytes / (1024 * 1024)).toFixed(2) + " MB"),

                        }))

                        return { totalCount, totalUsage, licenceCount, usersData, icon, totalUsers };
                    };

                    setMetrics({
                        Top10ExchangeUsers: calculateMetrics("top10MailUsers"),
                        Top10SharepointUsers: calculateMetrics("top10SharePointUsers"),
                        Top10OnedriveUsers: calculateMetrics("top10OneDrivesUsers"),
                        Top10TeamsUsers: calculateMetrics("top10TeamsUsers"),
                        UsersLicenseInfo: calculateMetrics("assignedLicense"),
                    });
                    setLicenseInfo(JSON.parse(responseData["licenseInfo"]));
                }
            })
            .catch((reason) => {
                console.error("Failed to fetch tenant summary:", reason);
            }).finally(() => {
                setLoading(false); // Hide loader
            });
    }
    useEffect(() => {
        getTenantSummary();
    }, []);
    const handleKpiCardClick = (data, titleName, key = "") => {

        if (titleName === "AssignedUsersLicenseInfo") {
            setHeaderName("Assigned License Info");
            setColumnDefs([
                { headerName: "Name", field: "SkuPartNumber", sortable: true, filter: true },
                { headerName: "Enabled Licence", field: "EnabledUnits", sortable: true, filter: true },
                {
                    headerName: "Assigned Licence",
                    field: "ConsumedUnits",
                    sortable: true,
                    filter: true,
                    cellRenderer: (params) => {
                        const EnabledLicense = params.data.EnabledUnits || 0;
                        const assignedPercentage = (params.value / EnabledLicense) * 100;
                        return `
            <div style="display: flex; align-items: center;">
                <div style="width: 100px; background-color: #e0e0e0; border-radius: 4px; margin-right: 8px; height: 8px;">
                    <div style="width: ${assignedPercentage}%; background-color: #4caf50; height: 100%; border-radius: 4px;"></div>
                </div>
                <span>${EnabledLicense}/${params.value}</span>
            </div>
        `;
                    },
                    headerValueGetter: (params) => {
                        const displayedRows = params.api.getDisplayedRowCount();
                        if (displayedRows === 0) {
                            return "Assigned Licence";
                        }

                        const firstRow = params.api.getDisplayedRowAtIndex(0)?.data;
                        const totalEnabled = firstRow?.EnabledUnits || 0;
                        const totalConsumed = firstRow?.ConsumedUnits || 0;
                        return `Assigned Licence ${totalEnabled}/${totalConsumed}`;
                    },
                },
                {
                    headerName: "Available Licence",
                    valueGetter: (params) => params.data.ConsumedUnits - params.data.EnabledUnits,
                    sortable: true,
                    filter: true,
                },
            ]);
        } else {
            setHeaderName(titleName);

            const baseColumns = [
                { headerName: "ID", field: "id", minWidth: 50, maxWidth: 70, flex: 1 },
                { headerName: "Name", field: "name", tooltipField: "name" },
                { headerName: "User ID", field: "userid", tooltipField: "userid", minWidth: 150 },
                { headerName: titleName === "Users License Info" ? "Licence Count" : "Usage Value", field: "value" },
            ];

            if (titleName === "Top 10 SharePoint Sites") {
                baseColumns.push(
                    { headerName: "SP Name", field: "spName", tooltipField: "spName" },
                    { headerName: "SP Web URL", field: "spWebUrl", tooltipField: "spWebUrl", minWidth: 150 }
                );
            }
            setColumnDefs(baseColumns);
        }
        setRowData(data);
        setModalOpen(true);
    };


    const closeModal = () => {
        setModalOpen(false);
    };
    const formatBytes = (bytes) => {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    };
    const cardStyle = {
        backgroundColor: '#f7f9fc',
        border: '1px solid #dfe3e8',
        borderRadius: '8px',
        padding: '30px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const defaultColDef = {
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: false,
        resizable: true,
        autoHeight: true,

    };
    
    return (
        <Container width='100%' id="">

            <NavBar page="tenantsummary" authContext={authContext} />
            <div style={{ padding: "20px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                        fontWeight: 500,
                        padding: "5px",
                    }}
                >
                    <h5>Tenant Summary</h5>
                    <h5>{orgName}</h5>
                </div>
                {loading ? (
                    <div style={{ textAlign: "center", marginTop: "20%" }}>
                        <img src={LoaderGif} alt="Loading..." style={{ height: "50px" }} />
                    </div>
                ) : (
                    <div
                        className="p-2"
                        style={{
                            maxHeight: "80vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                            margin: "15px",
                        }}
                    >
                        <div className="row">
                            {Object.entries(metrics).map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <div className="col-12 col-md-6 col-lg-4 mb-3">

                                        <div style={cardStyle}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div
                                                    style={{
                                                        borderRadius: "15px",
                                                        background: "lightgray",
                                                        padding: "0.7rem",
                                                    }}
                                                >
                                                    <img
                                                        src={value.icon} alt="icon"
                                                        style={{ width: "46px", borderRadius: "5px" }}
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <h3 style={{ margin: "0" }}>{key.replace(/([A-Z])/g, " $1")}</h3>
                                                    <span
                                                        style={{
                                                            fontSize: "15px",
                                                            color: "gray",
                                                        }}
                                                    >
                                                        {key === "UsersLicenseInfo" ? "" :"Top 10 Users"}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Body Section */}
                                            <div style={{ textAlign: "left", marginTop: "15px" }}>
                                                {key === "UsersLicenseInfo" ? (
                                                    <h6 style={{ marginTop: "13px", fontSize: "18px", fontWeight: 400 }}>
                                                        <span
                                                            style={{
                                                                fontSize: "30px",
                                                                fontWeight: "700",
                                                                color: "#000",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            {licenseInfo.reduce((total, item) => total + item.ConsumedUnits, 0)}
                                                        </span>
                                                        Licence Count
                                                    </h6>
                                                ) : (
                                                    <h6 style={{ marginTop: "13px", fontSize: "18px", fontWeight: 400 }}>
                                                        <span
                                                            style={{
                                                                fontSize: "30px",
                                                                fontWeight: "700",
                                                                color: "#000",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            {formatBytes(value.totalUsage)}
                                                        </span>
                                                        Total Usage
                                                    </h6>
                                                )}
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        background: "#abd2f9",
                                                        padding: "4px 11px",
                                                        fontWeight: "600",
                                                        borderRadius: "20px",
                                                        fontSize: "14px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    {value.totalUsers }: {value.totalCount}
                                                </span>
                                                <span
                                                    style={{
                                                        color: "#3176bc",
                                                        fontSize: "16px",
                                                        paddingLeft: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        let titleName;
                                                        switch (key) {
                                                            case "Top10ExchangeUsers":
                                                                titleName = "Top 10 Mail Users";
                                                                break;
                                                            case "Top10SharepointUsers":
                                                                titleName = "Top 10 SharePoint Sites";
                                                                break;
                                                            case "Top10OnedriveUsers":
                                                                titleName = "Top 10 OneDrive Sites";
                                                                break;
                                                            case "Top10TeamsUsers":
                                                                titleName = "Top 10 Teams Users";
                                                                break;
                                                            case "UsersLicenseInfo":
                                                                titleName = "Users License Info";
                                                                break;
                                                            default:
                                                                titleName = "Users Info";
                                                        }

                                                        handleKpiCardClick(value.usersData, titleName);
                                                    }}
                                                >
                                                    {key === "UsersLicenseInfo" ? "View: Users Info |" : "View Top 10"}
                                                </span>
                                            

                                                {key === "UsersLicenseInfo" && (
                                                    <div style={{ textAlign: "left", marginLeft:"10px" }}>
                                                        <span
                                                            style={{
                                                                    color: "#3176bc",
                                                                fontSize: "16px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => handleKpiCardClick(licenseInfo, "Assigned"+key )}
                                                        >
                                                            Assigned Licence
                                                        </span>
                                                </div>
                                                )}
                                            </div>
                                       
                                        </div>
                                        </div>
                                </React.Fragment>
                            ))}
                        </div>

                        {/* Modal and other content here */}
                            <div className="mt-3">

                                <div>

                                        <Modal
                                            className="modal-lg"
                                            isOpen={isModalOpen}
                                            onRequestClose={closeModal}
                                            style={{
                                                content: {
                                                    top: "50%",
                                                    left: "50%",
                                                    right: "50%",
                                                    bottom: "auto",
                                                    marginLeft: "-50%",
                                                    transform: "translate(-50%, -50%)",
                                                    width: "150%",
                                                    height: "100%",
                                                },
                                            }}
                                        >
                                        <div style={{ width: "100%" }}>
                                            <h4 style={{ textAlign: "center", marginBottom: "10px", marginTop: "10px" }}> {header}</h4>
                                                <button
                                                    onClick={closeModal}
                                                    style={{
                                                        position: "absolute",
                                                        top: "10px",
                                                        right: "10px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        padding: "5px 10px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    X
                                                </button>
                                                <div className="col-12 col-md-6 col-lg-4 mb-3" style={{ width: "100%" }}>
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            columnDefs={columnDefs}
                                                            defaultColDef={defaultColDef}
                                                            rowData={rowData}
                                                            pagination={!["Top 10 Mail Users", "Top 10 SharePoint Sites", "Top 10 OneDrive Sites", "Top 10 Teams Users"].includes(header)}
                                                            paginationPageSize={10}
                                                            overlayLoadingTemplate={`<div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div></div>`}
                                                            domLayout="autoHeight" // Optional: Adjust grid height dynamically
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </Modal>
                                    </div>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default TenantSummary;