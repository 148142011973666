import React, { useState, useEffect, useRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import "../../CSS/general.css";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Axios from "axios";
import { loginAuthProvider } from "../../LoginAuthProvider";

import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import decryptParameter from "../../Helpers/DeCryptParameter";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavBar } from "../NavBar";
import LoaderGif from "../../images/365 loading.gif";
import { trim } from "lodash";

const Invoice = () => {
    const [rowData, setRowData] = useState([]);
    const [tableRefresh, setTableRefresh] = useState(true);
    const [rowLinesData, setRowLinesData] = useState([]);
    const [show, setShow] = useState(false);
    const [urlParams, setParams] = useState(new URLSearchParams(window.location.search));
    const gridApi = useRef(null);
    const gridApiChild = useRef(null);

    useEffect(() => {
        async function fetchInvoices() {
            try {
                const token = await loginAuthProvider.getAccessToken();
                const tokenString = token.accessToken;
                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };
                let orgid = "0";
                if (urlParams.get("isAdmin") === "true") {
                    orgid = (sessionStorage.getItem("ResellerID")) === "1" ? "0" : sessionStorage.getItem("orgId")
                }
                else {
                    orgid = urlParams.get("orgId") ? urlParams.get("orgId") : sessionStorage.getItem("ManageOrgId");
                }
                const inputData = {
                     
                    OrgId: orgid,
                    Username: sessionStorage.getItem("Username") ? decryptParameter(sessionStorage.getItem("Username")) : "",
                };
                const response = await Axios.post(API_ROUTE + Routes.GET_INVOICE_LIST, inputData, config);

                if (response && response.data && response.data.result) {
                    const modifiedData = response.data.result.map(row => {
                        return {
                            ...row,
                            showChildGrid: false, // Initially hide the child grid

                        };
                    });

                    setRowData(modifiedData);
                    setTableRefresh(false);
                }
            
            } catch (error) {
                console.error('Error fetching invoices:', error);
            }
        }

        fetchInvoices();

    }, []);

    const dateFormatter = (params) => {
        return params.value ? moment(params.value, 'DD/MM/YYYY').format("DD-MM-YYYY") : '';
    };
    const dateFormatterValue = (value) => {
        return value ? moment(value, 'DD/MM/YYYY').format("DD-MM-YYYY") : '';
    };
    const renderTooltip = (props) => (
        <Tooltip id={`tooltip-${props.id}`} {...props}>
            {props.text}
        </Tooltip>
    );
    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            if (gridApi.current) {
                gridApi.current.sizeColumnsToFit();
            }
        }, 100); // Adjust the delay as needed
    };
  

    const columnDefs = [
        {
            headerName: '', width: 50,
            cellRendererFramework: (params) => {
                const handleClick = () => {

                    setRowLinesData(params.data.invoiceLines);
                    if (gridApi.current) {
                        gridApi.current.sizeColumnsToFit();
                    }
                    setShow(true);
                   
                };
                const hasInvoiceLines = params.data.invoiceLines && params.data.invoiceLines.length > 0;
                return(
                hasInvoiceLines === true ?
                    (
                        <a onClick={handleClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                            </svg>
                        </a>
                    ):
                    <div></div>
                );
            }
        },
        {
            headerName: 'Organisation Name', field: 'orgName', sortable: true, filter: true, width: 400, comparator: function (valueA, valueB) {
                // Handle null values
                if (valueA == null && valueB == null) {
                    return 0;
                }
                if (valueA == null) {
                    return -1;
                }
                if (valueB == null) {
                    return 1;
                }

                // Handle empty values
                if (valueA.trim() === '' && valueB.trim() === '') {
                    return 0;
                }
                if (valueA.trim() === '') {
                    return -1;
                }
                if (valueB.trim() === '') {
                    return 1;
                }

                // Compare non-null and non-empty values
                const normalizedA = valueA.toLowerCase();
                const normalizedB = valueB.toLowerCase();

                if (normalizedA < normalizedB) {
                    return -1;
                }
                if (normalizedA > normalizedB) {
                    return 1;
                }
                return 0;
            }
},


        {
            headerName: 'Invoice No.', field: 'invoiceNumber', sortable: true, filter: true, width: 200, comparator: function (valueA, valueB) {
                // Handle null values
                if (valueA == null && valueB == null) {
                    return 0;
                }
                if (valueA == null) {
                    return -1;
                }
                if (valueB == null) {
                    return 1;
                }

                // Handle empty values
                if (valueA.trim() === '' && valueB.trim() === '') {
                    return 0;
                }
                if (valueA.trim() === '') {
                    return -1;
                }
                if (valueB.trim() === '') {
                    return 1;
                }

                // Compare non-null and non-empty values
                const normalizedA = valueA.toLowerCase();
                const normalizedB = valueB.toLowerCase();

                if (normalizedA < normalizedB) {
                    return -1;
                }
                if (normalizedA > normalizedB) {
                    return 1;
                }
                return 0;
            }
},
        {
            headerName: 'Invoice Date', field: 'invoiceDate', sortable: true, filter: true, width: 150, valueFormatter: dateFormatter, comparator: function (date1, date2) {
                // Custom comparator function for datetime sorting
                if (date1 === undefined || date1 === null) {
                    // Treat undefined or null as greater than any defined date
                    return 1;
                }
                if (date2 === undefined || date2 === null) {
                    // Treat undefined or null as greater than any defined date
                    return -1;
                }

                return moment(date1, 'DD/MM/YYYY').diff(moment(date2, 'DD/MM/YYYY'));
            }, },
        {
            headerName: 'Status', field: 'status', sortable: true, filter: true, width: 200, comparator: function (valueA, valueB) {
                // Handle null values
                if (valueA == null && valueB == null) {
                    return 0;
                }
                if (valueA == null) {
                    return -1;
                }
                if (valueB == null) {
                    return 1;
                }

                // Handle empty values
                if (valueA.trim() === '' && valueB.trim() === '') {
                    return 0;
                }
                if (valueA.trim() === '') {
                    return -1;
                }
                if (valueB.trim() === '') {
                    return 1;
                }

                // Compare non-null and non-empty values
                const normalizedA = valueA.toLowerCase();
                const normalizedB = valueB.toLowerCase();

                if (normalizedA < normalizedB) {
                    return -1;
                }
                if (normalizedA > normalizedB) {
                    return 1;
                }
                return 0;
            }
},
        {
            headerName: 'Net', field: 'netValue', sortable: true, filter: true, resizable: true, width: 150, comparator: function (valueA, valueB) {
                // Handle null and empty values
                if (valueA == null && valueB == null) {
                    return 0;
                }
                if (valueA == null) {
                    return -1;
                }
                if (valueB == null) {
                    return 1;
                }

                // Convert string values to numbers
                const numberA = parseFloat(valueA);
                const numberB = parseFloat(valueB);

                // Handle NaN (non-numeric) values
                if (isNaN(numberA) && isNaN(numberB)) {
                    return 0;
                }
                if (isNaN(numberA)) {
                    return -1;
                }
                if (isNaN(numberB)) {
                    return 1;
                }

                // Compare numeric values
                if (numberA < numberB) {
                    return -1;
                }
                if (numberA > numberB) {
                    return 1;
                }
                return 0;
            } },
        {
            headerName: 'Gross', field: 'grossValue', sortable: true, filter: true, width: 100, resizable: true, comparator: function (valueA, valueB) {
                // Handle null and empty values
                if (valueA == null && valueB == null) {
                    return 0;
                }
                if (valueA == null) {
                    return -1;
                }
                if (valueB == null) {
                    return 1;
                }

                // Convert string values to numbers
                const numberA = parseFloat(valueA);
                const numberB = parseFloat(valueB);

                // Handle NaN (non-numeric) values
                if (isNaN(numberA) && isNaN(numberB)) {
                    return 0;
                }
                if (isNaN(numberA)) {
                    return -1;
                }
                if (isNaN(numberB)) {
                    return 1;
                }

                // Compare numeric values
                if (numberA < numberB) {
                    return -1;
                }
                if (numberA > numberB) {
                    return 1;
                }
                return 0;
            }
},

        {
            headerName: '', width: 20,
            cellRendererFramework: (params) => {
                const handleClick = () => {
                    window.open(params.data.xeroInvUrl, '_blank', 'height=800,width=1280,status=yes,toolbar=yes,menubar=yes,location=no');
                };
                return (
                   
                    <div className="justify-content-between d-flex align-items-center">
                        {params.data.invoiceNumber && params.data.invoiceNumber.includes("INV") &&
                            <a onClick={handleClick}>
                                <OverlayTrigger key="sort-1" placement="top" delay={{ show: 50, hide: 50 }} overlay={(props) => renderTooltip({
                                    ...props, id: "sort-1", text: "Click to View Invoice\nPayment Details \n Inv Period: " + params.data.invoicePeriod + "\n Payment Date: "
                                        + dateFormatterValue(params.data.paymentDate) + "\n DD Ref: " + params.data.goPayID
                                        + "\n Note: " + params.data.note
                                })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-receipt" viewBox="0 0 16 16">  <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />  <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" /></svg>
                                </OverlayTrigger>
                            </a>
                        }
                    </div>
                );
            },
        },
    ];



    return (
        <div style={{ width: "100%" }} >
            <div style={{ width: "99%", display: 'flex', justifyContent: 'flex-end' }}>
                <a id="ContentPlaceHolder1_btnClear" style={{ color: "#007bff", cursor: 'pointer' ,float: 'inline-end' }} className="me-1">
                    <Button onClick={(e) => {
                        if (gridApi.current) {
                            gridApi.current.setFilterModel(null);
                        }
                    }
                    } variant="primary" style={{ padding: '5px 7px' }} >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="white" stroke="white" viewBox="0 0 32 32" width="20" style={{ cursor: 'pointer' }}>
                            <path strokeWidth="1.5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>
                            <path strokeWidth="1.5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>
                        </svg>
                    </Button>
                </a>
            </div>
            <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", maxHeight: '77vh', borderCollapse: "collapse", height: '76vh' }}>
                {tableRefresh ?
                    <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                        <img src={LoaderGif} alt="loading" style={{ height: '70px' }} />
                    </div> :
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        //  defaultColDef={defaultColDef}
                        animateRows={true}
                        enableCellTextSelection="true"
                        overlayLoadingTemplate={
                            `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                        overlayNoRowsTemplate={'<span className="ag-overlay-loading-center">No records to show</span>'}

                        onGridReady={(params) => {

                            gridApi.current = params.api;
                            params.api.sizeColumnsToFit();
                           
                            
                        }}
                    ></AgGridReact>
                }
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Invoice Lines</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", maxHeight: '77vh', borderCollapse: "collapse", height: '76vh' }}>
                        <AgGridReact
                            rowData={rowLinesData}
                            enableCellTextSelection="true"
                            columnDefs={[
                                { headerName: 'Description', field: 'productName', sortable: true, filter: true, width: 600 },
                                { headerName: 'Qty', field: 'qty', sortable: true, filter: true, width: 150 },
                                { headerName: 'Price', field: 'cost', sortable: true, filter: true, width: 100 },
                                { headerName: 'Disc', field: 'discount', sortable: true, filter: true, width: 100 },
                                { headerName: 'Total', field: 'total', sortable: true, filter: true, width: 100 },
                            ]}
                            onGridReady={(params) => {
                                if (gridApi.current) {

                                    gridApi.current.sizeColumnsToFit();
                                }
                            }}
                            overlayLoadingTemplate={
                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Invoice;
