import React, { Component } from "react";
import loginIcon from "../../images/loginIcon.PNG";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "reactstrap";
import newloginIcon from "../../images/icon.png";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { LEGACY_SITE } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import { RegCheck } from "../RegCheck";
import { HelpDocuments } from "../HelpDocuments";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import parse from 'html-react-parser';

export class Login extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const autoLoginString = urlParams.get("autoLogin");
        var emailString = urlParams.get("email");
        var autoLogin = false;
        if (autoLoginString) {
            autoLogin = true;
        }
        this.state = {
            autoLogin: autoLogin,
            email: emailString,
        };
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.loginContinue = this.loginContinue.bind(this);
        this.showNotifications = this.showNotifications.bind(this);
    }

    static displayName = Login.name;

    componentDidMount() {
        // this.showNotifications();
    }

    login(event) {
        event.preventDefault();
        loginAuthProvider.login();
    }

    logout(event) {
        event.preventDefault();
        loginAuthProvider.logout();
    }
    async showNotifications() {
        const config = {
            headers: {
                Authorisation: `Bearer NOT REQUIRED`,
            },
        };

        let resData = await Axios.get(API_ROUTE + Routes.GET_NOTIFICATIONS, config)
            .then((response) => {
                let data = JSON.parse(response.data);
                for (var i = 0; i < data.length; i++) {
                    let item = data[i];
                    var element = parse(item.message);
                    toast(({ closeToast }) => <div>{parse(item.message)} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                }

                return data;
            })
            .catch((reason) => {
                console.log("catch Notification: " + reason);
            });
    }

    async loginContinue() {
     
        var accountInfo = loginAuthProvider.getAccountInfo();
        var email = accountInfo.account.userName;
        //var redirectString = LEGACY_SITE + "Login.aspx?email=" + email;
        sessionStorage.removeItem('IsRestore');
        sessionStorage.removeItem('lastUpdateTime');
        sessionStorage.removeItem('ResellerOrganisation');
        sessionStorage.removeItem('ResellerOrgs');
        sessionStorage.removeItem('Resellers');
        sessionStorage.removeItem('OrgStatuses');
        sessionStorage.removeItem('PriceNodels');
        sessionStorage.removeItem('ServerPath');
        sessionStorage.removeItem('ResellerID');
        sessionStorage.removeItem('Username');
        sessionStorage.removeItem('orgId');
        sessionStorage.removeItem('orgName');
        sessionStorage.removeItem('IsAdmin');
        sessionStorage.removeItem('ManageOrgName');
        sessionStorage.removeItem('ManageOrgId');
        sessionStorage.removeItem('DomainId');
        sessionStorage.removeItem('ResellerRole');
        sessionStorage.removeItem('UserType');
        sessionStorage.removeItem('IsEndUser');
       // window.location = redirectString;
    }








    render() {
        let forceLogin = true;
        if (this.state.autoLogin) {
            forceLogin = false;
        }
        var loginButton = (
            <AzureAD provider={loginAuthProvider} forceLogin={forceLogin}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return <RegCheck login={this.loginContinue} autoLogin={this.state.autoLogin} />;
                        case AuthenticationState.Unauthenticated:
                            logout();
                            if (error) {
                                return (
                                    <>
                                        <h4>Please log in</h4>
                                        <div>
                                            <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                                CloudCover 365 uses your Microsoft account to authenticate
                                                you.
                                            </p>
                                            <span onClick={logout} style={{ cursor: "pointer" }}>
                                                <img src={microsoftSignInDarkBig} alt="Sign In" />
                                            </span>
                                        </div>
                                    </>
                                    
                                );
                            } else {
                                return (
                                    <>
                                        <h4>Please log in</h4>
                                        <div>
                                            <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                                CloudCover 365 uses your Microsoft account to authenticate
                                                you.
                                            </p>
                                            <span onClick={this.login} style={{ cursor: "pointer" }}>
                                                <img src={microsoftSignInDarkBig} alt="Sign In" />
                                            </span>
                                        </div>
                                    </>
                                    
                                );
                            }
                        case AuthenticationState.InProgress:
                            return <div><p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Authenticating...</p><Button onClick={this.logout}>Cancel</Button></div>;
                        default:
                            return (
                                <div>
                                    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        CloudCover 365 uses your Microsoft account to authenticate
                                        you.
                                    </p>
                                    <span onClick={this.login} style={{ cursor: "pointer" }}>
                                        <img src={microsoftSignInDarkBig} alt="Sign In" />
                                    </span>
                                </div>
                            );
                    }
                }}
            </AzureAD>
        );
        const FrontPageTheme = React.lazy(() => 
            import("../ThemeObjects/FrontPage")
        );
        let chosenTheme = <FrontPageTheme />;
        let themeObject = (
            <React.Suspense fallback={<></>}>{chosenTheme}</React.Suspense>
        );
        const currentYear = new Date().getFullYear();
        return (
            <div id="ContentPlaceHolder1_UpdatePanel1" style={{ maxHeight: '90vh', overflowX: 'hidden', overflowY: 'auto' }}>
                {themeObject}
                <div className="row login" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center', height: '90vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 text-center">
                            <div className="card loginbox" style={{ borderRadius: '18px', border: '3px solid #4993dc' }}>
                                <div className="card-body" style={{ paddingLeft: '17%', paddingRight: '17%' }}>
                                <img src={newloginIcon} alt="Login" style={{ height: '65px' }} />
                                
                                <div
                                    id="ContentPlaceHolder1_UsernameBasic"
                                    className="form-group"
                                ></div>
                                {loginButton}
                                <br />
                                <HelpDocuments message="Having trouble logging in?" />
                            </div>
                                <div className="card-footer" style={{ marginLeft: '8%', marginRight: '8%', marginBottom: '8%', border: '1px solid lightgray', borderRadius: '10px' }}>
                                <section className="o-panel__section reg-now-content text-center">
                                    <h4 className="small-text__light" id="login-offline-customer">
                                        Do you need to register?
                                    </h4>
                                    <a id=" " href="/SignUp">
                                        Sign up now to protect your data
                                    </a>
                                    <hr />
                                </section>
                                <section className="small">
                                    <SubscriberAgreement />
                                    |
                                    <SecurityAndPrivacy />
                                    <br />© Saas It {currentYear}
                                </section>
                            </div>

                            <span
                                id="ContentPlaceHolder1_lblLoginResponse"
                                style={{ color: "red" }}
                            ></span>
                        </div>
                        </div>
                    </div>
                    <ToastContainer limit={4} style={{ fontSize: "small", top: "80px", right: "5px" }} />
                </div>
            </div>
        );
    }
}
