import React, { useState, useEffect,useContext } from "react";
import "../../CSS/Pricing.css";
import { Button, Modal, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
//import Spinner from 'react-bootstrap/Spinner';
import { CurrencyPound, CurrencyEuro } from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom'
import { trim } from "lodash";
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif";
const Pricing = () => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [resellerId, setResellerId] = useState(0);
    const [displayOnly, setDisplayOnly] = useState(true);
    const [collapsed, setCollapsed] = useState(true);
    const [clickedPlan, setClickedPlan] = useState("");
    const [planDetails, setPlanDetails] = useState([]);
    const [showLoader, setShoeLoader] = useState(true);
    const [show, setShow] = useState(false);
    const [qparams, setQparams] = useState(new URLSearchParams(window.location.search));

    const showToast = (type, message) => {
        if (type === "success") {
            toast.success(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
        else if (type === "error") {
            toast.error(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
    };
    const handleClose = () => {
       
        if (window.opener && window.opener.opener) {
            window.opener.opener.location.reload();
        }

        // Refresh the current window
        window.location.reload();
        setShow(false);
        setClickedPlan("");
 
    };
    const handleShow = () => setShow(true);
    const handlePlanSelection = (planName, planId) => {
        setClickedPlan(planName);
        handleShow();
    };
    const handlePlanConfirm = async () => {
        let orgId = qparams.get("orgId") ? qparams.get("orgId") : "";
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        window.parent.location.reload();
        const response = await Axios.post(API_ROUTE + Routes.PRICING_UPDATE + "/" + orgId + "/" + clickedPlan, null, config)
            .then((response) => {
                setSelectedPlan(clickedPlan);
                handleClose();
            }).catch((reason) => {
                let message = "";
                if (reason) {
                    if (reason.response) {
                        if (reason.response.data) {
                            message = reason.response.data;
                        }
                    }
                }
                handleClose();
            });
    };
    const [hoveredPlan, setHoveredPlan] = useState(null);
    const handleMouseEnter = (planName) => {
        setHoveredPlan(planName);
    };

    const handleMouseLeave = () => {
        setHoveredPlan(null);
    };

    const renderTooltip = (props) => (

        <Tooltip id={`tooltip-${props.id}`} {...props}>

            {props.text}

        </Tooltip>

    );
    
    useEffect(() => {
       
        const fetchData = async () => {
            try {
                let config = {
                    headers: {
                        Authorisation: 'Bearer Token not need for Display only',
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };
                let orgId = qparams.get("orgId") ? qparams.get("orgId") : "";
                let orgName = qparams.get("orgName") ? qparams.get("orgName") : "";              
                let resellerOnlyParam = qparams.get("resellerOnly") ? qparams.get("resellerOnly") : "false";
                let displayOnlyParam = qparams.get("displayOnly") ? qparams.get("displayOnly") : "true";
                let collapsedParam = qparams.get("collapsed") ? qparams.get("collapsed") : "false";
                if (displayOnlyParam.toLowerCase() === "false") {
                    var tokenString = await setAuthContext("", new Date());
                    config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                    };
                    setDisplayOnly(false);
                }
                else if (displayOnlyParam.toLowerCase() === "false") { 
                    //orgId = resellerId;
                    //setDisplayOnly(false)
                }
                if (collapsedParam === "true") {
                    setCollapsed(true);
                }
                else if (collapsedParam === "false") {
                    setCollapsed(false);
                }
                if (!orgId && orgId === "")
                    console.log("Error fetching Organisation Details");


                const availablePlans = await Axios.get(API_ROUTE + Routes.PRICING_MODEL + "/" + orgId + "/" + orgName + "/" + resellerOnlyParam + "/" + displayOnly+"/true", config).then((response) => {
                    var result = JSON.parse(response.data);
                    //setPlanDetails(result.filter((Detail) => Detail.features.length !== 0).sort((a, b) => b.features.length - a.features.length));
                    setPlanDetails(result.filter((Detail) => Detail.features.length !== 0 && (!Detail.price.hideinPricelist)).sort((a, b) => b.features.length - a.features.length));
                    return result;
                }).catch((reason) => {
                        console.log(reason);
                    setShoeLoader(false);
                    return "Error";
                    });
                
                if (displayOnlyParam === "false") {
                    const selectedplandetails = await Axios.get(API_ROUTE + Routes.PRICING_MODEL_ID + "/" + orgId + "/" + orgName, config).then((response) => {
                        const selectedPlanDetailsJson = JSON.parse(response.data)
                        if (selectedPlanDetailsJson.price !== null) {
                            setSelectedPlan(selectedPlanDetailsJson.price.name)
                            setResellerId(selectedPlanDetailsJson.price.resellerId)
                            if (availablePlans !== "Error") {
                                setPlanDetails(availablePlans.filter((Detail) => Detail.features.length !== 0 && (!Detail.price.hideinPricelist || selectedPlanDetailsJson.price.id === Detail.price.id)).sort((a, b) => b.features.length - a.features.length));
                            }
                        }
                        return selectedPlanDetailsJson;
                    })
                        .catch((reason) => {
                            console.log(reason);
                            setShoeLoader(false);
                        });
                }
                setShoeLoader(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    return (

        <>
            
            <div className="container p-0">
                <div className="centered-div">
                    {showLoader ? (<div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                        <img src={LoaderGif} alt="loading" style={{ height: '70px' }} />
                    </div>) : planDetails.length !== 0 ? (<>
                        <div className="d-flex" style={{ position: "sticky", top: '-1px', background: '#ffffff', zIndex: '100', minWidth: 'fit-content' }}>
                            <div
                                className="col-12 col-xl-2 d-none d-xl-block"
                                style={{ position: "relative" }}
                            >
                                <div className="pricing-features">
                                    <ul className="list-group list-group-flush">
                                        <li
                                            className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between"
                                            style={{ height: "47.5px" }}
                                        ></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-xl-10 d-flex" >
                                {planDetails.map(
                                    (Detail, index) =>
                                        Detail.features.length !== 0 && (
                                            <div
                                                onMouseEnter={() => handleMouseEnter(Detail.price.name)}
                                                onMouseLeave={handleMouseLeave}
                                                className={`plans heading ${selectedPlan === Detail.price.name && !displayOnly ? "selectedplan" : ""
                                                    } ${hoveredPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                    } ${clickedPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                    }`}

                                            >
                                                <div
                                                    className="pricing-title bg-blue-4 py-1 d-flex flex-column align-items-center justify-content-center"
                                                    style={{ height: "3rem" }}
                                                >
                                                    <h6 className="m-0 text-center">{Detail.price.name}</h6>
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                        <Accordion defaultActiveKey={collapsed ? ['0'] : ['0', '1']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >Plan Details</Accordion.Header>
                                <Accordion.Body className="d-flex p-0">
                                    <div
                                        className="col-12 col-xl-2 d-none d-xl-block"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="pricing-features">
                                            <ul className="list-group list-group-flush">

                                                {planDetails.length !== 0 &&

                                                        planDetails[0].features.filter((FeatureDetail) => !FeatureDetail.smallPrint).sort((a, b) => a.sortOrder - b.sortOrder).map((feature, index) =>
                                                        <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                                            <span>{feature.featureName}</span>
                                                            <OverlayTrigger key="sort-1" placement="right" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: feature.featureDescription })}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="20"
                                                                    fill="currentColor"
                                                                    className="bi bi-info-circle"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                                                                </svg>
                                                            </OverlayTrigger>

                                                        </li>
                                                    )}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-10 d-flex">
                                        {planDetails.map(
                                            (Detail, index) =>
                                                Detail.features.length !== 0 && (
                                                    <div
                                                        onMouseEnter={() => handleMouseEnter(Detail.price.name)}
                                                        onMouseLeave={handleMouseLeave}
                                                        className={`plans ${selectedPlan === Detail.price.name && !displayOnly ? "selectedplan" : ""
                                                            } ${hoveredPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                            } ${clickedPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                            }`}
                                                    >
                                                        <div className="pricing-detail">
                                                            <ul className="list-group list-group-flush">
                                                                {Detail.features.filter((FeatureDetail) => !FeatureDetail.smallPrint)
                                                                    .sort((a, b) => a.sortOrder - b.sortOrder)
                                                                    
                                                                    .map((feature) =>
                                                                        feature.featureValue.toLowerCase() === "true" ?
                                                                            <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="32"
                                                                                    height="20"
                                                                                    fill="currentColor"
                                                                                    className="bi bi-check text-success"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
                                                                                </svg>
                                                                                <span className="text-center text-secondary d-xl-none">
                                                                                    {feature.featureName}
                                                                                </span>
                                                                            </li>
                                                                            : feature.featureValue.toLowerCase() === "false" ?
                                                                                <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="32"
                                                                                        height="20"
                                                                                        fill="currentColor"
                                                                                        className="bi bi-x text-danger"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                                                                    </svg>

                                                                                    <span className="text-center text-secondary d-xl-none">
                                                                                        {feature.featureName}
                                                                                    </span>
                                                                                </li>
                                                                                : (trim(feature.featureValue) === "")
                                                                                    ? <li className="list-group-item bg-blue-2 d-flex flex-column align-items-center justify-content-center">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="32"
                                                                                            height="20"
                                                                                            fill="currentColor"
                                                                                            className="bi bi-x text-danger"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                                                                        </svg>

                                                                                        <span className="text-center text-secondary d-xl-none">
                                                                                            {feature.featureName}
                                                                                        </span>
                                                                                    </li>
                                                                                : isNaN(parseInt(feature.featureValue)) ?
                                                                                    <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                                                                        <span style={{ height: "20px" }}>
                                                                                            {feature.featureValue}
                                                                                        </span>
                                                                                        <span className="text-center text-secondary d-xl-none">
                                                                                            {feature.featureName}
                                                                                        </span>
                                                                                    </li>
                                                                                    :  <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                                                                        <span style={{ height: "20px" }}>
                                                                                            {feature.featureValue}{" "}
                                                                                            {feature.featureUnit}
                                                                                        </span>
                                                                                        <span className="text-center text-secondary d-xl-none">
                                                                                            {feature.featureName}
                                                                                        </span>
                                                                                    </li>
                                                                    )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>RRP Per User Per Month</Accordion.Header>
                                <Accordion.Body className="d-flex p-0">
                                    <div
                                        className="col-12 col-xl-2 d-none d-xl-block"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="pricing-features">
                                            <ul className="list-group list-group-flush">
                                                {planDetails.length !== 0 &&
                                                    planDetails[0].priceBook.sort((a, b) => a.id - b.id).map(
                                                        (Book, index) =>
                                                            Book.sband >= 500 ?
                                                                <li className="list-group-item bg-blue-2 d-flex align-items-center justify-content-between">
                                                                    <span style={{ height: '20px' }}>500+</span>
                                                                </li>
                                                                :
                                                                <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                                                    <span style={{ height: '20px' }}>{Book.sband}{"-"}{Book.eband}</span>
                                                                </li>
                                                    )}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-10 d-flex">
                                        {planDetails.map(
                                            (Detail, index) =>
                                                Detail.priceBook.length !== 0 && (
                                                    <div
                                                        onMouseEnter={() => handleMouseEnter(Detail.price.name)}
                                                        onMouseLeave={handleMouseLeave}
                                                        className={`plans ${selectedPlan === Detail.price.name && !displayOnly ? "selectedplan" : ""
                                                            } ${hoveredPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                            } ${clickedPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                            }`}
                                                    >
                                                        <div className="pricing-detail">
                                                            <ul className="list-group list-group-flush">
                                                                {Detail.priceBook.sort((a, b) => a.id - b.id).map(
                                                                    (perUser, index) =>
                                                                        perUser.sband >= 500 && displayOnly ?
                                                                            <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                                                                <span style={{ height: "20px" }}>
                                                                                    Contact Us
                                                                                </span>
                                                                            </li>
                                                                            :
                                                                        <li className="list-group-item bg-blue-1 d-flex flex-column align-items-center justify-content-center">
                                                                            <span style={{ height: "20px" }}>
                                                                                    {perUser.currency === "GBP" && <CurrencyPound style={{ color: 'black' }} />} {perUser.currency === "EUR" && <CurrencyEuro style={{ color: 'black' }} />}{parseFloat(perUser.price).toFixed(2)}
                                                                                </span>
                                                                                {perUser.sband >= 500 ? <span className="text-center text-secondary d-xl-none">

                                                                                    500+
                                                                                </span> : <span className="text-center text-secondary d-xl-none">

                                                                                    {perUser.sband}{"-"}{perUser.eband}
                                                                                </span> }
                                                                                
                                                                        </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>


                        {!displayOnly && <div className="d-flex" style={{ position: "sticky", bottom: 0, width: "100%", top: '0px', background: 'rgb(255, 255, 255)', zIndex: '100' }}>
                            <div
                                className="col-12 col-xl-2 d-none d-xl-block"
                                style={{ position: "relative" }}
                            >
                                <div className="pricing-features border-bottom">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item bg-blue-1 d-flex align-items-center justify-content-between">
                                            <span style={{ height: '30px', fontSize: '1rem', fontWeight: 'bold' }}></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-xl-10 d-flex">
                                {planDetails.map(
                                    (Detail, index) =>
                                        Detail.features.length !== 0 && (
                                            <div
                                                onMouseEnter={() => handleMouseEnter(Detail.price.name)}
                                                onMouseLeave={handleMouseLeave}
                                                className={`plans plan-checkbox ${selectedPlan === Detail.price.name && !displayOnly ? "selectedplan" : ""
                                                    } ${hoveredPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                    } ${clickedPlan === Detail.price.name && !displayOnly ? "hover-effect" : ""
                                                    }`}
                                                style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }}
                                            >
                                                {!Detail.price.nonSelectable ?
                                                    (<div className="justify-content-center d-flex p-2" style={{ borderBottom: "1px solid #dee2e6" }}>
                                                        <input
                                                            type="checkbox"
                                                            onClick={() => {
                                                                if (selectedPlan !== Detail.price.name) {
                                                                    handlePlanSelection(Detail.price.name, Detail.price.id);
                                                                }
                                                            }}
                                                            htmlFor={`checkbox_${Detail.price.id}`}
                                                            id={`checkbox_${Detail.price.id}`}
                                                            checked={selectedPlan === Detail.price.name}
                                                            className="form-check-input"
                                                            value="1"
                                                            readOnly
                                                        />
                                                        <label className="form-check-label"></label>
                                                    </div>) : !(selectedPlan === Detail.price.name) && (<div className="justify-content-center align-items-center d-flex p-2" style={{ borderBottom: "1px solid #dee2e6", height: '48px' }}>
                                                        <OverlayTrigger key="sort-1" placement="right" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: "Contact Support"})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16" style={{ color: '#649dd4' }}>
                                                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                                            </svg>
                                                        </OverlayTrigger>
                                                        
                                                        <label className="form-check-label"></label>
                                                    </div>) }
                                            </div>
                                        )
                                )}
                            </div>
                        </div>}

                        <div>
                                <ul className="note-list pt-2">
                                {planDetails.map(
                                    (Detail, index) =>
                                        Detail.features.length !== 0 && (
                                            Detail.features
                                                .filter((fd) => fd.smallPrint)
                                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                                .map((fe) => fe.featureDescription)
                                        )
                                ).flat().filter((value, index, self) => self.indexOf(value) === index).map((uniqueDescription,innerindex) => (
                                    <li key={"note_" + innerindex}>
                                        {uniqueDescription}
                                    </li>
                                ))}
                                </ul>
                            </div> </>)
                            : <div className="justify-content-center align-items-center d-flex" style={{ height:'100vh' }}>
                                <h3>No Active Price Model to display</h3>
                                </div> 
                    }

                </div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
              
                    <Modal.Body>
                        Are you sure you wish to use the <b> {clickedPlan} </b>price model,
                        your bill will reflect the selected model.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary " onClick={handlePlanConfirm}>
                            Yes
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
            {/*<SimpleBar>{[...Array(50)].map((x, i) => (*/}
            {/*    <p>{i}</p>*/}
            {/*))}</SimpleBar>*/}

        </>

    )
}

export default Pricing