import React from "react";

const HelpModal = ({ about }) => {
    
    const handleHelpClick = () => {
        const url = "https://cloudcover365.virtualdcs.co.uk/helpme/" + about; 
        window.open(url, '_blank'); 
    };

    return (
        <a id="Job_btnHelp" onClick={handleHelpClick}>
            <svg style={{ marginBottom: '.1rem' }} className="me-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 384 512">
                <g >
                    <path fill="currentColor" d="M182.4,373.5c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3c38.3,0,69.3-31,69.3-69.3 C251.7,404.5,220.7,373.5,182.4,373.5z"></path><path fill="currentColor" d="M367.9,153.6c0,116-125.3,117.8-125.3,160.6v5.8c0,13.3-10.7,24-24,24h-72.5c-13.3,0-24-10.7-24-24v-9.8 c0-61.8,46.9-86.5,82.3-106.4c30.4-17,49-28.6,49-51.2c0-29.8-38-49.6-68.8-49.6c-39.1,0-57.8,18.1-82.8,49.4 c-8.1,10.2-22.9,12-33.3,4.1l-43.1-32.7c-10.3-7.8-12.6-22.3-5.2-32.9C60.9,32.7,112.6,0,192.4,0C277.3,0,367.9,66.3,367.9,153.6z">
                    </path>
                </g>
            </svg>
        </a>);
}

export default HelpModal;
