import React, { useState, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import { AgGridReact } from 'ag-grid-react';
import { loginAuthProvider } from "../../LoginAuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import moment from "moment";
import AuthenticationContext from "../../Store/Authentication-Context";
import Button from 'react-bootstrap/Button';
import HelpModal from "./HelpMessagePopup";
import LoaderGif from "../../images/365 loading.gif";

const Eventlogs = ({ orgId, isAzureGuardianOnly }) => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState([]);
    const rowHeight = 25;
    const headerHeight = 30;
    const [rowData, setRowData] = useState([]);
    const [helpPopup, setHelpPopup] = useState(true);

    function closeModal(params) {
        setHelpPopup(params);
    }

    const dateFormatter = (params) => {
        return moment(params.value).format("DD-MM-YYYY HH:mm:ss").toString();
    };

    async function loadEvents() {
        gridRef.current.api.showLoadingOverlay();
        if (!(gridRef.current.api))
            return;
        //var token = await loginAuthProvider.getAccessToken();
        //var tokenString = token.accessToken;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let eventData = await Axios.get(API_ROUTE + Routes.GET_EVENTLOGS + "?orgId=" + orgId, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                gridRef.current.api.hideOverlay();
                return selectItems;
            })
            .catch((reason) => {
                console.log("catch Repo ; " + reason);
            });
        if (eventData) {
            gridRef.current.api.setRowData(eventData);
            gridRef.current.api.hideOverlay();
        }
    }
    const getRowStyle = params => {

        return { fontSize: "Small" };
    };
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }


    const defaultColDef = {
        sortable: true,
        resizable: true,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        loadEvents(params.api);
        params.api.sizeColumnsToFit();
    }

    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }

    const columnDefs = [
        {
            field: 'eventTime',
            headerName: 'Event Time',
            wrapText: true, filter: true,
            cellStyle: { 'text-align': 'left' },
            valueFormatter: dateFormatter, minWidth: 160, flex: 1,
            getQuickFilterText: params => {
                return dateFormatter(params);
            }
        },
        {
            field: 'eventDetail', headerName: 'Event Detail', wrapText: false, filter: true, cellStyle: { 'text-align': 'left' }, minWidth: 175, flex: 1
        },
        {
            field: 'eventUser', headerName: 'User', filter: true, cellStyle: { 'text-align': 'left' }, minWidth: 160, flex: 1
        }
    ];


    return (
        <div className="card mt-1" id="eventlogs">
            <div className="card-header p-1 d-flex align-items-center">
                <span className="SectionTitle ms-2" style={{ fontWeight: '500', width: '50%' }}>Access Logs 
                </span>
                <div className="d-flex justify-content-end " style={{ width: '50%' }}>
                    <a id="Eventlogs_btnReload" onClick={() => loadEvents()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 512 512" className="me-2 ms-2">
                                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z">
                                </path>
                            </svg>
                    </a>
                    <a id="ContentPlaceHolder1_btnClear" style={{ color: "#007bff", cursor: 'pointer' ,height:"25",width:"25"}} className="me-1" onClick={(e) => {
                        if (gridApi) {
                            gridApi.setFilterModel(null);
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="23" stroke="#007bff"  fill ="#007bff" viewBox="0 0 32 32" width="23" style={{ cursor: 'pointer' }}>
                            <path strokeWidth="1.5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>
                            <path strokeWidth="1.5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>
                        </svg>
                    </a>
                    <HelpModal about="Introduction.html" /> 
                    
                </div>
            </div>
            <div className="card-body p-0">
                <div id="ContentPlaceHolder1_pnlRespository" align="center" style={{ width: "100%", overflow: "100%", height:'263px' }}>


                    <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 263, borderCollapse: "collapse" }} >
                        <AgGridReact style={{ width: "100%" }}
                            ref={gridRef}

                            rowData={rowData}
                            getRowStyle={getRowStyle}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            onFirstDataRendered={onFirstDataRendered}
                            rowSelection="single"
                            enableCellTextSelection="true"
                            overlayLoadingTemplate={
                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`
                            }

                            overlayNoRowsTemplate={
                                '<span className="ag-overlay-loading-center">No records to show</span>'
                            }
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                            suppressScrollOnNewData={true}
                            columnDefs={columnDefs} >
                        </AgGridReact>
                    </div>
                </div>
            </div>
        </div>

    );

}
export default Eventlogs;