import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import AlertModal from "../MainComponents/AlertMessagePopup";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import PopupModal from "./PopupModal";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import LoaderGif from "../../images/365 loading.gif";

const Step2 = (props) => {
    const [helpPopup, setHelpPopup] = useState(true);
    const [helpWarningPopup, setHelpWarningPopup] = useState(true);
    const { setAuthContext } = useContext(AuthenticationContext);
    const [error, setError] = useState("");
    const [appContext] = useContext(AppContext);
    const [uniqueAdditionLines, setUniqueAdditionLines] = useState([]);
    
    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
    const [gridApi, setGridApi] = useState([]);
    //Popup form
    const [infoPopup, setInfoPopup] = useState(true);
    const [companyContacts, setCompanyContacts] = useState(null);


    const validate = async () => {
        try {
            await updateContactInCloudcover();
            await updateContactInConnectWise();
            setTimeout(() => {
                props.nextStep();
            }, 100);

        } catch (error) {
            console.error("Error in creating contacts or agreements:", error);

        };

    }

    async function getCompanyAgreementForAddditionLine() {
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        await Axios.get(API_ROUTE + Routes.GET_COMPANY_AGREEMENT_FOR_ADDITION, config)
            .then((response) => {

                if (response && response.data) {
                    let responsedata = response.data;
                    setUniqueAdditionLines(responsedata);
                    const emailRows = createEmailRows(responsedata);
                    

                }

            })
            .catch((reason) => {


            });
    }

    async function createConnectWiseAgreements(contacts) {
        
        //create New Agreements
        const createAgreements = [];
   
        // Iterate over companyContacts to prepare the contacts that need to be created
        uniqueAdditionLines.forEach((addition) => {
            const billingContact = contacts.find(contact =>
                contact.cwCompanyId === addition.cwCompanyID && contact.emailType === 'Billing'
            );
         
            if (!addition.cwAgreementID > 0) {
                const newAgreement = {
                    id: 0,
                    name: "CloudCover 365 – " + addition.orgName,
                    company: {
                        id: addition.cwCompanyID
                    },
                    contact: {
                        id: billingContact.contactId
                    },
                    type: {
                        id: 29,
                        name: 'Subscription'
                    },
                    customerPO: 'Agreed Online',
               
                    noEndingDateFlag: true,
                    billingCycle:
                    {
                        id: 2,
                        name: 'Monthly'
                    },

                    invoicingCycle: 'ContractYear',
                    customFields: [
                        {
                            id: 2,
                            caption: "Contract Term",
                            type: "Text",
                            entryMethod: "List",
                            numberOfDecimals: 0,
                            value: "12 Months"
                        },
                        {
                            id: 4,
                            caption: "Renewal Date",
                            type: "Date",
                            entryMethod: "EntryField",
                            numberOfDecimals: 0
                        },
                        {
                            id: 24,
                            caption: "Notice Period",
                            type: "Number",
                            entryMethod: "List",
                            numberOfDecimals: 2,
                            value: 30.0
                        }

                    ],
                };


                createAgreements.push(newAgreement);
            };
        });
        Promise.all(createAgreements);
      
        try {
            const tokenString = await setAuthContext("", new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
           
            await Axios.post(API_ROUTE + Routes.CREATE_CONNECTWISE_AGREEMENT, createAgreements, config)
                .then((response) => {
               
                    if (response && response.data && response.data.length > 0) {
                        updateAgreementID(response.data);
                    }
                })
                .catch((error) => {
                    console.error("Error creating Agreements:", error);
                });

        } catch (error) {
            console.error("Error in Agreements:", error);
        }
    }
   

    const renderTooltip = (props) => (

        <Tooltip id={`tooltip-${props.id}`} {...props}>
            {props.text}
        </Tooltip>

    );

    function closeModal(params) {
        setHelpPopup(params);
    }
    function closeWarningModal(params) {
        setHelpWarningPopup(params);
    }
    //Ag Grid
    const rowHeight = 25;
    const headerHeight = 35;
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const gridRef = useRef();

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true, flex: 1
    }), []);
   
    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "Error",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }
    async function updateContactInCloudcover() {

        const createContacts = [];
        if (companyContacts) {

            const filteredContacts = companyContacts.filter(contact =>
                contact.update === 1
            );

            for (const contact of filteredContacts) {
                const data = {
                    OrgId: contact.orgId,
                    Setting: contact.emailType === 'Billing' ? 'AdminEmail' : 'NotificationEmail',
                    SettingValue: contact.cwEmailId
                };

        // Make the API call to update the setting on the server
        try {
            const tokenString = await setAuthContext("", new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };

                    await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                        .then((response) => {


                        })
                        .catch((reason) => {
                            console.error("Error updating emailid:", reason);
                        });
                } catch (error) {
                    console.error("Error in handleCellValueChanged:", error);
                }
            }
        }
        
    }
    async function updateCloudCoverContact(params) {

                    const updatedLines = companyContacts.map(line => {
                        if (line.orgId === params.data.orgId && line.emailType === params.data.emailType && params.data.cwCompanyId == line.cwCompanyId) {
                            return { ...line, email: params.data.cwEmailId ,update:1};
                        }
                        else {
                            return line;
                        }
                    });
              
                    setCompanyContacts(updatedLines);

    }

    async function updateContactInConnectWise() {
        if (companyContacts) {
            let contacts = [...companyContacts];
            const createContacts = [];

            const filteredContacts = companyContacts.filter(contact =>
                contact.updateCC === 1 && contact.emailType === 'Billing'
            );

            filteredContacts.forEach(contact => {
                // Extract the first name from the email
                const firstNameFromEmail = contact.email.split('@')[0]; // Adjust logic if needed

                const newContact = {
                    id: 0,
                    orgId: contact.orgId,
                    orgName: contact.orgName,
                    cwAgreementId: contact.cwAgreementId,
                    agreementType: contact.cWAgreementID,
                    firstName: firstNameFromEmail,
                    company: {
                        id: contact.cwCompanyId,
                    },
                    relationship: {
                        id: 2,
                        name: 'Payments',
                    },
                    defaultBillingFlag: contact.emailType === 'Billing',
                    communicationItems: [
                        {
                            id: 0,
                            type: {
                                id: 1,
                                name: "Email - Work",
                            },
                            value: contact.email,
                            defaultFlag: contact.emailType === 'Billing',
                            communicationType: 'Email',
                        },
                    ],
                    types: [
                        {
                            id: contact.emailType === 'Billing' ? props.typesIds.billingTypeId : props.typesIds.notificationTypeId,
                            name: contact.emailType === 'Billing' ? "Billing Contact" : "CC365 Notification",
                        },
                    ],
                };

                createContacts.push(newContact);
            });
            const notificationdContacts = companyContacts.filter(contact =>
                contact.updateCC === 1 && contact.emailType === 'CCNotifications'
            );

            notificationdContacts.forEach(contact => {
                // Extract the first name from the email
                const firstNameFromEmail = contact.email.split('@')[0]; // Adjust logic if needed

                const newContact = {
                    id: 0,
                    firstName: firstNameFromEmail,
                    company: {
                        id: contact.cwCompanyId,
                    },
                    relationship: {
                        id: 2,
                        name: 'Payments',
                    },
                    defaultBillingFlag: contact.emailType === 'Billing',
                    communicationItems: [
                        {
                            id: 0,
                            type: {
                                id: 1,
                                name: "Email - Work",
                            },
                            value: contact.email,
                            defaultFlag: contact.emailType === 'Billing',
                            communicationType: 'Email',
                        },
                    ],
                    types: [
                        {
                            id: contact.emailType === 'Billing' ? props.typesIds.billingTypeId : props.typesIds.notificationTypeId,
                            name: contact.emailType === 'Billing' ? "Billing Contact" : "CC365 Notification",
                        },
                    ],
                };

            createContacts.push(newContact);
        });
        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

            try {

                await Axios.post(API_ROUTE + Routes.CREATE_CONNECTWISE_CONTACT, createContacts, config)
                    .then((response) => {
                        console.log(response);
                        if (response && response.data && response.data != "" && response.data.length > 0) {

                            props.updateAggreements(response.data);
                        }
                    })
                    .catch((error) => {
                        console.error("Error creating contact:", error);
                    });


            } catch (error) {
                console.error("Error in CreateContacts:", error);
            }

            //await createConnectWiseAgreements(contacts);
        }
    }

    async function updateConnectWiseContact(params) {


        const createContacts = [];
        const emailParts = params.data.email.split('@');
        const firstNameFromEmail = emailParts[0];
        const updatedLines = companyContacts.map(line => {
            if (line.orgId === params.data.orgId && line.emailType === params.data.emailType && params.data.cwCompanyId == line.cwCompanyId) {
                return { ...line, cwEmailId: params.data.email, updateCC: 1 };
            }
            else {
                return line;
            }
        });

        setCompanyContacts(updatedLines);





    }

    
    const columnDefs = [
        {
            headerClass: "white-header",
            children: [
                { field: 'orgName', headerName: 'Company Name', width: 200 },
            ]
        },
        {
            headerName: "Connect Wise", headerClass: "center-header",
            children: [
                { field: 'cwContactName', headerName: 'Contact Name', width: 150, },
                { field: 'cwEmailId', headerName: 'Email Address', width: 140, },
                { field: 'emailType', headerName: 'Type', width: 140, }
            ]
        },

        {
            headerClass: "white-header",
            children: [
                {
                    headerName: 'Update',
                    width: 70,

                    editable: false,
                    cellRendererFramework: (params) => {

                        return (
                            <div className="justify-content-between d-flex align-items-center">

                                <a onClick={() => updateConnectWiseContact(params)} className={params.data.email ? '' : 'disabled-link'}
                                    style={params.data.email ? {} : { pointerEvents: 'none', opacity: 0.5 }}
                                >
                                    <OverlayTrigger key="sort-1" placement="top" delay={{ show: 50, hide: 50 }} overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: "Manage Organisation" })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" /></svg>
                                    </OverlayTrigger>
                                </a>


                                <a onClick={() => updateCloudCoverContact(params)} className={params.data.cwEmailId ? '' : 'disabled-link'}
                                    style={params.data.cwEmailId ? {} : { pointerEvents: 'none', opacity: 0.5 }}
                                >
                                    <OverlayTrigger key="sort-1" placement="top" delay={{ show: 50, hide: 50 }} overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: "Manage Organisation" })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"  height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg>
                                    </OverlayTrigger>
                                </a>

                            </div>
                        );
                    },
                }
            ]
        },
        
        {
            headerName: "CloudCover", headerClass: "center-header",
            children: [
                { field: 'contactName', headerName: 'Contact Name', width: 150, },
                { field: 'email', headerName: 'Email Address', width: 140, },
                { field: 'emailType', headerName: 'Type', width: 140, },
            ]
        },
     
        
       
    ];
    const gridOptions = {
        getRowId: (params) => params.data.id,        
        isExternalFilterPresent: () => true,
        doesExternalFilterPass: (node) => {
         
            return node.data.cwEmailId != node.data.email || (node.data.update == 1 || node.data.updateCC ==1);
        }
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
        gridOptions.api.onFilterChanged();
    }
    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }

    async function updateAgreementID(response) {

        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        const changedupdatedAdditionLines = uniqueAdditionLines.map(line => {
            const createdAgreement = response.find(c =>
                line.cwCompanyID == c.company.id);
           
            if (createdAgreement) {
               
                try {
                   
                    const data = {
                        OrgId: line.orgId,
                        Setting: 'CWAgreementID',
                        SettingValue: createdAgreement.id.toString()
                    };

                      Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                        .then((response) => {
                            // Handle successful response if needed
                        })
                        .catch((reason) => {
                            console.error("Error updating emailid:", reason);
                        });
                } catch (error) {
                    console.error("Error in handleCellValueChanged:", error);
                }
                return {
                    ...line,
                    cwAgreementID: createdAgreement.id,
                    cwAgreementStatus: 'Created'
                };
            }
            return line;
        });
        props.updateAggreements(changedupdatedAdditionLines);
             
    }
   
    
    async function createAgreements() {

        //create New Agreements
        const createAgreements = [];
   
        // Iterate over companyContacts to prepare the contacts that need to be created
        uniqueAdditionLines.forEach((addition) => {
            const billingContact = companyContacts.find(contact =>
                contact.cwCompanyId === addition.cwCompanyID && contact.emailType === 'Billing'
            ); 

            if (addition.cwAgreementID === 0 || addition.cwAgreementID === null || addition.cwAgreementID === undefined) {
                const newAgreement = {
                    id: 0,
                    name: "CloudCover 365 – " + addition.orgName,
                    company: {
                        id: addition.cwCompanyID
                    },
                    contact: {
                        id: billingContact.contactId
                    },
                    ProrateFlag:true,
                    type: {
                        id: props.typesIds.subscriptionTypeId,
                        name: 'Subscription - CC365'
                    },
                    customerPO: 'Agreed Online',
                    startDate: new Date().toISOString().split('T')[0],
                    noEndingDateFlag: true,
                    billingCycle:
                    {
                        id: 2,
                        name: 'Monthly'
                    },

                    invoicingCycle: 'ContractYear',
                    customFields: [
                        {
                            id: 2,
                            caption: "Contract Term",
                            type: "Text",
                            entryMethod: "List",
                            numberOfDecimals: 0,
                            value: "12 Months"
                        },
                        {
                            id: 4,
                            caption: "Renewal Date",
                            type: "Date",
                            entryMethod: "EntryField",
                            numberOfDecimals: 0
                        },
                        {
                            id: 24,
                            caption: "Notice Period",
                            type: "Number",
                            entryMethod: "List",
                            numberOfDecimals: 2,
                            value: 30.0
                        }

                    ],
                };


                createAgreements.push(newAgreement);
            };
        });
        
        try {
            const tokenString = await setAuthContext("", new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            await Axios.post(API_ROUTE + Routes.CREATE_CONNECTWISE_AGREEMENT, createAgreements, config)
                .then((response) => {
                 
                    if (response && response.data) {
                        updateAgreementID(response.data);
                    }
                })
                .catch((error) => {
                    console.error("Error creating Agreements:", error);
                });

        } catch (error) {
            console.error("Error in Agreements:", error);
        }
    }

   
        
 
    async function createEmailRows (uniqueAdditionLines)  {
        const emailRows = [];

        var tokenString = await setAuthContext("", new Date());

     
        for (const row of uniqueAdditionLines) {
            let responsedata =null;
            
            if (row.cwCompanyID && row.cwCompanyID !== 0) {
                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };
              
                await Axios.get(API_ROUTE + Routes.GET_CONNECTWISE_CONTACT + "/" + row.cwCompanyID , config)
                    .then((response) => {
                
                        if (response && response.data) {
                            responsedata = response.data;


                        }

                    })
                    .catch((reason) => {


                    });
            }
      
            const adminContact = responsedata ? responsedata.find(contact => contact.types && contact.types.length > 0 && contact.types[0].name === "Billing Contact"):null;
            // Find notification email contact
            const notificationContact = responsedata ?responsedata.find(contact => contact.types  && contact.types.length>0 && contact.types[0].name === "CC365 Notification"):null;
            console.log(row);
            emailRows.push({
                contactId: adminContact ?adminContact.id:0,
                orgId: row.orgId,
                orgName: row.orgName,
                cwCompanyId: row.cwCompanyID,
                contactName: row.adminEmail ? row.adminEmail.split('@')[0] : "",
                cwContactName: adminContact ? adminContact.firstName : "",
                emailType: 'Billing',
                email: row.adminEmail,
                cwAgreementId: row.cwAgreementID,
                cwEmailId: adminContact ? adminContact.communicationItems[0].value:"",
               
            });
            emailRows.push({
                contactId: notificationContact ? notificationContact.id : 0,
                cwContactName: notificationContact ? notificationContact.firstName : "",
                orgId: row.orgId,
                orgName: row.orgName,
                cwCompanyId: row.cwCompanyID,
                contactName: row.notificationEmail ? row.notificationEmail.split('@')[0] : "",
                emailType: 'CCNotifications',
                email: row.notificationEmail,
                cwAgreementId: row.cwAgreementID,
                cwEmailId: notificationContact? notificationContact.communicationItems[0].value:"",
            });
        }
        setCompanyContacts(emailRows);
 
        return emailRows;
    };


    useEffect(() => {
        let isMounted = true;
        if (isMounted===true) {
            getCompanyAgreementForAddditionLine();
        }
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div id="step2">
            <div>
                <div id="UpdatePanel1">
                    <div className="card">
                        <h4 className="card-header ">
                            <span id="TitleLabel" className="SectionTitle">Check Contacts&nbsp;</span>
                            
                        </h4>
                        <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                            <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={companyContacts}
                                gridOptions={gridOptions}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                    domLayout="normal"
                                    overlayLoadingTemplate={
                                        `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                            />
                            </div>
                        </div>
                        <div>
                            <table cellSpacing="5" cellPadding="5" align="right">
                                <tbody>
                                    <tr>
                                        <td align="right">
                                            <ActionButtons {...props} nextStep={validate} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/*<HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step2-SelectingItems.html" />*/}
            {/*<HelpModal displayModal={!helpWarningPopup} closeModal={closeWarningModal} about="Introduction.html" />*/}
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
        </div>
    );
}

export default Step2;
