import React, { Component } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Spinner,
} from "reactstrap";
export class HelpDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message
    };
  }
  render() {
    
    return (
      <React.Fragment>
        <span className="small">
                  {this.state.message}{" "}
                  <button
                    id="LinkButtonHelp"
                    onClick={() => {
                        let url = "https://cloudcover365.virtualdcs.co.uk/helpme/login.html";
                        window.open(url, '_blank');
                    }}
                    style={{
                      backgroundColor: "white",
                      background: "none!important",
                      border: "none",
                      padding: "0!important",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    View our Help page
                  </button>
                </span>      
        </React.Fragment>
    );
  }
}
