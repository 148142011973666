import React, { useEffect, useState, useContext } from "react";
import { Button, Form, InputGroup } from 'react-bootstrap';

import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import Axios from "axios";
import { loginAuthProvider } from "../../LoginAuthProvider";

import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AuthenticationContext from "../../Store/Authentication-Context";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trim } from "lodash";
import LoaderGif from "../../images/365 loading.gif";
const GuardianEncryption = props => {

    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10010"

    };
    const [urlParams, setParams] = useState(new URLSearchParams(window.location.search));
    const { setAuthContext } = useContext(AuthenticationContext);
    const authContext = useContext(AuthenticationContext);

    const [validation, setValidation] = useState({
        isEncryptionKeyValid: true,
        encryptionKeyErrorMessage: "",
    });
    
    function submit() {
        props.closeModal(true);
        props.submitClick(props.action);
    }

    const showToast = (type, message) => {
        if (type === "success") {
            toast.success(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
        else if (type === "error") {
            toast.error(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
    };
    const [encryptionKey, setEncryptionKey] = useState();
    async function setEncryptionKeySettings(settingsValue) {

        try {

            var tokenString = await setAuthContext("", new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            const inputData = {
                OrgId: urlParams.get("orgId") ? urlParams.get("orgId") : sessionStorage.getItem("ManageOrgId"),
                EncryptionKey: settingsValue
            };
            await Axios.post(API_ROUTE + Routes.ENCRYPT_AND_SAVE, inputData, config)
                .then((response) => {

                    if (response && response.data && response.data) {

                       // toast.success(response.data);

                        if (response.data == "Success") {
                            toast.success("Encryption key has been created successfully");
                            setEncryptionKey("true");
                        }
                        else {
                            toast.error("An error occurred while updating the encryption key. Please contact support for assistance.");
                        }
                    }
                    else {
                        toast.error("An error occurred while updating the encryption key. Please contact support for assistance.");
                    }

                })
                .catch((reason) => {


                });
            submit();
        }
        catch (error) { }

    }
    const encryptionKeyUpdate = (event) => {

        const updatedEncryption = event.target.parentElement.parentElement.querySelector('input[type="password"]').value;

        if (updatedEncryption !== "" && validation.isEncryptionKeyValid === true) {
            setEncryptionKeySettings(updatedEncryption);
        }
        else {
            showToast("error", "Please enter the encryption key")
        }

    };
    
    function closeModal(e) {
        e.stopPropagation();
        props.closeModal(true);
    }
    
    return (
        <div >
            <div

                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Guardian Azure Encryptionkey has to be set before creating Azure Job
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" >
                            <Form>
                                <Form.Group controlId="formStorageEncryption">
                                    <Form.Label>Guardian Azure Encryption Key</Form.Label>
                                    {encryptionKey === "true" ? (<Form.Control type="password" value="" disabled placeholder="Already Enabled" />
                                    )
                                        : (<InputGroup className="mb-3">
                                            <Form.Control type="password" isInvalid={!validation.isEncryptionKeyValid} onChange={(event) => {
                                                const updatedEncryption = event.target.parentElement.parentElement.querySelector('input[type="password"]').value;


                                                if (updatedEncryption === "") {
                                                    setValidation({
                                                        ...validation,
                                                        isEncryptionKeyValid: false,
                                                        encryptionKeyErrorMessage: "Encryption Key should not be Empty"
                                                    });

                                                }
                                                else {
                                                    setValidation({
                                                        ...validation,
                                                        isEncryptionKeyValid: true,
                                                        encryptionKeyErrorMessage: ""
                                                    });
                                                }

                                            }} placeholder="Enter Encryption Key" />
                                            <Button variant="primary" value="" onClick={encryptionKeyUpdate}>Update</Button>
                                        </InputGroup>)
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {validation.encryptionKeyErrorMessage}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Form>

                        </div>
                        <div className="modal-footer" >
                            <button
                                type="button"
                                onClick={closeModal}
                            >Cancel

                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>
    );
};

export default GuardianEncryption;
