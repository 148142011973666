import React, { Component } from "react";
import Register from "../../images/Register.png";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import { MainComp } from "../MainComponents/MainComp";
import { authProvider } from "../../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import "../../CSS/general.css";
import Cookies from "universal-cookie";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { loginAuthProvider } from "../../LoginAuthProvider";
import LogRocket from "logrocket";
import decryptParameter from "../../Helpers/DeCryptParameter";
import AuthenticationContext from "../../Store/Authentication-Context";

export class Main extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
       /* if (urlParams.get("orgId")) {
            localStorage.setItem('orgId', urlParams.get("orgId"));

        }
        if (urlParams.get("orgName")) {
            localStorage.setItem('orgName', urlParams.get("orgName"));

        }*/
        this.state = {
            orgId: urlParams.get("orgId") ? urlParams.get("orgId") : "",
            isValid: false,
        };
        this.itemClick = this.itemClick.bind(this);
        this.validateToken = this.validateToken.bind(this);
    }
    //static displayName = Restore.name;

    itemClick(event, label) {
        event.preventDefault();
    }
    componentDidMount() {
        this.validateToken();
    }

    async validateToken(event) {
        if (event) {
            event.preventDefault();
        }
        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        let data = await Axios.get(API_ROUTE + Routes.VALIDATE_TOKEN + "/" + this.state.orgId, config)
            .then((response) => {

                let isValid = JSON.parse(response.data);
                if (isValid) {
                    this.setState({
                        isValid: true
                    });
                } else {
                    window.location.href = "/login";
                }
            })
            .catch((reason) => {
                //console.log("catch" + reason);
            });

    }

    render() {
        let mainBlock = (
            <div>

            </div>
        );
        if (this.state.isValid) {
            mainBlock = (<MainComp />);
        }

        let displaySection = (
            <AzureAD provider={loginAuthProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            LogRocket.identify(accountInfo.account.userName, {
                                name: accountInfo.account.name,
                            });
                            return (<div>{mainBlock}</div>);
                        case AuthenticationState.Unauthenticated:
                            window.location.href = "/login";
                            return (
                                <React.Fragment>
                                    <div>Authentication Failed</div>
                                </React.Fragment>
                            );
                        default:
                            return <p>Please contact support.</p>;
                    }
                }}
            </AzureAD>
        );
        return (
            <React.Fragment>
                {displaySection}
            </React.Fragment>

        );
    }
}