import React, { Component } from "react";
import loginIcon from "../../images/loginIcon.PNG";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import {
  Modal,
  Button,
} from "reactstrap";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { LEGACY_SITE } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import { RegCheck } from "../RegCheck";

export class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelpPages: false,
      };
      
      sessionStorage.removeItem('ResellerOrganisation');
      sessionStorage.removeItem('ResellerOrgs');
      sessionStorage.removeItem('Resellers');
      sessionStorage.removeItem('OrgStatuses');
      sessionStorage.removeItem('PriceNodels');
      sessionStorage.removeItem('ServerPath');
      sessionStorage.removeItem('ResellerID');
      sessionStorage.removeItem('Username');
      sessionStorage.removeItem('orgId');
      sessionStorage.removeItem('orgName');
      sessionStorage.removeItem('IsAdmin');
    loginAuthProvider.logout();
  }
  static displayName = Logout.name;

  render() {

    return (
      <div id="ContentPlaceHolder1_UpdatePanel1">
      </div>
    );
  }
}
