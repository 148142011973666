import React, { Component } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Spinner,
} from "reactstrap";
export class HelpFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
        };
    }
  render() {
    return (
      <React.Fragment>
        {this.state.message}{" "}
            <a href="" onClick={() => {
                let url = "https://cloudcover365.virtualdcs.co.uk/helpme/login.html";
                window.open(url, '_blank');
            }}
            style={{ marginLeft: "5px" }}>
            Help
          </a>
      </React.Fragment>
    );
  }
}
