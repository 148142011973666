import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { AppContext } from "../MainComponents/AppContext";
import AlertModal from "../MainComponents/AlertMessagePopup";
//import Spinner from "reactstrap/lib/Spinner";
import LoaderGif from "../../images/365 loading.gif";
import moment from "moment";
import AuthenticationContext from "../../Store/Authentication-Context";

const SyncOrgDataModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10050",
    };
    const { setAuthContext } = useContext(AuthenticationContext);
    const [inProgress, setInProgress] = useState(false);
    const [appContext] = useContext(AppContext);
    const [lastSyncTime, setLastSyncTime] = useState("N/A");
    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "Error",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }

    async function getAppContextData(link, attr) {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let payload = {
            url: link,
            orgId: appContext.orgId,
        };

        var isloaded = false;
        if (attr === "users" && appContext.users) {
            appContext.users.isloaded = isloaded;
            appContext.users.value = {};
        }
        if (attr === "groups" && appContext.groups) {
            appContext.groups.isloaded = isloaded;
            appContext.groups.value = {};
        }
        if (attr === "sites" && appContext.sites) {
            appContext.sites.isloaded = isloaded;
            appContext.sites.value = {};
        }
        if (attr === "teams" && appContext.teams) {
            appContext.teams.isloaded = isloaded;
            appContext.teams.value = {};
        }
        
        const data = await Axios.post(API_ROUTE + Routes.GET_DATA, payload, config)
            .then((response) => {
                isloaded = true;
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                setInProgress(false);
                closeAlertModal(reason.message);

            });
        switch (attr) {
            case "users":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            itemType: "user",
                            selected: false,
                            excluded: false
                        }));
                    if (appContext.users) {
                        appContext.users.isloaded = isloaded;
                        appContext.users.value = data.results;
                    }
                    break;
                }
            case "groups":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            mailbox: false,
                            groupMailbox: false,
                            archiveMailbox: false,
                            onedrive: false,
                            site: false,
                            groupSite: false,
                            itemType: "group",
                            selected: false,
                            excluded: false
                        }));
                    if (appContext.groups) {
                        appContext.groups.isloaded = isloaded;
                        appContext.groups.value = data.results;
                    }
                    break;
                }
            case "sites":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            site: false,
                            itemType: "site",
                            selected: false,
                            excluded: false
                        }));
                    if (appContext.sites) {
                        appContext.sites.isloaded = isloaded;
                        appContext.sites.value = data.results;
                    }
                    break;
                }
            case "teams":
                {
                    data.results = data.results.map((x) => (
                        {
                            ...x,
                            teams: false,
                            teamsChat: false,
                            itemType: "team",
                            selected: false,
                            excluded: false
                        }));
                    if (appContext.teams) {
                        appContext.teams.isloaded = isloaded;
                        appContext.teams.value = data.results;
                    }
                    break;
                }
            default:
                break;
        }
    }

    function closeModal(e) {
        props.closeModal(true);
    }

    async function HandleSyncOrgData(event) {
        setInProgress(true);
        var result = await updateSyncOrgData();
        var context = await refreshAppContext();
        setInProgress(false);
        if (context)
            props.closeModal(true);
    }

    async function refreshAppContext() {
        
        await getAppContextData(appContext.orgDetails._links.sites.href, "sites");
        
        await getAppContextData(appContext.orgDetails._links.users.href, "users");
        
        await getAppContextData(appContext.orgDetails._links.groups.href, "groups");
        
        await getAppContextData(appContext.orgDetails._links.teams.href, "teams");

        return true;
    }

    async function getSyncDate() {
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        let payload = {
            url: "/v7/Organizations/" + appContext.orgId + "/SyncState",
            orgId: appContext.orgId,
        };
        
        const data = await Axios.post(API_ROUTE + Routes.GET_DATA, payload, config)
            .then((response) => {

                var result = JSON.parse(response.data);
                if (result)
                    setLastSyncTime(getFormattedDateTime(result.lastSyncTime));

            })
            .catch((reason) => {
                setInProgress(false);
                console.log(reason);

            });
    }

    async function updateSyncOrgData() {
        var tokenString = await setAuthContext("", new Date());
        let jsonRequest = {
            type: "Incremental"
        };

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        let payload = {
            postString: JSON.stringify(jsonRequest),
            method: "POST",
            url: "/Organizations/" + appContext.orgId + "/Sync",
            orgId: appContext.orgId,
        };

        const data = await Axios.post(API_ROUTE + Routes.SYNC_ORG_DATA, payload, config)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((reason) => {
                setInProgress(false);
                console.log(reason);
            });
    }

    function getFormattedDateTime(dateTime) {
        let syncDate = moment(dateTime);
        let year = syncDate.format("YYYY");
        let month = syncDate.format("MM");
        let day = syncDate.format("DD");
        let hour = syncDate.format("HH");
        let minute = syncDate.format("mm");
        return `${day}/${month}/${year} ${hour}:${minute}`;
    }

    useEffect(() => {
        getSyncDate();
    }, [props.refresh]);

    return (
        <div>
            <div
                style={divStyle}
                className="modal"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ width: "500px", height:"250px"}}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Sync Data
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" style={{ height: "400px", minWidth: "100%", width: "inherit" }}>
                            <div className="ag-theme-alpine" style={{width: "100%", height: "100%", }} >
                                <p>The function resyncs our cache with your AzureAD, your last sync was at <b>{lastSyncTime}</b></p>
                                <p>Do you want to continue.</p>                               
                            </div>
                        </div>
                        <div className="modal-footer">
                            {inProgress &&
                                <div>
                                    <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                                        <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                                    </div>
                                </div>
                            }
                            {!inProgress &&
                                <div>
                                    <button onClick={HandleSyncOrgData} type="submit" className="btn btn-primary btn-sm">
                                    Yes
                                    </button>&nbsp;
                                    <button onClick={closeModal} className="btn btn-outline-primary btn-sm">
                                        No
                                    </button>
                                </div>
                            }
                            
                        </ div>
                    </div>
                </div>
            </div>
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
            <div data-act-control-type="modalPopupBackground" id="RepAdd_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>

    );
};

export default SyncOrgDataModal;
