import React, { useState, useContext, useEffect, useMemo } from "react";
import "../../CSS/general.css";
import Axios from "axios";
import ActionButtons from "./ActionButton";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import RepoModalPopup from "../../components/MainComponents/RepoModalPopup";
import { AppContext } from "../MainComponents/AppContext";
import AlertModal from "../MainComponents/AlertMessagePopup";
import { loginAuthProvider } from "../../LoginAuthProvider";
//import Spinner from "reactstrap/lib/Spinner";
import AuthenticationContext from "../../Store/Authentication-Context";
import { AgGridReact } from 'ag-grid-react';
import LoaderGif from "../../images/365 loading.gif";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
const Step4 = (props) => {
    const { setAuthContext, setRepoData, checkDataForId, RepoData } = useContext(AuthenticationContext);
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [helpPopup, setHelpPopup] = useState(true);
    const [inProgress, setInProgressp] = useState(true);
    
    const [appContext] = useContext(AppContext);
    const [additionLines, setAdditionLines] = useState();
    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
   
    const validate = () => {
         props.closePopupModal();
     
    };
    const [gridApi, setGridApi] = useState([]);
   

    function closeModal(params) {
        setHelpPopup(params);
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
    }
    const renderTooltip = (props) => {
  
        return (
            <pre>
            <Tooltip id={`tooltip-${props.id}`} {...props}>
                {props.text}
                </Tooltip>
                </pre>
        );

    }
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
     //  flex: 1
    }), []);
    const flagChangeQtyRenderer = (params) => {
        const prevQty = parseFloat(params.data.prevQty) || 0;
        const qty = parseFloat(params.data.qty) || 0;

        if (prevQty !== 0 && prevQty !== qty) 
        {
            return <span style={{ color: 'red' }} > {params.data.prevQty}</ span>;
        }
        else {
            return params.data.prevQty
        }
    };

    const flagChangePriceRenderer = (params) => {
        const prevPrice = parseFloat(params.data.prevPrice) || 0;
        const price = parseFloat(params.data.price) || 0;
        if (prevPrice !== 0 && prevPrice !== price) {
            return <span style={{ color: 'red' }} > {params.data.prevPrice}</ span>;
        }
        else {
            return params.data.prevPrice
        }
    };
    
    const columnDefs = [
        { field: 'orgName', sortable: true, headerName: 'Organisation Name', width: 350 },  
        { field: 'cwAgreementID', sortable: true, headerName: 'Agreement ID', width: 200 },
        { field: 'invoiceDescription', sortable: true, headerName: 'Invoice Description', width: 450 },
        {
            field: 'prevQty', sortable: true, headerName: 'Prev Qty', width: 75, flex: 1, cellRendererFramework: (params) => {
                return flagChangeQtyRenderer(params)
            }, },
        { field: 'qty', sortable: true, headerName: 'Qty', width: 75, flex: 1 },
        { field: 'prevPrice', sortable: true, headerName: 'Prev Price', width: 100, flex: 1,  cellRendererFramework: (params) => {
            return flagChangePriceRenderer(params)
        }, },
    { field: 'price', sortable: true, headerName: 'Price', width: 100, flex: 1, },
        { field: 'status', sortable: true, headerName: 'Status', width: 100, flex: 1, },
        
        {
            headerName: '', width: 50, flex: 1, 
            cellRendererFramework: (params) => {
                const handleClick = () => {
                    const errorMessage = params.data.errorMessage || 'No error message';
                    setModalMessage(errorMessage);
                
                    setIsModalOpen(true);
                };

                const closeModal = () => {
                    setIsModalOpen(false);
                };
                return (
                    <>
                        {params.data.errorMessage &&
                            <div className="justify-content-between d-flex align-items-center">
                                <OverlayTrigger key="sort-1" placement="top" delay={{ show: 50, hide: 50 }} overlay={(props) => renderTooltip({ ...props, id: "Error-1", text: params.data.errorMessage })}>
                                    <a title={params.data.errorMessage} onClick={() => handleClick(params)}>
                                   
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                   
                                    </a>
                                </OverlayTrigger>
                            </div>

                        }
                    </>

                );
            }
        },       

    ];
    

    const popupSwap = (show, reload = false) => {
        ////await getPriceModel()
       // setShowModal(show);
    }

    const clearData = () => { }

    const cancel = () => {
        //setShowModal(false);
    }

    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }
    async function getAddditionLine() {
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        await Axios.get(API_ROUTE + Routes.GET_ADDITION_LINES, config)
            .then((response) => {

                if (response && response.data) {
                    let responsedata = response.data;

                    processAdditionLines(responsedata);
                    
                }

            })
            .catch((reason) => {


            });
    }
    async function processAdditionLines(additionLines) {
        
        
        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        try {

            await Axios.post(API_ROUTE + Routes.PROCESS_CONNECTWISE_ADDITIONLINES, additionLines, config)
                .then((response) => {
           
                    if (response && response.data) {
                        setAdditionLines(response.data);
                        setInProgressp(false);
                    }


                })
                .catch((error) => {
                    console.error("Error processAdditionLines:", error);
                });

        } catch (error) {
            console.error("Error in processAdditionLines:", error);
        }


    }

    useEffect(() => {
        getAddditionLine();
    }, []);


    

    return (

        <div id="step4">
            <div id="UpdatePanel1">
                <div className="card">
                    <h4 className="card-header ">
                        <span id="TitleLabel" className="SectionTitle">Update Additions &nbsp;</span>
                      
                    </h4>
                    <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                        <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                        
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={additionLines}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                domLayout="normal"
                                overlayLoadingTemplate={
                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            inProgress &&
                            <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                                <img src={LoaderGif} alt="loading" style={{ height: '45px' }} />
                            </div>
                        }
                        {
                            !inProgress &&
                            <table cellSpacing="5" cellPadding="5" align="right">
                                <tbody>
                                    <tr>
                                        <td >
                                                <ActionButtons {...props} lastStep={validate}  />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                <input type="hidden" name="btnhelp" id="btnhelp" />
                <Modal
                    show={isModalOpen}
                    onHide={closeModal} backdrop="static"
                    size="xl"
                >  <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header><Modal.Body>
                        <>
                            {modalMessage}
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>

                </Modal>
            </div>
           
           
        </div>
    );
}
export default Step4;