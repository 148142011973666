import React, { useState, useEffect, useContext } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import "../../CSS/general.css";
import Step1 from "../JobControl/Step1";
import Step2 from "../JobControl/Step2";
import Step3 from "../JobControl/Step3";
import Step4 from "../JobControl/Step4";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import { AppContext } from "../MainComponents/AppContext";
import stepCompleted from "../../images/stepCompleted.png";
import stpperLoad from "../../images/stpperLoad.gif";

const JobControlModal = props => {
    const [stepWizrd, setStepWizard] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [appContext] = useContext(AppContext);

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleComplete = () => {
        props.closeModal(true);
    };

    const closeJobControlPopup = () => {
        stepWizrd.goToStep(1);
        stepWizrd.isActive = false;
        
        props.closeModal(true);
    }

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"

    };

    const closeModal = () => {
        closeJobControlPopup();
        props.closeModal(true);
    }

    useEffect(() => {
        appContext.isEditJob = props.isEditJob;
    }, [props.isEditJob])

    return (
        <div >
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ width: "auto"}} >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <span className="SectionTitle">{props.isEditJob ? "Edit Job " : "New Job "}</span>
                                <a id="Job_btnHelp" onClick={() => {
                                        let url = "https://cloudcover365.virtualdcs.co.uk/helpme/JobManagement.html";
                                        window.open(url, '_blank');
                                    }
                                } ><i className="fad fa-question"></i></a>
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="horizontal-stepper">
                                    <div className={`h-step ${activeStep === 0 ? " active" : (activeStep > 0 ? " completed" : "")}`}>
                                        <div className="circle">
                                        <span>1</span>
                                        </div>
                                        <div className="content">Job Detail</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 1 ? " active" : (activeStep > 1 ? " completed" : "")}`}>
                                        <div className="circle"><span>2</span></div>
                                        <div className="content">Select Items</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 2 ? " active" : (activeStep > 2 ? " completed" : "")}`}>
                                        <div className="circle"><span>3</span></div>
                                        <div className="content">Exclude Items</div>
                                        <div className="line"></div>
                                    </div>
                                    <div className={`h-step ${activeStep === 3 ? " active" : (activeStep > 3 ? " completed" : "")}`}>
                                        <div className="circle"><span>4</span></div>
                                        <div className="content">Schedule & Confirm</div>
                                    </div>
                                </div>
                            </div>
                            <StepWizard isLazyMount instance={assignStepWizard} currentStep={activeStep} onStepChange={handleStepChange}>
                                <Step1 closePopupModal={closeModal} />
                                <Step2 closePopupModal={closeModal} actualOrgUserCount={props.actualOrgUserCount} />
                                <Step3 closePopupModal={closeModal} />
                                <Step4 closePopupModal={closeModal} jobRefreshed={props.jobRefresh} callBackReloadRepository={props.reloadRepositories} copyJobState={props.copyJobState } />
                            </StepWizard>
                        </div>
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />

        </div>
    );
};

export default JobControlModal;
