import React, { useContext, useState, useEffect } from "react";
import { Input } from "reactstrap";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import { AgGridReact } from "ag-grid-react";
import CompanyDropDown from "./CompanyDropDown";
import AgreementDropDown from "./AgreementDropDown";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import LoaderGif from "../../images/365 loading.gif";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
const Step1 = (props) => {
    const [appContext] = useContext(AppContext);
    const [error, setError] = useState("");
    const { setAuthContext } = useContext(AuthenticationContext);
    const [helpPopup, setHelpPopup] = useState(true);

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [uniqueAdditionLines, setUniqueAdditionLines] = useState([]);
    const renderTooltip = (props) => (

        <Tooltip id={`tooltip-${props.id}`} {...props}>
            {props.text}
        </Tooltip>

    );    const handleCellValueChanged = async (params) => {
     
        if (params.newValue != 0) {
            const data = {
                OrgId: params.data.orgId,
                Setting: "CWCompanyID",
                SettingValue: params.newValue
            };

            // Make the API call to update the setting on the server
            try {
                const tokenString = await setAuthContext("", new Date());
            
                const config = {
                    headers: {
                        Authorisation: `Bearer ${tokenString}`,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };

                await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                    .then((response) => {
                        getCompanyAgreementForAddditionLine();
                    })
                    .catch((reason) => {
                        console.error("Error updating companyID:", reason);
                    });
            } catch (error) {
                console.error("Error in handleCellValueChanged:", error);
            }

      
        }
    };

     const handleAgreementChanged = async (params) => {
     
            const data = {
                OrgId: params.data.orgId,
                Setting: "CWAgreementID",
                SettingValue: params.newValue
            };

            // Make the API call to update the setting on the server
            try {
                const tokenString = await setAuthContext("", new Date());
            
                const config = {
                    headers: {
                        Authorisation: `Bearer ${tokenString}`,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };

                await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                    .then((response) => {
                        getCompanyAgreementForAddditionLine();
                    })
                    .catch((reason) => {
                        console.error("Error updating AgreementID:", reason);
                    });
            } catch (error) {
                console.error("Error in handleAgreementChanged:", error);
            }

      
        
    };

    const columnDefs = [
        
        { headerName: "Org Name", sortable: true, field: "orgName", flex: 1 },
        { headerName: "GoCardless ID", sortable: true, field: "goCardlessID", flex: 1 },
        {
            headerName: "CW Company ID", sortable: true, field: "cwCompanyID", editable: true, cellEditorFramework: CompanyDropDown,  cellEditorParams: {
                values: props.companies
            }, onCellValueChanged: handleCellValueChanged, flex: 1
        },
        {
            headerName: "",
            cellRendererFramework: (params) => (
                <a
                    onClick={() =>
                        params.api.startEditingCell({
                            rowIndex: params.node.rowIndex,
                            colKey: "cwCompanyID",
                        })
                    }
                >
                    <OverlayTrigger
                        key="sort-1"
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={(overenderTooltiprlayProps) =>
                            renderTooltip({
                                ...overenderTooltiprlayProps,
                                id: "sort-1",
                                text: "Edit Company",
                            })
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                        >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                        </svg>
                    </OverlayTrigger>
                </a>
            ),
            flex: 1,
            sortable: false,
        },
        {
            headerName: "CW Agreement ID", sortable: true, field: "cwAgreementID", editable:true, cellEditorFramework: AgreementDropDown,cellEditorParams: (params) => {
                const filteredAgreements = props.agreements && props.agreements.filter(agreement => agreement.company.id == params.data.cwCompanyID);
                return {
                    values: filteredAgreements
                };
            }, onCellValueChanged: handleAgreementChanged, flex: 1
        },
        {
            headerName: "",
            cellRendererFramework: (params) => (
                <a
                    onClick={() =>
                        params.api.startEditingCell({
                            rowIndex: params.node.rowIndex,
                            colKey: "cwAgreementID",
                        })
                    }
                >
                    <OverlayTrigger
                        key="sort-1"
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={(overenderTooltiprlayProps) =>
                            renderTooltip({
                                ...overenderTooltiprlayProps,
                                id: "sort-1",
                                text: "Edit Aggreement",
                            })
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                        >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                        </svg>
                    </OverlayTrigger>
                </a>
               
            ),
            flex: 1,
            sortable: false,
        },
    ];
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };
    const validate = async () => {
        try {
            const result = await CreateCompanies();
           
            setTimeout(() => {
                props.nextStep();
            }, 100);
           
        } catch (error) {
            console.error("Error in creating contacts or agreements:", error);
        }
    };
    /*const validate = () => {

        CreateCompanies().then((result) => {

            props.nextStep();

        }).catch((error) => {
            console.error("Error in creating contacts or agreements:", error);
        });
    }*/

    const onInputChanged = (event) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setError("");
        
    };
    async function CreateCompanies() {
        const createCompanies = [];
        let iscompanyCreated = false;

        // Iterate over companyContacts to prepare the contacts that need to be created
        uniqueAdditionLines.forEach((addition) => {
            if (addition.cwCompanyID === 0) {
                
                iscompanyCreated = true;
                const newCompany  = {
                    id: 0,
                    Identifier: addition.orgName,
                    name: addition.orgName,
                    orgId: addition.orgId
                    }

                    

                createCompanies.push(newCompany);
            }
        });


        // Assuming you have an endpoint to create contacts in ConnectWise
        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        try {

            await Axios.post(API_ROUTE + Routes.CREATE_CONNECTWISE_COMPANY, createCompanies, config)
                .then((response) => {
                   
                    if (response && response.data) {
                       

                        return "success";
                        }
                   
                
                })
                .catch((error) => {
                    console.error("Error creating contact:", error);
                });

        } catch (error) {
            console.error("Error in CreateContacts:", error);
        }
    }
   

    function closeModal(params) {
        setHelpPopup(params);
    }

    async function getCompanyAgreementForAddditionLine() {
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        await Axios.get(API_ROUTE + Routes.GET_COMPANY_AGREEMENT_FOR_ADDITION, config)
            .then((response) => {

                if (response && response.data) {
                    let responsedata = response.data;
                  
                    setUniqueAdditionLines(responsedata);

                }

            })
            .catch((reason) => {


            });
    }

    useEffect(() => {
      
    }, [props.companies,props.agreements]);
    useEffect(() => {
        getCompanyAgreementForAddditionLine();
    }, []);
    return (
        <div id="step1">
            <div>
                <div id="UpdatePanel1">
                    <div className="card">
                        <h4 className="card-header ">
                            <span id="TitleLabel" className="SectionTitle">Company Check</span>
                            
                        </h4>
                        <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                            <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={uniqueAdditionLines}
                                onGridReady={onGridReady}
                                    domLayout="normal"
                                    overlayLoadingTemplate={
                                        `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                                   
                            />
                            </div>
                        </div>
                    </div>
                    <div>
                        <table cellSpacing="5" cellPadding="5" align="right">
                            <tbody>
                                <tr>
                                    <td >
                                        <ActionButtons  {...props} nextStep={validate} currentStep={1} totalSteps={4} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div data-act-control-type="modalPopupBackground" id="PopMsg_backgroundElement" className="Background" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div><div data-act-control-type="modalPopupBackground" id="MPE_backgroundElement" className="AnotherModalPopup" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div></div>
                {/*<HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step1-NamingaJob.html" />*/}
            </div>
        </div>
    );
}
export default Step1;