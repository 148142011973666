import React from 'react';

function UnauthorizedPage() {
    return (
        <div className="d-flex justify-content-center">
            <div>
                <h1>403 - Forbidden </h1>
                <p className="text-center">You do not have permission to access this page.</p>
            </div>
        </div>
    );
}

export default UnauthorizedPage; 