import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import AlertModal from "../MainComponents/AlertMessagePopup";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import PopupModal from "./PopupModal";
import SyncOrgDataModal from "./SyncOrgDataModal";
import LoaderGif from "../../images/365 loading.gif";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Step2 = (props) => {
    const [helpPopup, setHelpPopup] = useState(true);
    const [helpWarningPopup, setHelpWarningPopup] = useState(true);
    const JobBackupSettingDefaultValue = 'PartialOrganization';
    const [error, setError] = useState("");
    const [appContext] = useContext(AppContext);
    const [appOrg, setAppOrg] = useContext(AppContext);
    const [itemTypeValue, setItemType] = useState("");
    const [isRefreshed, setIsRefreshed] = useState(false);
    const [isSyncRefreshed, setIsSyncRefreshed] = useState(false);
    const [allSelected, setAllServicesSelected] = useState(false);
    const [showTeamsChat, setShowTeamsChat] = useState(false);
    const [defaultSettingSelected, setDefaultSettingSelected] = useState(true);
    const [mailboxSelected, setmailboxSelected] = useState(false);
    const [archiveMailSelected, setarchiveMailSelected] = useState(false);
    const [onedriveSelected, setonedriveSelected] = useState(false);
    const [siteSelected, setsiteSelected] = useState(false);
    const [teamsSelected, setteamsSelected] = useState(false);
    const [teamsChatSelected, setteamsChatSelected] = useState(false);


    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
    const [gridApi, setGridApi] = useState([]);
    //Popup form
    const [infoPopup, setInfoPopup] = useState(true);
    const [syncDataPopup, setSyncDataPopup] = useState(true);

    const validate = () => {
        if (defaultSettingSelected) {
            if (appContext.partialOrg.length === 0) {
                setError("Please select any of the below services to proceed further");
                return;
            }
        }
        else {
            if (appContext.selectedObjects.length === 0) {
                setError("Please add objects to below grid to proceed further");
                return;
            }
        }
        props.nextStep();

    };

    const onChangevalue = (key) => {
        setError("");
        let targetValue = "";
        if (key === "BackupSelectedServices") {
            targetValue = "PartialOrganization";
        }
        else {
            targetValue = "SelectedItems";
        }
        setDefaultSettingSelected(targetValue === JobBackupSettingDefaultValue);
        if (targetValue !== JobBackupSettingDefaultValue) {
            setDefaultSettingSelected(false);
            appContext.partialOrg = [];
        }
        else {
            setDefaultSettingSelected(true);
            appContext.selectedObjects = [];
            setAllServicesSelected(false);
        }
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    const onChecked = (event) => {
        setError("");

        const targetValue = event.target.name;
        const checked = event.target.checked;
        if (targetValue === "allServices") {
            setAllServicesSelected(checked);
            appContext.job.selectedItems = [];
            if (checked) {
                appContext.partialOrg = [];
                appContext.partialOrg.push("mailbox");
                appContext.partialOrg.push("oneDrive");
                appContext.partialOrg.push("archiveMailbox");
                appContext.partialOrg.push("sites");
                appContext.partialOrg.push("teams");
                if (appContext.isTeamsChatsOnline)
                    appContext.partialOrg.push("teamsChat");
            }
            else {
                appContext.partialOrg = [];
            }
        }
        if (targetValue === "teams")
            setShowTeamsChat(checked && appContext.isTeamsChatsOnline);

        switch (targetValue) {
            case "mailbox":
                setmailboxSelected(checked);
                break;
            case "archiveMailbox":
                setarchiveMailSelected(checked);
                break;
            case "oneDrive":
                setonedriveSelected(checked);
                break;
            case "sites":
                setsiteSelected(checked);
                break;
            case "teams":
                setteamsSelected(checked);
                break;
            case "teamsChat":
                setteamsChatSelected(checked);
                break;
        }

        if (checked) {
            if (!appContext.partialOrg.find((item) => item === targetValue)) {
                appContext.partialOrg.push(targetValue);
            }
        }
        else {
            if (appContext.partialOrg.find((item) => item === targetValue))
                appContext.partialOrg = appContext.partialOrg.filter((item) => item !== targetValue);

            if (targetValue === "teams") {
                if (appContext.partialOrg.find((item) => item === "teamsChat"))
                    appContext.partialOrg = appContext.partialOrg.filter((item) => item !== "teamsChat");
            }

        }
    }

    function closeModal(params) {
        setHelpPopup(params);
    }
    function handleURLClick (url) {
        window.open(url, '_blank');
    };
    function closeWarningModal(params) {
        setHelpWarningPopup(params);
    }
    //Ag Grid
    const rowHeight = 25;
    const headerHeight = 35;
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const gridRef = useRef();

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true
    }), []);

    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "Error",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }

    const columnDefs = [
        { field: 'displayName', headerName: 'Display Name', filter: true, width: 200, },
        { field: 'name', headerName: 'Name', filter: true, width: 150, },
        { field: 'type', headerName: 'Type', filter: true, width: 140, },
        {
            field: 'mailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Mailbox" class='mail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;
                });
                return input;
            }
        },
        {
            field: 'archiveMailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Archive Mailbox" class='archivemail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;
                });
                return input;
            }
        },
        {
            field: 'onedrive',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Onedrive"  class='onedrive-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'site',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Sharepoint" class='sharepoint-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'groupMailbox',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Group Mailbox" class='groupmail-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'groupSite',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Group Sharepoint" class='groupsite-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'teams',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Teams" class='teams-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        },
        {
            field: 'teamsChat',
            headerName: '',
            width: 70,
            headerComponentParams: {
                template: `<span title="Teams chat" class='teamsChat-icon'></span>`
            },
            editable: false,
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.disabled = true;
                input.checked = params.value;
                input.addEventListener("click", function (event) {
                    params.value = !params.value;
                    params.node.data.selected = params.value;

                });
                return input;
            }
        }
    ];

    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }

    //Pop Modal

    const handleClose = (params) => {
        setError("");
        setIsRefreshed(false);
        setInfoPopup(params);
    };

    const openPopup = (event) => {
        setIsRefreshed(true);
        const name = event.target.name;
        setItemType(name);

        return setInfoPopup(false);
    }

    const manageControlsOnEdit = (param) => {
        setAllServicesSelected(param);

    }

    //sync Popup
    const handleReSync = (event) => {
        setIsSyncRefreshed(true);
        setSyncDataPopup(false);
    }

    const handleSyncModalClose = (params) => {
        setIsSyncRefreshed(false);
        setSyncDataPopup(params);
    };
    useEffect(() => {
        setDefaultSettingSelected(!appContext.selectedObjects?.length > 0);
    }, [appContext.selectedObjects]);

    useEffect(() => {
        manageControlsOnEdit(appContext.isEditJob);
    }, [appContext.isEditJob]);

    useEffect(() => {
        setmailboxSelected(appContext.partialOrg.find((x) => x === "mailbox"));
        setarchiveMailSelected(appContext.partialOrg.find((x) => x === "archiveMailbox"));
        setonedriveSelected(appContext.partialOrg.find((x) => x === "oneDrive"));
        setsiteSelected(appContext.partialOrg.find((x) => x === "sites"));
        setteamsSelected(appContext.partialOrg.find((x) => x === "teams"));
        setteamsChatSelected(appContext.partialOrg.find((x) => x === "teamsChat") && appContext.isTeamsChatsOnline);
    }, [appContext.partialOrg.length]);

    return (
        <div id="step2">
            <div>
                <div id="UpdatePanel1">
                    <div className="card">
                        <h4 className="card-header ">
                            <span id="TitleLabel" className="SectionTitle">Select Items&nbsp;</span>
                            <a id="Job_btnHelp" style={{ float: 'right' }} onClick={() => {
                                let url = "https://cloudcover365.virtualdcs.co.uk/helpme/Step2-SelectingItems.html";
                                    window.open(url, '_blank');
                                }
                            }><i className="fad fa-question"></i></a>
                            {parseInt(props.actualOrgUserCount) > 100 &&
                                <>
                                <span class="ms-3 me-1" style={{ color: 'red', fontSize: '15px' }}>! User Base Warning = Please create some Dynamic user groups to improve backup performance</span>
                                <a id="JobWarning_btnHelp"  onClick={() => { handleURLClick("https://app.storylane.io/share/uowzczvbjacg") }}><i className="fad fa-question"></i></a>
                                </>}
                        </h4>
                        <div className="card-body ">

                            <Tabs
                                defaultActiveKey={defaultSettingSelected ? "BackupSelectedServices" : "BackupFollowingItems"}
                                id="step2-tab"
                                className=""
                                onSelect={onChangevalue}
                                fill
                                key={defaultSettingSelected ? "BackupSelectedServices" : "BackupFollowingItems"}
                            >
                                <Tab eventKey="BackupSelectedServices" className="pt-3" style={{ border: '1px solid #dee2e6', borderTop: 'none', borderBottomLeftRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} title="Backup the selected services" disabled={appContext.isEditJob}>
                                    {
                                        defaultSettingSelected &&
                                        <div>
                                                {error && <div style={{ textAlign: 'center' }}><span id="WizardRequiredFieldValidatorJobName" style={{ color: "red", position: 'relative', top: '-11px' }} >{error}</span></div>}
                                                <div className="row p-3">
                                                    <div className="col-6">
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxMailBox">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">Mailboxes</span>
                                                                    <input id="CheckBoxMailBox" disabled={allSelected} type="checkbox" name="mailbox" onChange={onChecked} checked={mailboxSelected} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxOneDrive">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px',width: '160px', display: 'inline-flex' }} className="me-3">OneDrive</span>
                                                                    <input id="CheckBoxOneDrive" disabled={allSelected} type="checkbox" name="oneDrive" onChange={onChecked} checked={onedriveSelected} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxTeams">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">Teams</span>
                                                                    <input id="CheckBoxTeams" disabled={allSelected} type="checkbox" name="teams" onChange={onChecked} checked={teamsSelected} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxTeamsChat">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">Teams Channel Chat</span>
                                                                    <input id="CheckBoxTeamsChat" disabled={allSelected || !showTeamsChat} type="checkbox" name="teamsChat" onChange={onChecked} checked={teamsChatSelected} />
                                                                </span>
                                                            </label>
                                                        </div>




                                                        {/*<div className="p-1" style={{ display:'flex' }}>*/}
                                                        {/*    <input id="CheckBoxMailBox" disabled={allSelected} type="checkbox" name="mailbox" onChange={onChecked} checked={mailboxSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxMailBox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Mailboxes</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxOneDrive" disabled={allSelected} type="checkbox" name="oneDrive" onChange={onChecked} checked={onedriveSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxOneDrive" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;OneDrive</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxTeams" disabled={allSelected} type="checkbox" name="teams" onChange={onChecked} checked={teamsSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxTeams" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Teams</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxTeamsChat" disabled={allSelected || !showTeamsChat} type="checkbox" name="teamsChat" onChange={onChecked} checked={teamsChatSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxTeamsChat" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Teams Channel Chat</label>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxArchiveMailbox">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">Archive Mailboxes</span>
                                                                    <input id="CheckBoxArchiveMailbox" disabled={allSelected} type="checkbox" name="archiveMailbox" onChange={onChecked} checked={archiveMailSelected} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxSite">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">Sharepoint Sites</span>
                                                                    <input id="CheckBoxSite" disabled={allSelected} type="checkbox" name="sites" onChange={onChecked} checked={siteSelected} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="p-1 mb-3" style={{ display: 'flex' }}>
                                                            <label htmlFor="CheckBoxAll">
                                                                <span className="card-radio border p-2 bg-light" style={{ borderRadius: '0.375rem' }}>
                                                                    <span style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px', width: '160px', display: 'inline-flex' }} className="me-3">All</span>
                                                                    <input id="CheckBoxAll" type="checkbox" name="allServices" disabled={appContext.isEditJob} onChange={onChecked} />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        {/*<div class="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxArchiveMailbox" disabled={allSelected} type="checkbox" name="archiveMailbox" onChange={onChecked} checked={archiveMailSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxArchiveMailbox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Archive Mailboxes</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div class="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxSite" disabled={allSelected} type="checkbox" name="sites" onChange={onChecked} checked={siteSelected} />*/}
                                                        {/*    <label htmlFor="CheckBoxSite" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Sharepoint Sites</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div class="p-1" style={{ display: 'flex' }}>*/}
                                                        {/*    <input id="CheckBoxAll" type="checkbox" name="allServices" disabled={appContext.isEditJob} onChange={onChecked} />*/}
                                                        {/*    <label htmlFor="CheckBoxAll" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;All</label>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            {/*<table cellSpacing="25" cellPadding="10" className="radioWizard" style={{ borderColor: "White", borderWidth: "0", borderStyle: "Solid", width: "100%" }} >*/}
                                            {/*    <tbody>*/}
                                            {/*        <tr>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxMailBox" disabled={allSelected} type="checkbox" name="mailbox" onChange={onChecked} checked={mailboxSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxMailBox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Mailboxes</label>*/}
                                            {/*            </td>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxArchiveMailbox" disabled={allSelected} type="checkbox" name="archiveMailbox" onChange={onChecked} checked={archiveMailSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxArchiveMailbox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Archive Mailboxes</label>*/}
                                            {/*            </td>*/}
                                            {/*        </tr>*/}
                                            {/*        <tr>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxOneDrive" disabled={allSelected} type="checkbox" name="oneDrive" onChange={onChecked} checked={onedriveSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxOneDrive" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;OneDrive</label>*/}
                                            {/*            </td>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxSite" disabled={allSelected} type="checkbox" name="sites" onChange={onChecked} checked={siteSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxSite" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Sharepoint Sites</label>*/}

                                            {/*            </td>*/}
                                            {/*        </tr>*/}
                                            {/*        <tr>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxTeams" disabled={allSelected} type="checkbox" name="teams" onChange={onChecked} checked={teamsSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxTeams" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Teams</label>*/}
                                            {/*            </td>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxAll" type="checkbox" name="allServices" disabled={appContext.isEditJob} onChange={onChecked} />*/}
                                            {/*                <label htmlFor="CheckBoxAll" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;All</label>*/}
                                            {/*            </td>*/}
                                            {/*        </tr>*/}


                                            {/*        <tr>*/}
                                            {/*            <td>*/}
                                            {/*                <input id="CheckBoxTeamsChat" disabled={allSelected || !showTeamsChat} type="checkbox" name="teamsChat" onChange={onChecked} checked={teamsChatSelected} />*/}
                                            {/*                <label htmlFor="CheckBoxTeamsChat" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Teams Channel Chat</label>*/}
                                            {/*            </td>*/}
                                            {/*        </tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="BackupFollowingItems" className="pt-3" style={{ border: '1px solid #dee2e6', borderTop: 'none', borderBottomLeftRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} title="Backup the following Items" disabled={appContext.isEditJob}>
                                    {
                                        !defaultSettingSelected &&
                                        <div>
                                                
                                                {error && <div style={{ textAlign: 'center' }}><span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span></div>}
                                            <div id="SelectedPanel" style={{ borderStyle: "None", height: "210px", width: "100%" }} >
                                                <div>
                                                    <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 210, borderCollapse: "collapse" }} >
                                                        {/*<input type="search" placeholder="search..." onChange={onFilterTextChange} className="form-control form-control-sm" />*/}
                                                        {
                                                            <AgGridReact style={{ width: "80%" }}
                                                                ref={gridRef}
                                                                getRowStyle={getRowStyle}
                                                                onGridReady={onGridReady}
                                                                defaultColDef={defaultColDef}
                                                                rowSelection="single"
                                                                enableCellTextSelection="true"
                                                                rowData={appContext.selectedObjects}
                                                                columnDefs={columnDefs}
                                                                headerHeight={headerHeight}
                                                                overlayLoadingTemplate={
                                                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                                                                overlayNoRowsTemplate={
                                                                    '<span className="ag-overlay-loading-center">No records to show</span>'
                                                                }
                                                                rowHeight={rowHeight}
                                                            >
                                                            </AgGridReact>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <table cellSpacing="5" cellPadding="5">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <button name="user" value="Add User(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" > Add User(s) </button>
                                                            </td>
                                                            <td>
                                                                <button name="group" value="Add Group(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" >Add Group(s)</button>
                                                            </td>
                                                            <td>
                                                                <button name="site" value="Add Site(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" > Add Site(s) </button>
                                                            </td>
                                                            <td>
                                                                <button name="team" value="Add Team(s)" onClick={openPopup} className="btn btn-reverse-primary btn-sm" >Add Team(s)</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <PopupModal displayModal={!infoPopup} closeModal={handleClose} itemType={itemTypeValue} popupType="AddObjects" refresh={isRefreshed} />
                                        </div>
                                    }
                                </Tab>
                            </Tabs>
                            {/*<table cellSpacing="0" cellPadding="0" id="Wizard1" style={{ height: "100%", width: "100%", borderCollapse: "collapse" }} >*/}
                            {/*    <tbody>*/}
                            {/*        <tr style={{ height: "100%" }} >*/}
                            {/*            <td>*/}
                            {/*                <div className="form-group">*/}
                            {/*                    */}{/*<span id="GridTypeLabel" className="wizardtitles" >Selected items to backup </span>*/}

                            {/*                    <table id="jobbackupsetting" cellSpacing="20" cellPadding="10" className="radioWizard" style={{ borderColor: "White", borderWidth: "2px", borderStyle: "Solid", width: "100%" }}>*/}
                            {/*                        <tbody>*/}
                            {/*                            <tr>*/}
                            {/*                                <td>*/}
                            {/*                                    <input id="jobbackupsetting_1" type="radio" onChange={onChangevalue} checked={defaultSettingSelected} disabled={appContext.isEditJob} value="PartialOrganization" />*/}
                            {/*                                    <label htmlFor="jobbackupsetting_1" style={{ fontFamily: "'Poppins', sans-serif", fontWeight:'500' }} >&nbsp;Backup the selected services</label>*/}
                            {/*                                </td>*/}
                            {/*                                <td>*/}
                            {/*                                    <input id="jobbackupsetting_2" type="radio" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500' }} onChange={onChangevalue} checked={!defaultSettingSelected} disabled={appContext.isEditJob} value="SelectedItems" />*/}
                            {/*                                    <label htmlFor="jobbackupsetting_2">&nbsp;Backup the following Items</label>*/}
                            {/*                                </td>*/}
                            {/*                            </tr>*/}
                            {/*                        </tbody>*/}
                            {/*                    </table>*/}
                            {/*                </div>*/}
                            {/*                {*/}
                            {/*                    defaultSettingSelected &&*/}
                            {/*                    <div>*/}
                            {/*                        <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span>*/}
                            {/*                        <table cellSpacing="25" cellPadding="10" className="radioWizard" style={{ borderColor: "White", borderWidth: "2px", borderStyle: "Solid", width: "100%" }} >*/}
                            {/*                            <tbody>*/}
                            {/*                                <tr>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxMailBox" disabled={allSelected} type="checkbox" name="mailbox" onChange={onChecked} checked={mailboxSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxMailBox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Mailboxes</label>*/}
                            {/*                                    </td>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxArchiveMailbox" disabled={allSelected} type="checkbox" name="archiveMailbox" onChange={onChecked} checked={archiveMailSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxArchiveMailbox" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Archive Mailboxes</label>*/}
                            {/*                                    </td>*/}
                            {/*                                </tr>*/}
                            {/*                                <tr>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxOneDrive" disabled={allSelected} type="checkbox" name="oneDrive" onChange={onChecked} checked={onedriveSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxOneDrive" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;OneDrive</label>*/}
                            {/*                                    </td>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxSite" disabled={allSelected} type="checkbox" name="sites" onChange={onChecked} checked={siteSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxSite" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Sharepoint Sites</label>*/}

                            {/*                                    </td>*/}
                            {/*                                </tr>*/}
                            {/*                                <tr>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxTeams" disabled={allSelected} type="checkbox" name="teams" onChange={onChecked} checked={teamsSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxTeams" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;Teams</label>*/}
                            {/*                                    </td>*/}
                            {/*                                    <td>*/}
                            {/*                                        <input id="CheckBoxAll" type="checkbox" name="allServices" disabled={appContext.isEditJob} onChange={onChecked} />*/}
                            {/*                                            <label htmlFor="CheckBoxAll" style={{ fontFamily: "'Poppins', sans-serif", fontSize: '15px' }}>&nbsp;All</label>*/}
                            {/*                                    </td>*/}
                            {/*                                </tr>*/}


                            {/*                                <tr>*/}
                            {/*                                    <td>*/}
                            {/*                                            <input id="CheckBoxTeamsChat" disabled={allSelected || !showTeamsChat} type="checkbox" name="teamsChat" onChange={onChecked} checked={teamsChatSelected} />*/}
                            {/*                                            <label htmlFor="CheckBoxTeamsChat" style={{ fontFamily: "'Poppins', sans-serif", fontSize:'15px' }}>&nbsp;Teams Channel Chat</label>*/}
                            {/*                                    </td>*/}
                            {/*                                </tr>*/}
                            {/*                            </tbody>*/}
                            {/*                        </table>*/}
                            {/*                    </div>*/}
                            {/*                }*/}
                            {/*                {*/}
                            {/*                    !defaultSettingSelected &&*/}
                            {/*                    <div>*/}
                            {/*                        <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span>*/}
                            {/*                        <div id="SelectedPanel" style={{ borderStyle: "None", height: "210px", width: "100%" }} >*/}
                            {/*                            <div>*/}
                            {/*                                <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 210, borderCollapse: "collapse" }} >*/}
                            {/*                                    */}{/*<input type="search" placeholder="search..." onChange={onFilterTextChange} className="form-control form-control-sm" />*/}
                            {/*                                    {*/}
                            {/*                                        <AgGridReact style={{ width: "80%" }}*/}
                            {/*                                            ref={gridRef}*/}
                            {/*                                            getRowStyle={getRowStyle}*/}
                            {/*                                            onGridReady={onGridReady}*/}
                            {/*                                            defaultColDef={defaultColDef}*/}
                            {/*                                            rowSelection="single"*/}
                            {/*                                            enableCellTextSelection="true"*/}
                            {/*                                            rowData={appContext.selectedObjects}*/}
                            {/*                                            columnDefs={columnDefs}*/}
                            {/*                                            headerHeight={headerHeight}*/}
                            {/*                                                overlayLoadingTemplate={*/}
                            {/*                                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}*/}
                            {/*                                            overlayNoRowsTemplate={*/}
                            {/*                                                '<span className="ag-overlay-loading-center">No records to show</span>'*/}
                            {/*                                            }*/}
                            {/*                                            rowHeight={rowHeight}*/}
                            {/*                                        >*/}
                            {/*                                        </AgGridReact>*/}
                            {/*                                    }*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                        <div>*/}
                            {/*                            <table cellSpacing="5" cellPadding="5">*/}
                            {/*                                <tbody>*/}
                            {/*                                    <tr>*/}
                            {/*                                        <td>*/}
                            {/*                                            <button name="user" value="Add User(s)" onClick={openPopup} className="btn btn-outline-dark btn-sm" > Add User(s) </button>*/}
                            {/*                                        </td>*/}
                            {/*                                        <td>*/}
                            {/*                                            <button name="group" value="Add Group(s)" onClick={openPopup} className="btn btn-outline-dark btn-sm" >Add Group(s)</button>*/}
                            {/*                                        </td>*/}
                            {/*                                        <td>*/}
                            {/*                                            <button name="site" value="Add Site(s)" onClick={openPopup} className="btn btn-outline-dark btn-sm" > Add Site(s) </button>*/}
                            {/*                                        </td>*/}
                            {/*                                        <td>*/}
                            {/*                                            <button name="team" value="Add Team(s)" onClick={openPopup} className="btn btn-outline-dark btn-sm" >Add Team(s)</button>*/}
                            {/*                                        </td>*/}
                            {/*                                    </tr>*/}
                            {/*                                </tbody>*/}
                            {/*                            </table>*/}
                            {/*                        </div>*/}
                            {/*                        <PopupModal displayModal={!infoPopup} closeModal={handleClose} itemType={itemTypeValue} popupType="AddObjects" refresh={isRefreshed} />*/}
                            {/*                    </div>*/}
                            {/*                }*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*    </tbody>*/}
                            {/*</table>*/}
                        </div>
                        <div className="card-footer p-0">
                            <table cellSpacing="5" cellPadding="5" align="right">
                                <tbody>
                                    <tr>
                                        <td align="left">
                                            <button title="Synchronisation of Microsoft organization objects with the organization cache database" onClick={handleReSync} className="btn btn-primary btn-sm">Re-Sync</button>
                                        </td>
                                        <td align="right">
                                            <ActionButtons {...props} nextStep={validate} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <SyncOrgDataModal displayModal={!syncDataPopup} closeModal={handleSyncModalClose} refresh={isSyncRefreshed} />
            {/*<HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step2-SelectingItems.html" />*/}
            {/*<HelpModal displayModal={!helpWarningPopup} closeModal={closeWarningModal} about="Introduction.html" />*/}
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
        </div >
    );
}

export default Step2;
