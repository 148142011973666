
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';
const DefaultColumnSelector = ({ onClose }) => {
    const columnDefs = [
        { columnName: "resellerName", displayName: "Reseller Name" },
        { columnName: "orgName", displayName: "Organisation Name" },
        { columnName: "currentStatus", displayName: "Stage" },
        { columnName: "nextAction", displayName: "Next Action" },
        { columnName: "priceModel", displayName: "Price Model" },
        { columnName: "creationDate", displayName: "Date Created" },
        { columnName: "trialUntil", displayName: "Trial Date" },
        { columnName: "lastInvoice", displayName: "Last Inv" },
        { columnName: "goCardlessID", displayName: "GoCardless ID" },
        { columnName: "tennantDomain", displayName: "Tennant Domain" },
        { columnName: "xeroID", displayName: "Xero ID" },
        { columnName: "userCount", displayName: "Users" },
        { columnName: "spaceUsedGB", displayName: "GB" },
        { columnName: "orgId", displayName: "Org ID" },
        { columnName: "nextActionDate", displayName: "Next Action Date" },
        { columnName: "salesSource", displayName: "Sales Source" },
        { columnName: "isReseller", displayName: "Reseller" },
        { columnName: "lastBackup", displayName: "Last Run" },
        { columnName: "warningCount", displayName: "Status" },
        { columnName: "disableAccess", displayName: "Disabled" },
        { columnName: "managed", displayName: "Managed" }
    ];
    const [defaultColumn, setDefaultColumn] = useState(['Operations', 'Finance', 'Admin'])
    //"priceModel",
    //    "lastInvoice",
    //    "goCardlessID",
    //    "xeroID",
    //    "userCount",
    //    "spaceUsedGB",
    //    "orgId",
    //    "nextActionDate",
    //    "salesSource"
    const columnsToIncluded = ["resellerName",
        "orgName",
        "currentStatus",
        "nextAction",
        "creationDate",
        "trialUntil",
        "tennantDomain",
        "isReseller",
        "lastBackup",
        "warningCount",
        "disableAccess",
        "managed"
    ];
    
    const [selectedColumns, setSelectedColumns] = useState(sessionStorage.getItem("DefaultTab") || "");


    const handleColumnToggle = (columnField) => {

       
        setSelectedColumns(columnField);
        sessionStorage.setItem("DefaultTab", columnField)
        onClose(columnField);
    };
   
    return (
        <>
            {
           
                defaultColumn.map(column => (
                    <Dropdown.Item onClick={() => handleColumnToggle(column)} key={`grid-${column}`}>
                        <div>
                            <input
                                type="checkbox"
                                style={{ display: 'none' }}
                                id={column}
                                value={column}
                                checked={sessionStorage.getItem("DefaultTab")  === column}
                                onChange={() => handleColumnToggle(column)}
                            />
                            {sessionStorage.getItem("DefaultTab") === column && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>}
                            <span style={{ fontSize: '13px' }} >{column}</span>

                        </div></Dropdown.Item>
                ))
            }
            
        </>
    );
};

export default DefaultColumnSelector;
