import React, { Component, useRef, useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.css";
import "../CSS/general.css";
import { loginAuthProvider } from "../LoginAuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../Helpers/Constants";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { Button, FormGroup, Label, Input } from "reactstrap";
//import Spinner from "reactstrap/lib/Spinner";
import moment from "moment";
import Table from "reactstrap/lib/Table";
import lodash, { forEach } from "lodash";
import TimePicker from "react-time-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from "@material-ui/core";
import { Modal } from "reactstrap";
import { Modal as BootstrapModal } from 'react-bootstrap';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import "react-select-search/style.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
} from "reactstrap";
import { saveAs } from "file-saver";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import exchange from "../images/exchange.png";
import aadIcon from "../images/aad.png";
import aadIconDisabled from "../images/aad-grey.png";
import NavigateCalendar from "../images/NavigateCalendar.png"
import onedrive from "../images/onedrive.png";
import sharepoint from "../images/sharepoint.png";
import teams from "../images/teams-icon.png";
import exchangeDisabled from "../images/exchange-grey.png";
import AzureToolTip from "../images/AzureToolTip.jpg";
import cloud from "../images/Cloud.png";
import onedriveDisabled from "../images/onedrive-grey.png";
import sharepointDisabled from "../images/sharepoint-grey.png";
import teamsDisabled from "../images/teams-grey.png";
import { NavBar } from "./NavBar";
import 'react-toastify/dist/ReactToastify.css';
import { RestorePoints } from "./RestorePoints";
import { AgGridReact } from "ag-grid-react";
import { Calendar } from "react-calendar";
import { OverlayTrigger, Tooltip, Accordion, Overlay, Popover } from "react-bootstrap";
import AuthenticationContext from "../Store/Authentication-Context";
import 'bootstrap/dist/css/bootstrap.min.css';
import RestoreAccessModal from "./RestoreAccessModal";
import LoaderGif from "../images/365 loading.gif"
import DotsTyping from "../images/DotsTyping.gif";
import Exchanges from "../images/exchange.png";
import Onedrives from "../images/onedrive.png";
import Sharepoints from "../images/sharepoint.png";
import Teams from "../images/teams-icon.png";
export class RestoreTree extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        let time = moment(new Date());
        let hour = time.format("HH").toString();
        let minute = time.format("mm").toString();
        let timeString = hour + ":" + minute;
        let guardianValueCheck = (sessionStorage.getItem('GuardianOnlyEnabled-' + new URLSearchParams(window.location.search).get("orgId")) == "true");
        const urlParams = new URLSearchParams(window.location.search);
        const orgId = urlParams.get("orgId");
        const orgName = urlParams.get("orgName");
        this.gridRef = React.createRef();
        let impersonating = false;
        if (orgId) {
            impersonating = true;
        }
        var accountInfo = loginAuthProvider.getAccountInfo();
        let username = accountInfo.account.userName;
        this.state = {
            isAzureGuardianOnly: guardianValueCheck,
            isAzureGuardianOnlyLoaded: true,
            guardianStatusLoaded: false,
            disableBtn: true,
            orgId: orgId,
            impersonating: impersonating,
            firstRestoreDate: null,
            lastRestoreDate: null,
            gotRestoreDates: false,
            gettingDeviceCode: false,
            clearCacheChecked: false,
            showConfirmationModal: false,
            restoreType: "",
            siteURL: "",
            destinationOneDriveId: "",
            destinationFolder:"",
            destinationOneDriveUserName: "",
            destinationOneDriveUrl: "",
            restoreDate: new Date(),
            restoreTime: timeString,
            inProgressLeft: false,
            inProgressRight: false,
            ingettingDeviceCode: false,
            inProgressRestore: false,
            gotSelectItems: false,
            showDropdown: false,
            restore: null,
            openSharepointModal: false,
            showoneDriveModal: false,
            selectItems: [],
            treeItems: [],
            viewItems: [],
            oldItems: null,
            oldFlag: false,
            oldPage: 0,
            type:"",
            noResults: false,
            selectItem: null,
            selectedFolder: null,
            showRestorePoints: false,
            restorePoint: [],
            displayPage: 0,
            loadedCurrentConfig: false,
            nextBlocked: true,
            selectedItems: [],
            clearLeftSection: false,
            isMFA: false,
            itemClicked: "",
            copied: false,
            verificationModalOpen: false,
            restoreAction: "",
            actionType: "",
            deviceCode: "",
            username: username,
            password: "",
            mailModalOpen: false,
            mailSendTo: "",
            mailFrom: "",
            mailSubject: "",
            mailMessage: "Here are the files you requested.",
            command: "",
            rootRestoreOpen: false,
            folderRestoreOpen: false,
            itemRestoreOpen: false,
            fileName: "",
            queryString: "",
            restoreJob: null,
            lastClicked: "",
            downloadModalOpen: false,
            entraModalOpen: false,
            
            downloading: false,
            restoreModalOpen: false,
            restoring: false,
            restorePoints: [],
            selectedrestorePoints: [],
            restoreSuccess: null,
            errorMessage: "",
            searchTerm: "",
            searchTermDate: null,
            searchResultsFlag: false,
            SearchQueued: false,
            searchQueries: [],
            searchHistoryQueries: [],
            activeSearchList: {
                QueriesList: [],
                SearchCount: [],
                SetID: [],
                Title: [],
                SubTitle: [],
                BackUpTime: [],
                ValidTime: [],
                SearchQuery: [],
                Url: [],
                Type: [],
                Status: []
            },
            searchHistoryResultCount: [],
            QueryHistory: [],
            restorePointID: "",
            searchType: "null",
            sarchField: "null",
            isDateField: false,
            searchQueryType: "",
            conditionType: "Text",
            conditionOptions: [],
            dateRange: { start: null, end: null },
            value: 0,
            unit: 'KB',
            dropdownValues: {
                customDropdown: '----',
                sensitivityDropdown: '----',
                meetingStatusDropdown: '----',
                attachmentsDropdown: '----',
                flagStatusDropdown: '----',
                colorDropdown: '----',
            },
            searchTermVisible: true,
            lblsearchTermVisible: true,
            searchTermDateVisible: false,
            lblsearchTermDateVisible: false,
            customFieldDropdownVisible: false,
            lblBetweenDatesVisible: false,
            fileSizeVisible: false,
            isDateFields: false,
            isCustomField: false,
            isBooleanField: false,
            isSensitivityField: false,
            isMeetingStatusField: false,
            isFlagStatusField: false,
            isColorField: false,
            isSizeField: false,
            searchFilters: [],
            aadviewItems: [],
            searchPanelOpen: true,
            aadnoResults: true,
            orgName: "",
            searchHistoryPanelOpen: false,
            noRestoreDates: false,
            checkRestoreDates: false,
            expandedFolders: ["root"],
            largeDownload: false,
            serverPath: "",
            isEndUser: false,
            restoreMailTo: "",
            usersList: [],
            extraLargeDownload: false,
            columnDefs: [],
            AADColumnDefs: [],
            mark: [],
            aadData: null,
            //isEntraButtonEnabled: false,
            entraRestorePoint: null,
            sessionid: '',
            gridApi: null,
            priceModel: {},
            restorePath: "",
            listName: "",
            activeAccordion: "0",
            selectedRestoreType: "",
            selectedRestoreDate: "",
            selectedRestorePoint: "",
            showCommentPopup: false,
            restoreChallangeSubmitted: false,
            restoreVisibleMonthandYear: "",
            isRestoreCalanderLoaded: false,
            showRestoreCalendar: false,
            isAADRestoreTableLoaded:false,
            renderRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}>
                <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
            </div>,
            renderAzureRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}>
                <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
            </div>,
            azureGridApi: null,
            AADPreviousCurrent: null,
            AADPreviousCConfigHeadar: null,
            AADReportshow: false,
            restore365ActiveStartDate: new Date(new Date().getFullYear(), new Date().getMonth())
        };
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.resetState = this.resetState.bind(this);
        this.getOrgRestore = this.getOrgRestore.bind(this);
        this.getRestore = this.getRestore.bind(this);
        this.getSelectItems = this.getSelectItems.bind(this);
        this.getChildren = this.getChildren.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.folderClick = this.folderClick.bind(this);
        this.getView = this.getView.bind(this);
        this.getTable = this.getTable.bind(this);
        this.convertHeading = this.convertHeading.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.selectRestoreItem = this.selectRestoreItem.bind(this);
        this.buildRestore = this.buildRestore.bind(this);
        this.openVerificationModal = this.openVerificationModal.bind(this);
        this.closeVerificationModal = this.closeVerificationModal.bind(this);
        this.openMailModal = this.openMailModal.bind(this);
        this.closeMailModal = this.closeMailModal.bind(this);
        this.mailContinue = this.mailContinue.bind(this);
        this.closesharepointRestoreToModal = this.closesharepointRestoreToModal.bind(this);
        this.sharepointRestoreToContinue = this.sharepointRestoreToContinue.bind(this);
        this.closeOneDriveRestoreToModal = this.closeOneDriveRestoreToModal.bind(this);
        this.oneDriveRestoreToContinue = this.oneDriveRestoreToContinue.bind(this);
        this.getDeviceCode = this.getDeviceCode.bind(this);
        this.deviceTokenCheck = this.deviceTokenCheck.bind(this);
        this.handleFilter = this.handleFilter.bind(this);

        this.verificationContinue = this.verificationContinue.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.editParameter = this.editParameter.bind(this);
        this.closeDownloadModal = this.closeDownloadModal.bind(this);
        this.closeRestoreModal = this.closeRestoreModal.bind(this);
        this.resetCheckbox = this.resetCheckbox.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.changeSearchTerm = this.changeSearchTerm.bind(this);
        this.search = this.search.bind(this);
        this.changeSearchField = this.changeSearchField.bind(this);
        this.changeSearchType = this.changeSearchType.bind(this);
        this.addToQueryLines = this.addToQueryLines.bind(this);
        this.changeSearchQueryType = this.changeSearchQueryType.bind(this);
        this.deleteQuery = this.deleteQuery.bind(this);
        this.openSearchPanel = this.openSearchPanel.bind(this);
        this.clearFolder = this.clearFolder.bind(this);
        this.getRestoreDates = this.getRestoreDates.bind(this);
        this.openSearchHistoryPanel = this.openSearchHistoryPanel.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.getOrgName = this.getOrgName.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.showRestorePointsPopup = this.showRestorePointsPopup.bind(this);
        this.setRestorePoint = this.setRestorePoint.bind(this);
        this.getStringFromDate = this.getStringFromDate.bind(this);
        this.accordianChange = this.accordianChange.bind(this);
        this.handleRestoreChallangeSubmitted = this.handleRestoreChallangeSubmitted.bind(this);
        this.handleCommentSubmitted = this.handleCommentSubmitted.bind(this);
        this.restoreChallengePopup = this.restoreChallengePopup.bind(this);
        this.hideRestorePopup = this.hideRestorePopup.bind(this);
        this.aadorNot = this.aadorNot.bind(this);
        this.onActiveStartDateChange = this.onActiveStartDateChange.bind(this);
        this.findMaxBackupDate = this.findMaxBackupDate.bind(this);
        this.getRestorePointsOnMonth = this.getRestorePointsOnMonth.bind(this);
        this.getIsEndUser();
        this.getRestoreEmailType();
        this.getOrgUsers();
        this.getOrgName();
        //this.getRestorePoints(new Date());

        this.onGridReady = this.onGridReady.bind(this);
        this.OnItemSelected = this.OnItemSelected.bind(this);
        this.OnselectionChanged = this.OnselectionChanged.bind(this)
        this.setColumnDefinition = this.setColumnDefinition.bind(this);
        this.formatDateColumn = this.formatDateColumn.bind(this);
        this.setDynamicColumnDefinition = this.setDynamicColumnDefinition.bind(this);
        this.PreviewAADdiff = this.PreviewAADdiff.bind(this);
        this.AADReporthandleClose = this.AADReporthandleClose.bind(this);
        this.renderAgGrid = this.renderAgGrid.bind(this);
        this.getRowStyle = this.getRowStyle.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.handleSearchDate = this.handleSearchDate.bind(this);
        this.overlayProgress = this.overlayProgress.bind(this);
        //this.CheckAzureGuardianOnly = this.CheckAzureGuardianOnly.bind(this);

        this.logRestoreSession = this.logRestoreSession.bind(this);
        this.getAADBackupConfigData = this.getAADBackupConfigData.bind(this);
        //this.getCalanderRestorePoints = this.getCalanderRestorePoints.bind(this);
        this.onChange = this.onChange.bind(this);
        //this.onAADChange = this.onAADChange.bind(this);
        this.getAADBackupConfigDataListing = this.getAADBackupConfigDataListing.bind(this);
        this.onClickAADRestore = this.onClickAADRestore.bind(this);
        this.CheckForEntraBackup(orgName, orgId);

    }
    static displayName = RestoreTree.name;
    
    componentDidMount() {
        this.setState({
            checkRestoreDates: true
        });
    }
    hideRestorePopup() {
        this.setState({
            showCommentPopup: false,
        });
    }
    handleCommentSubmitted = () => {
        this.setState({
            showCommentPopup: false,
            viewItems: [],
            selectedItems: []
        });
        if (this.state.selectedRestoreType === '365 Backup') {
            this.getRestorePoints(new Date());
            this.accordianChange("next");
            this.aadorNot(false);
            this.getActiveSearchList()
        }
        else if (this.state.selectedRestoreType === 'Azure Backup') {
            this.accordianChange("aadazure");
            this.aadorNot(true);
        }
    };
    restoreChallengePopup = (aadorNot) => {
        this.setState({
            showCommentPopup: true
        });
        if (aadorNot) {
            this.setState({
                selectedRestoreType: "Azure Backup"
            });
        }
        else {
            this.setState({
                selectedRestoreType: "365 Backup",
                isRestoreCalanderLoaded: false
            });
        }
    };

    removeCSS() {
        var oldLinks = document.body.childNodes;
        for (var i = 0; i < oldLinks.length; i++) { }
    }

    handleDateChange(date) {
        this.setState({
            restoreDate: date,
        });
    }

    handleBetweenDateChange = (key, date) => {
        this.setState(prevState => {
            // Update the date range with the new date for the given key
            const newDateRange = { ...prevState.dateRange, [key]: date };
            const startDate = newDateRange.start ? this.formatDate(newDateRange.start) : '';
            const endDate = newDateRange.end ? this.formatDate(newDateRange.end) : '';
            const rangeString = startDate && endDate ? `${startDate} b/w ${endDate}` : '';

            return {
                dateRange: newDateRange,
                searchTermRangeStart: startDate,
                searchTermRangeEnd: endDate,
                searchTermDate: rangeString,
                searchTerm: rangeString 
            };
        });
    };


    formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };



    handleSearchDate = (date) => {
        var d = date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        let searchDate = [year, month, day].join('-');
        this.setState({
            searchTermDate: date,
            searchTerm: searchDate,
        });
    }
    showRestorePointsPopup() {
        this.setState({ showRestorePoints: false });
    }

    setRestorePoint(params) {
        this.resetState("");
        this.setState({
            restorePoint: params,
            restoreDate: new Date(params.backupTime),
            restorePointID: params.id.toString(),
            restoreDateTime: params.backupTimeStr,
            restoreTime: this.getStringFromDate(params.backupTimeStr),
            clearLeftSection: true,
            activeAccordion: "3"
        });
    }

    getStringFromDate = (d) => {
        var timeString = d.split(' ')[1];
        return timeString.substring(0, 8);
    }

    handleTimeChange(time) {
        this.setState({
            restoreTime: time,
        });
    }
    handleCheckboxChange(event) {
        this.setState(
            {
                clearCacheChecked: !this.state.clearCacheChecked
            })
    }
    openDownloadModal(event, action, type) {
        event.preventDefault();
        let queryString = "";
        let largeDownload = false;
        if (this.state.restoreType == "vex") {
            if (type == "item") {
                if (this.state.selectItem !== undefined || this.state.selectItem !== null)
                    queryString = this.state.selectItem.actions[0].href.replace(
                        "/exportToPst",
                        "/items/" + action
                    );
                if (this.state.selectedItems.length > 10) {
                    largeDownload = true;
                }
            } else if (type == "folder") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/exportToPst",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
                largeDownload = true;
            } else if (type == "root") {
                queryString = this.state.selectItem.actions[0].href;
                largeDownload = true;
            }
        } else if (this.state.restoreType == "vesp1") {
            if (this.state.lastClicked == "Libraries") {
                queryString = this.state.selectItem.libraries.replace(
                    "/libraries",
                    "/documents/" + action
                );
            } else if (this.state.lastClicked == "Lists") {
                queryString = this.state.selectItem.lists.replace(
                    "/lists",
                    "/lists/" + this.state.selectedFolder.id + "/" + action
                );
            } else if (this.state.lastClicked == "Subsites") {
                queryString = this.state.selectItem.libraries.replace(
                    "/libraries",
                    "/sites/" + action
                );
                largeDownload = true;
            }
        } else if (this.state.restoreType == "vesp") {
            if (this.state.lastClicked == "Libraries") {
                queryString = this.state.restorePath + "/documents/" + action;
            } else if (this.state.lastClicked == "Lists") {
                queryString = this.state.restorePath + "/lists/" + this.state.selectedFolder.id + "/" + action;
            } else if (this.state.lastClicked == "Subsites") {
                queryString = this.state.restorePath + "/sites/" + action;
                largeDownload = true;
            }
        }
        else if (this.state.restoreType == "veod") {

            if (type == "item") {
                queryString = this.state.selectItem.getFolders.replace("/folders?parentId=Root", "/documents/" + action);
            } else if (type == "folder") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
                largeDownload = true;
            }
        } else if (this.state.restoreType == "vet") {
            if (type == "item") {
                if (this.state.selectedFolder.title == "Posts") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/posts/" + action
                    );
                } else {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/files/" + action
                    );
                }
            } else if (type === "folder") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/libraries",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
                largeDownload = true;
            }
        }

        this.setState(
            {
                restoreAction: action,
                actionType: type,
                downloadModalOpen: true,
                downloading: true,
                restoreModalOpen: false,
                restoring: false,
                restoreSuccess: null,
                queryString: queryString,
                largeDownload: largeDownload
            },
            () => {
                this.buildRestore();
            }
        );
    }

    openMailModal(event, action, type) {
        event.preventDefault();
        let queryString = "";


        if (this.state.restoreType == "vex") {
            if (type == "item") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/exportToPst",
                    "/items/" + action
                );
            }
        } else if (this.state.restoreType == "vesp") {
            if (this.state.lastClicked == "Libraries") {
                queryString = this.state.selectItem.libraries.replace(
                    "/libraries",
                    "/documents/" + action
                );
            } else if (this.state.lastClicked == "Lists") {
                queryString = this.state.selectItem.lists.replace(
                    "/lists",
                    "/lists/" + this.state.selectedFolder.id + "/" + action
                );
            } else if (this.state.lastClicked == "Subsites") {
                queryString = this.state.selectItem.libraries.replace(
                    "/libraries",
                    "/sites/Action"
                );
            }
        } else if (this.state.restoreType == "veod") {
            if (type == "item") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/documents/" + action
                );
            } else if (type == "folder") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
            } else if (type == "root") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/documents/" + action
                );
            }
        } else if (this.state.restoreType == "vet") {
            if (type == "item") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/channels",
                    "/files/" + action
                );
            } else if (type == "folder") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/libraries",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
            } else if (type == "root") {
            }
        }
        if (action == "sendtoDefaultaddress") {
            this.setState(
                {
                    restoreAction: action,
                    actionType: type,
                    queryString: queryString,
                },
                () => {
                    this.buildRestore();
                }
            );
        } else {
            this.setState({
                restoreAction: action,
                actionType: type,
                queryString: queryString,
                mailModalOpen: true,
                largeDownload: false
            });
        }
    }

    async getRestoreDownloads(e) {
        if (e) {
            e.preventDefault();
        }
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let data = Axios.get(API_ROUTE + Routes.GET_RESTORE_DOWNLOADS, config)
            .then((response) => {
                this.setState({ disableBtn: false });
            })
            .catch((reason) => {
                console.log(reason);
            });
    }

    openVerificationModal(event, action, type) {
        if (event) {
            event.preventDefault();
        }
        this.setState(
            {
                verificationModalOpen: true
            }
        );
        let queryString = "";
        if (this.state.restoreType == "vex") {
            if (type == "root") {
                var actions = this.state.selectItem.actions;

                for (var i = 0; i < actions.length; i++) {
                    const action = actions[i];
                    if (action.name == "RestoreToOriginalLocation") {
                        queryString = action.href;
                    }
                }
            } else if (type == "item") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/exportToPst",
                    "/items/" + action
                );
            } else if (type == "folder") {
                queryString = this.state.selectItem.actions[0].href.replace(
                    "/exportToPst",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
            }
        } else if (this.state.restoreType == "vesp") {
            if (type == "root") {
                queryString = this.state.selectItem.libraries.replace(
                    "/libraries",
                    "/" + action
                );
            } else if (type == "folder") {
                if (this.state.lastClicked == "Libraries") {
                    /*queryString = this.state.selectItem.libraries.replace(
                        "/libraries",
                        "/libraries/" + this.state.selectedFolder.id + "/" + action
                    );*/
                    queryString = this.state.selectedFolder.self + "/" + action;
                } else if (this.state.lastClicked == "Lists") {
                    queryString = this.state.selectItem.lists.replace(
                        "/lists",
                        "/lists/" + this.state.selectedFolder.id + "/" + action
                    );
                } else if (this.state.lastClicked == "Subsites") {
                    queryString = this.state.selectItem.libraries.replace(
                        "/libraries",
                        "/sites/" + action
                    );
                }
            } else if (type == "item") {
                if (this.state.lastClicked == "Libraries") {
                    queryString = this.state.selectItem.libraries.replace(
                        "/libraries",
                        "/documents/" + action
                    );
                } else if (this.state.lastClicked == "Lists") {
                    queryString = this.state.selectItem.lists.replace(
                        "/lists",
                        "/items/" + action
                    );
                } else if (this.state.lastClicked == "Subsites") {
                    queryString = this.state.selectItem.libraries.replace(
                        "/libraries",
                        "/sites/" + action
                    );
                }
            }
        } else if (this.state.restoreType == "veod") {
            if (type == "item") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/documents/" + action
                );
            } else if (type == "folder") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/folders/" + this.state.selectedFolder.id + "/" + action
                );
            } else if (type == "root") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/folders?parentId=Root",
                    "/" + action
                );
            }
        } else if (this.state.restoreType == "vet") {
            if (type == "item") {
                if (this.state.selectedFolder.title == "Files") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/files/" + action
                    );
                } else if (this.state.selectedFolder.title == "Posts") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/posts/" + action
                    );
                } else if (this.state.selectedFolder.title == "Tabs") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/channels/" + this.state.lastClicked + "/tabs/" + action
                    );

                } else {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/files/" + action
                    );
                }
            } else if (type == "folder") {
                if (this.state.selectedFolder.title == "Tabs") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/channels/" + this.state.lastClicked + "/tabs/" +
                        //this.state.selectedFolder.id + "/" +
                        action
                    );
                }
                else if (this.state.selectedFolder.title == "Files") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        //"/channels/" + this.state.lastClicked +
                        "/files/" + action
                    );
                } else if (this.state.selectedFolder.title == "Posts") {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        //"/channels/" + this.state.lastClicked +
                        "/posts/" + action
                    );
                }
                else {
                    queryString = this.state.selectItem.getFolders.replace(
                        "/channels",
                        "/channels/" + this.state.lastClicked + "/" + action
                    );
                }
            } else if (type == "root") {
                queryString = this.state.selectItem.getFolders.replace(
                    "/channels",
                    "/" + action
                );
            }
        }
        this.setState(
            {
                verificationModalOpen: true,
                restoreAction: action,
                actionType: type,
                queryString: queryString,
                largeDownload: false
            },
            () => {
                if (this.state.isMFA && !this.state.gettingDeviceCode) {
                    this.getDeviceCode();
                }
            }
        );
    }

    closeMailModal(event) {
        event.preventDefault();
        this.setState({
            restoreAction: "",
            downloadModalOpen: false,
            downloading: false,
            verificationModalOpen: false,
            mailModalOpen: false,
            restoreModalOpen: false,
            restoreSuccess: null,
            restoring: false,
        });
    }
    closesharepointRestoreToModal(event) {
        event.preventDefault();
        this.setState({
            restoreAction: "",
            downloadModalOpen: false,
            downloading: false,
            verificationModalOpen: false,
            mailModalOpen: false,
            openSharepointModal: false,
            restoreModalOpen: false,
            restoreSuccess: null,
            restoring: false,
        });
    }

    closeOneDriveRestoreToModal(event) {
        event.preventDefault();
        this.setState({
            restoreAction: "",
            downloadModalOpen: false,
            downloading: false,
            verificationModalOpen: false,
            mailModalOpen: false,
            showoneDriveModal: false,
            restoreModalOpen: false,
            restoreSuccess: null,
            restoring: false,
        });
    } 

    mailContinue(event) {
        event.preventDefault();
        this.setState(
            {
                mailModalOpen: false,
                restoreModalOpen: true,
                restoring: true,
            },
            () => {
                this.buildRestore(null);
            }
        );
    }
    sharepointRestoreToContinue(event) {
        event.preventDefault();
        this.setState(
            {
                openSharepointModal: false,
             //  restoreModalOpen: true,
             //   restoring: true,
            },
            () => {
                // this.buildRestore(null);
                this.openVerificationModal(event, "restoreTo",this.state.type);
            }
        );
    }
    oneDriveRestoreToContinue(event) {
        event.preventDefault();
        this.setState(
            {
                showoneDriveModal: false,
                //  restoreModalOpen: true,
                //   restoring: true,
            },
            () => {
                // this.buildRestore(null);
                this.openVerificationModal(event, "copyTo", this.state.type);
            }
        );
    }

    closeVerificationModal(event) {
        event.preventDefault();
        this.setState({
            verificationModalOpen: false,
            gettingDeviceCode: false,
            restoreAction: "",
            downloadModalOpen: false,
            downloading: false,
            mailModalOpen: false,
            restoreModalOpen: false,
            restoreSuccess: null,
            restoring: false,
            deviceCode: "",
            password: "",
        });
    }

    async getDeviceCode() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        this.setState(
            {
                ingettingDeviceCode: true,
                gettingDeviceCode: true,
                deviceCode: "",
            },
            () => {
                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    }
                };

                let queryString = this.state.restore.links.organization.href + "/restoreDeviceCode";
                queryString = queryString.replace("https://127.0.0.1:4443/", this.state.serverPath);
                let data = {
                    Querystring: queryString,
                    orgId: this.state.orgId,
                };
                Axios.post(API_ROUTE + Routes.GET_RESTORE_DEVICE_CODE, data, config)
                    .then((response) => {
                        let deviceCode = response.data;
                        this.setState({
                            deviceCode: deviceCode,
                            inProgressRight: false,
                            ingettingDeviceCode: false,
                            disableBtn: true
                        });
                        // this.deviceTokenCheck(tokenString);
                    })
                    .catch((reason) => {
                        this.setState({
                            ingettingDeviceCode: false,
                            inProgressRight: false,
                        });
                    });
            }
        );
    }
    async deviceTokenCheck(tokenString) {
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let data = Axios.get(API_ROUTE + Routes.CHECK_DEVICE_CODE + "?code=" + this.state.deviceCode + "&orgId=" + this.state.orgId, config)
            .then((response) => {
                this.setState({ disableBtn: false });
            })
            .catch((reason) => {
                console.log(reason);
            });
    }

    async verificationContinue() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        let isDownload = false;
        isDownload = (this.state.fileName != null && this.state.fileName != "");
        let myTimeout;
        if (isDownload) {
            myTimeout = setTimeout(function () {
                this.setState({ extraLargeDownload: true });
            }.bind(this), 120000);
        }
        this.setState(
            {
                verificationModalOpen: false,
            },
            () => {
                let restoreJob = this.state.restoreJob;
                let queryString = this.state.queryString;
                let data = {
                    Job: restoreJob,
                    Command: "Not needed after v6 upgrade",
                    QueryString: queryString,
                    IsDownload: isDownload,
                    OrgId: this.state.orgId,
                };
                let timeout = 4500000;
                if (isDownload) {
                    let downloadType = "zip";
                    if (this.state.command == "exporttoPst") {
                        downloadType = "pst";
                    } else if (this.state.command == "export") {
                        downloadType = "html";
                    }

                    let fileName = this.state.fileName;
                    data.DownloadType = downloadType;
                    data.FileName = fileName;

                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                        timeout: timeout,
                        responseType: "blob",
                    };
                    Axios.post(API_ROUTE + Routes.COMPLETE_RESTORE, data, config)
                        .then((response) => {
                            this.overlayProgress(false);
                            //let saveType = fileName.includes(".zip") ? "application/zip" : fileName.includes(".pst") ? "application/vnd.ms-outlook" : fileName.includes(".html") ? "text/html" : "";
                            //if (response.headers["content-type"].includes("vnd.ms-outlook-msg")) {
                            //    saveType = "application/vnd.ms-outlook";
                            //    fileName = "exchange-file.msg";
                            //}

                            //var blob = new Blob([response.data], {
                            //    type: saveType,
                            //});
                            //clearTimeout(myTimeout);

                            //saveAs(blob, fileName);
                            this.logRestoreSession(tokenString);
                            this.setState({
                                inProgressRight: false,
                                mailModalOpen: false,
                                deviceCode: "",
                                verificationModalOpen: false,
                                selectedItems: [],
                                downloading: false,
                                restoring: false,
                                extraLargeDownload: false,
                            });
                        })
                        .catch((reason) => {
                            this.setState({
                                inProgressRight: false,
                                mailModalOpen: false,
                                verificationModalOpen: false,
                                downloading: false,
                                deviceCode: "",
                                restoring: false,
                                selectedItems: [],
                                extraLargeDownload: false,
                                errorMessage: reason.response.data.message,
                            });
                        });


                } else {
                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                    };
                    Axios.post(API_ROUTE + Routes.COMPLETE_RESTORE, data, config)
                        .then((response) => {
                            this.overlayProgress(false);
                            this.logRestoreSession(tokenString);
                            this.setState({
                                restoreSuccess: response.data,
                                inProgressRight: false,
                                mailModalOpen: false,
                                verificationModalOpen: false,
                                selectedItems: [],
                                downloading: false,
                                deviceCode: "",
                                restoring: false,
                            });
                        })
                        .catch((reason) => {
                            this.setState({
                                inProgressRight: false,
                                mailModalOpen: false,
                                verificationModalOpen: false,
                                selectedItems: [],
                                deviceCode: "",
                                downloading: false,
                                restoring: false,
                            });
                        });
                }
            }
        );
    }

    logRestoreSession(token) {
        const config = {
            headers: {
                Authorisation: `Bearer ` + token,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let _sessionID = this.state.sessionid;
        let sessionId = _sessionID.split('/')

        let data = {
            RestoreId: sessionId[sessionId.length - 1],
            OrgId: this.state.orgId
        };
        Axios.post(API_ROUTE + Routes.LOG_RESTORESESSION, data, config)
            .then((response) => {
                console.log('log successfull')
            })
            .catch((reason) => {
                console.log('error occured')
            });
    }

    buildRestore(event) {
        if (event) {
            event.preventDefault();
        }
        let restoreType = "";
        let restoreAction = this.state.restoreAction;
        let actionType = this.state.actionType;
        switch (this.state.restoreType) {
            case "vex":
                restoreType = "mailboxes";
                break;
            case "veod":
                restoreType = "onedrives";
                break;
            case "vesp":
                restoreType = "sites";
                break;
            case "vet":
                restoreType = "teams";
                break;
            default:
                break;
        }
        let restoreString = restoreType + "-" + actionType + "-" + restoreAction;
        let restoreJob = {};
        let restoreToCopyToType = "";
        let username = this.state.username;
        let items = [];
        let folders = [];
        let restoreToOrginalLocation = false;
        let command = "";
        let successMessage = "";
        let outputFile;
        this.state.selectedItems.forEach((item) => {
            if (item.id)
                items.push({ Id: item.id.toString() });
        });
        switch (restoreString) {
            case "teams-item-save":
                restoreJob.Files = items;
                //restoreJob.channelId = this.state.lastClicked;
                command = "save";
                outputFile = "Teams-files.zip";
                break;
            case "teams-item-export":
                let postItems = [];
                this.state.selectedItems.forEach((item) => {
                    postItems.push({ PostId: item.postId.toString() });
                });
                restoreJob.posts = postItems;
                //restoreJob.channelId = this.state.lastClicked;
                command = "export";
                outputFile = "Teams-Posts.html";
                break;
            case "teams-foder-restore":
                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = false;
                restoreJob.RestoreListViews = true;
                restoreJob.channelId = this.state.lastClicked;
                //restoreJob.List = RestoreSelection[5];
                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.SendSharedLinksNotification = "false";
                restoreJob.RestoreChangedItems = true;
                command = "restoreto";
                break;
            case "teams-item-restore":
            case "teams-folder-restore":
                restoreToOrginalLocation = true;
                restoreJob.usercode = this.state.deviceCode;

                //restoreJob.channelId = this.state.lastClicked;
                if (!this.state.selectedFolder) {
                    //restoreJob.userName = username;
                    restoreJob.RestoreChangedItems = true;
                    restoreJob.RestoreMissingItems = true;
                    restoreJob.RestoreMembers = true;
                    restoreJob.RestoreSettings = true;
                    restoreJob.FileVersion = "last";
                    restoreJob.FileLastVersionAction = "overwrite";
                } else {
                    if (this.state.selectedFolder.title == "Posts") {
                        //restoreJob.userName = username;
                        restoreJob.channelId = this.state.lastClicked;
                        restoreJob.posts = items;
                    } else if (this.state.selectedFolder.title == "Tabs") {
                        // restoreJob.userName = username;
                        // restoreJob.tabs = items;
                        restoreJob.RestoreMissingTabs = true;
                        restoreJob.RestoreChangedTabs = true;
                    } else {
                        //restoreJob.userName = username;
                        restoreJob.RestoreChangedItems = true;
                        restoreJob.RestoreMissingItems = true;
                        restoreJob.RestoreMembers = true;
                        restoreJob.RestoreSettings = true;
                        restoreJob.FileVersion = "last";
                        restoreJob.FileLastVersionAction = "overwrite";
                        restoreJob.Files = items;
                        restoreJob.ChannelId = this.state.lastClicked;
                    }
                }
                command = "restore";
                break;
            case "teams-root-restore":
                restoreToOrginalLocation = true;
                restoreJob.usercode = this.state.deviceCode;
                //restoreJob.userName = username;
                restoreJob.RestoreChangedItems = true;
                restoreJob.RestoreMissingItems = true;
                restoreJob.RestoreMembers = true;
                restoreJob.RestoreSettings = true;
                restoreJob.FileVersion = "last";
                restoreJob.FileLastVersionAction = "overwrite";
                command = "restore";
                break;
            case "teams-item-send":
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.channelId = this.state.lastClicked;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                restoreJob.Files = items;
                command = "send";
                successMessage =
                    "Selected files have been sent " + this.state.mailSendTo;
                break;
            case "sites-root-restore":
                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                restoreJob.usercode = this.state.deviceCode;

                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.RestoreListViews = true;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreSubsites = true;
                restoreJob.RestoreMasterPages = true;
                restoreJob.SendSharedLinksNotification = "false";
                restoreJob.userName = this.state.username;
                command = "restoreto";
                break;
            case "sites-root-restoreTo":

                restoreJob.usercode = this.state.deviceCode;
                restoreJob.DocumentLastVersionAction = "Overwrite";
                restoreJob.Alias = "last";
                restoreJob.RestoreListViews = true;
                restoreJob.RestorePermissions = "true";
                restoreJob.SiteURL = this.state.siteURL;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreSubsites = true;
                restoreJob.RestoreMasterPages = true;
                restoreJob.SendSharedLinksNotification = "false";
                restoreJob.userName = this.state.username;
                restoreJob.documentVersion = "All";
                command = "restoreto";
                break;
            case "sites-item-restore":
                restoreJob.usercode = this.state.deviceCode;
                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreListViews = true;
                //restoreJob.List = RestoreSelection[5];
                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.SendSharedLinksNotification = "false";
                command = "restoreto";
                if (this.state.lastClicked == "Libraries") {
                    restoreJob.Documents = items;
                    restoreJob.List = this.state.listName;
                } else if (this.state.lastClicked == "Lists") {
                    restoreJob.items = items;
                    restoreJob.List = this.state.selectedFolder.title;
                }
                break;
            case "sites-item-restoreTo":
                restoreJob.usercode = this.state.deviceCode;
                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreListViews = true;
                restoreJob.SiteURL = this.state.siteURL;
                //restoreJob.List = RestoreSelection[5];
                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.SendSharedLinksNotification = "false";
                command = "restoreto";
                if (this.state.lastClicked == "Libraries") {
                    restoreJob.Documents = items;
                    restoreJob.List = this.state.listName;
                } else if (this.state.lastClicked == "Lists") {
                    restoreJob.items = items;
                    restoreJob.List = this.state.selectedFolder.title;
                }
                restoreToCopyToType = "vesp";
                break;
            case "sites-folder-restore":
                restoreJob.usercode = this.state.deviceCode;
                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreListViews = true;
                if (this.state.lastClicked == "Libraries") {
                    restoreJob.Documents = null;
                    restoreJob.List = this.state.listName;
                } else if (this.state.lastClicked == "Lists") {
                    restoreJob.items = items;
                    restoreJob.List = this.state.selectedFolder.title;
                }
                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.SendSharedLinksNotification = "false";
                command = "restoreto";
                break;
            case "sites-folder-restoreTo":
                restoreJob.usercode = this.state.deviceCode;
                restoreToOrginalLocation = true;
                restoreJob.SiteURL = this.state.siteURL;
                restoreJob.ChangedItems = true;
                restoreJob.DeletedItems = true;
                restoreJob.RestoreListViews = true;
                if (this.state.lastClicked == "Libraries") {
                    restoreJob.Documents = null;
                    restoreJob.List = this.state.listName;
                } else if (this.state.lastClicked == "Lists") {
                    restoreJob.items = items;
                    restoreJob.List = this.state.selectedFolder.title;
                }
                restoreJob.DocumentVersion = "last";
                restoreJob.DocumentLastVersionAction = "Merge";
                restoreJob.RestorePermissions = "true";
                restoreJob.SendSharedLinksNotification = "false";
                command = "restoreto";
                restoreToCopyToType = "vesp";
                break;
            case "sites-item-send":
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                restoreJob.Documents = items;
                command = "sendTo";
                successMessage =
                    "Selected files have been sent " + this.state.mailSendTo;
                //SetMailServer("Site");
                break;
            case "sites-folderMailto":
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                command = "sendTo";
                successMessage =
                    "Selected files have been sent " + this.state.mailSendTo;
                //SetMailServer("Site");
                break;
            case "sites-item-save":
                restoreJob.Documents = items;
                command = "save";
                outputFile = "Sharepoint-files.zip";
                break;
            case "sites-folderexporttoLocal":
                command = "save";
                outputFile = "Sharepoint-folder.zip";
                break;
            case "mailboxes-root-restore":
                //Device code set already in GetAuthCode()
                restoreJob.usercode = this.state.deviceCode;

                break;
            case "mailboxes-item-sendtoDefaultaddress":
                restoreJob.items = items;
                command = "sendtoDefaultaddress";
                successMessage = "Restored items have been sent to the owner.";
                //SetMailServer("Mail");
                break;
            case "mailboxes-item-sendtoDifferentaddress":
                /*if (CallingCommand.Value == "")
                  {
                      CallingCommand.Value = "mailboxes-Mailto";
                      //MailToPopup.Show();
                      return;
                  }*/
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                restoreJob.items = items;
                command = "sendtoDifferentaddress";
                successMessage =
                    "Email Message have been sent " + this.state.mailSendTo;
                //SetMailServer("mail");
                break;
            case "mailboxes-restore": //mailboxes-root-restoreToOriginalLocation  
                restoreToOrginalLocation = true;
                // restoreJob.userName = username;
                restoreJob.items = items;
                command = "restoreToOriginalLocation";
                successMessage = "Selected files have been restore ";
                //CallingCommand.Value = "";
                break;
            case "mailboxes-folder-restore":
                restoreJob.usercode = this.state.deviceCode;

                restoreToOrginalLocation = true;
                //restoreJob.userName = username;
                command = "restoreToOriginalLocation";
                break;
            case "mailboxes-save":
                restoreJob.items = items;
                command = "savetoMsg";
                outputFile = "exchange-files.zip";
                break;
            case "mailboxes-sendtoDefaultaddress":
                restoreJob.items = items;
                command = "sendtoDefaultaddress";
                successMessage = "Restored items have been sent to the owner.";
                //SetMailServer("Mail");
                break;
            case "mailboxes-item-restore":
                restoreJob.usercode = this.state.deviceCode;
                restoreToOrginalLocation = true;
                restoreJob.items = items;
                command = "restoreToOriginalLocation";
                successMessage = "Selected files have been restore ";
                //CallingCommand.Value = "";
                break;
            case "mailboxes-item-save":
                restoreJob.items = items;
                command = "savetoMsg";
                outputFile = "exchange-files.zip";
                break;
            case "mailboxes-item-exporttoPst":
            case "mailboxes-root-exporttoPst":
                restoreJob.items = items;
                restoreJob.EnablePstSizeLimit = false;
                //restoreJob.pstSizeLimitBytes = 1073741824;
                command = "exporttoPst";
                outputFile = "exchange.pst";
                break;
            case "mailboxes-exporttoPst": //mailboxes-item-exporttoPst
                restoreJob.items = items;
                restoreJob.EnablePstSizeLimit = false;
                command = "exporttoPst";
                outputFile = "exchange.pst";
                break;
            case "mailboxes-folder-exporttoPst":
                command = "exporttoPst";
                restoreJob.EnablePstSizeLimit = false;
                outputFile = "exchange.pst";
                break;
            case "mailboxes-sendtoDifferentaddress":
                /*if (CallingCommand.Value == "")
                  {
                      CallingCommand.Value = "mailboxes-Mailto";
                      //MailToPopup.Show();
                      return;
                  }*/
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                restoreJob.items = items;
                command = "sendtoDifferentaddress";
                successMessage =
                    "Email Message have been sent " + this.state.mailSendTo;
                //SetMailServer("mail");
                break;
            case "onedrives-root-restore":
            case "onedrives-item-restore":
                restoreJob.usercode = this.state.deviceCode;

                restoreToOrginalLocation = true;
                //                restoreJob.userName = username;
                restoreJob.DocumentAction = "keep";
                restoreJob.DocumentVersion = "last";
                restoreJob.Documents = items;
                command = "restoreToOriginalLocation";
                break;
            case "onedrives-root-copyTo":
            case "onedrives-item-copyTo":
                restoreJob.usercode = this.state.deviceCode;
                restoreJob.changedItems = true;
                restoreJob.deletedItems = true;
                restoreJob.restorePermissions = "true";
                restoreJob.sendSharedLinksNotification = "true";
                restoreJob.onedrive = {
                    id: this.state.destinationOneDriveId,
                    name: this.state.destinationOneDriveUserName,
                    url: this.state.destinationOneDriveUrl,

                };
                restoreJob.folder = this.state.destinationFolder;
                restoreJob.documentVersion = "All";
                restoreJob.documentLastVersionAction = "Overwrite";

                restoreJob.Documents = items;
                command = "restoreToOriginalLocation";
                restoreToCopyToType = "veod";
                break;
            case "onedrives-folder-restore":
                restoreJob.usercode = this.state.deviceCode;

                restoreToOrginalLocation = true;
                //                restoreJob.userName = username;
                restoreJob.DocumentAction = "keep";
                restoreJob.DocumentVersion = "last";
                command = "restoreToOriginalLocation";
                break;
            case "onedrives-folder-copyTo":
                restoreJob.usercode = this.state.deviceCode;

                restoreJob.changedItems = true;
                restoreJob.deletedItems = true;
                restoreJob.restorePermissions = "true";
                restoreJob.sendSharedLinksNotification = "true";
                restoreJob.onedrive = {
                    id: this.state.destinationOneDriveId,
                    name: this.state.destinationOneDriveUserName,
                    url: this.state.destinationOneDriveUrl,

                };
                restoreJob.folder = this.state.destinationFolder;
                restoreJob.documentVersion = "All";
                restoreJob.documentLastVersionAction = "Overwrite";
                //                restoreJob.userName = username;
                restoreJob.DocumentAction = "keep";
                restoreJob.DocumentVersion = "last";
                command = "restoreToOriginalLocation";
                restoreToCopyToType = "veod";
                break;
            case "onedrives-item-save":
                restoreJob.asZip = true;
                restoreJob.Documents = items;
                command = "save";
                outputFile = "onedrive-files.zip";
                break;
            case "onedrives-folder-save":
                command = "save";
                outputFile = "onedrive-files.zip";
                break;
            case "onedrives-item-send":
                /*if (CallingCommand.Value == "")
                  {
                      CallingCommand.Value = "onedrives-Mailto";
                      //MailToPopup.Show();
                      return;
                  }*/
                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                restoreJob.Documents = items;
                command = "sendTo";
                successMessage =
                    "Selected files have been sent " + this.state.mailSendTo;
                //SetMailServer("OD");
                break;
            case "onedrives-folder-send":

                restoreJob.to = this.state.mailSendTo;
                //restoreJob.from = username;
                restoreJob.subject = this.state.mailSubject;
                restoreJob.text = this.state.mailMessage;
                command = "sendTo";
                successMessage =
                    "Selected files have been sent " + this.state.mailSendTo;
                //SetMailServer("OD");
                break;
            default:
                break;
        }
       
        if (
            restoreAction === "sendtoDefaultaddress" ||
            restoreAction === "restore" || restoreAction === "restoreTo" || restoreAction ==="copyTo"
        ) {
            this.setState(
                {
                    restoreJob: restoreJob,
                    restoreAction: restoreAction,
                    command: command,
                    restoring: true,
                    restoreModalOpen: true,
                    mailFrom: username,
                    fileName: outputFile,
                },
                () => {
                    this.verificationContinue(restoreToCopyToType);
                }
            );
        } else {
            this.setState(
                {
                    restoreJob: restoreJob,
                    restoreAction: restoreAction,
                    command: command,
                    mailFrom: username,
                    fileName: outputFile,
                },
                () => {
                    this.verificationContinue(restoreToCopyToType);
                }
            );
        }
    }

    toggleDropdown(event, menu) {
        event.preventDefault();
        this.setState({
            [menu]: !this.state[menu],
        });
    }

    editParameter(event) {
        event.preventDefault();
        let value = event.target.value;

        let parameter = event.target.name;

        if (parameter === "destinationOneDriveUrl") {
            if (value !== "null") {

                let selectedItem = this.state.selectItems.find(site => site.email === value);
                this.setState({
                    destinationOneDriveId: selectedItem.id,
                    destinationOneDriveUserName: selectedItem.title,
                    [parameter]: value,
                });
            }
            else {
                this.setState({
                    destinationOneDriveId: "",
                    destinationOneDriveUserName: "",
                    [parameter]: "",
                });
            }

        }
        else {
            this.setState({
                [parameter]: value,
            });
        }

      
    }

    changeSearchTerm(event) {
        event.preventDefault();
        let value = event.target.value;
        this.setState({
            searchTerm: value,
        });
    }
    getSearchField = (e) => {
        if (this.state.isDateFields) {
            return (<DatePicker
                selected={this.state.searchTerm}
                minDate={this.state.firstRestoreDate}
                maxDate={this.state.lastRestoreDate}
                onChange={(date) => this.handleDateChange(date)}
                dateFormat="dd/MM/yyyy"
                style={{ maxHeight: "30px" }}
                className="mr-1 datepicker"
            />)
        }
        return (<Input
            value={this.state.searchTerm}
            onChange={this.changeSearchTerm}
            type="text"
            name="searchTerm"
            id="searchTerm"
        />)
    }

    clearSearch(event) {
        event.preventDefault();
        const oldItems = this.state.oldItems;
        const oldBlocked = this.state.oldFlag;
        const oldPage = this.state.oldPage;
        const noResults = this.state.noResults
        this.setState({
            viewItems: oldItems ? oldItems : [],
            nextBlocked: oldBlocked,
            displayPage: oldPage,
            errorMessage: "",
            //oldItems: [],
            searchResultsFlag: false,
            noResults: oldItems ? oldItems.length == 0 : false,
            searchQueries: [],
            searchType: "null",
        }, () => {
            if (this.state.gridApi) {
                this.state.gridApi.setRowData([]);
                this.state.gridApi.refreshCells();
                this.state.gridApi.setRowData(this.state.viewItems);
                this.state.gridApi.refreshCells();
            }
            this.resetSearchValues();
        });
    }

    async getActiveSearchList() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        Axios.get(API_ROUTE + Routes.GET_ACTIVE_SEARCH_LIST + "/" + this.state.orgId + "/" + sessionStorage.getItem("UserName"), config)
            .then((response) => {
                if (response.data !== "") {
                    const data = JSON.parse(response.data);
                    const parsedData = data.map(item => ({
                        QueriesList: JSON.parse(item.QueriesList),
                        SearchCount: parseInt(item.SearchCount), 
                        SetID: item.SetID, 
                        Title: item.Title,
                        SubTitle: item.SubTitle,
                        BackUpTime: item.BackUpTime,
                        ValidTime: new Date(item.ValidTime), 
                        SearchQuery: JSON.parse(item.SearchQuery),
                        Url: item.Url,
                        Type: item.Type,
                        Status: item.Status 
                    }));
                    this.setState({

                        activeSearchList: {
                            QueriesList: parsedData.map(item => item.QueriesList),
                            SearchCount: parsedData.map(item => item.SearchCount),
                            SetID: parsedData.map(item => item.SetID),
                            Title: parsedData.map(item => item.Title),
                            SubTitle: parsedData.map(item => item.SubTitle),
                            BackUpTime: parsedData.map(item => item.BackUpTime),
                            ValidTime: parsedData.map(item => item.ValidTime),
                            SearchQuery: parsedData.map(item => item.SearchQuery),
                            Url: parsedData.map(item => item.Url),
                            Type: parsedData.map(item => item.Type),
                            Status: parsedData.map(item => item.Status) 
                        },
                        QueryHistory: data,
                        searchHistoryQueries: data.map(item => JSON.parse(item.QueriesList)),
                        searchHistoryResultCount: data.map(item => JSON.parse(item.SearchCount))
                    });
                }


            })
            .catch((reason) => {
                this.overlayProgress(false);
                console.log(reason);

                this.setState({
                    inProgressRight: false,
                });
            });

    }
    
    createSelectItem(type, url, titles)
    {
        let json;
        let restoreSession = this.state.restore.id;
        let userdetail = { title: "", email: "" };
        let title = userdetail.title;
        let email = userdetail.email;
        try {
            userdetail = JSON.parse(titles);
            title = userdetail.title || "";
            email = userdetail.email || "";
        }
        catch (ex) {
            title = title;
            email = title;

        }
        if (type.toLowerCase() === "mailboxes") {
            const mailboxIdMatch = url.match(/mailboxes\/([a-f0-9-]+)/);
            const mailboxId = mailboxIdMatch ? mailboxIdMatch[1] : null;

            if (mailboxId) {
                json = {
                    id: mailboxId,
                    title: title,
                    getFolders: `/v7/restoresessions/${restoreSession}/organization/mailboxes/${mailboxId}/folders?parentId=root`,
                    email: email,
                    actions: [
                        {
                            name: "ExportToPST",
                            href: `/v7/restoresessions/${restoreSession}/organization/mailboxes/${mailboxId}/exportToPst`,
                            type: "POST"
                        },
                        {
                            name: "RestoreTo",
                            href: `/v7/restoresessions/${restoreSession}/organization/mailboxes/${mailboxId}/restoreTo`,
                            type: "POST"
                        },
                        {
                            name: "RestoreToOriginalLocation",
                            href: `/v7/restoresessions/${restoreSession}/organization/mailboxes/${mailboxId}/restore`,
                            type: "POST"
                        },
                        {
                            name: "Search",
                            href: `/v7/restoresessions/${restoreSession}/organization/mailboxes/${mailboxId}/search`,
                            type: "POST"
                        }
                    ]
                };

            }
            else {
                json = null;
            }
        }
        else if (type.toLowerCase() === "sites") {
            const siteIdMatch = url.match(/sites\/([a-f0-9-]+)/);
            const siteId = siteIdMatch ? siteIdMatch[1] : null;

            if (siteId) {
                json = {
                    sites: `/v7/restoresessions/${restoreSession}/organization/sites?parentId=${siteId}`,
                    libraries: `/v7/restoresessions/${restoreSession}/organization/sites/${siteId}/libraries`,
                    lists: `/v7/restoresessions/${restoreSession}/organization/sites/${siteId}/lists`,
                    id: siteId,
                    title: title,
                    getFolders: `/v7/restoresessions/${restoreSession}/organization/sites/${siteId}/folders`,
                    email: email.toUpperCase(),
                    actions: []
                };

            } else {
                json = null;
            }
        }
        else if (type.toLowerCase() === "onedrives")
        {
            const oneDriveIdMatch = url.match(/onedrives\/([a-f0-9-]+)/);
            const oneDriveId = oneDriveIdMatch ? oneDriveIdMatch[1] : null;

            // Create the JSON structure
            if (oneDriveId) {
                json = {
                    getItems: `/v7/restoresessions/${restoreSession}/organization/onedrives/${oneDriveId}/documents?parentId=Root`,
                    id: oneDriveId,
                    title: title, 
                    getFolders: `/v7/restoresessions/${restoreSession}/organization/onedrives/${oneDriveId}/folders?parentId=Root`,
                    email: email.toUpperCase(), 
                    actions: null
                };

            } else {
                json = null;
            }

        }
        else if (type.toLowerCase() === "teams")
        {
            json = null;
        }

        this.setState({
            selectItem: json
        })
    }

    async showSearch(event, q) {
        if (event) {
            event.preventDefault();
        }
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let restoreType;
        switch (this.state.activeSearchList.Type[q].toLowerCase()) {
            case 'mailboxes':
                restoreType = 'vex';
                break;
            case 'sites':
                restoreType = 'vesp';
                break;
            case 'onedrives':
                restoreType = 'veod'; 
                break;
            case 'teams':
                restoreType = 'vet';
                break;
            default:
                restoreType = ''; 
        }   
        this.setState({
            SearchQueued: false,
            searchHistoryPanelOpen: false,
            searchPanelOpen: false,
            restoreType: restoreType,
            inProgressRight: true,
            selectItem: null,
            viewItems: [],
        });
        
        let href = this.state.activeSearchList.Url[q];
        let page = 0;
        let data = {
            Href: href,
            Body: JSON.stringify(this.state.activeSearchList.SearchQuery[q]),
            Page: page,
            Type: restoreType,
            Query: JSON.stringify(this.state.activeSearchList.QueriesList[q]),
            RestorePointID: this.state.restorePointID ? this.state.restorePointID : " ",
            OrgId: this.state.orgId,
            Title: this.state.activeSearchList.Title[q] ? this.state.activeSearchList.Title[q] : "",
            SubTitle: this.state.activeSearchList.SubTitle[q] ? this.state.activeSearchList.SubTitle[q] : "",
            RPointDateTime: this.state.activeSearchList.BackUpTime[q] ? this.state.activeSearchList.BackUpTime[q] : "",
            UserMailID: sessionStorage.getItem("UserName"),
        };
        Axios.post(API_ROUTE + Routes.SEARCH, data, config)
            .then((response) => {
                this.overlayProgress(false);
                let results = response.data.results;
                let restoreSession = response.data.restoreSession;
                if (results.length > 0) {
                    this.renderAgGrid(results);
                }
                let nextBlocked = false;
                if (results.length < 30) {
                    nextBlocked = true;
                }
                this.setState(
                    {
                        inProgressRight: false,
                        searchResultsFlag: true,
                        displayPage: page,
                        viewItems: response.data.results,
                        noResults: !(response.data.results.length > 0),
                        nextBlocked: nextBlocked,
                        restoreType: restoreType,
                        restore: restoreSession,
                        serverPath: restoreSession.serverPath,
                        isMFA: restoreSession.isMFA,
                        searchFilters: restoreSession.fields,
                        sessionid: restoreSession.links.self.href,

                    },
                    () => {
                        if (this.state.activeSearchList.Title[q] !== "" && (this.state.selectItem === null || this.state.restoreType !== restoreType || this.state.restoreDateTime !== this.state.activeSearchList.BackUpTime[q])) {
                            this.createSelectItem(this.state.activeSearchList.Type[q].toLowerCase(), this.state.activeSearchList.Url[q], this.state.activeSearchList.Title[q]);
                        }
                    }
                );
            })
            .catch((reason) => {
                this.overlayProgress(false);
                console.log(reason);

                this.setState({
                    inProgressRight: false,
                });
            });
    }

    async search(event, direction, date = "") {
        if (event) {
            event.preventDefault();
        }
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        this.overlayProgress(true);
        this.setState(
            {
                inProgressRight: true,
                SearchQueued: false,
                oldFlag: this.state.nextBlocked,
                oldPage: this.state.displayPage,
                oldItems: !this.state.searchResultsFlag ? this.state.viewItems : this.state.oldItems,
                errorMessage: "",
            },
            () => {
                if (this.state.restoreType === "aad") {
                    let fileName = "";
                    var restorePoint = null;
                    for (var i = 0; i < this.state.mark.length; i++) {
                        restorePoint = this.state.mark[i];

                        if (restorePoint.restoreDate.includes(date)) {
                            this.setState({
                                entraRestorePoint: restorePoint
                            });
                            fileName = restorePoint.fileName;
                            break;
                        }
                    }
                    let g = this.getAADBackupConfigData(fileName, this.state.orgName);
                    return;
                }
                let searchBody = "";
                for (var i = 0; i < this.state.searchQueries.length; i++) {
                    const line = this.state.searchQueries[i]; 
                    let fields = line.Field.split(',');
                    let field = fields[0].trim();
                    searchBody += field;

                    if (line.Inclusion === "contains") {
                        searchBody += ":";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "isNot") {
                        searchBody += ": -";
                        searchBody += line.Term;
                    }else if (line.Inclusion === "isExactly") {
                        searchBody += ":";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "lessThan") {
                        searchBody += ":<";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "greaterThan") {
                        searchBody += ":>";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "startsWith") {
                        searchBody += ":";
                        searchBody += `${line.Term}*`;
                    } else if (line.Inclusion === "endsWith") {
                        searchBody += ":";
                        searchBody += `*${line.Term}`; 
                    } else if (line.Inclusion === "doesNotContain") {
                        searchBody += ": -";
                        searchBody += line.Term;
                    }
                    // else if (line.Inclusion === "wordEquals") {
                    //    searchBody += ":";
                    //    searchBody += line.Term;
                    //}
                    //else if (line.Inclusion === "wordStartsWith") {
                    //    searchBody += ":^=";
                    //    searchBody += line.Term;
                    //}
                    else if (line.Inclusion === "lessThanOrEqual") {
                        searchBody += ":<=";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "greaterThanOrEqual") {
                        searchBody += ":>=";
                        searchBody += line.Term;
                    } else if (line.Inclusion === "between") {
                        const start = this.state.searchTermRangeStart;
                        const end = this.state.searchTermRangeEnd;
                        let fieldName = line.Field ; 
                        searchBody = `${fieldName}:>${start} AND ${fieldName}:<${end}`;
                    } else if (line.Inclusion === "last7Days") {
                        const currentDate = new Date();
                        const last7DaysDate = new Date();
                        last7DaysDate.setDate(currentDate.getDate() - 7);

                        const currentDateString = currentDate.toISOString().split('T')[0];
                        const last7DaysDateString = last7DaysDate.toISOString().split('T')[0];
                        searchBody = `${line.Field}:>=${last7DaysDateString} AND ${line.Field}:<${currentDateString}`;
                    }
                    else if (["today", "yesterday", "next7Days", "tomorrow",
                            "last Week", "next Week", "this Week", "lastMonth",
                            "nextMonth", "thisMonth", "lastYear", "nextYear",
                            "thisYear"].includes(line.Inclusion)) {
                        searchBody += ":" + line.Inclusion;
                    }

                    if (i < this.state.searchQueries.length - 1) {
                        searchBody += " ";
                    }
                }
                let body = '{"query":"' + searchBody + '"}';
                //let restoreSearch = false;
                let href = "";
                if (this.state.restoreType === "vet") {
                    if (this.state.searchType === "Tabs") {
                        let channelId = this.state.selectedFolder.id;
                        channelId = channelId.substring(0, channelId.length - 4)
                        href = this.state.selectItem.getFolders + "/" + channelId + "/tabs";
                    }
                    else {
                        href = this.state.selectItem.getFolders.replace("channels", this.state.searchType);
                    }

                }
                else if (this.state.selectItem) {
                    if (this.state.selectItem.sites)
                    {
                        href = this.state.selectItem.sites.split("?parentId=")[0] + "/" + this.state.selectItem.id ;
                        if (this.state.selectedFolder) {
                            if (!["libraries", "subsites", "lists"].includes(this.state.selectedFolder.title.toLowerCase()))
                                href = this.state.selectedFolder.self;
                        }
                     }

                    else {
                        href = this.state.selectItem.getFolders;
                        href = href.includes("?parentId=Root") ? href.replace("/folders?parentId=Root", "") : href.replace("/folders?parentId=root", "");
                        if (this.state.selectedFolder)
                            href = href + "/folders/" + this.state.selectedFolder.id;
                    }
                }
                else
                {
                    //restoreSearch = true;
                    href = this.state.restore.links.organization.href
                    if (this.state.restoreType === "vex") {
                        href = href + "/mailboxes";
                    } else if (this.state.restoreType === "vesp") {
                        href = href + "/sites";
                    } else if (this.state.restoreType === "veod") {
                        href = href + "/onedrives";
                    }
                }
                href = href + "/search"
                
                let page = this.state.displayPage;
                if (direction == "previous") {
                    page = page - 1;
                } else if (direction == "next") {
                    page = page + 1;
                }
                //href = href.replace("https://127.0.0.1:4443/v6", this.state.serverPath);

                let title = JSON.stringify({
                        title: this.state.selectItem?this.state.selectItem.title:"",
                        email: this.state.selectItem?this.state.selectItem.email:""
                    });


                let subTitle = JSON.stringify({
                    title: "",
                    email: ""
                });
                if (this.state.selectedFolder) {
                    let check = !["libraries", "subsites", "lists"].includes(this.state.selectedFolder.title.toLowerCase());
                        subTitle = JSON.stringify({
                            title: check ? this.state.selectedFolder.title : "",
                            email: check ? this.state.selectedFolder.url :""
                        });
                }
                    
      
                let data = {
                    Href: href,
                    Body: body.toString(),
                    Page: page,
                    Type: this.state.restoreType,   
                    Query: JSON.stringify(this.state.searchQueries),
                    RestorePointID: this.state.restorePointID,
                    OrgId: this.state.orgId,
                    Title: title,
                    SubTitle: subTitle,
                    RPointDateTime: this.state.restoreDateTime ? this.state.restoreDateTime : "",
                    UserMailID: sessionStorage.getItem("UserName"),
                };
                this.delayedResponseTimer = setTimeout(() => {
                    this.setState({
                        SearchQueued: true,
                        inProgressRight: false,
                        searchPanelOpen: false,
                    }, () => {
                        this.getActiveSearchList();
                    })
                }, 10000); 

                Axios.post(API_ROUTE + Routes.SEARCH, data, config)
                    .then((response) => {
                        clearTimeout(this.delayedResponseTimer); // Clear the timer if the API responds before 10 seconds
                        this.overlayProgress(false);
                        if (response.data.results) {
                            toast.success('Search completed!', { autoClose: 10000 });
                        }
                        let results = response.data.results;
                        if (results.length > 0) {
                            this.renderAgGrid(results);
                        }
                        let nextBlocked = false;
                        if (results.length < 30) {
                            nextBlocked = true;
                        }
                        this.setState({
                            inProgressRight: false,
                            searchResultsFlag: true,
                            viewItems: this.state.SearchQueued ? [] : response.data.results,
                            displayPage: page,
                            noResults: !(response.data.results.length > 0),
                            resultToast: true,
                            nextBlocked: nextBlocked,
                            searchPanelOpen: false,
                        }, () => {
                            this.getActiveSearchList();
                        });
                    })
                    .catch((reason) => {
                        clearTimeout(this.delayedResponseTimer); // Clear the timer if there's an error
                        this.overlayProgress(false);
                        console.log(reason);

                        this.setState({
                            inProgressRight: false,
                        });
                    });
            }
        );
    }

    resetState(restoreType) {
        this.overlayProgress(false);
        this.setState(
            {
                restoreType: restoreType,
                SearchQueued: false,
                inProgressLeft: false,
                inProgressRight: false,
                inProgressRestore: false,
                gotSelectItems: false,
                restore: null,
                siteURL: "",
                destinationOneDriveId: "",
                type:"",
                destinationOneDriveUserName: "",
                destinationOneDriveUrl: "",
                destinationFolder:"",
                openSharepointModal: false,
                showoneDriveModal: false,
                selectItems: [],
                treeItems: [],
                viewItems: [],
                oldItems: null,
                oldPage: 0,
                gettingDeviceCode: false,
                oldFlag: false,
                noResults: false,
                selectItem: null,
                selectedFolder: null,
                displayPage: 0,
                loadedCurrentConfig: false,
                nextBlocked: true,
                selectedItems: [],
                clearLeftSection: false,
                isMFA: false,
                itemClicked: "",
                copied: false,
                verificationModalOpen: false,
                restoreAction: "",
                deviceCode: "",
                password: "",
                mailModalOpen: false,
                mailSendTo: "",
                mailFrom: "",
                mailSubject: "",
                mailMessage: "Here are the files you requested.",
                command: "",
                rootRestoreOpen: false,
                folderRestoreOpen: false,
                itemRestoreOpen: false,
                fileName: "",
                queryString: "",
                restoreJob: null,
                lastClicked: "",
                downloadModalOpen: false,
                entraModalOpen: false,
                downloading: false,
                restoreModalOpen: false,
                //restorePoints: [],
                restoring: false,
                restoreSuccess: null,
                errorMessage: "",
                searchTerm: "",
                searchResultsFlag: false,
                searchQueries: [],
                searchType: "null",
                sarchField: "null",
                searchQueryType: "",
                value: 0,
                conditionOptions: [],
                searchFilters: [],
                searchPanelOpen: true,
                expandedFolders: ["root"],
                serverPath: "",
                extraLargeDownload: false,
                restorePath: "",
                listName: ""
            },
            () => {
                if (restoreType !== "")
                    this.getRestore();
            }
        );
    }

    //getCalanderRestorePoints() {
    //    Axios.post(API_ROUTE + Routes.GET_RESTOREPOINTS_DATES + '/M365x98575502.onmicrosoft.com')
    //        .then((response) => {
    //            this.overlayProgress(false);
    //            let results = response.data.results;
    //        })
    //        .catch((reason) => {
    //            this.overlayProgress(false);
    //            console.log(reason);
    //            this.setState({
    //                inProgressRight: false,
    //            });
    //        });
    //}

    async getAADBackupConfigData(selectedDate, orgName, restorePoint) {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        var fileName = selectedDate.split('.')[0];
        Axios.get(API_ROUTE + Routes.GET_ENTRA_CHANGES + '/' + fileName + '/' + orgName, config)
            .then((response) => {
                this.overlayProgress(false);
                let results = JSON.parse(response.data);
                var responseData = [];
                var oldData = [];
                var stateData = [];
                var uniqueResources = results.resources.filter(function (resource, index, array) {
                    return array.findIndex(function (otherResource) {
                        return otherResource.resourceInstanceName === resource.resourceInstanceName;
                    }) === index;
                });

                for (var i = 0; i < results.resources.length; i++) {
                    var res = results.resources[i];
                    stateData.push(results);
                    var responseItems = {
                        ConfigItem: res.resourceInstanceName,
                        Property: res.properties[0].parameterName,
                        Previous: res.properties[0].valueInSource,
                        Current: res.properties[0].valueInDestination
                    };
                    var existingIndex = oldData.findIndex(item => item.resourceInstanceName === res.resourceInstanceName);

                    if (existingIndex !== -1) {
                        responseData[existingIndex].Property += '<>' + responseItems.Property;
                        responseData[existingIndex].Previous += '<>' + responseItems.Previous;
                        responseData[existingIndex].Current += '<>' + responseItems.Current;
                    } else {
                        oldData.push(res);
                        responseData.push(responseItems);
                    }
                }

                // Function to parse a value that could be an array or a string
                function parseValue(value) {
                    try {
                        return JSON.parse(value);
                    } catch (e) {
                        return value;
                    }
                }

                // Function to compare arrays and find added and removed items
                function compareArrays(prevArray, currArray) {
                    let prevSet = new Set(prevArray);
                    let currSet = new Set(currArray);

                    let added = [...currSet].filter(x => !prevSet.has(x));
                    let removed = [...prevSet].filter(x => !currSet.has(x));

                    return { added, removed };
                }

                // Process the responseData to find added and removed items
                responseData.forEach(item => {
                    // Parse Previous and Current values
                    if (item.Previous.split('<>').length === 1 || item.Current.split('<>').length === 1) {
                        let prevValue = parseValue(item.Previous);
                        let currValue = parseValue(item.Current);

                        let prevArray, currArray;

                        if (Array.isArray(prevValue)) {
                            prevArray = prevValue;
                        } else {
                            prevArray = [prevValue];
                        }

                        if (Array.isArray(currValue)) {
                            currArray = currValue;
                        } else {
                            currArray = [currValue];
                        }

                        let { added, removed } = compareArrays(prevArray, currArray);

                        item.Added = added;
                        item.Removed = removed;
                        item.Change = <div>{added}{removed}</div>
                    }
                    else {
                        let prevlist = item.Previous.split('<>');
                        let currlist = item.Current.split('<>');
                        let addedlist = []
                        let removedlist=[]
                        prevlist.forEach((item, index) => {
                            let prevValue = parseValue(item);
                            let currValue = parseValue(currlist[index]);

                            let prevArray, currArray;

                            if (Array.isArray(prevValue)) {
                                prevArray = prevValue;
                            } else {
                                prevArray = [prevValue];
                            }

                            if (Array.isArray(currValue)) {
                                currArray = currValue;
                            } else {
                                currArray = [currValue];
                            }

                            let { added, removed } = compareArrays(prevArray, currArray);
                            addedlist.push(added)
                            removedlist.push(removed)
                        })
                        

                        item.Added = addedlist;
                        item.Removed = removedlist;
                        item.Change = <div>{addedlist}{removedlist}</div>
                    }
                });

                if (this.state.gridApi) {
                    this.state.gridApi.setRowData([]);
                    this.state.gridApi.refreshCells();
                    this.state.gridApi.setRowData(responseData);
                    this.state.gridApi.refreshCells();
                }
                results.resources = uniqueResources;
                this.setState({
                    inProgressRight: false,
                    searchResultsFlag: false,
                    viewItems: responseData,
                    noResults: !(responseData.length > 0),
                    searchPanelOpen: false,
                    aadData: results,
                    rp: restorePoint
                })
            })
            .catch((reason) => {
                this.overlayProgress(false);
                this.setState({
                    inProgressRight: false,
                });
            });
    }

    getOrgRestore(event, type) {
        document.getElementById('RestoreLandingPage').style.display = "none";
        event.preventDefault();
        this.resetState(type);
    }


    async getRestoreDates() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        this.setState(
            {
                errorMessage: "",
            },
            () => {
                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                        CallDomain: window.location.origin,
                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                    },
                };
                let data = {
                    OrgId: this.state.orgId,
                    UserName: this.state.username
                };
                Axios.post(API_ROUTE + Routes.GET_RESTORE_DATES, data, config)
                    .then((response) => {
                        var dates = response.data;
                        let startDate = new Date(moment(dates[0]).toString());
                        let endDate = new Date(moment(dates[1].toString()));
                        //toast("Wow so easy!");
                        this.setState({
                            gotRestoreDates: true,
                            firstRestoreDate: startDate,
                            lastRestoreDate: endDate,
                            noRestoreDates: false,
                        });
                    })
                    .catch((reason) => {
                        console.log("catch");

                        //let id= toast("There are currently no restore points available for your organisation. Please wait for your first backup job to complete or contact support if you believe you have a problem.");

                        this.setState({
                            inProgressLeft: false,
                            gotRestoreDates: true,
                            noRestoreDates: false,
                        });
                    });
            }
        );
    }

    async getOrgName() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let data = {
            OrgId: this.state.orgId,
        };
        Axios.post(API_ROUTE + Routes.GET_ORG_NAME, data, config)
            .then((response) => {
                let orgName = response.data;
                this.setState(
                    {
                        orgName: orgName,
                    },
                    () => {
                        this.getRestoreDates();                       
                    }
                );
            })
            .catch((reason) => {
                this.setState({
                    inProgressLeft: false,
                });
            });
    }

    async getIsEndUser() {
        //if (localStorage.getItem('GuardianOnlyEnabled-' + this.state.orgId) === undefined)
        //    this.CheckAzureGuardianOnly(new URLSearchParams(window.location.search).get("orgId"));
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };

        Axios.get(API_ROUTE + Routes.IS_END_USER, config)
            .then((response) => {
                if (response.data)
                    this.setState({ isEndUser: true });
                //let orgName = response.data;
                //this.setState(
                //    {
                //        orgName: orgName,
                //    },
                //    () => {
                //        this.getRestoreDates();
                //    }
                //);
            })
            .catch((reason) => {
                this.setState({ isEndUser: false });
            });
    }
    async getOrgUsers() {

        try {

            const authContext = this.props.authContext || this.context;
            const { setAuthContext } = authContext;
            var tokenString = await setAuthContext(new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            //  url: "/v7/Organizations/" + this.state.orgId + "/Users",
            const inputData = {
                OrgId: this.state.orgId,
                ServerPath: sessionStorage.getItem("ServerPath") ? sessionStorage.getItem("ServerPath") : "",

                TenantName: sessionStorage.getItem("TenantName") ? sessionStorage.getItem("TenantName") : "",
                EmailAddress: sessionStorage.getItem("UserName") ? sessionStorage.getItem("UserName") : "",
            };
            await Axios.post(API_ROUTE + Routes.GET_ORG_USERS, inputData, config)
                .then((response) => {

                    if (response && response.data) {



                        this.setState({
                            usersList: response.data,
                        });

                    }

                })
                .catch((reason) => {


                });

        }
        catch (error) { }

    }
    async getRestoreEmailType() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),

            },
        };
        let data = {
            OrgId: this.state.orgId,
            Setting: "RestoreMailTo",
        };

        Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
            .then((response) => {
                if (response.data.result)
                    this.setState({ restoreMailTo: response.data.result });

            })
            .catch((reason) => {
                this.setState({ restoreMailTo: "0" });
            });
    }
    async getRestorePoints(ActualDate) {
        
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        const year = ActualDate.getFullYear();
        const month = ActualDate.getMonth();
        const firstDate = new Date(year, month, 1);
        const lastDate = new Date(year, month + 1, 0);
        let data = {
            OrgId: this.state.orgId,
            UserName: this.state.username,
            From: firstDate,
            To: lastDate
        };
        const filteredResults = this.state.restorePoints.filter(item => {
            const timeDate = new Date(item.backupTime);
            return timeDate.getUTCFullYear() === year && timeDate.getUTCMonth() === month;
        });
        this.setState(
            {
                renderRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}><img src={LoaderGif} alt="loading" style={{ height: '50px' }} /></div>,
                restore365ActiveStartDate: new Date(year, month)
            }
        );
        if (filteredResults.length === 0) {
            Axios.post(API_ROUTE + Routes.GET_RESTORE_POINTS, data, config)
                .then((response) => {
                    let result = response.data;
                    let existingrestorePoints = this.state.restorePoints
                    result.forEach(item2 => {
                        const exists = existingrestorePoints.some(item1 => item1.id === item2.id);
                        if (!exists) {
                            existingrestorePoints.push(item2);
                        }
                    });
                    result = existingrestorePoints;
                    if (result.length === 0) {
                        this.setState(
                            {
                                restorePoints: result,
                                selectedrestorePoints: result,
                                renderRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}><span>No Restore Points available</span></div>
                            }
                        );
                    }
                    else {
                        this.findMaxBackupDate(result);
                        this.setState(
                            {
                                restorePoints: result,
                                selectedrestorePoints: result,
                                renderRestoreestoreCalendar: <React.Fragment> <Calendar style={{ height: 500 }}
                                    onChange={(date) => this.selectRestoreOnChange(date, result)}
                                    value={this.state.date}
                                    maxDate={new Date()}
                                    tileClassName={({ date, view }) => {
                                        for (var i = 0; i < result.length; i++) {
                                            var x = result[i].backupTimeStr.split(' ')[0];
                                            if (x === moment(date).format("DD-MM-YYYY")) {

                                                return 'highlight';
                                            }
                                        }
                                    }}
                                    activeStartDate={this.state.restore365ActiveStartDate}
                                    onActiveStartDateChange={this.onActiveStartDateChange}
                                >
                                </Calendar>
                                    <div className="m-2" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button onClick={this.getRestorePointsOnMonth} className="btn btn-primary w-100" style={{ fontFamily: 'Roboto, sans-serif', fontSize: '19px' }}>Show all restore points</button>
                                    </div></React.Fragment>
                            }
                        );
                    }

                })
                .catch((reason) => {
                    this.setState({
                        inProgressLeft: false,
                        renderRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}><span>Error getting Restore points</span></div>
                    });
                });
        }
        else {
            this.setState(
                {
                    restorePoints: this.state.restorePoints,
                    selectedrestorePoints: this.state.restorePoints,
                    renderRestoreestoreCalendar: <React.Fragment> <Calendar style={{ height: 500 }}
                        onChange={(date) => this.selectRestoreOnChange(date, this.state.restorePoints)}
                        value={this.state.date}
                        maxDate={new Date()}
                        tileClassName={({ date, view }) => {
                            for (var i = 0; i < this.state.restorePoints.length; i++) {
                                var x = this.state.restorePoints[i].backupTimeStr.split(' ')[0];
                                if (x === moment(date).format("DD-MM-YYYY")) {

                                    return 'highlight';
                                }
                            }
                        }}
                        activeStartDate={this.state.restore365ActiveStartDate}
                        onActiveStartDateChange={this.onActiveStartDateChange}
                    >
                    </Calendar>
                        <div className="m-2" style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={this.getRestorePointsOnMonth} className="btn btn-primary w-100" style={{ fontFamily: 'Roboto, sans-serif', fontSize: '19px' }}>Show all restore points</button>
                        </div></React.Fragment>
                }
            );
        }
    }

    findMaxBackupDate = (mark) => {
        //const { mark } = this.state;
        const maxBackupDate = mark.reduce((max, item) => {
            const currentDate = new Date(item.backupTime);
            return currentDate > max ? currentDate : max;
        }, new Date(mark[0].backupTime));
        //console.log('Max backup date:', moment(maxBackupDate).format('MMMM YYYY'));
        this.setState({
            restoreVisibleMonthandYear: moment(maxBackupDate).format("MMMM YYYY"),
        });
    };

    getFormattedDateTime() {
        let restoreDate = moment(this.state.restoreDate);
        let year = restoreDate.format("YYYY");
        let month = restoreDate.format("MM");
        let day = restoreDate.format("DD");
        return `${year}-${month}-${day} ${this.state.restoreTime}`;
    }

    async getRestore() {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        if (!this.state.inProgressLeft) {
            this.setState(
                {
                    inProgressLeft: true,
                    restore: null,
                    siteURL: "",
                    destinationOneDriveId: "",
                    type: "",
                    destinationOneDriveUserName: "",
                    destinationOneDriveUrl: "",
                    destinationFolder: "",
                    openSharepointModal: false,
                    showoneDriveModal: false,
                    treeItems: [],
                    viewItems: [],
                    oldItems: null,
                    noResults: false,
                },
                () => {
                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CallDomain: window.location.origin,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                    };
                    let fullRestoreTime = this.getFormattedDateTime();
                    let data = {
                        RestoreType: this.state.restoreType,
                        OrgId: this.state.orgId,
                        RestoreDateStr: fullRestoreTime,
                        UserName: this.state.username,
                    };
                    if (this.state.restoreType === 'aad') {
                        Axios.get(API_ROUTE + Routes.GET_ENTRA_RESTORE_POINTS + '/' + this.state.orgName, config)
                            .then((response) => {
                                var jsonData;
                                if (response.data === "") {
                                    jsonData = []
                                }
                                else {
                                    jsonData = JSON.parse(response.data)
                                }
                                if (jsonData.length === 0) {
                                    this.setState(
                                        {
                                            inProgressLeft: false,
                                            mark: jsonData,
                                            renderAzureRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}><span>No Restore Points available</span></div>,
                                            selectItems: []
                                        }
                                    );
                                }
                                else {
                                    this.setState(
                                        {
                                            inProgressLeft: false,
                                            mark: jsonData,
                                            renderAzureRestoreestoreCalendar: <Calendar style={{ height: 500 }}
                                                onChange={this.onChange}
                                                value={this.state.date}
                                                tileClassName={({ date, view }) => {
                                                    // Format the date to match the format used in jsonData
                                                    const formattedDate = moment(date).format("DD-MM-YYYY");

                                                    // Get all entries that match the formatted date
                                                    const entriesForDate = jsonData.filter(item => item.restoreDate.split(' ')[0] === formattedDate);

                                                    // If there are entries for this date
                                                    if (entriesForDate.length > 0) {
                                                        // Check if all diffCount values are "0"
                                                        const allZeroCount = entriesForDate.every(item => item.diffCount === "0");

                                                        // Return 'zeroCount' if all diffCount are "0", otherwise return 'highlight'
                                                        return allZeroCount ? 'zeroCount' : 'highlight';
                                                    }

                                                    // If no matching entry, return undefined (no class)
                                                    return undefined;
                                                }}
                                            >
                                            </Calendar>,
                                            selectItems: JSON.parse(response.data),
                                            
                                        }
                                    );
                                }
                                
                            })
                            .catch((reason) => {
                                this.setState({
                                    inProgressLeft: false,
                                    errorMessage: reason.response.data,
                                    renderAzureRestoreestoreCalendar: <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}><span>Error getting Restore points</span></div>
                                });
                            });
                        return;
                    }
                    Axios.post(API_ROUTE + Routes.GET_RESTORE, data, config)
                        .then((response) => {
                           
                            this.setState(
                                {
                                    inProgressLeft: false,
                                    restore: response.data,
                                    serverPath: response.data.serverPath,
                                    isMFA: response.data.isMFA,
                                    searchFilters: response.data.fields,
                                    sessionid: response.data.links.self.href,
                                },
                                () => {
                                    this.getSelectItems();
                                }
                            );
                        })
                        .catch((reason) => {
                            this.setState({
                                inProgressLeft: false,
                                errorMessage: reason.response.data,
                            });
                        });
                }
            );
        }
    }

    async CheckForEntraBackup(orgName, orgId) {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        var _orgName = orgName ? orgName : this.state.orgName;
        var _orgId = orgId ? orgId : this.state.orgId;
        //Axios.get(API_ROUTE + Routes.CHECK_ENTRA_JOB + "/" + _orgName, config)
        //    .then((response) => {
        //        if (response.data !== "" && response.data !== "Failed") {
        //            this.setState({
        //                isEntraButtonEnabled: true
        //            });
        //        }
        //    })
        //    .catch((reason) => {
        //    });
        let ModelUserName = "NA";
        if (sessionStorage.UserName) {
            ModelUserName = sessionStorage.UserName;
        }
        Axios.get(API_ROUTE + Routes.PRICING_MODEL_ID + "/" + _orgId + "/" + ModelUserName, config)
            .then((response) => {
                if (response.data !== "" && response.data !== "Failed") {
                    let selectItems = JSON.parse(response.data);
                    this.setState({
                        priceModel: selectItems,
                        guardianStatusLoaded: true,
                    });

                }
            })
            .catch((reason) => {
            });
    }

    async getSelectItems() {

        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        if (!this.state.inProgressLeft) {
            this.setState(
                {
                    inProgressLeft: true,
                    gotSelectItems: false,
                    showDropdown: false,
                    items: null,
                },
                () => {
                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CallDomain: window.location.origin,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                    };
                    let href = this.state.serverPath;
                    let data = {
                        RestoreId: this.state.restore.id,
                        Href: href,
                        RestoreType: this.state.restoreType,
                        clearCache: this.state.clearCacheChecked,
                        orgId: this.state.orgId,
                    };
                    Axios.post(API_ROUTE + Routes.GET_RESTORE_ITEMS, data, config)
                        .then((response) => {
                            this.setState({
                                inProgressLeft: false,
                                selectItems: response.data,
                                gotSelectItems: true,
                                showDropdown: true,
                                clearLeftSection: false,
                            });
                            try {
                                document.getElementById('searchTermDate').style.display = 'none';
                                document.getElementById('lblsearchTermDate').style.display = 'none';
                            } catch (e) {

                            }
                        })
                        .catch((reason) => {
                            this.setState({
                                inProgressLeft: false,
                            });
                        });
                }
            );
        }
    }

    getSiteTreeItems(folder) {
        let treeItems = [];
        let subsitesTreeItem = {
            id: folder.id + "subsites",
            title: "Subsites",
            getChildren: folder.sites,
            isSharepointTopLevel: true,
            getItems: null,
        };
        let librariesTreeItem = {
            id: folder.id + "libraries",
            title: "Libraries",
            getChildren: folder.libraries,
            isSharepointTopLevel: true,
            getItems: null,
        };
        let listsTreeItem = {
            id: folder.id + "lists",
            title: "Lists",
            getChildren: folder.lists,
            isSharepointTopLevel: true,
            getItems: null,
        };
        treeItems.push(subsitesTreeItem);
        treeItems.push(librariesTreeItem);
        treeItems.push(listsTreeItem);
        return treeItems;
    }

    getTeamsTreeItems(folder) {
        let treeItems = [];
        if (folder.posts) {
            let postsTreeItem = {
                id: folder.id + "posts",
                title: "Posts",
                getItems: folder.posts,
                getChildren: null,
            };
            treeItems.push(postsTreeItem);
        }
        if (folder.files) {
            let filesTreeItem = {
                id: folder.id + "files",
                title: "Files",
                isChannelTopLevel: true,
                getItems: folder.files,
                getChildren: folder.files,
            };
            treeItems.push(filesTreeItem);
        }
        if (folder.tabs) {
            let tabsTreeItem = {
                id: folder.id + "tabs",
                title: "Tabs",
                getItems: folder.tabs,
                getChildren: null,
            };
            treeItems.push(tabsTreeItem);
        }
        return treeItems;
    }

    getOdRoot(folder) {
        let treeItems = [];
        let rootTreeItem = {
            id: folder.id + "root",
            title: "Root",
            getChildren: folder.getFolders,
            getItems: folder.getItems,
            isOnedriveTopLevel: true,
        };
        treeItems.push(rootTreeItem);
        return treeItems;
    }

    async getTreeItems() {
        this.setState(
            {

                loadedCurrentConfig: false,

            });
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        if (this.state.restoreType == "vesp") {
            let treeItems = this.getSiteTreeItems(this.state.selectItem);
           

            this.setState({
                inProgressLeft: false,
                treeItems: treeItems,
            });
        } else if (this.state.restoreType == "veod") {
            let treeItems = this.getOdRoot(this.state.selectItem);
          

            this.setState({
                inProgressLeft: false,
                treeItems: treeItems,
            });
        } else {
            const item = this.state.selectItem;
            if (!this.state.inProgressLeft) {
                this.setState(
                    {
                        inProgressLeft: true,
                        treeItems: [],
                    },
                    () => {
                        const config = {
                            headers: {
                                Authorisation: `Bearer ` + tokenString,
                                CallDomain: window.location.origin,
                                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                            },
                        };
                        let href = this.state.selectItem.getFolders.replace("https://127.0.0.1:4443/", this.state.serverPath)
                        let data = {
                            Href: href,
                            Operation: "GET",
                            RestoreType: this.state.restoreType,
                            Parent: this.state.selectItem.id,
                        };
                        Axios.post(API_ROUTE + Routes.GET_RESTORE_FOLDERS, data, config)
                            .then((response) => {
                                var results = response.data;
                                this.setState({
                                    inProgressLeft: false,
                                    treeItems: results,
                                });
                            })
                            .catch((reason) => {
                                this.setState({
                                    inProgressLeft: false,
                                });
                            });
                    }
                );
            }
        }
    }

    async getChildren(link) {

        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        if (!this.state.inProgressLeft) {
            this.setState(
                {
                    inProgressLeft: true,
                },
                () => {
                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            CallDomain: window.location.origin,
                            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                        },
                    };
                    link = link.replace("https://127.0.0.1:4443/", this.state.serverPath);
                    let data = {
                        Href: link,
                        Operation: "GET",
                        RestoreType: this.state.restoreType,
                    };
                    Axios.post(API_ROUTE + Routes.EXPAND_RESTORE_FOLDER, data, config)
                        .then((response) => {
                            this.setState(
                                {
                                    inProgressLeft: false,
                                },
                                () => {
                                    return response.data;
                                }
                            );
                        })
                        .catch((reason) => {
                            this.setState(
                                {
                                    inProgressLeft: false,
                                },
                                () => {
                                    return null;
                                }
                            );
                        });
                }
            );
        }
    }

    async getView(link, direction) {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        let page = this.state.displayPage;
        if (direction == "previous") {
            page = page - 1;
        } else if (direction == "next") {
            page = page + 1;
        }
        link = link.replace("https://127.0.0.1:4443/", this.state.serverPath);
        let data = {
            Href: link,
            Operation: "GET",
            RestoreType: this.state.restoreType,
            Page: page,
        };
        let route = Routes.GET_RESTORE_FOLDER_ITEMS;
        Axios.post(API_ROUTE + route, data, config)
            .then((response) => {
                this.overlayProgress(false);
                let results = response.data.results;
                let number = this.state.displayPage;
                if (results.length > 0) {
                    this.renderAgGrid(results);
                }
                if (results.length == 0) {
                    this.setState({
                        viewItems: number,
                        noResults: true,
                        ResultsFlag: false,
                        displayPage: this.state.displayPage,
                        nextBlocked: true,
                        loadedCurrentConfig: true,
                        inProgressRight: false,
                    });
                } else {
                    let nextBlocked = this.state.nextBlocked;
                    if (results.length < 30) {
                        nextBlocked = true;
                    }
                    if (direction == "next") {
                        number = number + 1;
                    } else if (direction == "previous") {
                        number = number - 1;
                    }
                    this.setState({
                        displayPage: number,
                        nextBlocked: nextBlocked,
                        viewItems: results,
                        noResults: !(results.length > 0),
                        searchResultsFlag: false,
                        loadedCurrentConfig: true,
                        inProgressRight: false,
                    });
                }
            })
            .catch((reason) => {
                this.overlayProgress(false);
                this.setState({
                    inProgressRight: false,
                });
            });
    }

    async folderClick(event, folder, coordinates, isUnclickable) {
        if (isUnclickable != true && !this.state.inProgressLeft && !this.state.inProgressRight) {
            event.preventDefault();
            if (folder.url && folder.url !== "") {
                this.setState({
                    listName: folder.title
                })
            }
            const authContext = this.props.authContext || this.context;
            const { setAuthContext } = authContext;
            var tokenString = await setAuthContext(new Date());
            let lastClicked = this.state.lastClicked;
            let viewItems = this.state.viewItems;
            let expandedFolders = this.state.expandedFolders;
            if (!expandedFolders.includes(folder.id)) {
                expandedFolders.push(folder.id);
            } else {
                var index = expandedFolders.indexOf(folder.id);
                expandedFolders.splice(index);
            }
            if (folder.isTeamsTopLevel || folder.isSharepointTopLevel) {
                viewItems = [];
            }
            if (this.state.restoreType == "vesp" && folder.self) {
                let subStr = folder.self.split("/sites/");
                let siteId = subStr[1].split("/");
                let rstPath = subStr[0] + "/sites/" + siteId[0];
                this.setState({ restorePath: rstPath });
            }
            if (this.state.restoreType == "vesp" || this.state.restoreType == "vet") {
                if (

                    (folder.isSharepointTopLevel &&
                        (folder.title == "Lists" ||
                            folder.title == "Libraries" ||
                            folder.title == "Subsites")) ||
                    (folder.isTeamsTopLevel &&
                        folder.title != "Posts" &&
                        folder.title != "Files" &&
                        folder.title != "Tabs")
                ) {
                    if (folder.isTeamsTopLevel) {
                        lastClicked = folder.id;
                    } else {
                        lastClicked = folder.title;
                    }
                }
            }
            if (!this.state.inProgressRight) {
                if (folder.isTeamsTopLevel) {
                    let folders = this.state.treeItems;
                    let teamsTreeItems = this.getTeamsTreeItems(folder);
                    folders = this.addChildren(teamsTreeItems, coordinates);
                    this.setState({
                        treeItems: folders,
                        selectedItems: [],
                        lastClicked: lastClicked,
                        viewItems: viewItems,
                        noResults: !(viewItems.length > 0),
                        searchResultsFlag: false,
                        inProgressLeft: false,
                        expandedFolders: expandedFolders,
                    });
                } else {
                    let folderId = folder.id;
                    let loadedCurrentConfig = this.state.loadedCurrentConfig;
                    if (this.state.selectedFolder) {
                        if (folderId != this.state.selectedFolder.id) {
                            loadedCurrentConfig = false;
                        }
                    }
                    this.setState(
                        {
                            selectedFolder: folder,
                            lastClicked: lastClicked,
                            selectedItems: [],
                            viewItems: viewItems,
                            noResults: !(viewItems.length > 0),
                            searchResultsFlag: false,
                            loadedCurrentConfig: loadedCurrentConfig,
                            expandedFolders: expandedFolders,
                        },
                        () => {
                            if (!this.state.loadedCurrentConfig) {
                                if (folder.getItems) {
                                    this.overlayProgress(true);
                                    this.setState(
                                        {
                                            inProgressRight: true,
                                            lastClicked: lastClicked,
                                            selectedItems: [],
                                            viewItems: viewItems,
                                            noResults: !(viewItems.length > 0),
                                            searchResultsFlag: false,
                                            displayPage: 0,
                                            expandedFolders: expandedFolders,
                                        },
                                        () => {
                                            this.getView(folder.getItems, "initial");
                                        }
                                    );
                                }
                            }
                            if (!folder.children) {
                                if (!this.state.inProgressLeft) {
                                    if (!folder.getChildren || (!folder.isChannelTopLevel && !folder.title == "Files")) {
                                        if (this.state.restoreType == "vesp") {
                                            let folders = this.state.treeItems;
                                            let siteTreeItems = this.getSiteTreeItems(folder);
                                            folders = this.addChildren(siteTreeItems, coordinates);
                                            this.setState({
                                                treeItems: folders,
                                                nextBlocked: false,
                                                inProgressLeft: false,
                                                selectedItems: [],
                                                lastClicked: lastClicked,
                                                viewItems: viewItems,
                                                noResults: !(viewItems.length > 0),
                                                searchResultsFlag: false,
                                                expandedFolders: expandedFolders,
                                            });
                                        } else {
                                            this.overlayProgress(true);
                                            this.setState(
                                                {
                                                    inProgressRight: true,
                                                    nextBlocked: false,
                                                    lastClicked: lastClicked,
                                                    selectedItems: [],
                                                    searchResultsFlag: false,
                                                    viewItems: viewItems,
                                                    noResults: !(viewItems.length > 0),
                                                    displayPage: 0,
                                                    expandedFolders: expandedFolders,
                                                },
                                                () => {
                                                    this.getView(folder.getItems, "initial");
                                                }
                                            );
                                        }
                                    } else {
                                        this.setState(
                                            {
                                                inProgressLeft: true,
                                                nextBlocked: false,
                                                selectedItems: [],
                                                displayPage: 0,
                                                lastClicked: lastClicked,
                                                viewItems: viewItems,
                                                noResults: !(viewItems.length > 0),
                                                searchResultsFlag: false,
                                                expandedFolders: expandedFolders,
                                            },
                                            () => {
                                                const config = {
                                                    headers: {
                                                        Authorisation: `Bearer ` + tokenString,
                                                        CallDomain: window.location.origin,
                                                        CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                                                    },
                                                };
                                                let href = folder.getChildren.replace("https://127.0.0.1:4443/", this.state.serverPath);
                                                let data = {
                                                    Href: href,
                                                    Operation: "GET",
                                                    RestoreType: this.state.restoreType,
                                                };

                                                Axios.post(
                                                    API_ROUTE + Routes.EXPAND_RESTORE_FOLDER,
                                                    data,
                                                    config
                                                )
                                                    .then((response) => {
                                                        let folders = this.state.treeItems;
                                                        folders = this.addChildren(
                                                            response.data,
                                                            coordinates
                                                        );
                                                        this.setState({
                                                            treeItems: folders,
                                                            selectedItems: [],
                                                            inProgressLeft: false,
                                                            lastClicked: lastClicked,
                                                            viewItems: viewItems,
                                                            //noResults: !(viewItems.length > 0),
                                                            searchResultsFlag: false,
                                                            expandedFolders: expandedFolders,
                                                        });

                                                    })
                                                    .catch((reason) => {
                                                        this.setState(
                                                            {
                                                                inProgressLeft: false,
                                                                lastClicked: lastClicked,
                                                                viewItems: viewItems,
                                                                //noResults: viewItems.length > 0,
                                                                searchResultsFlag: false,
                                                                selectedItems: [],
                                                                expandedFolders: expandedFolders,
                                                            },
                                                            () => {
                                                                return null;
                                                            }
                                                        );
                                                    });
                                            }
                                        );
                                    }
                                }
                            }
                        }
                    );
                }
            }
        }
    }

    addChildren(children, coordinates) {
        let folders = this.state.treeItems;
        let returnFolders = this.coordinateRecursion(
            coordinates,
            children,
            folders
        );
        return returnFolders;
    }

    coordinateRecursion(coordinates, content, folders) {
        let newFolders = [];
        if (coordinates.length == 0) {
            return null;
        }
        const coordinate = coordinates[0];
        if (coordinates.length == 1) {
            for (var i = 0; i < folders.length; i++) {
                const folder = folders[i];
                if (i == coordinate) {
                    folder.children = content;
                }
                newFolders.push(folder);
            }
            return newFolders;
        } else if (coordinates.length > 1) {
            for (var i = 0; i < folders.length; i++) {
                const folder = folders[i];
                if (i == coordinate) {
                    let newCoordinates = this.copyArray(coordinates);
                    newCoordinates.shift();
                    folder.children = this.coordinateRecursion(
                        newCoordinates,
                        content,
                        folder.children
                    );
                }
                newFolders.push(folder);
            }
            return newFolders;
        }
    }

    copyArray(array) {
        let newArray = [];
        array.forEach((e) => newArray.push(e));
        return newArray;
    }

    createTreeItem(item, i, coordinates) {
        const folder = item;
        if (!coordinates) {
            coordinates = [];
        }
        coordinates.push(i);
        let children = [];
        if (folder.children) {
            for (var j = 0; j < folder.children.length; j++) {
                const child = folder.children[j];
                let childCoordinates = this.copyArray(coordinates);
                var childItem = this.createTreeItem(child, j, childCoordinates);
                children.push(childItem);
            }
        }
        let icon = <i className="fas fa-folder" />;
        if (this.state.restoreType == "vex") {
            switch (folder.title) {
                case "Inbox":
                    icon = <i className="fas fa-inbox" />;
                    break;
                case "Calendar":
                    icon = <i className="fas fa-calendar-alt" />;
                    break;
                case "Deleted Items":
                    icon = <i className="fas fa-trash-alt" />;
                    break;
                case "Contacts":
                    icon = <i className="fas fa-id-card" />;
                    break;
                case "Notes":
                    icon = <i className="fas fa-sticky-note" />;
                    break;
                case "Tasks":
                    icon = <i className="fas fa-tasks" />;
                    break;
                case "Journal":
                    icon = <i className="fas fa-newspaper" />;
                    break;
                default:
                    break;
            }
        } else if (this.state.restoreType == "vesp") {
            switch (folder.title) {
                case "Subsites":
                    icon = <i className="fas fa-sitemap" />;
                    break;
                case "Libraries":
                    icon = <i className="fas fa-books" />;
                    break;
                case "Lists":
                    icon = <i className="fas fa-list" />;
                    break;
                default:
                    break;
            }
        } else if (this.state.restoreType == "vet") {
            switch (folder.title) {
                case "Posts":
                    icon = <i className="fas fa-map-pin" />;
                    break;
                case "Files":
                    icon = <i className="fas fa-file" />;
                    break;
                default:
                    break;
            }
        }
        let buttonDisabled =
            this.state.inProgressLeft ||
            this.state.inProgressRight ||
            this.state.inProgressRestore;
        let treeItem = (
            <TreeItem
                key={folder.id}
                nodeId={folder.id}
                label={folder.title}
                icon={icon}
                onClick={(e) => this.folderClick(e, folder, coordinates)}
                disabled={buttonDisabled}
            >
                {children}
            </TreeItem>
        );
        return treeItem;
    }

    async onClickAADRestore(event) {

        var _resources = [];
        var filteredAADData = this.state.aadData.resources.filter((a) => {
            return this.state.selectedItems.find((p) => p.ConfigItem === a.resourceInstanceName);
        });

        for (var i = 0; i < filteredAADData.length; i++) {
            var _aadData = filteredAADData[i];
            _resources.push({
                ResourceName: _aadData.resourceName,
                Key: _aadData.key,
                KeyValue: _aadData.keyValue,
                ResourceInstanceName: _aadData.resourceInstanceName,
                Properties: [
                    {
                        ValueInDestination: _aadData.properties[0].valueInDestination,
                        ParameterName: _aadData.properties[0].parameterName,
                        ValueInSource: _aadData.properties[0].valueInSource
                    }
                ]
            })
        }
        var payLoaddata = {
            Resources: _resources,
            orgName: this.state.aadData.orgName,
            restorePoint: {
                fileName: this.state.rp.diffFile,
                restoreDate: this.state.rp.startTime,
                currFileName: this.state.rp.currFile,
                prevFileName: this.state.rp.prevFile,
            }
        }
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            }
        };
        Axios.post(API_ROUTE + Routes.RESTORE_ENTRA_CHANGES + '/' + this.state.orgName, payLoaddata, config)
            .then((response) => {
                let deviceCode = response.data;

                this.setState({
                    deviceCode: deviceCode,
                    inProgressRight: false,
                    ingettingDeviceCode: false,
                    disableBtn: true,
                    entraModalOpen: true,
                    entraRestoreMessage: response.data === 'fail' ?
                        'Your restore has failed, Please contact support.' :
                        (response.data.includes("Failed -") ?
                            response.data.split("Failed -")[1] :
                            'Your restore has been requested. You will be informed when it is completed.'),
                    downloading: false
                });
                // this.deviceTokenCheck(tokenString);
            })
            .catch((reason) => {
                console.log(reason);
                this.setState({
                    ingettingDeviceCode: false,
                    inProgressRight: false,
                });
            });
    }

    closeDownloadModal(event) {
        event.preventDefault();
        this.setState({
            restoreAction: "",
            entraModalOpen: false,
            downloading: false,
            downloadModalOpen: false,
            verificationModalOpen: false,
            mailModalOpen: false,
            restoreModalOpen: false,
            restoreSuccess: null,
            restoring: false,
            selectedItems: []
        });
        if (this.state.gridApi) {
            this.state.gridApi.setRowData([]);
            this.state.gridApi.refreshCells();
            this.state.gridApi.setRowData(this.state.viewItems);
            this.state.gridApi.refreshCells();
        }
        this.resetCheckbox();
    }

    closeRestoreModal(event) {
        event.preventDefault();
        this.setState({
            verificationModalOpen: false,
            gettingDeviceCode: false,
            mailModalOpen: false,
            restoreModalOpen: false,
            entraModalOpen: false,
            downloadModalOpen: false,
            restoreSuccess: null,
            restoring: false,
        });
        if (this.state.gridApi != null) {
            this.state.gridApi.setRowData([]);
            this.state.gridApi.refreshCells();
            this.state.gridApi.setRowData(this.state.viewItems);
            this.state.gridApi.refreshCells();
            this.resetCheckbox();
        }
    }
    resetCheckbox() {
        const deselected = [];
        this.state.gridApi.forEachNode(function (node) {
            if (node.isSelected()) {
                deselected.push(node);
            }
        });
        // this.state.gridApi.setNodesSelected({ nodes: deselected, newValue: false });
    }

    selectItem(event) {
        //event.preventDefault();
        var itemId = event;

        var item = null;
        for (var i = 0; i < this.state.selectItems.length; i++) {
            if (!item) {
                const selectItem = this.state.selectItems[i];
                if (selectItem.id == itemId) {
                    item = selectItem;
                }
            }
        }
        if (item) {
            this.setState(
                {
                    selectItem: item,
                    selectedFolder: null,
                    selectedItems: [],
                    viewItems: [],
                    oldItems: [],
                    noResults: false,
                    displayPage: 0,
                    itemClicked: "root",
                    restorePath: item.libraries ? item.libraries.split("/libraries")[0] : ""
                },
                () => {

                    this.getTreeItems();
                }
            );
        } else {
            this.resetState(this.state.restoreType);
        }
    }

    convertHeading(text) {
        let finalResult = lodash.startCase(text);
        return finalResult;
    }

    selectRestoreItem(event, item) {
        try {

            event.preventDefault();

        } catch (e) {

        }
        if (item) {
            var selectedItems = this.state.selectedItems;
            if (selectedItems.includes(item)) {
                var index = selectedItems.indexOf(item);
                if (selectedItems !== -1) {
                    selectedItems.splice(index, 1);
                }
            } else {
                selectedItems.push(item);
            }
            this.setState({
                selectedItems: selectedItems,
            });
        }
    }


    /**
     New Methd for migrating table to AG grid
     */
    overlayProgress = (show) => {
        if (show) {
            try {
                this.state.gridApi.setRowData([]);
            } catch (e) {

            }
        }
        try {
            if (show) {
                document.getElementById('restore-grid').style.display = 'none';
                this.state.showOverlay()
            }
            else {
                document.getElementById('restore-grid').style.display = 'block';
                this.state.hideOverlay()
            }
        } catch (e) {

        }
        // if (show)
        //     document.getElementById('main-div-form-elements').style.display = 'block';
        // else
        //     document.getElementById('main-div-form-elements').style.display = 'none';
    }

    OnselectionChanged = (e) => {

    }
    OnItemSelected = (e) => {

        this.selectRestoreItem(e, e.data);
    }

    onGridReady = (p) => {
        this.setState({
            gridApi: p.api
        });

        var _colDef = this.setDynamicColumnDefinition(this.state.viewItems);
        p.api.setColumnDefs(_colDef)
        p.api.setRowData(this.state.viewItems);

    }

    getRowStyle = params => {
        return { fontSize: "Small" };
    };

    renderAgGrid = (items) => {
        if (this.state.gridApi) {
            var _colDef = this.setDynamicColumnDefinition(items);

            this.state.gridApi.setColumnDefs(_colDef)
            if (items.length > 0)
                this.state.gridApi.setRowData(items);
            else
                this.state.gridApi.setRowData([])
        }
    }
    formatDateColumn = (p) => {
        let value = p.value;

        let property = p.column.colId;

        if (
            property == "startTime" ||
            property == "endTime" ||
            property == "sent" ||
            property == "received" ||
            property == "startDate" ||
            property == "dueDate" ||
            property == "modificationTime" ||
            property == "modified" ||
            property == "creationTime"
            ||
            property.indexOf("Time") > -1 ||
            property.indexOf("Date") > -1
        ) {
            let _dateValue = moment(value).format("hh:mm DD-MM-YYYY").toString();
            return _dateValue;
        }
        return value;
    }
    AADReporthandleClose = () => {
        this.setState({
            AADReportshow: false,
        });
    }
    PreviewAADdiff = (params) => {
        console.log(params)
        //'Previous' || heading == 'Current'
        let AADPopup =<></>
        try {
            let previewResult = <></>
            let currentResult = <></>
            let trResult=[]
            if (params.data.Previous.split('<>').length === 1 || params.data.Current.split('<>').length === 1) {
                let Previous = JSON.parse(params.data.Previous);
                let Current = JSON.parse(params.data.Current);

                if ((Array.isArray(Previous) || Array.isArray(Current)) && (typeof Previous[0] !== 'object' || typeof Current[0] !== 'object')) {
                    const prevSet = new Set(Previous);
                    const currSet = new Set(Current);
                    const notInCurrent = Previous.filter(item => !currSet.has(item));

                    const notInPrevious = Current.filter(item => !prevSet.has(item));

                    previewResult = <>[{Previous.map((item, index) => (
                        <>
                            <span
                                key={index}
                                style={{
                                    color: notInCurrent.includes(item) ? 'red' : 'black',
                                    textDecoration: item && item.length > 0 && notInCurrent.includes(item) ? 'underline' : 'none',
                                }}>
                                "{item}"
                            </span>
                            {index < Previous.length - 1 ? ", " : ""}
                        </>
                    ))}]</>

                    currentResult = <>[{Current.map((item, index) => (
                        <><span
                            key={index}
                            style={{
                                color: notInPrevious.includes(item) ? 'green' : 'black',
                                fontWeight: notInPrevious.includes(item) ? 'bold' : 'normal',
                            }}>
                            "{item}"
                        </span>
                            {index < Current.length - 1 ? ", " : ""}
                        </>

                    ))}]</>
                    trResult.push(<tr>
                        <td>{previewResult}</td>
                        <td>{currentResult}</td>
                    </tr>);
                }
                else if (typeof Previous[0] === 'object' || typeof Current[0] === 'object') {
                    const newOutput = {};
                    const oldOutput = {};

                    Current.forEach((currentItem, index) => {
                        const previousItem = Previous[index];

                        // Compare by key-value pairs inside the objects
                        Object.keys(currentItem).forEach((key) => {
                            if (!previousItem || !previousItem.hasOwnProperty(key)) {
                                // Key is new in Current
                                newOutput[`${index}-${key}`] = { value: currentItem[key], keyColor: 'green', valueColor: 'green', fontWeight: 'bold' };
                            } else if (currentItem[key] !== previousItem[key]) {
                                // Key exists in both, but values are different
                                newOutput[`${index}-${key}`] = { value: currentItem[key], keyColor: 'black', valueColor: 'green', fontWeight: 'bold' };
                                oldOutput[`${index}-${key}`] = { value: previousItem[key], keyColor: 'black', valueColor: 'red', textDecoration: 'underline' };
                            } else {
                                // Key and value are the same
                                newOutput[`${index}-${key}`] = { value: currentItem[key], keyColor: 'black', valueColor: 'black' };
                                oldOutput[`${index}-${key}`] = { value: previousItem[key], keyColor: 'black', valueColor: 'black' };
                            }
                        });

                        // Handle keys that exist in Previous but not in Current
                        if (previousItem) {
                            Object.keys(previousItem).forEach((key) => {
                                if (!currentItem.hasOwnProperty(key)) {
                                    oldOutput[`${index}-${key}`] = { value: previousItem[key], keyColor: 'red', valueColor: 'red', textDecoration: 'underline' };
                                }
                            });
                        }
                    });
                    if (Previous.length > Current.length) {
                        for (let i = Current.length; i < Previous.length; i++) {
                            const previousItem = Previous[i];
                            Object.keys(previousItem).forEach((key) => {
                                oldOutput[`${i}-${key}`] = { value: previousItem[key], keyColor: 'red', valueColor: 'red', textDecoration: 'underline' };
                            });
                        }
                    }
                    // Preview of old object
                    const groupByIndex = (output) => {
                        const grouped = {};
                        Object.entries(output).forEach(([key, value]) => {
                            const index = key.split('-')[0]; // Extract index from key
                            if (!grouped[index]) {
                                grouped[index] = [];
                            }
                            grouped[index].push({ key: key.split('-')[1], ...value });
                        });
                        return grouped;
                    };

                    const groupedOldOutput = groupByIndex(oldOutput);
                    const groupedNewOutput = groupByIndex(newOutput);

                    // Preview of old object
                    previewResult = (
                        <div>
                            {'['}
                            {Object.entries(groupedOldOutput).map(([index, entries], idx, arr) => (
                                <div key={index} style={{ marginLeft: '20px', color: 'black' }}>
                                    {'{'}
                                    {entries.map(({ key, value, keyColor, valueColor }) => (
                                        <div key={key} style={{ color: keyColor, marginLeft: '40px' }}>
                                            {key}: <span style={{ color: valueColor, fontSize: '1rem' }}>{value.toString()}</span>
                                        </div>
                                    ))}
                                    <div style={{ marginLeft: '20px' }}>{'}'}{idx < arr.length - 1 && ','}</div>
                                    {/* Add a comma if this is not the last object */}
                                    
                                </div>
                            ))}
                            {']'}
                        </div>
                    );

                    // Current result rendering
                    currentResult = (
                        <div>
                            {'['}
                            {Object.entries(groupedNewOutput).map(([index, entries], idx, arr) => (
                                <div key={index} style={{ marginLeft: '20px', color: 'black' }}>
                                    {'{'}
                                    {entries.map(({ key, value, keyColor, valueColor }) => (
                                        <div key={key} style={{ color: keyColor, marginLeft: '40px' }}>
                                            {key}: <span style={{ color: valueColor, fontSize: '1rem' }}>{value.toString()}</span>
                                        </div>
                                    ))}
                                    <div style={{ marginLeft: '20px' }}>{'}'}{idx < arr.length - 1 && ','}</div>
                                    {/* Add a comma if this is not the last object */}
                                    
                                </div>
                            ))}
                            {']'}
                        </div>
                    );

                    trResult.push(<tr>
                        <td>{previewResult}</td>
                        <td>{currentResult}</td>
                    </tr>);
                }
                else if (typeof Previous === 'object' || typeof Current === 'object') {
                    const newOutput = {};
                    const oldOutput = {};

                    Object.keys(Current).forEach((key) => {
                        if (!Previous.hasOwnProperty(key)) {
                            newOutput[key] = { value: Current[key], keyColor: 'green', valueColor: 'green', fontWeight:'bold' }; // New key and value
                        } else if (Current[key] !== Previous[key]) {
                            newOutput[key] = { value: Current[key], keyColor: 'black', valueColor: 'green', fontWeight: 'bold' }; // Same key, different value
                            oldOutput[key] = { value: Previous[key], keyColor: 'black', valueColor: 'red', textDecoration: 'underline' };
                        } else {
                            newOutput[key] = { value: Current[key], keyColor: 'black', valueColor: 'black' }; // Same key, same value
                            oldOutput[key] = { value: Previous[key], keyColor: 'black', valueColor: 'black' };
                        }
                    });

                    Object.keys(Previous).forEach((key) => {
                        if (!Current.hasOwnProperty(key)) {
                            oldOutput[key] = { value: Previous[key], keyColor: 'red', valueColor: 'red', textDecoration: 'underline' }; // Old key and value
                        }
                    });

                    previewResult = (
                        <div>
                            {'{'}
                            {Object.entries(oldOutput).map(([key, { value, keyColor, valueColor }]) => (
                                <div key={key} style={{ color: keyColor }}>
                                    {key}: <span style={{ color: valueColor, fontSize: '1rem' }}>{value.toString()}</span>
                                </div>
                            ))}
                            {'}'}
                        </div>
                    );

                    currentResult = (
                        <div>
                            {'{'}
                            {Object.entries(newOutput).map(([key, { value, keyColor, valueColor }]) => (
                                <div key={key} style={{ color: keyColor }}>
                                    {key}: <span style={{ color: valueColor, fontSize: '1rem' }}>{value.toString()}</span>
                                </div>
                            ))
                            }
                            {'}'}
                        </div >
                    );
                    trResult.push(<tr>
                        <td>{previewResult}</td>
                        <td>{currentResult}</td>
                    </tr>);
                }
                else {
                    throw new Error("Not an array or object.");
                }

                AADPopup = <table className="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Previous backup</th>
                            <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Selected backup</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trResult}
                    </tbody>
                </table>
            }
            else {
                let listofPrevious = params.data.Previous.split('<>');
                let listofCurrent = params.data.Current.split('<>');
                let tr = [];
                listofPrevious.forEach((item, index) => {
                    let Previous = JSON.parse(item);
                    let Current = JSON.parse(listofCurrent[index]);
                    
                    if (Array.isArray(Previous) || Array.isArray(Current)) {
                        const prevSet = new Set(Previous);
                        const currSet = new Set(Current);
                        const notInCurrent = Previous.filter(item => !currSet.has(item));

                        const notInPrevious = Current.filter(item => !prevSet.has(item));

                        previewResult = <>[{Previous.map((items, indexs) => (
                            <>

                                <span
                                    key={indexs}
                                    style={{
                                        color: notInCurrent.includes(items) ? 'red' : 'black',
                                        textDecoration: items && items.length > 0 && notInCurrent.includes(items) ? 'underline' : 'none',
                                    }}>
                                    "{items}"
                                </span>
                                {index < Previous.length - 1 ? ", " : ""}
                            </>
                        ))}]</>

                        currentResult = <>[{Current.map((items, indexs) => (
                            <><span
                                key={indexs}
                                style={{
                                    color: notInPrevious.includes(items) ? 'green' : 'black',
                                    fontWeight: notInPrevious.includes(items) ? 'bold' : 'normal',
                                }}>
                                "{items}"
                            </span>
                                {indexs < Current.length - 1 ? ", " : ""}
                            </>

                        ))}]</>

                        tr.push(<tr>
                            <td>
                                <span className="fw-bold">
                                    {params.data.Property.split('<>')[index]}
                                </span>
                                <div>{previewResult}</div>
                            </td>
                            <td><span className="fw-bold" style={{ height: '24px', display: 'block' }}>
                            </span>
                                <div>{currentResult}</div></td>
                        </tr>)

                    }
                    else {
                        throw new Error("Not an array or object.");
                    }
                });
                
                AADPopup = <table className="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Previous backup</th>
                            <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Selected backup</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tr }
                    </tbody>
                </table>
            }

            

            this.setState({
                AADReportshow: true,
                AADPreviousCurrent: AADPopup,
                AADPreviousCConfigHeadar: params.data.ConfigItem
            }); 
        } 
        catch (e) {
            let AADPopup = <table className="table table-bordered table-responsive">
                <thead>
                    <tr>
                        <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Previous backup</th>
                        <th scope="col" style={{ backgroundColor: '#5191ce', color: 'white' }}>Selected backup</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{params.data.Previous}</td>
                        <td>{params.data.Current}</td>
                    </tr>
                </tbody>
            </table>
            this.setState({
                AADReportshow: true,
                AADPreviousCurrent: AADPopup,
                AADPreviousCConfigHeadar: params.data.ConfigItem
            }); 
        }
        
    }
    setDynamicColumnDefinition = (items) => {

        var colDefnition = [];
        let columnHeadings = [];
        columnHeadings.push("select");
        items.forEach((item) => {
            for (const property in item) {
                if (
                    property !== "id" &&
                    property !== "links" &&
                    property !== "itemClass" &&
                    property !== "actions" &&
                    property !== "recurring" &&
                    property !== "attachments" &&
                    property !== "reminder"
                ) {
                    if (
                        property === "startTime" ||
                        property === "endTime" ||
                        property === "sent" ||
                        property === "received" ||
                        property === "startDate" ||
                        property === "dueDate" ||
                        property === "modificationTime" ||
                        property === "modified" ||
                        property === "creationTime" ||
                        property.includes("Time") ||
                        property.includes("Date")
                    ) {
                        var itemTime = moment(item[property]);
                        if (itemTime.format("YYYY").toString() > 1970) {
                            if (item[property] && !columnHeadings.includes(property)) {
                                let headingContent = this.convertHeading(property);
                                columnHeadings.push(property);
                                // var _tempColDef={
                                //     field:property,
                                //     headerName:headingContent,
                                //     tooltipField:property,
                                //     valueFormatter: this.formatDateColumn
                                // }
                                // colDefnition.push(_tempColDef);
                            }
                        }
                    } else {
                        if (item[property] && !columnHeadings.includes(property)) {
                            let headingContent = this.convertHeading(property);
                            columnHeadings.push(property);
                            // var _tempColDef={
                            //     field:property,
                            //     headerName:headingContent,
                            //     tooltipField:property
                            // }
                            // colDefnition.push(_tempColDef);
                        }
                    }
                }
            }
        });

        columnHeadings = columnHeadings.sort();
        // ORDER HEADERS HERE
        let newHeadings = [];
        if (columnHeadings.includes("select")) {
            newHeadings.push("select");
        }
        if (columnHeadings.includes("name")) {
            newHeadings.push("name");
        }
        if (columnHeadings.includes("creationTime")) {
            newHeadings.push("creationTime");
        }
        if (columnHeadings.includes("modificationTime")) {
            newHeadings.push("modificationTime");
        }
        if (columnHeadings.includes("createdBy")) {
            newHeadings.push("createdBy");
        }
        if (columnHeadings.includes("modifiedBy")) {
            newHeadings.push("modifiedBy");
        }
        if (columnHeadings.includes("fullName")) {
            newHeadings.push("fullName");
        }
        if (columnHeadings.includes("email")) {
            newHeadings.push("email");
        }
        if (columnHeadings.includes("displayAs")) {
            newHeadings.push("displayAs");
        }
        if (columnHeadings.includes("mobile")) {
            newHeadings.push("mobile");
        }
        if (columnHeadings.includes("from")) {
            newHeadings.push("from");
        }
        if (columnHeadings.includes("to")) {
            newHeadings.push("to");
        }
        if (columnHeadings.includes("subject")) {
            newHeadings.push("subject");
        }
        if (columnHeadings.includes("sent")) {
            newHeadings.push("sent");
        }
        if (columnHeadings.includes("received")) {
            newHeadings.push("received");
        }
        if (columnHeadings.includes("startTime")) {
            newHeadings.push("startTime");
        }
        if (columnHeadings.includes("endTime")) {
            newHeadings.push("endTime");
        }
        if (columnHeadings.includes("location")) {
            newHeadings.push("location");
        }
        if (columnHeadings.includes("ConfigItem")) {
            newHeadings.push("ConfigItem");
        }
        if (columnHeadings.includes("Property")) {
            newHeadings.push("Property");
        }
        if (columnHeadings.includes("Previous")) {
            newHeadings.push("Previous");
        }
        if (columnHeadings.includes("Current")) {
            newHeadings.push("Current");
        }
        columnHeadings.forEach((heading) => {
            if (!newHeadings.includes(heading)) {
                newHeadings.push(heading);
            }
        });
        columnHeadings = newHeadings;
        columnHeadings.forEach((heading) => {
            // items.forEach((item) => {
            let headingContent = this.convertHeading(heading);
            var _itemValue = items[0][heading]
            if (headingContent === 'Size Bytes')
                headingContent = 'Size(MB)';
            if (heading == 'select') {
                var _tempColDef = {
                    field: '',
                    headerName: headingContent,
                    tooltipField: heading,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    filter: false,
                    maxWidth: 50
                }
                colDefnition.push(_tempColDef);
            }
            else if (heading == 'Property') {
                var _tempColDef = {
                    field: 'Property',
                    headerName: headingContent,
                    cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
                    cellRendererFramework: (params) => {
                        if (params.data.Property.split('<>').length > 1) {
                            let spans =<></>
                            let result = []
                            let array = params.data.Property.split('<>')
                            array.forEach((item, index) => {
                                if (index != 0 && index < array.length ) {
                                    result.push(<hr key="divider" />);
                                }
                                    result.push(
                                        <div key={`azure-property-${index}`}>
                                            {item}
                                        </div>
                                    );
                                    
                                });
                            //result.push(<hr key="divider" />);
                            spans = <div>{result}</div>;
                            return spans;
                            }
                            else {
                                return params.data.Property
                            }
                        }
                }
                colDefnition.push(_tempColDef);
            }
            else if (heading == 'Change') {
                var _tempColDef = {
                    field: 'Change',
                    headerName: headingContent,
                    //tooltipField: heading,
                    minWidth: 50,
                    cellRendererFramework: (params) => {
                        let spans = <></>
                        try {
                            //JSON.parse(params.data.Added);
                            if (params.data.Previous.split('<>').length === 1 || params.data.Current.split('<>').length === 1) {
                                if (Array.isArray(params.data.Added[0]) || Array.isArray(params.data.Removed[0])) {
                                    //console.log('The value is an array.');
                                    spans = <div>
                                        {params.data.Added.map((item, index) => (
                                            <span key={index} style={{ color: 'green', fontWeight:'bold' }}>
                                                {item}
                                                {index < params.data.Added.length - 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                        {params.data.Removed.map((item, index) => (
                                            <span key={index} style={{ color: 'red', textDecoration: 'underline' }}>
                                                {item}
                                                {index < params.data.Removed.length - 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                    </div>
                                }
                                else if ((typeof params.data.Added[0] === 'object' && params.data.Added[0] !== null) || (typeof params.data.Removed[0] === 'object' && params.data.Removed[0] !== null)) {
                                    //console.log('The value is a JSON object.');
                                    const result = [];
                                    // Get the maximum length of both arrays to handle cases where one is longer
                                    const maxLength = Math.max(params.data.Added.length, params.data.Removed.length);

                                    // Iterate over the arrays of objects
                                    for (let i = 0; i < maxLength; i++) {
                                        const addedObj = params.data.Added[i] || {};  // Handle cases where one array is shorter
                                        const removedObj = params.data.Removed[i] || {};

                                        // Get all keys from both objects in the current index
                                        const allKeys = new Set([
                                            ...Object.keys(removedObj),
                                            ...Object.keys(addedObj)
                                        ]);

                                        // Compare the individual objects at this index
                                        allKeys.forEach((key) => {
                                            const oldValue = removedObj[key];
                                            const newValue = addedObj[key];

                                            if (oldValue === undefined && newValue !== undefined) {
                                                result.push({ key, value: newValue, keyColor: "green", valueColor: "green", index: i, fontWeight:'bold' });
                                            } else if (newValue === undefined && oldValue !== undefined) {
                                                result.push({ key, value: oldValue, keyColor: "red", valueColor: "red", index: i, textDecoration:'underline' });
                                            } else if (oldValue !== newValue) {
                                                result.push({ key, value: newValue, keyColor: "black", valueColor: "green", index: i, fontWeight: 'bold' });
                                            }
                                        });
                                    }

                                    // Display the result
                                    spans = (
                                        <div>
                                            {result.map(({ key, value, keyColor, valueColor, index }) => (
                                                <>
                                                    {index > 0 && <hr style={{ margin: '0' }}></hr>}
                                                <div key={`${index}-${key}`}>
                                                    {/*<span style={{ color: keyColor }}>"{key}" (Object {index + 1}): </span>*/}
                                                    <span style={{ color: keyColor }}>"{key}": </span>
                                                    <span style={{ color: valueColor }}>{JSON.stringify(value)}</span>,
                                                    </div>
                                                    
                                                </>
                                            ))}
                                        </div>
                                    );
                                }
                                else {
                                    //console.log('The value is of a different type.');
                                    throw new Error("Not an array or object.");
                                }
                            }
                            else {
                                let listofAdded = params.data.Added;
                                let listofRemoved = params.data.Removed;
                                let actualSpans = [];
                                listofAdded.forEach((item, index) => {
                                    //item = JSON.parse(item)
                                    var removed = listofRemoved[index]
                                    if (Array.isArray(item) || Array.isArray(removed)) {
                                        //console.log('The value is an array.');
                                        if (actualSpans.length > 0) {
                                            actualSpans.push(<hr></hr>);
                                        }
                                        actualSpans.push(<div style={{ minHeight:'22px' }} >
                                            {item.map((items, indexs) => (
                                                <span key={indexs} style={{ color: 'green', display: 'block', fontWeight: "bold" }}>
                                                    {items}
                                                    {indexs < item.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                            {removed.map((items, indexs) => (
                                                <span key={indexs} style={{ color: 'red', display: 'block', textDecoration: 'underline' }}>
                                                    {items}
                                                    {indexs < removed.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </div>)
                                    }
                                })
                                return actualSpans;
                            }
                            

                            return spans;
                        } catch (e) {
                            let spans = <div></div>
                            if (params.data.Current === "Present") {
                                spans = <div>
                                    <span style={{ color: 'green', display: 'block', fontWeight: "bold" }}>
                                        Created
                                    </span>
                                </div>
                            }
                            else if (params.data.Current === "Absent") {
                                spans = <div>
                                    <span style={{ color: 'red', display: 'block', textDecoration: 'underline' }}>
                                        Deleted
                                    </span>
                                </div>
                            }
                            else {
                                spans = <div>
                                    <span style={{ color: 'green', display: 'block', fontWeight: "bold" }}>
                                        {params.data.Added.join(', ')}
                                    </span>
                                    <span style={{ color: 'red', display: 'block', textDecoration: 'underline' }}>
                                        {params.data.Removed.join(', ')}
                                    </span>
                                </div>
                            }

                            return spans;
                        }
                    }
                }
                colDefnition.push(_tempColDef);
                var _tempColDefClick = {
                    field: '',
                    headerName: 'Preview',
                    maxWidth: 100,
                    filter: false, 
                    cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
                    cellRendererFramework: (params) => {
                        return (
                            <div className="justify-content-between d-flex align-items-center">
                                <a onClick={() => this.PreviewAADdiff(params)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                </a>
                            </div>
                        );
                    }
                }
                colDefnition.push(_tempColDefClick);
            }
            else if (heading == 'Previous' || heading == 'Current' || heading == 'Added' || heading == 'Removed') {

            } else if (heading == 'contentUrl') {
                var _tempColDef = {
                    field: heading,
                    headerName: headingContent,
                    tooltipField: heading,
                    valueFormatter: this.formatDateColumn,
                    filter: heading !== 'select' ? true : false,
                    cellStyle: { whiteSpace:'nowrap' },
                }
                colDefnition.push(_tempColDef);
            }
            else {
                var _tempColDef = {
                    field: heading,
                    headerName: headingContent,
                    tooltipField: heading,
                    valueFormatter: this.formatDateColumn,
                    filter: heading !== 'select' ? true : false
                }
                colDefnition.push(_tempColDef);
            }
            // });

        });

        return colDefnition;
    }

    setColumnDefinition = () => {
        let m = this.state.selectedFolder.title;
        let _colDef = [];
        if (m == 'Inbox') {
            _colDef = [{ field: '', headerName: 'Select', toolTip: '', checkboxSelection: true },
            { field: 'from', headerName: 'From', toolTip: '' },
            { field: 'to', headerName: 'To', toolTip: '' },
            { field: 'subject', headerName: 'Subject', toolTip: '' },
            { field: 'sent', headerName: 'Sent', toolTip: '', valueFormatter: this.formatDateColumn },
            { field: 'received', headerName: 'Received', toolTip: '', valueFormatter: this.formatDateColumn }
            ];
        }

        return _colDef;
    }

    /**
     * End of migrations
     */

    getTable(items) {

        if (this.state.inProgressRight) {
            return (
                <React.Fragment>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </React.Fragment>
            );
        }
        //this.renderAgGrid(items);
        let columns = [];
        let columnHeadings = [];
        columnHeadings.push("select");
        let styleObject = null;
        items.forEach((item) => {
            for (const property in item) {
                if (
                    property != "id" &&
                    property != "links" &&
                    property != "itemClass" &&
                    property != "actions" &&
                    property != "recurring" &&
                    property != "attachments" &&
                    property != "reminder"
                ) {
                    if (
                        property == "startTime" ||
                        property == "endTime" ||
                        property == "sent" ||
                        property == "received" ||
                        property == "startDate" ||
                        property == "dueDate" ||
                        property == "modificationTime" ||
                        property == "modified" ||
                        property == "creationTime" ||
                        property.includes("Time") ||
                        property.includes("Date")
                    ) {
                        var itemTime = moment(item[property]);
                        if (itemTime.format("YYYY").toString() > 1970) {
                            if (item[property] && !columnHeadings.includes(property)) {
                                columnHeadings.push(property);
                            }
                        }
                    } else {
                        if (item[property] && !columnHeadings.includes(property)) {
                            columnHeadings.push(property);
                        }
                    }
                }
            }
        });
        columnHeadings = columnHeadings.sort();
        // ORDER HEADERS HERE
        let newHeadings = [];
        if (columnHeadings.includes("select")) {
            newHeadings.push("select");
        }
        if (columnHeadings.includes("name")) {
            newHeadings.push("name");
        }
        if (columnHeadings.includes("creationTime")) {
            newHeadings.push("creationTime");
        }
        if (columnHeadings.includes("modificationTime")) {
            newHeadings.push("modificationTime");
        }
        if (columnHeadings.includes("createdBy")) {
            newHeadings.push("createdBy");
        }
        if (columnHeadings.includes("modifiedBy")) {
            newHeadings.push("modifiedBy");
        }
        if (columnHeadings.includes("fullName")) {
            newHeadings.push("fullName");
        }
        if (columnHeadings.includes("email")) {
            newHeadings.push("email");
        }
        if (columnHeadings.includes("displayAs")) {
            newHeadings.push("displayAs");
        }
        if (columnHeadings.includes("mobile")) {
            newHeadings.push("mobile");
        }
        if (columnHeadings.includes("from")) {
            newHeadings.push("from");
        }
        if (columnHeadings.includes("to")) {
            newHeadings.push("to");
        }
        if (columnHeadings.includes("subject")) {
            newHeadings.push("subject");
        }
        if (columnHeadings.includes("sent")) {
            newHeadings.push("sent");
        }
        if (columnHeadings.includes("received")) {
            newHeadings.push("received");
        }
        if (columnHeadings.includes("startTime")) {
            newHeadings.push("startTime");
        }
        if (columnHeadings.includes("endTime")) {
            newHeadings.push("endTime");
        }
        if (columnHeadings.includes("location")) {
            newHeadings.push("location");
        }
        columnHeadings.forEach((heading) => {
            if (!newHeadings.includes(heading)) {
                newHeadings.push(heading);
            }
        });
        columnHeadings = newHeadings;
        columnHeadings.forEach((heading) => {
            let headingContent = this.convertHeading(heading);
            let header = (
                <th style={styleObject} key={heading}>
                    {headingContent}
                </th>
            );
            columns.push(header);
        });
        items.forEach((item) => {
            for (const property in item) {
                if (
                    property != "id" &&
                    property != "links" &&
                    property != "itemClass" &&
                    property != "actions" &&
                    property != "recurring" &&
                    property != "attachments" &&
                    property != "reminder"
                ) {
                    let headingContent = this.convertHeading(property);
                    if (
                        property == "startTime" ||
                        property == "endTime" ||
                        property == "sent" ||
                        property == "received" ||
                        property == "startDate" ||
                        property == "dueDate" ||
                        property == "modificationTime" ||
                        property == "creationTime" ||
                        property == "modified" ||
                        property.includes("Time") ||
                        property.includes("Date")
                    ) {
                        var itemTime = moment(item[property]);
                        if (itemTime.format("YYYY").toString() > 1970) {
                            if (item[property] && !columnHeadings.includes(property)) {
                                let header = (
                                    <th style={styleObject} key={property}>
                                        {headingContent}
                                    </th>
                                );
                                columnHeadings.push(property);
                                columns.push(header);
                            }
                        }
                    } else {
                        if (item[property] && !columnHeadings.includes(property)) {
                            let header = (
                                <th style={styleObject} key={property}>
                                    {headingContent}
                                </th>
                            );
                            columnHeadings.push(property);
                            columns.push(header);
                        }
                    }
                }
            }
        });
        let headerRow = (
            <thead>
                <tr>{columns}</tr>
            </thead>
        );
        let rows = [];
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            let columns = [];
            let styleObject = null;
            for (var j = 0; j < columnHeadings.length; j++) {
                const heading = columnHeadings[j];
                if (heading == "select") {
                    let isSelected = this.state.selectedItems.includes(item);
                    let column = (
                        <td style={styleObject} key={heading + j.toString()}>
                            <Checkbox color="primary" checked={isSelected} />
                        </td>
                    );
                    columns.push(column);
                }
                if (item[heading]) {
                    let content = item[heading].toString();
                    if (heading == "to" || heading == "attendees") {
                        content = content.substring(0, 45);
                        if (content.length == 45) {
                            content += "...";
                        }
                    }
                    if (item.itemClass && item.itemClass.includes("appointement")) {
                        content = moment(item[heading])
                            .format("hh:mm DD-MM-YYYY")
                            .toString();
                    } else {
                        if (
                            heading == "startTime" ||
                            heading == "endTime" ||
                            heading == "sent" ||
                            heading == "received" ||
                            heading == "startDate" ||
                            heading == "dueDate" ||
                            heading == "creationTime" ||
                            heading == "modificationTime" ||
                            heading == "modified" ||
                            heading.includes("Time") ||
                            heading.includes("Date")
                        ) {
                            content = moment(item[heading])
                                .format("hh:mm DD-MM-YYYY")
                                .toString();
                        }
                    }
                    let column = (
                        <td style={styleObject} key={heading}>
                            {content}
                        </td>
                    );
                    columns.push(column);
                } else {
                    if (heading != "select") {
                        let column = <td style={styleObject} key={heading}></td>;
                        columns.push(column);
                    }
                }
            }
            let row = (
                <tr onClick={(e) => this.selectRestoreItem(e, item)} key={i}>
                    {columns}
                </tr>
            );
            rows.push(row);
        }
        let displayRows = <tbody>{rows}</tbody>;
        return (
            <React.Fragment>
                {headerRow}
                {displayRows}
            </React.Fragment>
        );
    }

    previousPage(event, isSearch) {
        event.preventDefault();
        if (this.state.displayPage > 0 && !this.state.inProgressRight) {
            this.overlayProgress(true);
            this.setState(
                {
                    nextBlocked: false,
                    inProgressRight: true,
                },
                () => {
                    if (isSearch) {
                        this.search(null, "previous");
                    } else {
                        this.getView(this.state.selectedFolder.getItems, "previous");
                    }
                }
            );
        }
    }

    nextPage(event, isSearch) {
        event.preventDefault();
        if (!this.state.inProgressRight) {
            this.overlayProgress(true);
            this.setState(
                {
                    loadedCurrentConfig: false,
                    nextBlocked: false,
                    inProgressRight: true,
                },
                () => {
                    if (isSearch) {
                        this.search(null, "next");
                    } else {
                        this.getView(this.state.selectedFolder.getItems, "next");
                    }
                }
            );
        }
    }
    // property == "startTime" ||
    // property == "endTime" ||
    // property == "sent" ||
    // property == "received" ||
    // property == "startDate" ||
    // property == "dueDate" ||
    // property == "modificationTime" ||
    // property == "modified" ||
    // property == "creationTime" 

    changeSearchField(event) {
        event.preventDefault();
        let value = event.target.value;
        let type = event.target.selectedOptions[0].getAttribute('type');

        let dateFieldValue = ['startTime', 'endTime', 'sent', 'received', 'start', 'end', 'modificationTime', 'modified', 'creationTime'];
        let CustomFieldValue = ['importance', 'priority'];
        let BooleanFieldValue = [ 'recurring', 'HasAttachments'];
        let SensitivityFieldValue = ['sensitivity'];
        let MeetingStatusFieldValue = ['meetingstatus'];
        let FlagStatusFieldValue = ['flagstatus'];
        let ColorFieldValue = ['color'];
        let SizeFieldValue = ['size'];

        let isDateFields = value && (value.includes('date') || dateFieldValue.includes(value));
        let isCustomField = value && CustomFieldValue.includes(value);
        let isBooleanField = value && BooleanFieldValue.includes(value);
        let isSensitivityField = value && SensitivityFieldValue.includes(value);
        let isMeetingStatusField = value && MeetingStatusFieldValue.includes(value);
        let isFlagStatusField = value && FlagStatusFieldValue.includes(value);
        let isColorField = value && ColorFieldValue.includes(value);
        let isSizeField = value && SizeFieldValue.includes(value);

        let conditionOptions = this.getConditionOptions(type);

        this.setState({
            searchField: value, 
            searchQueryType: '',
            isDateField: isDateFields,
            isCustomField: isCustomField,
            isBooleanField: isBooleanField,
            isSensitivityField: isSensitivityField,
            isMeetingStatusField: isMeetingStatusField,
            isFlagStatusField: isFlagStatusField,
            isColorField: isColorField,
            isSizeField: isSizeField,
            searchTerm: '', 
            searchTermDate: null, 
            value: null, 
            unit: 'KB', 
            isBetweenCondition: false, 
            dateRange: { start: '', end: '' }, 
            conditionOptions: conditionOptions, 
            searchTermVisible: !isDateFields && !isCustomField && !isSizeField && !isSensitivityField && !isFlagStatusField && !isColorField && !isBooleanField && !isMeetingStatusField,
            lblsearchTermVisible: !isDateFields && !isCustomField && !isSizeField,
            searchTermDateVisible: isDateFields,
            lblsearchTermDateVisible: isDateFields,
            customFieldDropdownVisible: isCustomField,
            lblBetweenDatesVisible: isCustomField && this.state.isBetweenCondition,
            fileSizeVisible: isSizeField,
            sensitivityDropdownVisible: isSensitivityField,
        });
    }


    handleValueChange(event) {
        const newValue = Number(event.target.value);
        this.setState(this.handleFileSizeChange(newValue, this.state.unit));
    }


    handleUnitChange = (event) => {
        const newUnit = event.target.value;
        const { value } = this.state; 
        const updatedFileSize = this.handleFileSizeChange(value, newUnit);
        this.setState(updatedFileSize); 
    };

    handleFileSizeChange = (value, unit) => {
        const formattedSize = `${value} ${unit}`;
        return {
            value,
            unit,
            searchTerm:formattedSize,
        };
    };
    handleDropdownChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            dropdownValues: {
                ...prevState.dropdownValues,
                [name]: value,
            },
            searchTerm: value
        }));
    };

    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    };

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    };
    changeSearchType(event) {
        event.preventDefault();
        let value = event.target.value;
        this.setState({
            searchType: value,
        }, this.resetSearchValues);
    }
    resetSearchValues = () => {
        this.setState({
            searchField: 'null',
            searchQueryType: '',
            searchTerm: '',
            searchTermDate: null,
            dateRange: { start: null, end: null },
            value: 0,
            isDateField: false,
            isCustomField: false,
            isBooleanField: false,
            isSensitivityField: false,
            isMeetingStatusField: false,
            isFlagStatusField: false,
            isColorField: false,
            isSizeField: false,
            selectedCondition: '',
        });
    }

    changeSearchQueryType(event) {
        event.preventDefault();
        let value = event.target.value;
        this.setState({
            searchQueryType: value,
            isBetweenCondition: value === 'between',
            selectedCondition: value,
            searchTermDate: null,
            dateRange: { start: null, end: null },
            searchTerm: '',
            value: 0,
            dropdownValues: {
                customDropdown: '----',
                sensitivityDropdown: '----',
                meetingStatusDropdown: '----',
                attachmentsDropdown: '----',
                flagStatusDropdown: '----',
                colorDropdown: '----',
            },
        });
    }

    getConditionOptions(type) {
        //const { conditionType } = this.state;

        if (type === "Text") {
            return [
                //{ value: "isExactly", label: "Is Exactly" },
                //{ value: "isNot", label: "Is Not" },
                { value: "contains", label: "Contains" },
                { value: "doesNotContain", label: "Doesn't Contain" },
                { value: "startsWith", label: "Starts With" },
                { value: "endsWith", label: "Ends With" },
                //{ value: "wordEquals", label: "Word Equals" },
                //{ value: "wordStartsWith", label: "Word Starts With" },
                //{ value: "dosWildcards", label: "DOS Wild Cards" },
            ];
        } else if (type == "Date") {
            return [
                { value: "isExactly", label: "Is Exactly" },
                { value: "isNot", label: "Is Not" },
                { value: "lessThan", label: "Less Than" },
                { value: "lessThanOrEqual", label: "Less Than or Equal" },
                { value: "greaterThan", label: "Greater Than" },
                { value: "greaterThanOrEqual", label: "Greater Than or Equal" },
                { value: "between", label: "Between" },
                { value: "today", label: "Today" },
                { value: "yesterday", label: "Yesterday" },
                { value: "tomorrow", label: "Tomorrow" },
                { value: "last7Days", label: "In the last 7 days" },
                { value: "next7Days", label: "In the next 7 days" },
                { value: "last Week", label: "Last week" },
                { value: "this Week", label: "This week" },
                { value: "next Week", label: "Next week" },
                { value: "lastMonth", label: "Last month" },
                { value: "nextMonth", label: "Next month" },
                { value: "thisMonth", label: "This month" },
                { value: "lastYear", label: "Last Year" },
                { value: "nextYear", label: "Next Year" },
                { value: "thisYear", label: "This Year" },
            ];
        } else if (type == "Custom") {
            return [
                { value: "isExactly", label: "Is Exactly" },
                { value: "isNot", label: "Is Not" },
            ]
        }
        return [];
    }
    
    addToQueryLines(event) {
        event.preventDefault();
        const dateConditions = [
            'today', 'yesterday', 'tomorrow', 'last7Days', 'next7Days',
            'last Week', 'this Week', 'next Week', 'lastMonth', 'nextMonth','thisMonth',
            'lastYear', 'nextYear', 'thisYear'
        ];
        let line = {
            Type: this.state.searchType,
            Field: this.state.searchField,
            Inclusion: this.state.searchQueryType,
            Term: this.state.searchTerm,
        };
        if (!line.Type && line.Type === '') {
            return false;
        }
        if (!line.Field && line.Field === '') {
            return false;
        }
        if (!line.Term && line.Term === '' && !dateConditions.includes(line.Inclusion)) {
            return false;
        }
        let lines = this.state.searchQueries;
        lines.push(line);
        this.setState({
            searchQueries: lines,
        });
    }

    onChange(event) {
        ////this.search(null, "", moment(event).format("DD-MM-YYYY"));//aadviewItems
        //if (this.state.aadviewItems.length > 0) {
        this.getAADBackupConfigDataListing(this.state.orgName, moment(event).format("DD-MM-YYYY"), "Date")
        this.setState({
            selectedRestoreDate: moment(event).format("DD-MM-YYYY"),
        });
        //}
    }
    onActiveStartDateChange = ({ activeStartDate }) => {
        this.getRestorePoints(activeStartDate);
        this.setState({
            restoreVisibleMonthandYear: moment(activeStartDate).format("MMMM YYYY"),
        });
    };
    selectRestoreOnChange(event, restorePoints) {
        //selectRestoreOnChange(event) {
        //    alert(event)
        //    alert(moment(event).format("DD-MM-YYYY"))

        let filteredData = restorePoints.filter(e => e.backupTimeStr.split(' ')[0] === moment(event).format("DD-MM-YYYY"))
        if (filteredData.length > 0)
            this.setState({
                selectedrestorePoints: filteredData,
                activeAccordion: "2",
                aadnoResults: true,
                selectedRestoreDate: moment(event).format("DD-MM-YYYY")
            });

    }
    getRestorePointsOnMonth() {
        let restorePoints = this.state.restorePoints;
        const [month, year] = this.state.restoreVisibleMonthandYear.split(' ');
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let filteredData = restorePoints.filter(item => {
            const date = new Date(item.backupTime);
            const monthIndex = date.getUTCMonth();
            const monthName = monthNames[monthIndex];
            const yearName = date.getUTCFullYear().toString();
            return monthName === month && yearName === year;
        });


        if (filteredData.length > 0)
            this.setState({
                selectedrestorePoints: filteredData,
                activeAccordion: "2",
                aadnoResults: true,
                selectedRestoreDate: this.state.restoreVisibleMonthandYear
            });
    }
    handleRestoreChallangeSubmitted() {
        this.setState({
            restoreChallangeSubmitted: true
        });
    }
    accordianChange(type, accordionNumber) {

        if (accordionNumber === "1") {
            this.setState({
                selectedRestoreDate: "",
            });
        }
        if (type === 'previous' && this.state.restoreChallangeSubmitted) {
            if (accordionNumber < this.state.activeAccordion && this.state.activeAccordion == "3") {
                this.setState({
                    activeAccordion: accordionNumber,                    
                });
            }
            else if (accordionNumber < this.state.activeAccordion && this.state.activeAccordion == "2") {
                this.setState({
                    activeAccordion: accordionNumber,
                });
            }
        }
        else if (type === 'first') {
            this.setState({
                selectedRestoreType: "",
                selectedRestoreDate: "",
                activeAccordion: accordionNumber,
                restoreChallangeSubmitted: false
            });
        }
        else if (type === 'next') {
            this.setState({
                activeAccordion: String(Number(this.state.activeAccordion[0]) + 1),
            });
            document.getElementById('restoreCalendar').style.display = "block";
            document.getElementById('aadCalendar').style.display = "none";
        }
        else if (type === 'aadazure') {
            document.getElementById('restoreCalendar').style.display = "none";
            document.getElementById('aadCalendar').style.display = "block";
            this.setState({
                activeAccordion: String(Number(this.state.activeAccordion) + 1),
            });
            this.setState(
                {
                    restoreType: "aad",
                    inProgressLeft: false,
                    inProgressRight: false,
                    inProgressRestore: false,
                    gotSelectItems: false,
                    restore: null,
                    siteURL: "",
                    destinationOneDriveId: "",
                    type: "",
                    destinationOneDriveUserName: "",
                    destinationOneDriveUrl: "",
                    destinationFolder: "",
                    openSharepointModal: false,
                    showoneDriveModal: false,
                    selectItems: [],
                    treeItems: [],
                    viewItems: [],
                    oldItems: [],
                    oldPage: 0,
                    gettingDeviceCode: false,
                    oldFlag: false,
                    noResults: false,
                    selectItem: null,
                    selectedFolder: null,
                    displayPage: 0,
                    loadedCurrentConfig: false,
                    nextBlocked: true,
                    selectedItems: [],
                    clearLeftSection: false,
                    isMFA: false,
                    itemClicked: "",
                    copied: false,
                    verificationModalOpen: false,
                    restoreAction: "",
                    deviceCode: "",
                    password: "",
                    mailModalOpen: false,
                    mailSendTo: "",
                    mailFrom: "",
                    mailSubject: "",
                    mailMessage: "Here are the files you requested.",
                    command: "",
                    rootRestoreOpen: false,
                    folderRestoreOpen: false,
                    itemRestoreOpen: false,
                    fileName: "",
                    queryString: "",
                    restoreJob: null,
                    lastClicked: "",
                    downloadModalOpen: false,
                    entraModalOpen: false,
                    downloading: false,
                    restoreModalOpen: false,
                    //restorePoints: [],
                    restoring: false,
                    restoreSuccess: null,
                    errorMessage: "",
                    searchTerm: "",
                    searchResultsFlag: false,
                    searchQueries: [],
                    searchType: "null",
                    sarchField: "null",
                    searchQueryType: "includes",
                    conditionOptions: [],
                    searchFilters: [],
                    searchPanelOpen: true,
                    expandedFolders: ["root"],
                    serverPath: "",
                    extraLargeDownload: false,
                    restorePath: "",
                    listName: ""
                },
                () => {

                    this.getRestore();
                }
            );
        }

    }
    aadorNot(aadorNot) {
        if (aadorNot) {
            document.getElementById('Exchange_restore').style.display = "none";
            document.getElementById('Sharepoint_restore').style.display = "none";
            document.getElementById('OneDrive_restore').style.display = "none";
            document.getElementById('Teams_restore').style.display = "none";
            document.getElementById('AAD_restore').style.display = "block";
            document.getElementById('accordion_3').style.display = "none";
            this.setState({
                selectedRestoreType: "Azure Backup"
            });
        }
        else {
            document.getElementById('Exchange_restore').style.display = "block";
            document.getElementById('Sharepoint_restore').style.display = "block";
            document.getElementById('OneDrive_restore').style.display = "block";
            document.getElementById('Teams_restore').style.display = "block";
            document.getElementById('AAD_restore').style.display = "none";
            document.getElementById('accordion_3').style.display = "block";
            this.setState({
                selectedRestoreType: "365 Backup"
            });
        }
    }
    //renderCustomDay(event) {
    //    alert(event)

    //}
    //onAADChange(event) {
    //    this.search(null, "", moment(event, "DD-MM-YYYY").format("DD-MM-YYYY"));
    //}

    async getAADBackupConfigDataListing(orgName, filterDate, type) {
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
                CallDomain: window.location.origin,
                CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
            },
        };
        this.setState({
            isAADRestoreTableLoaded: false
        })
        //let fileName = "";
        //var restorePoint = null;
        //for (var i = 0; i < this.state.mark.length; i++) {
        //    restorePoint = this.state.mark[i];
        //    console.log(this.state.selectedRestoreTime);
        //    if (restorePoint.restoreDate.includes(this.state.selectedRestoreTime)) {
        //        this.setState({
        //            entraRestorePoint: restorePoint
        //        });
        //       // fileName = restorePoint.fileName;
        //        break;
        //    }
        //}

        Axios.get(API_ROUTE + Routes.GET_ENTRA_BACKUP_LOGS + '/' + orgName, config)
            .then((response) => {
                this.overlayProgress(false);
                let results = JSON.parse(response.data);
                results = results.filter(item => item.startTime.includes(filterDate) && item.diffCount !== '0');
                if (results.length > 0) {
                    this.setState({
                        aadviewItems: results,
                        aadnoResults: false,
                        activeAccordion: "2",
                        isAADRestoreTableLoaded: true
                    })
                }
            })
            .catch((reason) => {
                this.overlayProgress(false);
                this.setState({
                    inProgressRight: false,
                    isAADRestoreTableLoaded: true
                });
            });
    }
    deleteQuery(event, query) {
        event.preventDefault();
        var array = this.state.searchQueries;
        var index = array.indexOf(query);
        if (index !== -1) {
            array.splice(index, 1);
        }
        this.setState({
            searchQueries: array,
        });
    }

    openSearchPanel(event) {
        event.preventDefault();
        this.setState({
            searchPanelOpen: !this.state.searchPanelOpen,
        });
    }

    openSearchHistoryPanel(event) {
        event.preventDefault();
        this.setState({
            searchHistoryPanelOpen: !this.state.searchHistoryPanelOpen,
        });
    }

    clearFolder(event) {
        event.preventDefault();
        var item = event.target;
        this.setState({
            selectedFolder: null,
            viewItems: [],
            oldItems: [],
            noResults: false,
            selectedItems: [],
            expandedFolders: [],
        });
    }

    handleFilter(items) {
        /*return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;*/
        // };
    };

    render() {
        //true = disable
        let isAddButtonDisabled = false
        let dropdownsCheck = this.state.searchType !== "----" && this.state.searchType !== "null" && this.state.searchField !== "----" && this.state.searchField !== "null" && this.state.searchQueryType !== "----" && this.state.searchQueryType !== "null" && this.state.searchQueryType;
        if (this.state.isBetweenCondition) {
            isAddButtonDisabled = dropdownsCheck && this.state.searchTerm.trim() !== "";
        }
        else if (this.state.searchTermDateVisible) {
            isAddButtonDisabled = (dropdownsCheck && this.state.searchTermDate !== null) ||
                (dropdownsCheck && ['today', 'yesterday', 'tomorrow', 'last7Days', 'next7Days',
                'last Week', 'this Week', 'next Week', 'lastMonth', 'nextMonth', 'thisMonth', 'lastYear', 'nextYear', 'thisYear'].includes(this.state.selectedCondition));
        }
        else {
            isAddButtonDisabled = dropdownsCheck && this.state.searchTerm.trim() !== "" && this.state.searchTerm.trim() !== "---";
        }
        isAddButtonDisabled = !isAddButtonDisabled;
        
        //if (!this.state.gotRestoreDates) {
        //    return <React.Fragment>
        //        <div>Please wait..</div>
        //    </React.Fragment>;
        //} else 
        if (this.state.checkRestoreDates && this.state.noRestoreDates) {
            return (
                <React.Fragment>

                    <NavBar
                        page="restore"
                        impersonating={this.state.impersonating}
                        orgName={this.state.orgName}
                        isAzureGuardianOnly={this.state.GuardianOnlyEnabled}
                        authContext={this.props.authContext || this.context}
                    />
                    <div className="row">
                        {/* <div
                            className="col"
                            style={{ minWidth: "385px", maxWidth: "400px" }}
                        >
                            <div className="card">
                                <div className="card-header">Error</div>
                            </div>
                        </div>*/}
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    There are currently no restore points available for your organisation. Please wait for your first backup job to complete or contact support if you believe you have a problem.                                </div>
                            </div>

                        </div>

                    </div>

                </React.Fragment>
            );
        }
        let buttonDisabled =
            this.state.inProgressLeft ||
            this.state.inProgressRight ||
            this.state.inProgressRestore;
        let select = null;
        let onSelectChange = (item) => {
        }
        let selectOptions = [];
        let tree = null;
        let treeItems = [];
        let refreshIcon = [];
        let confirmationModal = [];
        let confirmationModalContent = [];

        if (this.state.restoreType == "vesp") {
            confirmationModalContent = (
                <div className="card">
                    <div className="card-body">
                        <p>Resetting your cache may take some time, Are you sure?</p>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col" />
                            <div className="col">
                                <Button onClick={() => {
                                    selectOptions = [];
                                    this.setState({
                                        //dropdownOptions:[],
                                        clearCacheChecked: true,
                                        showConfirmationModal: false,
                                    });
                                    this.getSelectItems();
                                }}>
                                    Ok
                                </Button>

                            </div>
                            <div className="col" >

                                <Button onClick={() => {
                                    this.setState({
                                        showConfirmationModal: false
                                    });
                                }}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="col" />
                        </div>
                    </div>
                </div>
            );
            confirmationModal = (
                <Modal isOpen={this.state.showConfirmationModal}>
                    {confirmationModalContent}
                </Modal>
            );

            refreshIcon = (<a id="ContentPlaceHolder1_btnResetCache" onClick={() => {
                this.setState({
                    showConfirmationModal: true
                });

            }}
            ><i className="fa fa-refresh"></i>&nbsp; </a>);
        }
        if (this.state.restoreType === 'aad') {

        }

        if (this.state.restore) {
            if (this.state.selectItems.length > 0) {
                for (var i = 0; i < this.state.selectItems.length; i++) {
                    const item = this.state.selectItems[i];
                    selectOptions.push({ name: item.title, value: item.id });
                }
                select = (
                    <FormGroup>
                        <Label for="itemSelect">Select item to restore from.&emsp;&emsp;&emsp;&emsp;&emsp;</Label>
                        {refreshIcon}
                        <SelectSearch
                            options={this.state.showDropdown ? selectOptions : []}
                            placeholder="search"
                            filterOptions={fuzzySearch}
                            style={{ whiteSpace: "nowrap" }}
                            value={this.state.selectItem ? this.state.selectItem.id : ""}
                            search
                            onChange={this.selectItem}
                        />
                    </FormGroup>
                );
                if (this.state.treeItems.length > 0) {
                    for (var i = 0; i < this.state.treeItems.length; i++) {
                        const folder = this.state.treeItems[i];
                        let treeItem = this.createTreeItem(folder, i);
                        treeItems.push(treeItem);
                    }
                }
            }
            let treeInner = null;
            if (this.state.selectItem) {
                let label = "";
                if (this.state.restoreType == "vex") {
                    label = "Mailbox";
                }
                if (this.state.restoreType == "vesp") {
                    label = "Sites";
                } else if (this.state.restoreType == "veod") {
                    label = "Drive";
                } else if (this.state.restoreType == "vet") {
                    label = "Team";
                }
                let buttonDisabled =
                    this.state.inProgressLeft ||
                    this.state.inProgressRight ||
                    this.state.inProgressRestore;
                if (this.state.restoreType == "veod") {
                    treeInner = <React.Fragment>{treeItems}</React.Fragment>;
                } else {
                    treeInner = (
                        <TreeItem
                            key={"root"}
                            nodeId={"root"}
                            label={label}
                            onClick={e => this.folderClick(e, null, null, true)}
                            disabled={buttonDisabled}
                        >
                            {treeItems}
                        </TreeItem>
                    );
                }
            }
            tree = (
                <TreeView
                    expanded={this.state.expandedFolders}
                    style={{ textAlign: "left" }}
                    disableSelection={buttonDisabled}
                >
                    {select}
                    {treeInner}
                </TreeView>
            );
        }
        let noItemsWarning;
        if (this.state.gotSelectItems && this.state.selectItems.length == 0) {
            noItemsWarning = (
                <div className="row">
                    <div className="col">
                        <p>Could not retreive any items you can restore.</p>
                    </div>
                </div>
            );
        }
        let leftSpinner;
        if (this.state.inProgressLeft) {
            leftSpinner = (
                <div className="d-flex justify-content-center">
                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                </div>
            );
        }

        let leftSection = (
            <React.Fragment>
                {tree}
                {leftSpinner}
            </React.Fragment>
        );



        if (this.state.restoreType === "aad") {
            leftSection = (
                <React.Fragment>
                    <Calendar style={{ height: 500 }}
                        onChange={this.onChange}
                        value={this.state.date}

                        tileClassName={({ date, view }) => {
                            for (var i = 0; i < this.state.mark.length; i++) {
                                var x = this.state.mark[i].restoreDate.split(' ')[0];
                                if (x === moment(date).format("DD-MM-YYYY")) {
                                    return 'highlight';
                                }
                            }

                        }}
                    >
                    </Calendar>

                    {!this.state.aadnoResults && (
                        <div>
                            <Table border="1" className="mt-3">
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Date</th>
                                        <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Process</th>
                                        <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Diff</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '13px' }}>
                                    {this.state.aadviewItems.map((item, index) => (
                                        <tr key={index}
                                            onClick={() => this.getAADBackupConfigData(item.diffFile, this.state.orgName, item)} style={{ cursor: 'pointer' }}>
                                            <td>{item.startTime}</td>
                                            <td>{item.process}</td>
                                            <td>{item.diffCount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </React.Fragment>
            )
        }
        let rightSection;
        if (this.state.viewItems.length === 0 && this.state.selectItems.length === 0 && this.state.inProgressRight === false && !this.state.isAzureGuardianOnly) {

            rightSection = (
                <Collapse isOpen={!this.state.searchHistoryPanelOpen}>
                    <div className="ps-3">
                        <p className="titletext" style={{ fontWeight: '500', fontSize: '25px', textAlign: "center" }}> CloudCover 365 </p>

                        <p className="mb-3">Restore all Microsoft 365 data from the CloudCover 365 recovery screen, including Exchange, SharePoint, OneDrive, Teams and Azure (if it forms part of your package).</p>
                        <p className="mb-3" style={{ fontWeight: 'bold' }}>How to use the recovery tool:</p>

                        <ol>
                            <li className="mb-2" style={{ display: 'list-item' }}>Select the restoration type.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>Add the reason for the restoration.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>Select a restoration date from the calendar, or via the &lsquo;Show all restore points &lsquo; option.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>Choose the item type you wish to restore.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>Select the required data and start the restoration process.</li>
                        </ol>


                        <p className="mb-3" style={{ fontWeight: 'bold' }}>Tips:</p>

                        <ul>
                            <li className="mb-2" style={{ display: 'list-item' }}>If you need to restore a file that was last seen two weeks ago, select a restore point before this date.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>To restore a tree item, highlight it and use the &lsquo;restore folders&lsquo; menu. This will show you your tree restoration options.</li>
                            <li className="mb-2" style={{ display: 'list-item' }}>To restore items with tree nodes (e.g. Mail items), simply select them with the tick box and then use the &lsquo;restore items&lsquo; menu. This will show you your item restore options.</li>

                        </ul>

                        <p className="mb-3">Please note that some items may take a while to download.</p>

                    </div>
                </Collapse>

            );


        }
        const style = {
            width: '99%',
            height: '100%'
        };
        const defaultColDef = {
            editable: false,
            sortable: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
            minWidth: 100,
            filter: false,
            resizable: true
        };
        let result;
        if (this.state.viewItems.length > 0) {
            let tableInner = this.getTable(this.state.viewItems);

            let table = (
                <div style={{ maxHeight: "1300px" }}>
                    {/* {agGridValue} overflowY: "scroll", */}
                    {/* <Table style={{ textAlign: "center" }} striped>
                        {tableInner}
                    </Table> */}



                    <div className="ag-theme-alpine" id='restore-grid' style={{ borderColor: "#F0F0F0", width: "100%", borderStyle: "Solid", maxHeight: '1300px', borderCollapse: "collapse", height: this.state.selectedRestoreType ==="Azure Backup"?"710px":"500px"  }}>
                        <AgGridReact style={style}
                            columnDefs={this.state.columnDefs}
                            defaultColDef={defaultColDef}
                            getRowStyle={this.getRowStyle}
                            onGridReady={this.onGridReady}
                            rowSelection={'multiple'}
                            enableCellTextSelection="true"
                            onRowSelected={this.OnItemSelected}
                            //autoSizeStrategy={autoSizeStrategy}
                            autoHeight={true}
                            pagination={true}
                            selectionChanged={this.OnselectionChanged}
                            paginationPageSize={100}
                            overlayLoadingTemplate={
                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:50px;"></div>`}
                            overlayNoRowsTemplate={'<span className="ag-overlay-loading-center">No rows to display</span>'}
                            suppressScrollOnNewData={true}>
                        </AgGridReact>
                    </div>
                </div>
            );

            result = (
                <React.Fragment>
                    {table}
                    <div
                        className="row"
                        style={{
                            minWidth: "100%",
                        }}
                    >
                    </div>

                </React.Fragment>
            );
        }
        let restoreButtons = [];
        if (this.state.restore) {
            if (this.state.selectItem) {
                let type = "root";
                let pstButton = null;
                let pstCommand;
                let restoreToOrignalCommand = "restore";
                if (this.state.restoreType == "vesp") {
                    restoreToOrignalCommand = "restore";
                }
                if (this.state.restoreType == "vet") {
                    restoreToOrignalCommand = "restore";
                }
                if (this.state.restoreType == "vex") {
                    pstCommand = "exporttoPst";
                    pstButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, pstCommand, type)}
                        >
                            Restore to Local PST
                        </DropdownItem>
                    );
                }
                let rootRestoreString = "Root Restore";
                if (this.state.restoreType == "vet") {
                    rootRestoreString = "Team Restore";
                }
                let rootRestore = (
                    <Dropdown
                        key="root"
                        value="rootRestoreOpen"
                        isOpen={this.state.rootRestoreOpen}
                        toggle={(e) => this.toggleDropdown(e, "rootRestoreOpen")}
                        className="me-2"
                    >
                        <DropdownToggle caret style={{ maxWidth: "150px" }}>
                            {rootRestoreString}
                        </DropdownToggle>
                        <DropdownMenu>
                            {!this.state.isEndUser ? (< DropdownItem
                                onClick={(e) =>
                                    this.openVerificationModal(e, restoreToOrignalCommand, type)
                                }
                            >
                                Restore to Original Location
                            </DropdownItem>) : < DropdownItem disabled="true">Restore to Original Location</DropdownItem>}
                            {this.state.restoreType == "vesp" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {
                                            this.setState(
                                                {
                                                    openSharepointModal: true,
                                                    type: type

                                                },
                                                () => {
                                                    
                                                }
                                            );
                                           
                                        }
                                    }}
                                >
                                   
                                    Restore to Another Sharepoint Site
                                </DropdownItem>
                            }
                            {this.state.restoreType == "veod" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {

                                            this.setState(
                                                {
                                                    showoneDriveModal: true,
                                                    type: type

                                                },
                                                () => {

                                                }
                                            );

                                        }
                                    }}
                                >
                                    Copy to Another OneDrive Folder
                                </DropdownItem>
                            }
                            {pstButton}
                        </DropdownMenu>
                    </Dropdown>
                );
                if (!this.state.searchResultsFlag) {
                    restoreButtons.push(rootRestore);
                }
            }
            if (
                (this.state.restoreType == "vet" && this.state.lastClicked) ||
                (this.state.selectedFolder &&
                    !this.state.selectedFolder.isSharepointTopLevel &&
                    !this.state.selectedFolder.isOnedriveTopLevel)
            ) {
                let type = "folder";
                let pstCommand = "exporttoPst";
                let zipCommand = "save";
                let mailCommand = "send";
                let restoreToOrignalCommand = "restore";
                let pstButton;
                let zipButton;
                let mailButton;
                if (this.state.restoreType == "vex") {
                    restoreToOrignalCommand = "restore";
                    pstCommand = "exporttoPst";
                    pstButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, pstCommand, type)}
                        >
                            Restore to Local PST
                        </DropdownItem>
                    );
                } else if (this.state.restoreType == "vesp") {
                    restoreToOrignalCommand = "restore";
                } else if (this.state.restoreType == "veod") {
                    zipButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, zipCommand, type)}
                        >
                            Restore to Local Zip
                        </DropdownItem>
                    );
                    mailButton = (
                        <DropdownItem
                            onClick={(e) => this.openMailModal(e, mailCommand, type)}
                        >
                            Mail To Someone
                        </DropdownItem>
                    );

                }
                let folderRestoreString = "Folder Restore";
                if (this.state.restoreType == "vet") {
                    folderRestoreString = "Channel Restore";
                }
                let folderRestore = (
                    <Dropdown
                        key="folder"
                        value="folderRestoreOpen"
                        isOpen={this.state.folderRestoreOpen}
                        toggle={(e) => this.toggleDropdown(e, "folderRestoreOpen")}
                        className="me-2"
                    >
                        <DropdownToggle caret style={{ maxWidth: "150px" }}>
                            {folderRestoreString}
                        </DropdownToggle>
                        <DropdownMenu>
                            {!this.state.isEndUser ? (< DropdownItem
                                onClick={(e) =>
                                    this.openVerificationModal(e, restoreToOrignalCommand, type)
                                }
                            >
                                Restore to Original Location
                            </DropdownItem>) : < DropdownItem disabled="true">Restore to Original Location</DropdownItem>}

                            {this.state.restoreType == "vesp" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {
                                            this.setState(
                                                {
                                                    openSharepointModal: true,
                                                    type: type
                                                },
                                                () => {

                                                }
                                            );

                                        }
                                    }}
                                >
                                    Restore to Another Sharepoint Site
                                </DropdownItem>
                            }


                            {this.state.restoreType == "veod" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {
                                            this.setState(
                                                {
                                                    showoneDriveModal: true,
                                                    type: type

                                                },
                                                () => {

                                                }
                                            );

                                        }
                                    }}
                                >
                                    Copy to Another OneDrive Folder
                                </DropdownItem>
                            }
                            {pstButton}
                            {zipButton}
                            {this.state.restoreMailTo !== "0" && mailButton}
                        </DropdownMenu>
                    </Dropdown>
                );
                if (!this.state.searchResultsFlag) {
                    restoreButtons.push(folderRestore);
                }
            }
            if (this.state.selectedItems.length > 0) {
                let type = "item";
                let restoreToOrignalCommand = "";
                let mailToCommand = "";
                let mailToOwnerCommand = "";
                let pstCommand = "";
                let zipCommand = "";
                let localPstButton = null;
                let localZipButton = null;
                let mailToOwnerButton = null;
                let mailToSomeoneButton = null;
                if (this.state.restoreType == "vex") {
                    restoreToOrignalCommand = "restore";
                    mailToCommand = "sendtoDifferentaddress";
                    mailToOwnerCommand = "sendtoDefaultaddress";
                    pstCommand = "exporttoPst";
                    zipCommand = "save";
                    localPstButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, pstCommand, type)}
                        >
                            Restore to Local PST
                        </DropdownItem>
                    );
                    mailToOwnerButton = (
                        <DropdownItem
                            onClick={(e) => this.openMailModal(e, mailToOwnerCommand, type)}
                        >
                            Mail to Owner
                        </DropdownItem>
                    );
                    mailToSomeoneButton = (
                        <DropdownItem
                            onClick={(e) => this.openMailModal(e, mailToCommand, type)}
                        >
                            Mail to Someone
                        </DropdownItem>
                    );
                    localZipButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, zipCommand, type)}
                        >
                            Restore to Local Zip
                        </DropdownItem>
                    );
                } else if (this.state.restoreType == "vesp") {
                    restoreToOrignalCommand = "restore";
                    mailToCommand = "send";
                    mailToOwnerCommand = "MailtoSource";
                    zipCommand = "save";
                    if (this.state.lastClicked == "Libraries") {
                        localZipButton = (
                            <DropdownItem
                                onClick={(e) => this.openDownloadModal(e, zipCommand, type)}
                            >
                                Restore to Local Zip
                            </DropdownItem>
                        );
                        mailToSomeoneButton = (
                            <DropdownItem
                                onClick={(e) => this.openMailModal(e, mailToCommand, type)}
                            >
                                Mail to Someone
                            </DropdownItem>
                        );
                    }
                } else if (this.state.restoreType == "veod") {
                    restoreToOrignalCommand = "restore";
                    mailToCommand = "send";
                    mailToOwnerCommand = "MailtoSource";
                    zipCommand = "save";
                    localZipButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, zipCommand, type)}
                        >
                            Restore to Local Zip
                        </DropdownItem>
                    );
                    mailToSomeoneButton = (
                        <DropdownItem
                            onClick={(e) => this.openMailModal(e, mailToCommand, type)}
                        >
                            Mail to Someone
                        </DropdownItem>
                    );
                } else if (this.state.restoreType == "vet") {
                    restoreToOrignalCommand = "restore";
                    mailToCommand = "send";
                    mailToOwnerCommand = "MailtoSource";
                    zipCommand = this.state.selectedFolder.title == "Files" ? "save" : "export";
                    let ddtext = this.state.selectedFolder.title == "Files" ? "Restore to Local Zip" : "Restore to Local HTML File";
                    localZipButton = (
                        <DropdownItem
                            onClick={(e) => this.openDownloadModal(e, zipCommand, type)}
                        >
                            {ddtext}                        </DropdownItem>
                    );
                    mailToSomeoneButton = (
                        <DropdownItem
                            onClick={(e) => this.openMailModal(e, mailToCommand, type)}
                        >
                            Mail to Someone
                        </DropdownItem>
                    );
                }
                if (this.state.restoreType == "vet") {
                    if (this.state.selectedFolder) {
                        if (this.state.selectedFolder.title == "Tabs") {
                            localPstButton = null;
                            localZipButton = null;
                            mailToOwnerButton = null;
                            mailToSomeoneButton = null;
                        }
                        if (this.state.selectedFolder.title == "Posts") {
                            localPstButton = null;
                            mailToOwnerButton = null;
                            mailToSomeoneButton = null;
                        }
                    }
                }
                let itemRestore = (
                    <Dropdown
                        key="item"
                        value="itemRestoreOpen"
                        isOpen={this.state.itemRestoreOpen}
                        toggle={(e) => this.toggleDropdown(e, "itemRestoreOpen")}
                    >
                        <DropdownToggle caret style={{ maxWidth: "150px" }}>
                            Item Restore
                        </DropdownToggle>
                        <DropdownMenu>
                            {!this.state.isEndUser ? (< DropdownItem
                                onClick={(e) =>
                                    this.openVerificationModal(e, restoreToOrignalCommand, type)
                                }
                            >
                                Restore to Original Location
                            </DropdownItem>) : < DropdownItem disabled="true">Restore to Original Location</DropdownItem>}


                            {this.state.restoreType == "vesp" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {
                                            this.setState(
                                                {
                                                    openSharepointModal: true,
                                                    type: type
                                                },
                                                () => {

                                                }
                                            );

                                        }
                                    }}
                                >
                                    Restore to Another Sharepoint Site
                                </DropdownItem>
                            }


                            {this.state.restoreType == "veod" &&
                                <DropdownItem
                                    disabled={this.state.isEndUser}
                                    onClick={(e) => {
                                        if (!this.state.isEndUser) {
                                            this.setState(
                                                {
                                                    showoneDriveModal: true,
                                                    type: type
                                                },
                                                () => {

                                                }
                                            );

                                        }
                                    }}
                                >
                                    Copy to Another OneDrive Folder
                                </DropdownItem>
                            }
                            {localPstButton}
                            {localZipButton}
                            {mailToOwnerButton}
                            {this.state.restoreMailTo !== "0" && mailToSomeoneButton}
                        </DropdownMenu>
                    </Dropdown>
                );
                restoreButtons.push(itemRestore);
            }
        }


        


        if (this.state.searchResultsFlag && !this.state.selectItem && this.state.selectedItems.length === 0) {
            restoreButtons = [];
        }
        let verificationModal = null;
        let mailModal = null;
        let restoreModal = null;
        let restoreAAD = (
            <div>
                <Button key="RestoreAAD" onClick={this.onClickAADRestore}>
                    Restore
                </Button>
            </div>
        )
        if (this.state.restoreType === 'aad' && this.state.selectedItems.length > 0) {
            restoreButtons.push(restoreAAD);
        }
       
        let siteModal = null;
        let oneDriveModal = null;
                siteModal = (
                    <Modal isOpen={this.state.openSharepointModal}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <p>Sharepoint Site :</p>
                                        <select className='form-select'
                                            value={this.state.siteURL}
                                            name="siteURL"
                                            onChange={this.editParameter}
                                        >
                                            <option value={"null"}>----</option>
                                            {this.state.selectItems.map(site => (
                                                <option key={site.email} value={site.email}>
                                                    {site.title}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col">
                                        <Button onClick={this.sharepointRestoreToContinue} color="primary">
                                            Continue
                                        </Button>
                                    </div>
                                    <div className="col">
                                        <Button onClick={this.closesharepointRestoreToModal}>Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );

            
       oneDriveModal = (
            <Modal isOpen={this.state.showoneDriveModal}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                               <p>Destination OneDrive:</p>
                                <select className='form-select'
                                   value={this.state.destinationOneDriveUrl}
                                   name="destinationOneDriveUrl"
                                    onChange={this.editParameter}
                               >
                                   <option value={"null"}>----</option>
                                    {this.state.selectItems.map(oneDrive => (
                                        <option key={oneDrive.email} value={oneDrive.email}>
                                            {oneDrive.title}
                                        </option>
                                    ))}

                                </select>
                           </div>
                           <div className="col">
                               <p>Destination OneDrive Folder:</p>
                               <input
                                   type="text"
                                   value={this.state.destinationFolder}
                                   placeholder="Destination Folder"
                                   name="destinationFolder"
                                   style={{ width: "80%" }}
                                   onChange={this.editParameter}
                               />
                              
                           </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col">
                                <Button onClick={this.oneDriveRestoreToContinue} color="primary">
                                    Continue
                                </Button>
                            </div>
                            <div className="col">
                                <Button onClick={this.closeOneDriveRestoreToModal}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );

        if (this.state.restoreModalOpen) {
            let restoreModalContent = null;
            if (this.state.restoring) {
                restoreModalContent = (
                    <div className="card">
                        <div className="card-body">
                            <p>Please wait while we prepare your restore.</p>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex justify-content-center">
                                <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                            </div>
                        </div>
                    </div>
                );
            } else {
                let restoreMessage;
                let restoreMessages = [];
                let issueMessages = [];
                if (this.state.restoreSuccess) {
                    let result = this.state.restoreSuccess.result;
                    for (const item in result) {
                        const value = result[item];
                        if (item !== "restoreIssues") {
                            if (value) {
                                let messageObject = "";
                                if (item.toLocaleLowerCase() === "skippeditemscount") {
                                    messageObject = (
                                        <p key={item.toString()}>
                                            Skipped Items (already exist in original location): {value}
                                        </p>
                                    );
                                }
                                else {
                                    messageObject = (
                                        <p key={item.toString()}>
                                            {lodash.startCase(item)}: {value}
                                        </p>
                                    );
                                }
                                restoreMessages.push(messageObject);
                            }
                        } else {
                            if (value) {
                                for (var i = 0; i < value.length; i++) {
                                    const issue = value[i];
                                    let warningsItem = [];
                                    if (issue.warnings) {
                                        let warnings = [];
                                        for (var w = 0; w < issue.warnings.length; w++) {
                                            const warning = issue.warnings[w];
                                            var warningObject = <li key={warning}>{warning}</li>;
                                            warnings.push(warningObject);
                                        }
                                        warningsItem = <ul>{warnings}</ul>;
                                        if (issue.warnings.length > 0) {
                                            let messageObject = (
                                                <div key={item.toString() + issue.title}>
                                                    Warnings for {issue.title}: {warningsItem}
                                                </div>
                                            );
                                            restoreMessages.push(messageObject);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (!result.message) {
                        restoreMessage = <div>{restoreMessages}</div>;
                        restoreModalContent = (
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <p>Restore completed.</p>
                                    </div>
                                    <div className="row">{restoreMessage}</div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col" />
                                        <div className="col">
                                            <Button onClick={this.closeRestoreModal}>Ok</Button>
                                        </div>
                                        <div className="col" />
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        restoreMessage = <p>{result.message}</p>;
                        restoreModalContent = (
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <p>Error.</p>
                                    </div>
                                    <div className="row">{restoreMessage}</div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col" />
                                        <div className="col">
                                            <Button onClick={this.closeRestoreModal}>Ok</Button>
                                        </div>
                                        <div className="col" />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            }
            if (this.state.restoreModalOpen) {
                restoreModal = (
                    <Modal isOpen={this.state.restoreModalOpen}>
                        {restoreModalContent}
                    </Modal>
                );
            }
        }        

        let entraModal = null;
        if (this.state.entraModalOpen) {
            let entraModalContent = (
                <div className="card">
                    <div className="card-body">
                        <p>{this.state.entraRestoreMessage}</p>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col" />
                            <div className="d-flex align-items-center">
                                <Button onClick={this.closeDownloadModal}>
                                    Ok
                                </Button>
                            </div>
                            <div className="col" />
                        </div>
                    </div>
                </div>
            );
            if (this.state.entraModalOpen) {
                entraModal = (
                    <Modal isOpen={this.state.entraModalOpen}>
                        {entraModalContent}
                    </Modal>
                );
            }
        }


        let downloadModal = null;
        if (this.state.downloadModalOpen) {
            let downloadingModalContent = (
                <div className="card">
                    <div className="card-body">
                        <p>The requested file(s) will be available in the download section once it is ready.</p>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col" />
                            <div className="d-flex align-items-center">
                                <Button onClick={this.closeDownloadModal}>
                                    Ok
                                </Button>
                            </div>
                            <div className="col" />
                        </div>
                    </div>
                </div>
            );            
            if (this.state.downloadModalOpen) {
                downloadModal = (
                    <Modal isOpen={this.state.downloadModalOpen}>
                        {downloadingModalContent}
                    </Modal>
                );
            }
        }
        let waitForDeviceCode = [];
        if (this.state.ingettingDeviceCode) {
            waitForDeviceCode = (
                <div className="d-flex justify-content-center">
                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                </div>
            );
        } else {
            waitForDeviceCode = (<div>
                <input type="text" value={this.state.deviceCode} name="deviceCode" id="deviceCode" disabled />
                <CopyToClipboard
                    text={this.state.deviceCode}
                    onCopy={() => {
                        this.setState({
                            copied: true,
                            disableBtn: false
                        });
                        window.open("https://microsoft.com/devicelogin", '_blank', 'noopener,noreferrer');
                    }
                    }
                >
                    <button style={{ color: "grey" }} className="btn">
                        Copy
                    </button>


                </CopyToClipboard>

            </div>);
        }

        if (this.state.verificationModalOpen) {
            if (this.state.isMFA) {
                // if (this.state.deviceCode != "")
                {
                    verificationModal = (
                        <Modal isOpen={this.state.verificationModalOpen}>
                            <div className="card">
                                <div className="card-body">
                                    <p>To restore, authenticate with the code below at:</p>
                                    <a href="https://microsoft.com/devicelogin" target="_blank">
                                        https://microsoft.com/devicelogin
                                    </a>
                                    <p>
                                        Please copy below code to clipboard before following above
                                        URL.
                                    </p>
                                    <p>
                                        When you have done this please click the continue button
                                        below.
                                    </p>

                                    {waitForDeviceCode}
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col">
                                            <Button onClick={this.buildRestore} color="primary" disabled={this.state.disableBtn}>
                                                Continue
                                            </Button>
                                        </div>
                                        <div className="col">
                                            <Button onClick={this.closeVerificationModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    );
                }
            } else {
                verificationModal = (
                    <Modal isOpen={this.state.verificationModalOpen}>
                        <React.Fragment>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <p>
                                            To restore, please provide us with your username and
                                            password. This will not be stored.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div style={{ width: "20%" }}>Email</div>
                                            <input
                                                type="text"
                                                value={this.state.username}
                                                placeholder="Email"
                                                name="username"
                                                style={{ width: "80%" }}
                                                onChange={this.editParameter}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div style={{ width: "20%" }}>Password</div>
                                            <input
                                                type="password"
                                                value={this.state.password}
                                                name="password"
                                                style={{ width: "80%" }}
                                                onChange={this.editParameter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col">
                                            <Button
                                                className="ml-3"
                                                onClick={this.buildRestore}
                                                color="primary"
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                        <div className="col">
                                            <Button onClick={this.closeVerificationModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Modal>
                );
            }
        }
       
        if (this.state.mailModalOpen) {
            mailModal = (
                <Modal isOpen={this.state.mailModalOpen}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <p>To:</p>
                                    {this.state.restoreMailTo === "2" &&
                                        <input
                                            type="text"
                                            value={this.state.mailSendTo}
                                            name="mailSendTo"
                                            onChange={this.editParameter}
                                        />}
                                    {this.state.restoreMailTo === "1" &&
                                        <select className='form-select'
                                            value={this.state.mailSendTo}
                                            name="mailSendTo"
                                            onChange={this.editParameter}
                                        >
                                            <option value="" disabled>
                                                ---------
                                            </option>
                                            {this.state.usersList.map(user => (
                                                <option key={user.name} value={user.name}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </select>}
                                </div>
                                <div className="col">
                                    <p>Subject:</p>
                                    <input
                                        type="text"
                                        value={this.state.mailSubject}
                                        name="mailSubject"
                                        onChange={this.editParameter}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p>Message:</p>
                                    <textarea
                                        type="text"
                                        style={{
                                            width: "100%",
                                            resize: "vertical",
                                            maxHeight: "150px",
                                            minHeight: "150px",
                                        }}
                                        value={this.state.mailMessage}
                                        name="mailMessage"
                                        onChange={this.editParameter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col">
                                    <Button onClick={this.mailContinue} color={this.state.mailSendTo === ''?"#666":"primary"} disabled={this.state.mailSendTo===''}>
                                        Continue
                                    </Button>
                                </div>
                                <div className="col">
                                    <Button onClick={this.closeMailModal}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }
        if (
            this.state.viewItems.length == 0 && this.state.activeAccordion === '0' &&
            !this.state.inProgressRight &&
            !this.state.restore 
        ) {
            rightSection = (
                <div className="ps-3">
                    <p className="titletext" style={{ fontWeight: '500', fontSize: '25px', textAlign: "center" }}> CloudCover 365 </p>

                    <p className="mb-3">Restore all Microsoft 365 data from the CloudCover 365 recovery screen, including Exchange, SharePoint, OneDrive, Teams and Azure (if it forms part of your package).</p>
                    <p className="mb-3" style={{ fontWeight: 'bold' }}>How to use the recovery tool:</p>

                    <ol>
                        <li className="mb-2" style={{ display: 'list-item' }}>Select the restoration type.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>Add the reason for the restoration.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>Select a restoration date from the calendar, or via the &lsquo;show all restore points&lsquo; option.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>Choose the item type you wish to restore.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>Select the required data and start the restoration process.</li>
                    </ol>


                    <p className="mb-3" style={{ fontWeight: 'bold' }}>Tips:</p>

                    <ul>
                        <li className="mb-2" style={{ display: 'list-item' }}>If you need to restore a file that was last seen two weeks ago, select a restore point before this date.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>To restore a tree item, highlight it and use the &lsquo;restore folders&lsquo; menu. This will show you your tree restoration options.</li>
                        <li className="mb-2" style={{ display: 'list-item' }}>To restore items with tree nodes (e.g. Mail items), simply select them with the tick box and then use the &lsquo;restore items&lsquo; menu. This will show you your item restore options.</li>

                    </ul>

                    <p className="mb-3">Please note that some items may take a while to download.</p>

                </div>
            );
        }
        let searchBar = null;
        if ((this.state.restoreType == "vet" && (this.state.selectItem) && (this.state.restore) && this.state.selectedRestoreType !== "Azure Backup") ||
            ((this.state.restore) && this.state.selectedRestoreType !== "Azure Backup" && this.state.restoreType !== "vet")) {
            let searchTypeOptions = [];
            let searchFieldOptions = [];
            for (var i = 0; i < this.state.searchFilters.length; i++) {
                const filter = this.state.searchFilters[i];
                var filterOption = (
                    <option key={i} value={filter.label}>
                        {filter.label}
                    </option>
                );
                searchTypeOptions.push(filterOption);
            }
            for (var j = 0; j < this.state.searchFilters.length; j++) {
                const option = this.state.searchFilters[j];
                if (option.label == this.state.searchType) {
                    for (var k = 0; k < option.fields.length; k++) {
                        const field = option.fields[k];
                        var filterOption = (
                            <option key={"field" + k.toString()} value={field.shortcut} type={field.conditionType}>
                                {field.label}
                            </option>
                        );
                        searchFieldOptions.push(filterOption);
                    }
                }
            }
            let queries = [];
            for (var q = 0; q < this.state.searchQueries.length; q++) {
                const query = this.state.searchQueries[q];
                // Split the Field by commas and take the first one
                let fields = query.Field.split(',');
                let field = fields[0].trim(); // Use only the first field value
                var queryLine = (
                    <tr key={q}>
                        <td className="pb-0 pt-0">{lodash.startCase(query.Type)} </td>
                        <td className="pb-0 pt-0">{lodash.startCase(field)}</td>
                        <td className="pb-0 pt-0">{lodash.startCase(query.Inclusion)}</td>
                        <td className="pb-0 pt-0">{query.Term}</td>
                        <td className="pb-0 pt-0">
                            <Button
                                className="btn-sm"
                                onClick={(e) => this.deleteQuery(e, query)}
                            >
                                X
                            </Button>
                        </td>
                    </tr>
                );
                queries.push(queryLine);
            }
            let tableBody;
            if (queries.length > 0) {
                tableBody = <tbody>{queries}</tbody>;
            }
            let queryTable = (
                <div
                    className="col-12"
                    style={{ overflowY: "auto", height: "150px" }}
                >
                    <Table>
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Field</th>
                                <th scope="col">Condition</th>
                                <th scope="col">Search</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {tableBody}
                    </Table>
                </div>
            );
            let searchButtonIcon = <i className="fas fa-chevron-down" />;
            if (this.state.selectItems.length > 0 && this.state.activeAccordion === "3") {
                let searchString = "";
                let searchDisabled = true;
                if (!this.state.selectItems.length > 0 && this.state.searchQueries) {

                }
                else {
                     searchString = "- All";
                    if (this.state.restoreType == "vex") {
                        searchString += " Mailboxes";
                    } else if (this.state.restoreType == "vesp") {
                        searchString += " Sites";
                    } else if (this.state.restoreType == "veod") {
                        searchString += " OneDrives";
                    } else if (this.state.restoreType == "vet") {
                        searchString += " Teams";
                    }
                    if (this.state.selectItem) {
                        if (this.state.selectItem.email === null) {
                            searchString = "- " + this.state.selectItem.title;
                        }
                        else if (this.state.restoreType === "vex" || this.state.restoreType === "vet") {
                            searchString = "- " + this.state.selectItem.title + " - " + this.state.selectItem.email;
                        }
                        else {
                            searchString = "- " + this.state.selectItem.title + " - " + this.state.selectItem.email.toLowerCase();
                        }
                    }
                    if (this.state.selectedFolder) {
                        searchString = "- " + this.state.selectedFolder.title;
                    }
                   
                    if (this.state.searchQueries.length > 0) {
                        searchDisabled = false;
                    }
                }
                searchBar = (
                    <React.Fragment>
                        <div style={{ backgroundColor: "#5191CE", width: "100%", margin: "auto" }} className="row p-1" onClick={this.openSearchPanel}>
                            <div style={{ color: "white" }} className="col-11 SectionTitle">
                                Search {searchString}
                            </div>
                            <div className="col clearfix">
                                <Button className="float-right" onClick={this.openSearchPanel} style={{ backgroundColor: 'rgb(81, 145, 206)', borderColor: 'rgb(81, 145, 206)', float: 'right' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" color="white" width="20" height="20" fill="currentColor" className={`arrow ${this.state.searchPanelOpen ? 'arrow_rotate bi bi-chevron-down' : 'bi bi-chevron-down'}`} viewBox="0 0 16 16">
                                        <path fill="#FFFFFF" strokeWidth="2"  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </Button>
                            </div>
                           
                        </div>
                        <Collapse isOpen={this.state.searchPanelOpen}>
                            <div className="row" id="searchbody">
                                <div className="col-12">
                                    <div className="row">{queryTable}</div>
                                    <div
                                        style={{ backgroundColor: "#5191CE", color: "white", margin: "auto" }}
                                        className="row align-items-end pb-2"
                                    >
                                        <div className="col mt-2">
                                            <FormGroup>
                                                <Label className="h6" for="searchType">
                                                    Category
                                                </Label>
                                                <Input
                                                    onChange={this.changeSearchType}
                                                    value={this.state.searchType}
                                                    type="select"
                                                    name="searchType"
                                                    id="searchType"
                                                    className="form-select"
                                                >
                                                    <option value={"null"}>----</option>
                                                    {searchTypeOptions}
                                                </Input>
                                            </FormGroup>
                                        </div>
                                        <div className="col">
                                            <FormGroup>
                                                <Label className="h6" for="searchField">
                                                    Field
                                                </Label>
                                                <Input
                                                    onChange={this.changeSearchField}
                                                    value={this.state.searchField}
                                                    type="select"
                                                    name="searchField"
                                                    id="searchField"
                                                    className="form-select"
                                                >
                                                    <option value={"null"}>----</option>
                                                    {searchFieldOptions}
                                                </Input>
                                            </FormGroup>
                                        </div>
                                        <div className="col">
                                            <FormGroup>
                                                <Label className="h6" for="queryType">
                                                    Condition
                                                </Label>
                                                <Input
                                                    onChange={this.changeSearchQueryType}
                                                    value={this.state.searchQueryType}
                                                    type="select"
                                                    name="queryType"
                                                    id="queryType"
                                                    className="form-select"
                                                >
                                                    <option value={"null"}>----</option>
                                                    {this.state.conditionOptions.map((option, index) => (
                                                        <option key={index} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>
                                        <div className="col">
                                            <FormGroup>
                                                <Label className="h6" >Search</Label>
                                                {/*<Label className="h6" for="searchTermDate" id="lblsearchTermDate">Search</Label>*/}
                                                {/*<Label className="h6" for="lblBetweenDates" id="lblBetweenDates">Search</Label>*/}

                                                {/*<DatePicker*/}
                                                {/*    selected={this.state.searchTermDate}*/}
                                                {/*    onChange={(date) => this.handleSearchDate(date)}*/}
                                                {/*    dateFormat="dd/MM/yyyy"*/}
                                                {/*    style={{ maxHeight: "30px" }}*/}
                                                {/*    className="mr-1 datepicker form-control"*/}
                                                {/*    id="searchTermDate"*/}
                                                {/*   // style={{ display: !this.state.isBetweenCondition && this.state.isDateField ? 'block' : 'none' }}*/}
                                                {/*/>*/}
                                                {/* For "between" condition */}
                                                {this.state.isBetweenCondition ? (
                                                    <div id="lblBetweenDates" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                                        <DatePicker
                                                            selected={this.state.dateRange.start}
                                                            onChange={(date) => this.handleBetweenDateChange('start', date)}
                                                            selectsStart
                                                            startDate={this.state.dateRange.start}
                                                            endDate={this.state.dateRange.end}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="mr-1 datepicker form-control"
                                                            placeholderText="Start Date"
                                                            style={{ width: '100%' }}
                                                        />
                                                        <DatePicker
                                                            selected={this.state.dateRange.end}
                                                            onChange={(date) => this.handleBetweenDateChange('end', date)}
                                                            selectsEnd
                                                            startDate={this.state.dateRange.start}
                                                            endDate={this.state.dateRange.end}
                                                            minDate={this.state.dateRange.start}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="mr-1 datepicker form-control"
                                                            placeholderText="End Date"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                ) : <div style={{ width:"100%" }}>
                                                        {this.state.searchTermDateVisible && (
                                                            <DatePicker
                                                                selected={this.state.searchTermDate}
                                                                onChange={this.handleSearchDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                className="mr-1 datepicker form-control"
                                                                disabled={['today', 'yesterday', 'tomorrow', 'last7Days', 'next7Days',
                                                                    'last Week', 'this Week', 'next Week', 'lastMonth', 'nextMonth','thisMonth',
                                                                    'lastYear', 'nextYear', 'thisYear'].includes(this.state.selectedCondition)
                                                                }
                                                            />
                                                        )}

                                                    </div>
                                                }
                                                {this.state.searchTermVisible && (
                                                    <Input
                                                        value={this.state.searchTerm}
                                                        onChange={this.changeSearchTerm}
                                                        type="text"
                                                        name="searchTerm"
                                                    />
                                                )}
                                                {this.state.fileSizeVisible && (
                                                        <div id="fileSize">
                                                        <Input
                                                            type="number"
                                                            value={this.state.value}
                                                            onChange={this.handleValueChange}
                                                            style={{ width: "100px", height: "37px" }}
                                                        />
                                                            <Input style={{ height: "37px", width:"auto" }} value={this.state.unit} type="select" className="form-select" onChange={this.handleUnitChange}>
                                                            <option value="KB">KB</option>
                                                            <option value="MB">MB</option>
                                                        </Input>
                                                    </div>
                                                )}
                                                <div id="customFieldDropdown">
                                                    {this.state.customFieldDropdownVisible && (
                                                        <Input
                                                            type="select"
                                                            name="customDropdown"
                                                            value={this.state.dropdownValues.customDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {this.state.isCustomField && this.state.searchField === 'importance' && (
                                                                ['----','Low', 'Normal', 'High'].map(option => (
                                                                    <option key={option} value={option}>{option}</option>
                                                                ))
                                                            )}
                                                            {this.state.isCustomField && this.state.searchField === 'priority' && (
                                                                ['----','Low', 'Normal', 'High'].map(option => (
                                                                    <option key={option} value={option}>{option}</option>
                                                                ))
                                                            )}
                                                        </Input>
                                                    )}
                                                    {this.state.sensitivityDropdownVisible && (
                                                        <Input
                                                            type="select"
                                                            name="sensitivityDropdown"
                                                            value={this.state.dropdownValues.sensitivityDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {['----','Normal', 'Personal', 'Private', 'Confidential'].map(option => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                    {this.state.isMeetingStatusField && (
                                                        <Input
                                                            type="select"
                                                            name="meetingStatusDropdown"
                                                            value={this.state.dropdownValues.meetingStatusDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {['----','None', 'Organized', 'Tentative', 'Accepted', 'Declined', 'Not Responded'].map(option => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                    {this.state.isBooleanField && (
                                                        <Input
                                                            type="select"
                                                            name="attachmentsDropdown"
                                                            value={this.state.dropdownValues.attachmentsDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {['----','Yes', 'No'].map(option => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                    {this.state.isFlagStatusField && (
                                                        <Input
                                                            type="select"
                                                            name="flagStatusDropdown"
                                                            value={this.state.dropdownValues.flagStatusDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {['----','Not flagged', 'Completed','Follow Up'].map(option => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                    {this.state.isColorField && (
                                                        <Input
                                                            type="select"
                                                            name="colorDropdown"
                                                            value={this.state.dropdownValues.colorDropdown}
                                                            onChange={this.handleDropdownChange}
                                                            className="form-select"
                                                        >
                                                            {['----','Blue', 'Green','Pink','Yellow','White','Light Green'].map(option => (
                                                                <option key={option} value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-2" style={{ flex:"", width:"" }}>
                                            <Button
                                                className=""
                                                id="plusbutton"
                                                onClick={this.addToQueryLines}
                                                disabled={isAddButtonDisabled}
                                            >
                                                +
                                            </Button>
                                            <Button
                                                className="ms-2"
                                                disabled={searchDisabled}
                                                onClick={this.search}
                                            >
                                                {/*{this.state.inProgressRight ?'Stop' : 'Search' }*/}
                                                Search
                                            </Button>
                                            &nbsp;
                                           
                                                <a id="ContentPlaceHolder1_btnclr" className="RestoreButtons" style={{ color: "currentcolor", cursor: 'pointer', hover: { color: "#fff" } }} >
                                                <Button onClick={(e) => {
                                                    if (this.state.gridApi) {
                                                        this.state.gridApi.setFilterModel(null);
                                                    }
                                                    this.setState(
                                                        {
                                                            //searchQueries: [],
                                                            searchType: "null",
                                                        },
                                                        () => {
                                                            this.resetSearchValues(); 
                                                        }
                                                    );
                                                }
                                                } style={{ padding: '5px 7px', color: '#fff' }}  >
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" fill="#fff" stroke="#fff" viewBox="0 0 28 28" width="20" style={{ cursor: 'pointer', margin: "5px 0 0 5px" }}>
                                                        <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 .29-.02.57-.06.85l1.52 1.52c.33-.79.54-1.64.54-2.54 0-4.42-3.58-8-8-8zm-1 14v3l4-4-4-4v3c-3.31 0-6-2.69-6-6 0-.29.02-.57.06-.85L3.94 8.47c-.33.79-.54 1.64-.54 2.53 0 4.42 3.58 8 8 8z" />
                                                    </svg>

                                                    {/*<svg xmlns="http://www.w3.org/2000/svg" height="20" fill="#fff" stroke="#fff" viewBox="0 0 32 32" width="20" style={{ cursor: 'pointer' }}>*/}
                                                    {/*    <path strokeWidth=".5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>*/}
                                                    {/*    <path strokeWidth=".5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>*/}
                                                    {/*</svg>*/}
                                                </Button>

                                            </a>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </React.Fragment>
                );
            }
        }

        const ActiveSearch = ({ queriesList }) => {
            const getRowHeight = (params) => {
                const queriesListArray = params.data.queriesList || [];
                let rowHeight = 20;
                for (let i = 0; i < queriesListArray.length; i++) {
                    rowHeight += 25;
                }
                return rowHeight;
            };
            const headerHeight = 30;

            const [gridApi, setGridApi] = useState(null);

            const processQueriesList = (params) => {
                if (queriesList && queriesList.QueriesList) {
                    const searchHistoryQueries = [];

                    queriesList.QueriesList.forEach((queries, index) => {
                        searchHistoryQueries.push({
                            backupTime: queriesList.BackUpTime[index],
                            restoreType: queriesList.Type[index],
                            level: [queriesList.Title[index], queriesList.SubTitle[index]],
                            queriesList: queriesList.QueriesList[index],
                            resultCount: queriesList.SearchCount[index],
                            status: queriesList.Status[index],
                        });
                    });
                   params.setRowData(searchHistoryQueries);
                }
            };

            const activeSearchOnGrid= (params) => {
                setGridApi(params.api);
                processQueriesList(params.api);
                    };
            const renderTooltip = (props) => (

                <Tooltip id={`tooltip-${props.id}`} {...props}>
                    {props.text}
                </Tooltip>

            );

            const TableRenderer = (props) => {
                const searchQuery = props.value;
                const headerHeight = 20;
                const rowHeight = 25;

                const columnDefs = useMemo(() => [
                    { headerName: 'Field', field: 'Field', sortable: true, cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }, filter: true},
                    { headerName: 'Condition', field: 'Inclusion', sortable: true, cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }, filter: true },
                    { headerName: 'Value', field: 'Term', sortable: true, cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }, filter: true }
                ], []);

                const defaultColDef = {
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    sortable: true,
                    filter: false,
                    headerStyle: {
                        backgroundColor: '#007bff',  // Blue background
                        color: 'white',  // White text
                        fontWeight: 'bold',  // Bold font
                        textAlign: 'center',  // Centered text
                        padding: '10px',  // Padding for the header
                        display: 'flex',
                        justifyContent: 'center',
                        
                    }
                };

                return (
                    <div className="ag-theme-alpine" style={{ width: '100%', height: 200 }}>
                        <AgGridReact
                            rowData={searchQuery}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                            suppressVerticalScroll={true}
                        />
                    </div>
                );
            };

            const columnDefs = useMemo(() => [
                {
                    headerName: 'Type', field: 'restoreType', filter: 'agTextColumnFilter', maxWidth: 100,
                    cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
                    cellRendererFramework: (params) => {
                        const imageSources = {
                            "mailboxes": Exchanges,
                            "sites": Sharepoints,
                            "onedrives": Onedrives,
                            "teams": Teams
                        };
                        const imageSrc = imageSources[params.value.toLowerCase()];

                        return (
                            <div>
                                {imageSrc &&
                                    <OverlayTrigger
                                        key="restoreTypeIcon-1"
                                        placement="top"
                                        width
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: `${params.value.toUpperCase()}` })}
                                    >
                                        <img src={imageSrc} alt="Restore Type" style={{ width: '1.2vw', height: '1.2vw' }} />
                                    </OverlayTrigger>
                                }
                            </div>
                        );
                    }
                },
                {
                    headerName: 'Restore Point', field: 'backupTime', filter: 'agDateColumnFilter', sortable: true, maxWidth: 150,
                    cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
                },
                {
                    headerName: 'Level', field: 'level', filter: 'agTextColumnFilter', minWidth: 50,
                    cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
                    cellRendererFramework: (params) => {

                        let user = { email: "", title: "" };
                        let folder = {subtitle: "", url: "" };

                        if (params.value[0]) {
                            try {
                                
                                const parsedUser = JSON.parse(params.value[0]);
                                user.email = parsedUser.email || "";
                                user.title = parsedUser.title || "";
                            } catch (error) {
                               
                                user.email = params.value[0];
                                user.title = params.value[0];
                            }
                        }

                        if (params.value[0]) {
                            try {

                                const parsedUser = JSON.parse(params.value[1]);
                                folder.email = parsedUser.email || "";
                                folder.title = parsedUser.title || "";
                            } catch (error) {

                                folder.email = params.value[1];
                                folder.title = params.value[1];
                            }
                        }
                        return (
                            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>

                                <div style={{ margin: '0' }}>
                                    <OverlayTrigger
                                        key="sort-1"
                                        placement="top"
                                        width
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: `${user.email}` })}
                                    >
                                        <span>{user.title}</span>
                                    </OverlayTrigger>
                                </div>

                                <div style={{ margin: '0' }}>
                                    <OverlayTrigger
                                        key="sort-1"
                                        placement="top"
                                        width
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={(props) => renderTooltip({ ...props, id: "sort-1", text: `${folder.email}` })}
                                    >
                                        <span>{folder.title}</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    headerName: 'Queries List',
                    field: 'queriesList',
                    filter: false, minWidth: 500,
                    cellRendererFramework: TableRenderer,

                },
                {
                    headerName: 'Count', field: 'resultCount', filter: 'agNumberColumnFilter', maxWidth: 100,
                    cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
                },
                {
                    headerName: 'Status', field: 'status', maxWidth: 125, filter: false,
                    cellStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
                    cellRendererFramework: (params) => <div>
                        {params.value === "Active" && 
                            < Button
                                style={{
                                    height: "24px",
                                    padding: "0",
                                    background: "transparent",
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                    display: "inline-flex",
                                    alignItems: "center"
                                }}
                                onClick={(e) => this.showSearch(e,params.rowIndex)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </Button>
                        }
                        {params.value === "Expired" && <span style={{ color: 'red' }}>
                            {params.value}
                        </span>}
                        {params.value === "In Progress" && <span style={{ color: '#3498db' }}>
                            {params.value}
                        </span>}
                    </div>
                },

            ], []);



            const defaultColDef = useMemo(() => ({
                flex: 1,
                editable: false, 
                resizable: true,
                headerStyle: { textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
            }), []);

            if (this.state.activeAccordion !== '0' && this.state.selectedRestoreType !== "Azure Backup") {
                return (
                    <>
                        <div>
                            <div style={{ backgroundColor: "#5191CE", width: "100%", margin: "auto" }} className="row p-1" onClick={this.openSearchHistoryPanel}>
                                <div style={{ color: "white" }} className="col-11 SectionTitle">
                                    Active Search List
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-diskspace">Active search results will expire in 8 hours and will be removed from Active search list in 10 hours</Tooltip>}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="ms-2 bi bi-info-circle" viewBox="0 0 16 16" >
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                        </svg>
                                    </OverlayTrigger>
                                </div>
                                <div className="col clearfix">
                                    <Button className="float-right" onClick={this.openSearchHistoryPanel} style={{ backgroundColor: 'rgb(81, 145, 206)', borderColor: 'rgb(81, 145, 206)', float: 'right' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="white" width="20" height="20" fill="currentColor" className={`arrow ${this.state.searchHistoryPanelOpen ? 'arrow_rotate bi bi-chevron-down' : 'bi bi-chevron-down'}`} viewBox="0 0 16 16">
                                            <path fill="#FFFFFF" strokeWidth="2" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                            <Collapse isOpen={this.state.searchHistoryPanelOpen}>
                                <div className="row" id="searchQuerybody">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="ag-theme-alpine" style={{ width: '100%', maxHeight: 600 }}>
                                                <AgGridReact
                                                    //rowData={ActiveSearchrowData}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={defaultColDef}
                                                    getRowHeight={getRowHeight}
                                                    headerHeight={headerHeight}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    overlayLoadingTemplate={
                                                        `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:50px;"></div>`}
                                                    overlayNoRowsTemplate={
                                                        '<span className="ag-overlay-loading-center">No Search records to show</span>'
                                                    }
                                                    onGridReady= {activeSearchOnGrid}
                                                    domLayout="autoHeight"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </>
                );
            } else {
                return null;
            }
        };
        let footer = null;
        if (this.state.viewItems.length > 0) {
            let nextPageButton;
            let previousPageButton;
            if (!this.state.nextBlocked) {
                nextPageButton = (
                    <Button
                        onClick={(e) => this.nextPage(e, this.state.searchResultsFlag)}
                        disabled={this.state.nextBlocked}
                        style={{ marginLeft: "10px" }}
                    >
                        +
                    </Button>
                );
            }
            if (this.state.displayPage !== 0) {
                previousPageButton = (
                    <Button
                        onClick={(e) => this.previousPage(e, this.state.searchResultsFlag)}
                        disabled={this.state.displayPage === 0}
                        style={{ marginRight: "10px" }}
                    >
                        -
                    </Button>
                );
            }
            footer = (
                <div className="card-footer">
                    <div
                        className="btn-toolbar"
                        role="toolbar"
                        style={{
                            position: "sticky",
                            bottom: "0",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {/*{previousPageButton}*/}
                        {/*<Button disabled>{this.state.displayPage + 1}</Button>*/}
                        {/*{nextPageButton}*/}
                    </div>
                </div>
            );
        }
        let noResultsSection;
        let searchMsgAfter10Sec = null;
        if( (this.state.noResults && !this.state.inProgressRight && !this.state.SearchQueued)) {
            noResultsSection = (
                <div className="row">
                    <div className="col" />
                    <div className="col">
                        <p>No results.</p>
                    </div>
                    <div className="col" />
                </div>
            );
            
        }
        if (this.state.SearchQueued && !this.state.inProgressRight){
            searchMsgAfter10Sec = (
                <div className="row">
                    <div className="col" style={{ textAlign: 'center', padding : '10px' }}>
                        <p style={{ marginBottom: '5px', lineHeight: '1.2' }}>
                            Your search request has been successfully queued in the active search list.
                        </p>
                        <p style={{ marginBottom: '5px', lineHeight: '1.2' }}>
                            You will be notified once the results are ready, and you'll be able to view them as soon as they're available.
                        </p>
                    </div>
                </div>
            );

        }
        let leftCardSection;
        if (
            this.state.selectItems.length > 0 ||
            this.state.inProgressLeft ||
            this.state.errorMessage !== ""
        ) {
            leftCardSection = (
                <div className="card-body" style={{ maxHeight: '779px', overflowY: 'auto', minHeight:'470px' }}>
                    <p>{this.state.errorMessage}</p>
                    {leftSection}
                </div>
            );
        } else if (
            this.state.selectItems.length === 0 &&
            this.state.gotSelectItems
        ) {
            leftCardSection = <div className="card-body">{noItemsWarning}</div>;
        }
        if (this.state.clearLeftSection) {
            leftCardSection = []
        }
        if (this.state.inProgressRight) {
            noResultsSection = (
                <div className="d-flex justify-content-center">
                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                </div>
            );
        }
        if (this.state.searchResultsFlag && this.state.viewItems.length > 0) {
            var clearButton = (
                <div>
                    <Button key="clear" onClick={this.clearSearch} className="ms-2">
                        Clear Search
                    </Button>
                </div>
            );
            restoreButtons.push(clearButton);
        }
        if (!this.state.downloadModalOpen) {
            downloadModal = null;
        }
        if (!this.state.verificationModalOpen) {
            verificationModal = null;
        }
        if (!this.state.mailModalOpen) {
            mailModal = null;
        }
        if (!this.state.restoreModalOpen) {
            restoreModal = null;
        }

        let exchangeTabClassName = "btn nav-link pad-5";
        let sharepointTabClassName = "btn nav-link pad-5";
        let onedriveTabClassName = "btn nav-link pad-5";
        let teamsTabClassName = "btn nav-link pad-5";
        let aadTabClassName = "btn nav-link pad-5";
        if (this.state.restoreType == "vex") {
            exchangeTabClassName += " active";
        } else if (this.state.restoreType == "vesp") {
            sharepointTabClassName += " active";
        } else if (this.state.restoreType == "veod") {
            onedriveTabClassName += " active";
        } else if (this.state.restoreType == "vet") {
            teamsTabClassName += " active";
        } else if (this.state.restoreType == "aad") {
            aadTabClassName += " active";
        }
        let chosenTheme;
        const ResellerTheme = React.lazy(() =>
            import("./ThemeObjects/RestorePage")
        );
        chosenTheme = <ResellerTheme />;
        let restorePointPopup = null;
        // if (this.state.showRestorePoints)
        
        restorePointPopup = (
            <></>
            //<Modal
            //    isOpen={this.state.showRestorePoints}>
            //    <RestorePoints
            //        restorePoints={this.state.restorePoints}
            //        popupSwap={this.showRestorePointsPopup}
            //        showPopup={this.state.showRestorePoints}
            //        setRestorePoint={this.setRestorePoint} />
            //</Modal>
        );
        let restoreCalendar = null;
        restoreCalendar  =(<><Calendar style={{ height: 500 }}
            onChange={(date) => this.selectRestoreOnChange(date, this.state.restorePoints)}
            value={this.state.date}
            tileClassName={({ date, view }) => {
                for (var i = 0; i < this.state.restorePoints.length; i++) {
                    var x = this.state.restorePoints[i].backupTimeStr.split(' ')[0];
                    if (x === moment(date).format("DD-MM-YYYY")) {

                        return 'highlight';
                    }
                }
            }}
            onActiveStartDateChange={this.onActiveStartDateChange}
        >
        </Calendar>
            <div className="mt-2 mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={this.getRestorePointsOnMonth} className="btn btn-primary">Show All</button>
            </div>
        </>)
        const azureColDefs = [
            { field: "startTime", headerName: 'Date', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth:155 },
            { field: "process", headerName: 'Process', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 100 },
            { field: "diffCount", headerName: 'Diff', headerTooltip: 'Number of changes captured.', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 80 }
        ];
        //const [gridApi, setGridApi] = useState([]);
        const getRowStyle = params => {
            return { fontSize: "Small" };
        };
        const rowHeight = 25;
        const headerHeight = 30;
        const onRowClicked = (params) => {
            this.getAADBackupConfigData(params.data.diffFile, this.state.orgName, params.data)
            
        }
        const renderTooltip = (props) => (
            <Tooltip id="AzureInfo-tooltip" {...props}>
                <img
                    src={AzureToolTip}
                    alt="Tooltip Image"
                    style={{ width:'26vw' }}
                />
            </Tooltip>
        );
        const onGridReady = (params) => {
            //setGridApi(params.api);
            this.setState({
                azureGridApi: params.api,
            });
            //params.api.sizeColumnsToFit();
        }
        return (
            <React.Fragment>

                <React.Suspense fallback={<></>}>{chosenTheme}</React.Suspense>
                {verificationModal} 
                {mailModal}
                {siteModal}
                {oneDriveModal}
                {downloadModal}
                {entraModal}
                {restoreModal}
                {confirmationModal}
                <NavBar
                    page="restore"
                    impersonating={this.state.impersonating}
                    orgName={this.state.orgName}
                    authContext={this.props.authContext || this.context} 
                    isAzureGuardianOnly={this.state.isAzureGuardianOnly}
                />
                {restorePointPopup}
                <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
                <div className="row pb-4" style={{ margin: "auto", overflowY: 'auto', maxHeight: '89vh', minHeight:'700px' }}>
                    <div className="col-3" style={{ width: '21%' }}>
                        <Accordion defaultActiveKey={["0"]} activeKey={this.state.activeAccordion} style={{ minWidth:'auto' }} id="restore_accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header onClick={() => this.accordianChange("first", "0")}>Select a restore type {this.state.selectedRestoreType !== "" && "- "+this.state.selectedRestoreType }</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    {this.state.isAzureGuardianOnlyLoaded ? 
                                        <div className="row">
                                            {!this.state.isAzureGuardianOnly && <div className="col">
                                                <span className="p-1 m-1 ps-2 pe-2" onClick={() => { this.restoreChallengePopup(false); }} style={{ boxShadow: '2px 3px 7px gray', cursor: 'pointer', display: 'flex', background: '#5191ce', color: 'white', borderRadius: '4px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <b className="me-1">365 </b> Restore
                                                    <img
                                                        src={cloud}
                                                        alt="365 Restore"
                                                        title="365 Restore"
                                                        className={exchangeTabClassName}
                                                        style={{ paddingLeft: '7px', height: '22px' }}
                                                    />
                                                </span>
                                            </div>}
                                            <div className="col">
                                                {(Object.keys(this.state.priceModel).length !== 0 && this.state.priceModel.features.find(feature => feature.featureType === "AZURE") !== undefined && this.state.priceModel.features.find(feature => feature.featureType === "AZURE").featureValue.toLowerCase() === "true") && (sessionStorage.getItem('IsEndUser') !== "true") && (sessionStorage.getItem('IsEndUser') !== true) ?

                                                    <span className="p-1 m-1 ps-2 pe-2" onClick={() => { this.restoreChallengePopup(true); }} style={{ boxShadow: '2px 3px 7px gray', cursor: 'pointer', display: 'flex', background: '#5191ce', color: 'white', borderRadius: '4px', alignItems: 'center', justifyContent: 'center' }}> <b className="me-1">Azure </b> Restore
                                                        <img
                                                            src={cloud}
                                                            alt="AAD Restore"
                                                            title="AAD Restore"
                                                            className={aadTabClassName} style={{ paddingLeft: '7px', height: '22px' }} />
                                                    </span>
                                                    : !this.state.guardianStatusLoaded ?
                                                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-diskspace">Loading...</Tooltip>}>
                                                            <span className={aadTabClassName + " p-1 m-1 ps-2 pe-2"} style={{ boxShadow: '2px 3px 7px gray', cursor: 'pointer', fontSize: '13px', display: 'flex', background: '#d3dde7', color: 'white', borderRadius: '4px', alignItems: 'center', justifyContent: 'center' }}> <b className="me-1">Azure </b> Restore
                                                                <img
                                                                    src={cloud}
                                                                    alt="AAD Restore"
                                                                    title="AAD Restore"
                                                                    className={aadTabClassName} style={{ paddingLeft: '7px', height: '22px' }} />
                                                            </span>
                                                        </OverlayTrigger>
                                                        : <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-diskspace">{(Object.keys(this.state.priceModel).length !== 0 && this.state.priceModel.features.find(feature => feature.featureType === "AZURE") !== undefined && this.state.priceModel.features.find(feature => feature.featureType === "AZURE").featureValue.toLowerCase() === "true") ? "Not Available" : "Not Available in your selected package"}</Tooltip>}>
                                                            <span className={aadTabClassName + " p-1 m-1 ps-2 pe-2"} style={{ boxShadow: '2px 3px 7px gray', cursor: 'pointer', fontSize: '13px', display: 'flex', background: '#d3dde7', color: 'white', borderRadius: '4px', alignItems: 'center', justifyContent: 'center' }}> <b className="me-1">Azure </b> Restore
                                                                <img
                                                                    src={cloud}
                                                                    alt="AAD Restore"
                                                                    title="AAD Restore"
                                                                    className={aadTabClassName} style={{ paddingLeft: '7px', height: '22px' }} />
                                                            </span>
                                                        </OverlayTrigger>}
                                            </div>
                                        </div>
                                        :
                                        <div className="row" style={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                            <img src={DotsTyping} alt="loading" style={{ height: '8px', width:'60px' }} />
                                        </div>}
                                    
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header className={`${this.state.activeAccordion[0] < '1' ? 'accordian-disabled' : ''}`} onClick={() => this.accordianChange("previous", "1")}>Select a restore date {this.state.selectedRestoreDate !== "" && "- " + this.state.selectedRestoreDate} {this.state.selectedRestoreType === "Azure Backup" &&
                                    <OverlayTrigger placement="right" overlay={renderTooltip} delay={{ show: 250, hide: 400 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-2 bi bi-info-circle" viewBox="0 0 16 16" >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                    </svg>
                                </OverlayTrigger>}</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div id="aadCalendar">
                                        {this.state.renderAzureRestoreestoreCalendar}
                                        {/*<Calendar style={{ height: 500 }}*/}
                                        {/*    onChange={this.onChange}   */}
                                        {/*    value={this.state.date}*/}
                                        {/*    tileClassName={({ date, view }) => {*/}
                                        {/*        for (var i = 0; i < this.state.mark.length; i++) {*/}
                                        {/*            var x = this.state.mark[i].restoreDate.split(' ')[0];*/}
                                        {/*            if (x === moment(date).format("DD-MM-YYYY")) {*/}
                                        {/*                return 'highlight';*/}
                                        {/*            }*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*</Calendar>*/}
                                        
                                    </div>
                                    <div id="restoreCalendar">
                                        {this.state.renderRestoreestoreCalendar}
                                        {/*<Calendar style={{ height: 500 }} */}
                                        {/*    onChange={(date) => this.selectRestoreOnChange(date, this.state.restorePoints)}*/}
                                        {/*    value={this.state.date}*/}
                                        {/*    tileClassName={({ date, view }) => {*/}
                                        {/*        for (var i = 0; i < this.state.restorePoints.length; i++) {*/}
                                        {/*            var x = this.state.restorePoints[i].backupTimeStr.split(' ')[0];*/}
                                        {/*            if (x === moment(date).format("DD-MM-YYYY")) {*/}
                                                        
                                        {/*                return 'highlight';*/}
                                        {/*            }*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*    onActiveStartDateChange={this.onActiveStartDateChange}*/}
                                        {/*>*/}
                                        {/*</Calendar>*/}
                                        {/*<div className="m-2" style={{ display: 'flex', justifyContent: 'center' }}>*/}
                                        {/*    <button onClick={this.getRestorePointsOnMonth} className="btn btn-primary w-100" style={{ fontFamily: 'Roboto, sans-serif', fontSize:'19px' }}>Show All Restore Points</button>*/}
                                        {/*</div>*/}
                                    </div>
                                    
                                    {/*<div style={{ textAlign: 'end' }}>*/}
                                    {/*    <svg className="m-1 me-3 mt-2" onClick={() => this.accordianChange("previous")} xmlns="http://www.w3.org/2000/svg" version="1.0" width="20" height="20" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">*/}
                                    {/*        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#5191ce" stroke="#5191ce" strokeWidth="400">*/}
                                    {/*            <path d="M2450 3939 c-35 -15 -304 -279 -1223 -1197 -693 -692 -1187 -1193 -1198 -1215 -63 -121 -11 -279 112 -341 68 -33 162 -36 229 -5 35 15 284 259 1118 1092 l1072 1072 1073 -1072 c833 -833 1082 -1077 1117 -1092 67 -31 161 -28 229 5 123 62 175 220 112 341 -11 22 -505 523 -1198 1215 -919 918 -1188 1182 -1223 1197 -30 14 -66 21 -110 21 -44 0 -80 -7 -110 -21z" />*/}
                                    {/*        </g>*/}
                                    {/*    </svg>*/}
                                    {/*</div>*/}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className={`${this.state.activeAccordion < '2' ? 'accordian-disabled' : ''}`} onClick={() => this.accordianChange("previous", "2")}>Select a restore point {this.state.selectedRestorePoint !== "" && "- " + this.state.selectedRestorePoint}</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    {this.state.aadnoResults && (
                                    <RestorePoints
                                        restorePoints={this.state.selectedrestorePoints}
                                        popupSwap={this.showRestorePointsPopup}
                                        showPopup={this.state.showRestorePoints}
                                            setRestorePoint={this.setRestorePoint}
                                            isEndUser={this.state.isEndUser}
                                        />
                                    )}
                                    {!this.state.aadnoResults && (
                                        <div>
                                            {this.state.isAADRestoreTableLoaded ? <div className="card-body p-0">

                                                <div id="azureRestorePointList" className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 300, borderCollapse: "collapse" }} >

                                                    <AgGridReact
                                                        ref={this.gridRef}
                                                        rowData={this.state.aadviewItems}
                                                        enableCellTextSelection="true"
                                                        getRowStyle={getRowStyle}
                                                        onRowClicked={onRowClicked}
                                                        onGridReady={onGridReady}
                                                        defaultColDef={defaultColDef}
                                                        rowSelection="single"
                                                        columnDefs={azureColDefs}
                                                        width={700}
                                                        headerHeight={headerHeight}
                                                        rowHeight={rowHeight}
                                                        overlayLoadingTemplate={
                                                            `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:50px;"></div>`}
                                                        overlayNoRowsTemplate={
                                                            '<span className="ag-overlay-loading-center">No error records to show</span>'
                                                        }
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                                : <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '225px' }}>
                                                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                                                </div>}
                                            
                                        
                                            {/*<Table border="1" className="mt-0 mb-0">*/}
                                            {/*    <thead>*/}
                                            {/*        <tr>*/}
                                            {/*            <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Date</th>*/}
                                            {/*            <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Process</th>*/}
                                            {/*            <th style={{ backgroundColor: 'rgb(81, 145, 206)', color: 'white' }}>Diff</th>*/}
                                            {/*        </tr>*/}
                                            {/*    </thead>*/}
                                            {/*    <tbody style={{ fontSize: '13px' }}>*/}
                                            {/*        {this.state.aadviewItems.map((item, index) => (*/}
                                            {/*            <tr key={index}*/}
                                            {/*                onClick={() => this.getAADBackupConfigData(item.diffFile, this.state.orgName, item)} style={{ cursor: 'pointer' }}>*/}
                                            {/*                <td>{item.startTime}</td>*/}
                                            {/*                <td>{item.process}</td>*/}
                                            {/*                <td>{item.diffCount}</td>*/}
                                            {/*            </tr>*/}
                                            {/*        ))}*/}
                                            {/*    </tbody>*/}
                                            {/*</Table>*/}
                                        </div>)}
                                    {/*<div style={{ textAlign:'end' }}>*/}
                                    {/*    <svg className="m-1 me-3 mt-2" onClick={() => this.accordianChange("previous")} xmlns="http://www.w3.org/2000/svg" version="1.0" width="20" height="20" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">*/}

                                    {/*        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#5191ce" stroke="#5191ce" strokeWidth="400">*/}
                                    {/*            <path d="M2450 3939 c-35 -15 -304 -279 -1223 -1197 -693 -692 -1187 -1193 -1198 -1215 -63 -121 -11 -279 112 -341 68 -33 162 -36 229 -5 35 15 284 259 1118 1092 l1072 1072 1073 -1072 c833 -833 1082 -1077 1117 -1092 67 -31 161 -28 229 5 123 62 175 220 112 341 -11 22 -505 523 -1198 1215 -919 918 -1188 1182 -1223 1197 -30 14 -66 21 -110 21 -44 0 -80 -7 -110 -21z" />*/}
                                    {/*        </g>*/}
                                    {/*    </svg>*/}
                                    {/*</div>*/}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" id="accordion_3">
                                <Accordion.Header className={`${this.state.activeAccordion < '3' ? 'accordian-disabled' : ''}`} onClick={() => this.accordianChange("previous","3")}>Select an item type</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="card" style={{ maxHeight: '853px' }}>
                                        <div className="card-header">
                                            <ul className="nav nav-tabs card-header-tabs justify-content-between">
                                                <li className="nav-item" id="Exchange_restore">
                                                    <img
                                                        src={this.state.restorePoint.length == 0 ? exchange : this.state.restorePoint.isExchange ? exchange : exchangeDisabled}
                                                        onClick={(e) => {
                                                            if (this.state.restorePoint.length == 0 || this.state.restorePoint.isExchange)
                                                                this.getOrgRestore(e, "vex");
                                                            else
                                                                return;
                                                        }
                                                        }
                                                        alt="Exchange Restore"
                                                        title="Exchange Restore"
                                                        className={exchangeTabClassName}
                                                        style={{ paddingLeft: '7px', paddingRight: '7px' }}
                                                    />
                                                </li>
                                                <li className="nav-item" id="Sharepoint_restore">
                                                    <img
                                                        src={(this.state.restorePoint.length == 0 ? sharepoint : this.state.restorePoint.isSharePoint ? sharepoint : sharepointDisabled)}
                                                        onClick={(e) => {
                                                            if (this.state.restorePoint.length == 0 || this.state.restorePoint.isSharePoint)
                                                                this.getOrgRestore(e, "vesp");
                                                            else
                                                                return;
                                                        }
                                                        }
                                                        alt="Sharepoint Restore"
                                                        title="Sharepoint Restore"
                                                        className={sharepointTabClassName}
                                                        style={{ paddingLeft: '7px', paddingRight: '7px' }}
                                                    />
                                                </li>
                                                <li className="nav-item" id="OneDrive_restore">
                                                    <img
                                                        src={
                                                            (this.state.restorePoint.length === 0 ? onedrive: this.state.restorePoint.isOneDrive ? onedrive:onedriveDisabled)
                                                        }
                                                        onClick={(e) => {
                                                            if (this.state.restorePoint.length === 0 || this.state.restorePoint.isOneDrive) {
                                                                this.getOrgRestore(e, "veod");
                                                            }
                                                            else
                                                            return;
                                                        }}
                                                        alt="OneDrive Restore"
                                                        title="OneDrive Restore"
                                                        className={onedriveTabClassName}
                                                        style={{
                                                            paddingLeft: '7px',
                                                            paddingRight: '7px',      
                                                        }}
                                                    />
                                                </li>
                                                <li className="nav-item" id="Teams_restore">
                                                    <img
                                                        src={
                                                            this.state.isEndUser
                                                                ? teamsDisabled
                                                                : (this.state.restorePoint.length === 0 ? teams : this.state.restorePoint.isTeams ? teams : teamsDisabled)}
                                                        onClick={(e) => {
                                                            if (!this.state.isEndUser && (this.state.restorePoint.length === 0 || this.state.restorePoint.isTeams)) {
                                                                this.getOrgRestore(e, "vet");
                                                            }

                                                        }}                                                        
                                                        alt="Teams Restore"
                                                        title={this.state.isEndUser ? "Disabled for End Users" : "Teams Restore"}
                                                        className={teamsTabClassName}
                                                    tyle={{
                                                        paddingLeft: '7px',
                                                        paddingRight: '7px',
                                                        opacity: this.state.isEndUser ? 0.5 : 1,
                                                        cursor: this.state.isEndUser ? 'not-allowed' : 'pointer'
                                                    }}
                                                    />
                                                </li>

                                                <li className="nav-item" id="AAD_restore">

                                                    {(Object.keys(this.state.priceModel).length !== 0 && this.state.priceModel.features.find(feature => feature.featureType === "AZURE") !== undefined && this.state.priceModel.features.find(feature => feature.featureType === "AZURE").featureValue.toLowerCase() === "true") && (sessionStorage.getItem('IsDirectCustomer') !== "true") && (sessionStorage.getItem('IsEndUser') !== true) ? <img
                                                        src={aadIcon}

                                                        onClick={(e) => { this.getOrgRestore(e, "aad"); }}
                                                        alt="AAD Restore"
                                                        title="AAD Restore"
                                                        className={aadTabClassName}
                                                        style={{ paddingLeft: '7px', paddingRight: '7px' }}
                                                    /> : <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-diskspace">{(Object.keys(this.state.priceModel).length !== 0 && this.state.priceModel.features.find(feature => feature.featureType === "AZURE") !== undefined && this.state.priceModel.features.find(feature => feature.featureType === "AZURE").featureValue.toLowerCase() === "true") ? "Not Available" : "Not Available in your selected package"}</Tooltip>}>
                                                        <img
                                                            src={aadIconDisabled}
                                                            alt="AAD Restore"
                                                            className={aadTabClassName}
                                                            style={{ paddingLeft: '7px', paddingRight: '7px' }}
                                                        />
                                                    </OverlayTrigger>}
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="RestoreLandingPage" style={{ padding: "20px" }}>
                                            {/*<span*/}
                                            {/*    style={{ fontSize: "18px", fontWeight: 500, textAlign: "center", display: "block", padding: "10px" }}*/}
                                            {/*>*/}
                                            {/*    Select a restore point.*/}
                                            {/*</span>*/}
                                            {/*<div className=" col-10 d-flex justify-content-center" id="datePickerRestore" style={{ padding: "15px 0 15px 0" }}>*/}
                                            {/*    <DatePicker*/}
                                            {/*        selected={this.state.restoreDate}*/}
                                            {/*        minDate={this.state.firstRestoreDate}*/}
                                            {/*        maxDate={this.state.lastRestoreDate}*/}
                                            {/*        onChange={(date) => this.handleDateChange(date)}*/}
                                            {/*        dateFormat="dd/MM/yyyy"*/}
                                            {/*        style={{ maxHeight: "30px" }}*/}
                                            {/*        className="mr-1 datepicker"*/}
                                            {/*    />*/}
                                            {/*    <TimePicker*/}
                                            {/*        onChange={(time) => this.handleTimeChange(time)}*/}
                                            {/*        disableClock={true}*/}
                                            {/*        value={this.state.restoreTime}*/}
                                            {/*        style={{ maxHeight: "30px" }}*/}
                                            {/*        className="timepicker"*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            {/*<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
                                            {/*    <a*/}
                                            {/*        id="ShowRestorePoints"*/}
                                            {/*        style={{ color: "#007bff", marginBottom: '1rem' }}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.setState({*/}
                                            {/*                showRestorePoints: true*/}
                                            {/*            });*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        Show restore points*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}

                                            {/*<div style={{ textAlign: "center" }}>*/}
                                            {/*    <img*/}
                                            {/*        src={NavigateCalendar} alt="Click to choose"*/}
                                            {/*        style={{ margin: "18px auto 0 auto", display: "block" }}*/}
                                            {/*    />*/}
                                            {/*    <span style={{ fontSize: "18px", marginTop: "15px", fontWeight: 500 }}>Click to choose</span>*/}
                                            {/*</div>*/}
                                        </div>
                                        {leftCardSection}
                                    </div>
                                    {/*<div style={{ textAlign: 'end' }}>*/}
                                    {/*    <svg className="m-1 me-3 mt-2" onClick={() => this.accordianChange("previous")} xmlns="http://www.w3.org/2000/svg" version="1.0" width="20" height="20" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">*/}
                                    {/*        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#5191ce" stroke="#5191ce" strokeWidth="400">*/}
                                    {/*            <path d="M2450 3939 c-35 -15 -304 -279 -1223 -1197 -693 -692 -1187 -1193 -1198 -1215 -63 -121 -11 -279 112 -341 68 -33 162 -36 229 -5 35 15 284 259 1118 1092 l1072 1072 1073 -1072 c833 -833 1082 -1077 1117 -1092 67 -31 161 -28 229 5 123 62 175 220 112 341 -11 22 -505 523 -1198 1215 -919 918 -1188 1182 -1223 1197 -30 14 -66 21 -110 21 -44 0 -80 -7 -110 -21z" />*/}
                                    {/*        </g>*/}
                                    {/*    </svg>*/}
                                    {/*</div>*/}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                       
                    </div>
                    <div className="col-9 ps-0" style={{ width: '79%' }}>
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-3">
                                        <span
                                            className="SectionTitle mb-3"
                                            style={{ fontSize: "21px", fontWeight: '500' }}
                                        >
                                            CloudCover Recovery Suite
                                        </span>
                                    </div>
                                    {/*<div className="col-3" id="datePickerRestore" style={{ padding: "none" }}>*/}
                                    {/*    <DatePicker*/}
                                    {/*        selected={this.state.restoreDate}*/}
                                    {/*        minDate={this.state.firstRestoreDate}*/}
                                    {/*        maxDate={this.state.lastRestoreDate}*/}
                                    {/*        onChange={(date) => this.handleDateChange(date)}*/}
                                    {/*        dateFormat="dd/MM/yyyy"*/}
                                    {/*        style={{ maxHeight: "30px" }}*/}
                                    {/*        className="mr-1 datepicker"*/}
                                    {/*    />*/}
                                    {/*    <TimePicker*/}
                                    {/*        onChange={(time) => this.handleTimeChange(time)}*/}
                                    {/*        disableClock={true}*/}
                                    {/*        value={this.state.restoreTime}*/}
                                    {/*        style={{ maxHeight: "30px" }}*/}
                                    {/*        className="timepicker"*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                  
                                    <div className="col-4 btn-group" role="group">
                                            {restoreButtons}
                                    </div>
                                    
                                    {this.state.orgName && this.state.orgName !== "" &&
                                        <div className="col-5  d-flex justify-content-end" style={{ fontWeight: '500', fontSize:"21px" }}>
                                            {this.state.orgName}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div
                                style={{ backgroundColor: "#E7F0F8" }}
                                className="col-12"
                                id="restore_activeAggrid"
                            >
                                <ToastContainer />
                                {((this.state.isAzureGuardianOnly || this.state.selectedRestoreType === "Azure Backup") && this.state.viewItems.length === 0 &&
                                    !this.state.inProgressRight && !this.state.restore) ? (

                                        <div className="ps-3">
                                            <p className="titletext" style={{ fontWeight: '500', fontSize: '25px', textAlign: "center" }}> CloudCover Guardian for Azure </p>

                                            <p className="mb-3">The highlighted dates show when there has been a configuration change.</p>

                                            <p className="mb-3">Select the date to see a list of configuration backups for that day.</p>

                                            <p className="mb-3">Select a configuration file to see the actual changes.</p>

                                            <p className="mb-3">You will be able to revert these changes from this interface.</p>


                                            <p className="mb-0">Notes:</p>
                                            <div className="ms-4">
                                                <p className="mb-0" style={{ display: 'list-item' }}>You should not revert changes unless you fully understand what you are doing.</p>
                                                <p className="mb-0" style={{ display: 'list-item' }}>Microsoft constantly changes the content of configuration components; some changes may be as a result of MS configuration</p>
                                                <p className="mb-3" style={{ display: 'list-item' }}>updates, It is therefore important to verify the changes reported.</p>
                                            </div>
                                            
                                        </div>
                                ) : (rightSection)}
                                <ActiveSearch queriesList={this.state.activeSearchList} />
                                {searchBar}
                                {result}
                                {noResultsSection}
                                {searchMsgAfter10Sec}
                            </div>
                            {footer}
                        </div>
                    </div>
                    <RestoreAccessModal displayModal={this.state.showCommentPopup} type={this.state.selectedRestoreType} closeModal={this.hideRestorePopup} orgId={this.state.orgId} onCommentSubmitted={this.handleCommentSubmitted} handleRestoreChallangeSubmitted={this.handleRestoreChallangeSubmitted } />
                    
                    <BootstrapModal id="AAD-difference-report" show={this.state.AADReportshow} onHide={this.AADReporthandleClose} centered size="xl">
                        <BootstrapModal.Header closeButton>
                            <BootstrapModal.Title>{this.state.AADPreviousCConfigHeadar} - Comparison</BootstrapModal.Title>
                        </BootstrapModal.Header>
                        <BootstrapModal.Body style={{ maxHeight:'80vh' }}>
                            {this.state.AADPreviousCurrent && this.state.AADPreviousCurrent}
                        </BootstrapModal.Body>
                    </BootstrapModal>
                </div>

            </React.Fragment>
        );
    }
}
