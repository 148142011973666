import React, { useState, useMemo, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { AgGridReact } from 'ag-grid-react';
import { Form} from 'react-bootstrap';
import moment from "moment";
import { AppContext } from "./AppContext";
import Button from 'react-bootstrap/Button';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpModal from "./HelpMessagePopup";
import LoaderGif from "../../images/365 loading.gif";
function JobDetail(props) {
    const [sessionLogs, setSessionLogs] = useState([]);
    const [appOrg, setAppOrg] = useContext(AppContext);
    const [logType, setLogType] = useState("Error");
    const [gridApi, setGridApi] = useState(null);
    const [gridOptions, setGridOptions] = useState(null);
    const [helpPopup, setHelpPopup] = useState(true);
    const [filterChecked, setFilterChecked] = useState(["Error"])
    const [historyData, setHistoryData] = useState([])
    const rowHeight = 25;
    const headerHeight = 30;

    const getRowStyle = params => {
        return { fontSize: "Small" };
    };

    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    const dateFormatter = (params) => {
        if (!params.value)
            return "";
        if (params.value.includes('T')) {
            var dateString = params.value.split('T');
            if (dateString.length > 0) {
                var timeString = dateString[1];
                var parseTime = timeString.substring(0, 8);
                return `${parseDate(dateString[0])} ${parseTime}`;
            }
        }else
        return params.Value
    };

    const parseDate = (date) => {
        var parsedDate = date.split('-');
        return `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`;
    }

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    const onGridReady = (params) => {
        params.api.showLoadingOverlay();

        setGridOptions(params);
        setGridApi(params.api);
        loadData(params.api);
        
        params.api.sizeColumnsToFit();
        params.api.hideOverlay();
        //console.log(appOrg);
    }

    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);

        if (gridApi?.getDisplayedRowCount() == 0)
            gridApi.showNoRowsOverlay();
    }
    
    const onFilterChanged = (e) => {
        const checkboxValue = e.target.value;
        const isChecked = e.target.checked;

        let updatedFilterChecked;
       
        if (checkboxValue === "All") {
            if (isChecked) {
                updatedFilterChecked = ["Success", "Error", "Warning", "All"];
            } else {
                updatedFilterChecked = [];
            }
        } else {
            if (isChecked) {
                updatedFilterChecked = [...filterChecked, checkboxValue];
            } else {
                updatedFilterChecked = filterChecked.filter(item => item !== checkboxValue);
            }
            const allChecked = ["Error", "Success", "Warning"].every(value => updatedFilterChecked.includes(value));
            if (allChecked) {
                updatedFilterChecked = ["Success", "Error", "Warning", "All"];
            } else {
                updatedFilterChecked = updatedFilterChecked.filter(item => item !== "All");
            }
        }

        setFilterChecked(updatedFilterChecked);

        const filteredData = historyData.filter(row => {
            return updatedFilterChecked.some(keyword => row.title.includes("[" + keyword + "]"));
        });
        
        gridApi.setRowData(filteredData);
    }


    async function loadData(api) {
        if (props.jobLogUrl === "") {
            api.setRowData([]);
            return;
        }
       
        if (props.jobLogUrl === "Entra") {
            if (!api)
                return;

            api.setRowData([]);
            api.showLoadingOverlay();
            const config = {
                headers: {
                    Authorisation: `Bearer ` + props.token,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            const data = await Axios.get(API_ROUTE + Routes.GET_ENTRA_BACKUP_SESSION_LOGS + "/" + appOrg.tenantId + "/" + appOrg.azureJobSession, config)
                .then((response) => {
                    if (response !== null)
                        return JSON.parse(response.data);
                })
                .catch((reason) => {
                });

            if (data) {
                var rowData = [];
                for (var i = 0; i < data.length; i++) {
                    var row = {
                        creationTime: data[i].startTime,
                        endTime: data[i].endTime,
                        title:"["+ data[i].status+"] "+ data[i].description,
                      
                    }
                    rowData.push(row);
                }  
                api.setRowData(rowData.filter(detail => filterChecked.some(filter => detail.title.includes(filter))));

                setHistoryData(rowData)

                api.hideOverlay();
             
            }
        }
        else {
            if (!api)
                return;
            const config = {
                headers: {
                    Authorisation: `Bearer ` + props.token,
                    CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
                },
            };
            let payload = {
                url: props.jobLogUrl,
                orgId: appOrg.orgId,
            };
            const data = await Axios.post(API_ROUTE + Routes.GET_DATA, payload, config)
                .then((response) => {
                    return JSON.parse(response.data).results;
                })
                .catch((reason) => {
                    console.log("load Data catch" + reason.response.data);
                });
            if (data) {
                //api.setRowData(data.filter(detail => detail.title.includes("[Error]")));
                api.setRowData(data.filter(detail => filterChecked.some(filter => detail.title.includes(filter))));
                //setFilterChecked(['Error'])
                setHistoryData(data)
                api.hideOverlay();
                //api.setQuickFilter("Error");
                if (api?.getDisplayedRowCount() === 0)
                    api.showNoRowsOverlay();
                else
                    api.hideOverlay();
            }
        }
        //api.hideOverlay();
    }

    const columnDefs = [

        {
            field: 'creationTime', headerName: 'Creation Time', valueFormatter: dateFormatter, filter: true, maxWidth: 160, minWidth: 160, flex: 1,
            getQuickFilterText: params => {
                return dateFormatter(params);
            }, comparator: function (date1, date2) {
                // Custom comparator function for datetime sorting
                if (date1 === undefined || date1 === null) {
                    // Treat undefined or null as greater than any defined date
                    return 1;
                }
                if (date2 === undefined || date2 === null) {
                    // Treat undefined or null as greater than any defined date
                    return -1;
                }

                return moment(date1, 'DD/MM/YYYY HH:mm:ss').diff(moment(date2, 'DD/MM/YYYY HH:mm:ss'));
            }
        },
        {
            field: 'endTime', headerName: 'End Time', valueFormatter: dateFormatter, maxWidth: 160, minWidth: 160, flex: 1,
            getQuickFilterText: params => {
                return dateFormatter(params);
            }, comparator: function (date1, date2) {
                // Custom comparator function for datetime sorting
                if (date1 === undefined || date1 === null) {
                    // Treat undefined or null as greater than any defined date
                    return 1;
                }
                if (date2 === undefined || date2 === null) {
                    // Treat undefined or null as greater than any defined date
                    return -1;
                }

                return moment(date1, 'DD/MM/YYYY HH:mm:ss').diff(moment(date2, 'DD/MM/YYYY HH:mm:ss'));
            }
        },
        { field: 'title', headerName: 'Description', wrapText: true, filter: true, autoHeight: true, minWidth: 400, flex: 1 },
    ];



    useEffect(() => {
        //console.lo
        if (gridApi) {
            gridApi.showLoadingOverlay();
            gridApi.setRowData([]);
            loadData(gridApi);
            //setLogType("Error");
            gridApi.hideOverlay();

        }
    }, [props.jobLogUrl, appOrg.azureJobSession]);

    /*   useEffect(() => {
           if(gridApi)
               gridApi.setQuickFilter(logType);
           if (gridApi?.getDisplayedRowCount() === 0)
               gridApi.showNoRowsOverlay();
       }, [logType]);*/

    //useEffect(() => {
    //    if (gridApi) {
    //        loadData(gridApi);
    //    }
    //}, [props.refresh]);

    return (

        <div className="card mt-1" id="jobdetail">
            
            <div className="card-header  p-1">
                <div className="row align-items-center">
                    <div className="col-5">
                        <span className="SectionTitle ms-2" style={{ fontWeight: '500', width: '50%' }}> Job Detail
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-jobdetail">Click job history above</Tooltip>}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-2 bi bi-info-circle" viewBox="0 0 16 16" style={{position:'absolute'} }>
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                            </OverlayTrigger>
                        </span>
                    </div>

                    <div className="col d-flex justify-content-between align-items-center">
                        <Form.Group controlId="ContentPlaceHolder1_RadioButtonJobDetailFilter" className="d-flex">
                            <Form.Check id="ContentPlaceHolder1_RadioButtonJobDetailFilter_3" label="Error" name="JobDetailRadioButton" value="Error" checked={filterChecked.includes("Error")} onChange={onFilterChanged} />
                            <Form.Check id="ContentPlaceHolder1_RadioButtonJobDetailFilter_1" label="Warning" name="JobDetailRadioButton" value="Warning" checked={filterChecked.includes("Warning")} onChange={onFilterChanged} />
                            <Form.Check id="ContentPlaceHolder1_RadioButtonJobDetailFilter_2" label="Success" name="JobDetailRadioButton" value="Success" checked={filterChecked.includes("Success")} onChange={onFilterChanged} />
                            <Form.Check id="ContentPlaceHolder1_RadioButtonJobDetailFilter_0" label="All" name="JobDetailRadioButton" value="All" checked={filterChecked.includes("All")} onChange={onFilterChanged} />

                        </Form.Group>
                        <span>
                        <a id="ContentPlaceHolder1_btnDownload" title="download" onClick={() => {
                            if (gridApi) {
                                gridApi.exportDataAsCsv();

                            }
                        }}>
                                    <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20" viewBox="0 0 512 512" className="mb-1 me-2">
                                        <path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z">
                                        </path>
                                    </svg> </a>
                            <a id="ContentPlaceHolder1_btnClear" style={{ color: "#007bff", cursor: 'pointer', height: "25", width: "25" }} className="me-1" onClick={(e) => {
                                if (gridApi) {
                                    gridApi.setFilterModel(null);
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="23" stroke="#007bff" fill= "#007bff" viewBox="0 0 32 32" width="23" style={{ cursor: 'pointer' }}>
                                    <path strokeWidth="1.5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>
                                    <path strokeWidth="1.5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>
                                </svg>
                            </a>
                            {!props.isAzureGuardianOnly && props.activeTab === "365" && <HelpModal about="Introduction.html" />}
                        </span>
                    </div>
                </div>
            </div>
            
            <div className="card-body p-0">
                <div id="ContentPlaceHolder1_UpdatePanel">
                    <div id="ContentPlaceHolder1_LogViewPanel" style={{  width: "100%",height:'263px' }}>
                        {(props.jobLogUrl.length > 0) ?
                            (
                                <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: "263px", borderCollapse: "collapse" }} >                                    
                                    <div style={{ width: "100%", height: "257px"}}>
                                        <AgGridReact style={{ width: "100%" }}
                                            rowData={sessionLogs}
                                            getRowStyle={getRowStyle}
                                            onGridReady={onGridReady}
                                            defaultColDef={defaultColDef}
                                            onFirstDataRendered={onFirstDataRendered}
                                            rowHeight={rowHeight}
                                            rowBuffer={500} 
                                            pagination={true}
                                            paginationPageSize={50}
                                            headerHeight={headerHeight}
                                            enableCellTextSelection="true"
                                            ensureDomOrder="true"
                                            overlayLoadingTemplate={
                                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`
                                            }
                                            overlayNoRowsTemplate={
                                                '<span className="ag-overlay-loading-center">No error records to show</span>'
                                            }
                                            suppressScrollOnNewData={true}
                                            columnDefs={columnDefs} >
                                        </AgGridReact>

                                    </div>

                                </div>

                            )
                            :
                            <div></div>}
                    </div>
                </div>
            </div>
        </div>


    );
}

export default JobDetail;