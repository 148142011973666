import React, { Component } from "react";
import { Button } from "reactstrap";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AddBankAccount } from "./AddBankAccount";

export class ViewBankAccount extends Component {
  constructor(props) {
      super(props);
      const {  orgId } = this.props;
    this.state = {
      bankAccount: null,
      fetchedBankAccount: false,
      adding: false,
        loading: false,
        orgId: orgId
    };
    this.getBankAccount = this.getBankAccount.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount(){
    this.getBankAccount();
  }
  
    async getBankAccount() {
   
    var token = await this.props.loginAuthProvider.getAccessToken();
    this.setState({
      loading: true
    },()=>{
      var tokenString = token.accessToken;
      const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
          CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
      };
        Axios.get(API_ROUTE + Routes.GET_BANK_ACCOUNT + "/" + this.state.orgId, config)
    .then((response) => {
      let responseData = response.data;
      if(!response.data){
        this.setState({
          hasBankAccount: false,
          adding: true,
          fetchedBankAccount: true,
          loading: false
        });
      } else {
        this.setState({
          bankAccount: responseData,
          fetchedBankAccount: true,
          loading: false
        });

      }  
    })
    .catch((reason) => {
      this.setState({
        fetchedBankAccount: true,
        loading: false
      });
    });
    });
  }

  async delete(event){
    event.preventDefault();
    if(!this.state.loading){
      var token = await this.props.loginAuthProvider.getAccessToken();
      this.setState({
        loading: false
      },()=>{
        var tokenString = token.accessToken;
        const config = {
            headers: { Authorisation: `Bearer ` + tokenString },
            CloudCoverToken: sessionStorage.getItem('CloudCoverToken'),
        };
          Axios.get(API_ROUTE + Routes.DELETE_BANK_ACCOUNT + "/" + this.state.orgId, config)
            .then((response) => {
                this.setState({
                    bankAccount: null,
                    fetchedBankAccount: true,
                    adding: true,
                    loading: false
                });  
        })
        .catch((reason) => {
          this.setState({
            loading: false,
            adding: false
          });
        });
      });
    }
  }

  render() {
    if(this.state.fetchedBankAccount){
      if(this.state.bankAccount){
        return (
          <React.Fragment>
            <p>This is the current mandate we have for you. If you want to change it, please click delete to create a new one.</p>
            <table style={{border: "none", textAlign: "center"}}>
              <tr><td><b>Account Number Ending:</b></td>{this.state.bankAccount.accNumberEnding}<td></td></tr>
              <tr><td><b>Bank Name:</b></td><td>{this.state.bankAccount.bankName}</td></tr>
              <tr><td><b>Country Code:</b></td><td>{this.state.bankAccount.countryCode}</td></tr>
              <tr><td><b>Currency:</b></td><td>{this.state.bankAccount.currency}</td></tr>
            </table>
            <Button onClick={this.delete} color="danger">Delete</Button>
          </React.Fragment>
      );
      } else if(this.state.adding) {
        return (
          <React.Fragment>
                <AddBankAccount reloadFunction={this.getBankAccount} orgId={ this.state.orgId} />
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <p>Error</p>
          </React.Fragment>
        );
      }

    } else {
      return (
        <React.Fragment>
          <p>Loading</p>
        </React.Fragment>
      );
    }      
  }
}
