import React, { Component } from "react";
import { loginAuthProvider } from "../LoginAuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes, LEGACY_SITE } from "../Helpers/Constants";
import { Button } from "reactstrap";
import AuthenticationContext from "../Store/Authentication-Context";
import {  Spinner } from 'react-bootstrap';
export class RegCheck extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            autoLogin: props.autoLogin,
            checked: false,
            isRegistered: false,
            connectionError: false,
            errorMessage: "",
            processLoginErrorMessage: "",
            loggingOff: false,
            loginInProgress:false
        };
        this.regCheck = this.regCheck.bind(this);
        this.loginContinue = this.loginContinue.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.regCheck();
    }
    async processLogin() {

        var token = await loginAuthProvider.getAccessToken();
        var tokenString = token.accessToken;
        var accountInfo = loginAuthProvider.getAccountInfo();
        var email = accountInfo.account.userName;
        var name = accountInfo.account.name;
        const config = {
            headers: { Authorisation: `Bearer ` + tokenString },
        };
        const inputData = {
           Url: window.location.origin,
            ServerPath: "",
            ApiVersion: "44c6d527-a263-40ae-a9eb-f0a05b93f083",
          EmailAddress: email,
            
        };
        await Axios.post(API_ROUTE + Routes.GET_PROCESS_LOGIN, inputData, config)
            .then((response) => {
                
                if (response && response.data && response.data.result)
                {
                    if (response.data.result.errorMessage) {
                        this.setState(prevState => ({
                            processLoginErrorMessage: response.data.result.errorMessage
                        }));
                        return;
                    } 
                    //setcontextdata
                    sessionStorage.setItem('DomainId', response.data.result.domainId);
                    sessionStorage.setItem('ResellerRole', response.data.result.resellerRole);
                    sessionStorage.setItem('UserType', response.data.result.userType);
                    sessionStorage.setItem('ServerPath', response.data.result.serverPath);
                    sessionStorage.setItem('UserName', response.data.result.userName);
                    sessionStorage.setItem('ResellerID', response.data.result.resellerID);
                    sessionStorage.setItem('orgId', response.data.result.loginOrgID);
                    sessionStorage.setItem('orgName', response.data.result.loginOrgName);
                    sessionStorage.setItem('ManageOrgId', response.data.result.navigationOrgId);
                    sessionStorage.setItem('ManageOrgName', response.data.result.navigationOrgName);
                    sessionStorage.setItem('IsAdministrator', response.data.result.isAdministrator);
                    sessionStorage.setItem('AppID', response.data.result.appID);
                    //localStorage.setItem('parentID', response.data.result.parentID);
                    //localStorage.setItem('Url', response.data.result.url);
                    sessionStorage.setItem('TenantName', response.data.result.tenantName);
                    sessionStorage.setItem('Photo', response.data.result.photo);
                    sessionStorage.setItem('UserDisplayName', response.data.result.userDisplayName);
                    sessionStorage.setItem('UserEmailID', response.data.result.userEmailID); 
                    //localStorage.setItem('DisabledAccess', response.data.result.disabledAccess);
                    sessionStorage.setItem('IsVeeam365Engineer', response.data.result.isVeeam365Engineer);
                    sessionStorage.setItem('IsVeeam365JobManagement', response.data.result.isVeeam365JobManagement);
                    sessionStorage.setItem('IsVeeam365Restore', response.data.result.isVeeam365Restore);
                    sessionStorage.setItem('CloudCoverToken', response.data.result.cloudCoverToken);
                    if (response.data.result.m365Enabled) {
                        sessionStorage.setItem('GuardianOnlyEnabled-' + response.data.result.loginOrgID, !(response.data.result.m365Enabled == "true"));
                    }
                    const isAdmin = JSON.parse(sessionStorage.getItem('IsAdministrator'));
                    if (!isAdmin) {
                        if (response.data.result.redirectUrl.includes("Restore"))
                        {
                            sessionStorage.setItem('IsEndUser', true);
                        }
                    }
                    window.location.href = response.data.result.redirectUrl; 
                    return;
                }
              
            })
            .catch((reason) => {
                this.setState(prevState => ({
                    loginInProgress: false
                }));
                console.log("catch Notification: " + reason);
            });
    }

    async regCheck() {
        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.REG_CHECK, config)
            .then((response) => {
                let responseData = response.data;
                if (responseData.connectionError) {
                    this.setState({
                        checked: true,
                        isRegistered: false,
                        connectionError: true,
                        errorMessage: responseData.errorMessage
                    });
                } else if (!responseData.registered) {
                    this.setState({
                        checked: true,
                        isRegistered: false,
                        connectionError: false,
                        errorMessage: responseData.errorMessage
                    });
                } else {
                    this.setState(
                        {
                            checked: true,
                            isRegistered: responseData.registered
                        }, () => {
                            if (this.state.autoLogin && responseData.registered && this.state.loggingOff ===false) {
                                this.loginContinue();
                            }
                        });
                }

            })
            .catch((reason) => {

            });
    }

    async loginContinue() {
        
        await this.props.login();
        this.processLogin();
        this.setState(prevState => ({
            loginInProgress: true
        }));
    }

    register(event) {
        event.preventDefault();
        var redirectString = "/SignUp";
        window.location = redirectString;
    }

    logout(event) {
        event.preventDefault();
        this.setState(prevState => ({
            ...prevState,
            loggingOff: true
        }));
        loginAuthProvider.logout();
    }

    render() {
        if (!this.state.checked) {
            return (
                <React.Fragment>
                    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                        Getting registration status.
                    </p>
                </React.Fragment>
            );
        } else {
            
            if (this.state.isRegistered && this.state.processLoginErrorMessage === "") {
                var accountInfo = loginAuthProvider.getAccountInfo();
                var email = accountInfo.account.userName;
                var name = accountInfo.account.name;
                return (
                    <React.Fragment>
                        <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                            You are logged in as {name} ({email}).
                        </p>
                        <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Use these credentials to login to CloudCover 365?</p>
                        <div>
                            {this.state.loginInProgress
                                ?
                                <Button color="primary" onClick={this.loginContinue} style={{ padding: '4px 33px', fontWeight: 'bold', fontSize: '13px' }}><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Button>
                                :
                                <Button color="primary" onClick={this.loginContinue} style={{ padding: '4px 26px', fontWeight: 'bold', fontSize: '13px', marginRight: "5px" }}>Log In</Button>}
                            
                            <Button color="primary" onClick={this.logout} style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px', marginLeft: "5px" }}>Log Out</Button>
                        </div>
                    </React.Fragment>
                );
            } else {
               

                if (this.state.processLoginErrorMessage !== "" && this.state.processLoginErrorMessage.includes('Your organisation does not appear to have an account')) {
                    return (
                        <React.Fragment>
                            <p>
                                It appears you aren't registered.
                            </p>
                            <div>
                                <Button color="primary" onClick={this.register} style={{ marginRight: "5px" }}>Register</Button>
                                <Button color="primary" onClick={this.logout} style={{ marginLeft: "5px" }}>Log Out</Button>
                            </div>
                        </React.Fragment>
                    );
                } 
                if (this.state.processLoginErrorMessage !== "") {
                    return (
                        <React.Fragment>
                            <p style={{ color: "red", fontSize: "small" }}>
                                {this.state.processLoginErrorMessage}
                            </p>
                            <div>
                                <Button color="primary" onClick={this.logout}>Log Out</Button>
                            </div>
                        </React.Fragment>
                    );
                } 

                if (this.state.connectionError) {
                    return (
                        <React.Fragment>
                            <p>
                                Service unavailable, please try later.
                            </p>
                            <div>
                                <Button color="primary" onClick={this.logout}>Log Out</Button>
                            </div>
                        </React.Fragment>
                    );
                } else {
                    if (this.state.errorMessage !== "" && this.state.errorMessage !== null) {
                        return (
                            <React.Fragment>
                                <p style={{ color: "red", fontSize: "small" }}>
                                    {this.state.errorMessage}
                                </p>

                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment>
                                <p>
                                    It appears you aren't registered.
                                </p>
                                <div>
                                    <Button color="primary" onClick={this.register} style={{ marginRight: "5px" }}>Register</Button>
                                    <Button color="primary" onClick={this.logout} style={{ marginLeft: "5px" }}>Log Out</Button>
                                </div>
                            </React.Fragment>
                        );
                    }
                }
            }
        }
    }
}
